import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FrontendInfoService } from '../../services/frontend-info/frontend-info.service';
import { BackendInfoService, BackendInfo } from 'colimo-ui-library';
import { Store } from '@ngrx/store';
import { ToggleSidenavCollapsedAction } from 'src/app/actions/sidenav.actions';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { Subject, takeUntil } from 'rxjs';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'colimo-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss'],
})
export class AppInfoComponent implements OnInit, OnDestroy {
  @Input() public heading: string;

  public isLoading: boolean;
  public backendInfo: BackendInfo;
  public appVersion: string;
  public appName: string;

  public isMobile: boolean;
  private stop$: Subject<boolean> = new Subject();

  constructor(private backendInfoService: BackendInfoService, private frontendInfoService: FrontendInfoService, private mobileCheck: MobileCheck, private store: Store<fromRoot.State>) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.appName = this.frontendInfoService.getFrontendName();
    this.appVersion = this.frontendInfoService.getFrontendVersion();
    this.loadBackendInfo();

    this.mobileCheck
      .getScreenSize()
      .pipe(takeUntil(this.stop$))
      .subscribe((deviceInfo) => {
        this.isMobile = deviceInfo.isMobile;
      });
  }

  private loadBackendInfo(): void {
    this.backendInfoService.getBackendInfo().subscribe((backendInfo: BackendInfo) => {
      this.backendInfo = backendInfo;
      this.isLoading = false;
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public openSidenav(): void {
    this.store.dispatch(new ToggleSidenavCollapsedAction(true));
  }
}
