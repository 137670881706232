import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderInterceptorService implements HttpInterceptor {
  constructor() {}

  public intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const enhancedRequest = this.enhanceHttpRequestAndReturn(originalRequest);

    return next.handle(enhancedRequest);
  }

  /**
   * Takes the original http request and adds http header information to it.
   *
   * @param originalRequest the original request that was intercepted by this class.
   * @return the original request including the token information.
   */
  private enhanceHttpRequestAndReturn(originalRequest: HttpRequest<any>): HttpRequest<any> {
    const enhancedRequest = originalRequest.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      },
    });
    return enhancedRequest;
  }
}
