<ng-container *ngIf="project">
  <colimo-project-header *ngIf="!!project" [project]="project" [lineId]="lineId" [modelId]="modelId"
                         (productSelectionEvent)="productSelectionChanged($event)" [showProductSelection]="true">
  </colimo-project-header>

  <colimo-modal [size]="'md'"></colimo-modal>

  <div class="batch-page-wrapper flex-column gap-15 gap-lt-sm-0">
    <div class="container">
      <h2 class="dashboard-headline">{{ 'dashboard.charge' | translate }}</h2>
    </div>
    <div *ngIf="project.batches" class="container flex-column gap-20">
      <div *ngFor="let productBatch of displayBatches">
        <colimo-project-batch
          id="{{ productBatch.batchId }}"
          [projectId]="project.id"
          [batch]="productBatch"
          [modelId]="modelId"
          [projectPage]="currentPage"
          [isColorRoute]="isColorRoute"
          [batchLineSelections]="batchLineSelections"
        >
        </colimo-project-batch>
      </div>
    </div>
    <div *ngIf="batchesLoaded && !project.batches" class="background-well project-empty">
      <div class="empty-text">{{ 'dashboard.noBatches' | translate }}</div>
    </div>
    <div class="container" *ngIf="!isLastPage">
      <button (click)="loadMore()" class="btn btn-link" [disabled]="!moreBatchesLoaded">
        {{ 'dashboard.more' | translate }}
        <colimo-icons
          [icon]="'arrow-small'"
          [width]="'18px'"
          [height]="'11px'"
          [top]="'1px'"
          [direction]="'right'"
        ></colimo-icons>
      </button>
      <colimo-loader [background]="'transparent'" [isRelative]="true" *ngIf="!moreBatchesLoaded"></colimo-loader>
    </div>
  </div>
</ng-container>

<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="!batchesLoaded"></colimo-loader>
