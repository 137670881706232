import { IDegData } from '../chart-deg.model';
import { ComponentGroups } from './component-groups.model';
import { ChartStyle, DeltaEChartViewModel } from './delta-e-chart.viewmodel';

export class DeltaEViewModel {
  public BODY: number[];
  public BUMPER: number[];
  public scaleTolerances: number[];

  private activeComponents: ComponentGroups;

  private activePage = 0;

  constructor(values?: IDegData) {
    if (values) {
      this.setValues(values);
    }
  }

  public get componentGroups(): ComponentGroups {
    return this.activeComponents;
  }

  public set componentGroups(activeComponents: ComponentGroups) {
    this.activeComponents = activeComponents;
  }

  public setValues(values: IDegData): void {
    const hasBodyFilter = !this.componentGroups || this.componentGroups.bodyOn;
    const hasBodyData = values.data && values.data.BODY;
    const hasBumperFilter = !this.componentGroups || this.componentGroups.bumperOn;
    const hasBumperData = values.data && values.data.BUMPER;
    this.scaleTolerances = values.scaleTolerance;
    this.BODY = hasBodyFilter && hasBodyData ? this.normalizeDeltaEArray(values.data.BODY) : null;
    this.BUMPER = hasBumperFilter && hasBumperData ? this.normalizeDeltaEArray(values.data.BUMPER) : null;
  }

  public get count(): number {
    if (this.BODY && this.BODY.length) {
      return Math.ceil(this.BODY.length / 10);
    }

    if (this.BUMPER && this.BUMPER.length) {
      return Math.ceil(this.BUMPER.length / 10);
    }

    return 0;
  }

  /**
   * Return a CSS object for areas left,
   * for distributing borders and pages
   */
  public get areaSectionPagesStyles(): { [key: string]: string }[] {
    if (!this.count) {
      return [];
    }

    const areaSectionPagesStyles: { [key: string]: string }[] = [];
    const parts = 100 / this.count;
    let leftValue = 0;
    let nextLeftValue: number;
    let width: number;

    for (let i = 0; i < this.count; i++) {
      leftValue = Math.floor(i * parts);
      nextLeftValue = Math.floor((i + 1) * parts);
      width = nextLeftValue - leftValue;

      areaSectionPagesStyles[i] = {
        left: leftValue + '%',
        width: width + '%',
      };
    }

    return areaSectionPagesStyles;
  }

  public get activeAreaSectionPagesStyles(): { [key: string]: string } {
    if (!this.count) {
      return {};
    }

    return this.areaSectionPagesStyles[this.active];
  }

  public set active(val: number) {
    this.activePage = val;
  }

  public get active(): number {
    return this.activePage;
  }

  public get chartConfig(): any {
    const datasets: ChartStyle[] = [];
    let labels: number[] = [];

    if (this.BODY && this.BODY.length) {
      datasets.push(
        Object.assign({}, DeltaEChartViewModel.bodyData, {
          data: this.BODY ? [...this.BODY] : null,
          backgroundColor: this.getBodyColor(),
          borderColor: this.getBodyColor(),
        }),
      );
    }

    if (this.BUMPER && this.BUMPER.length) {
      datasets.push(
        Object.assign({}, DeltaEChartViewModel.bumperData, {
          data: this.BUMPER ? [...this.BUMPER] : null,
          backgroundColor: this.getBumperColor(),
          borderColor: this.getBumperColor(),
        }),
      );
    }

    const chartOptions = Object.assign({}, DeltaEChartViewModel.chartOptions, {
      scaleTolerance: this.scaleTolerances || [],
      showToleranceLines: Boolean(this.scaleTolerances),
    });

    // fill label depending on page count
    if (this.count > 0) {
      labels = new Array(this.count * 10).fill(0);
    }

    return {
      type: 'line',
      data: {
        datasets,
        labels,
      },
      options: chartOptions,
    };
  }

  private getBodyColor(): string {
    return 'rgba(0, 48, 103, 0.3)';
  }

  private getBumperColor(): string {
    return 'rgba(0, 154, 255, 0.3)';
  }

  /**
   * normalizes curve if no measurement is given
   */
  public normalizeDeltaEArray(iterated: number[]): number[] {
    const iteratedClone: number[] = [...iterated];

    const isNumber = (val): boolean => {
      return val !== null && !isNaN(val);
    };

    let replaceValue: number = null;
    let nullCountFromBeginning = -1;

    for (let i = 0; i < iteratedClone.length; i++) {
      if (isNumber(iteratedClone[i])) {
        // save last valid value for use as null replacement
        replaceValue = iteratedClone[i];
      } else if (!isNumber(iteratedClone[i]) && isNumber(replaceValue)) {
        // use saved predecessor if null
        iteratedClone[i] = replaceValue;
      } else if (!isNumber(replaceValue)) {
        // save last null index from the beginning before valid value in array
        nullCountFromBeginning = i;
      }
    }

    if (nullCountFromBeginning > -1) {
      // use successor if null for the beginning
      replaceValue = iteratedClone[nullCountFromBeginning + 1];
      let iteratorCount = 0;

      while (nullCountFromBeginning <= 0 && iteratorCount < iteratedClone.length) {
        iteratedClone[nullCountFromBeginning] = replaceValue;
        nullCountFromBeginning--;
        iteratorCount++;
      }
    }

    return iteratedClone;
  }
}
