import { Injectable } from '@angular/core';

/**
 * Service responsible for dealing with selected angles.
 */
@Injectable({
  providedIn: 'root',
})
export class AnglesFactoryService {
  constructor() {}

  /**
   * Creates selected angles model of the same length as input angles array.
   * @param inputAngles available angles e.g. [15, 25, 75, 110]
   * @param anglesSelected angles that were selected previously, e.g. [false, false, true, true, false]
   * @returns the updated selection of angles due to given input, e.g. [true, true, true, true]
   */
  public createSelectedAnglesArrayFromInput(inputAngles: number[], anglesSelected: boolean[]): boolean[] {
    if (!(inputAngles && inputAngles.length)) {
      return [];
    }
    // same number of angles as the already selected ones, so angles did not change
    if (inputAngles.length === anglesSelected.length) {
      anglesSelected = this.handleSameAngles(inputAngles, anglesSelected);
    } else {
      anglesSelected = this.handleDifferentAngles(inputAngles, anglesSelected);
    }
    return anglesSelected;
  }

  /**
   * Handles the behavior for same angles. The old value will be preserved if it already exists.
   * This is useful when other filters have changed that do not have effect on the angles.
   * So the angles selection will not be reset.
   */
  private handleSameAngles(inputAngles: number[], anglesSelected: boolean[]): boolean[] {
    inputAngles.forEach((angle: number, index: number) => {
      // Preserve old value if it already existed
      if (anglesSelected[index] === undefined) {
        anglesSelected[index] = true;
      }
    });
    return anglesSelected;
  }

  /**
   * Handles the behavior for different angles. Per default every angle will be selected.
   */
  private handleDifferentAngles(inputAngles: number[], anglesSelected: boolean[]): boolean[] {
    inputAngles.forEach((angle: number, index: number) => (anglesSelected[index] = true));
    // Remove old and not needed items
    if (anglesSelected.length > inputAngles.length) {
      anglesSelected.splice(inputAngles.length);
    }
    return anglesSelected;
  }
}
