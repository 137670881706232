<mat-card appearance="outlined" class="flex-row-lt-sm-column gap-15 gap-lt-sm-5 padding-16">
  <div class="flex-column justify-center flex-16 flex-lt-sm-start flex-lt-sm-50">
    <h4 class="batch-number">{{ batch.batchNumber }}
      <mat-icon *ngIf="batch.batchComments?.length > 0"
      class="batch-comment-circle"
      [matTooltip]="batchCommentTooltip"
      matTooltipPosition="above"
      matTooltipClass="multiline-tooltip">circle</mat-icon>
    </h4>
    <div class="flex-row">
      <div *ngIf="batch.expirationDate" class="expiration-date">
        {{'dashboard.expDate' | translate}}
      </div>
      <div *ngIf="batch.expirationDate" [ngClass]="!isExpired ? 'expiration-date' : 'expiration-date expired'">
        {{batch.expirationDate | date: 'dd.MM.y'}}
      </div>
    </div>
    <div class="flex-row flex-lt-sm-row gap-lt-sm-4 gap-8 flex-lt-md-column">
      <span class="product-number">{{ batch.productName }}</span>
      <span
        *ngIf="batch.productType !== 'BODY_BUMPER'"
        class="product-type"
        [ngClass]="{ 'is-bumper': batch.productType === 'BUMPER', 'is-body': batch.productType === 'BODY' }"
      >
        {{ batch.productType }}
      </span>
      <div *ngIf="batch.productType === 'BODY_BUMPER'">
        <span class="product-type is-body">{{ 'dashboard.body' | translate }}</span>
        <span class="product-type is-bumper">{{ 'dashboard.bumper' | translate }}</span>
      </div>
    </div>
    <div
      *ngIf="!!batch.batchState && batch.batchState !== 'COMPLETED' && batch.batchState !== 'ON_HOLD'"
      class="project-state-item"
    >
      <span *ngIf="batch.batchState === 'IN_LINE'">
        {{ 'dashboard.recentLine' | translate }}
      </span>
      <span *ngIf="batch.batchState === 'IN_EXAMINATION'">
        {{ 'dashboard.recentAudit' | translate }}
      </span>
    </div>
  </div>

  <div Class="flex-column flex-33 flex-lt-sm-100">
    <colimo-accordion-wrapper
      *ngIf="batch.forecastAvailable"
      [headerTitle]="'dashboard.colorCareInfoHeadline' | translate"
      [showOnlyOnMobile]="true"
    >
      <div *ngIf="!isMobile">
        <div class="batch-info colorCare" [ngClass]="'gap'">
          <p class="batch-info-label">ColorCare {{ forecastMeasurementResult }}</p>
        </div>
        <div *ngIf="batch.forecastMeasurements">
          <div class="form-field-group">
            <button
              class="refresh-button"
              mat-icon-button
              *ngIf="batch.forecastMeasurements"
              matTooltip="{{ 'dashboard.updateForecastMeasurements' | translate }}"
              [matTooltipPosition]="'above'"
              [disabled]="isForecastMeasurementLoading"
              (click)="updateForecastMeasurementsByBatchId()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field appearance="fill">
              <mat-label class="select-label">{{ 'dashboard.selectForecastMeasurement' | translate }}</mat-label>
              <mat-select
                name="selectedForecastMeasurement_{{ batch.batchId }}"
                [(ngModel)]="selectedForecastMeasurement"
                [disabled]="isForecastMeasurementLoading"
                id="forecastmeasurement_{{ batch.batchId }}"
              >
                <mat-option *ngFor="let option of batch.forecastMeasurements" [value]="option">{{
                  option.info
                }}</mat-option>
              </mat-select>
              <button
                *ngIf="selectedForecastMeasurement"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="selectedForecastMeasurement = undefined; $event.stopPropagation()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="isMobile" class="flex-column">
        <div class="batch-info">
          <p class="batch-info-label">ColorCare {{ forecastMeasurementResult }}</p>
        </div>
        <div *ngIf="batch.forecastMeasurements">
          <div class="form-field-group">
            <button
              class="refresh-button"
              mat-icon-button
              *ngIf="batch.forecastMeasurements"
              matTooltip="{{ 'dashboard.updateForecastMeasurements' | translate }}"
              [matTooltipPosition]="'above'"
              [disabled]="isForecastMeasurementLoading"
              (click)="updateForecastMeasurementsByBatchId()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field appearance="fill">
              <mat-label class="select-label">{{ 'dashboard.selectForecastMeasurement' | translate }}</mat-label>
              <mat-select
                name="selectedForecastMeasurement_{{ batch.batchId }}"
                [(ngModel)]="selectedForecastMeasurement"
                [disabled]="isForecastMeasurementLoading"
                id="forecastmeasurement_{{ batch.batchId }}"
              >
                <mat-option *ngFor="let option of batch.forecastMeasurements" [value]="option">{{
                  option.info
                }}</mat-option>
              </mat-select>
              <button
                *ngIf="selectedForecastMeasurement"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="selectedForecastMeasurement = undefined; $event.stopPropagation()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </colimo-accordion-wrapper>

    <colimo-accordion-wrapper
      *ngIf="!batch.forecastAvailable"
      [headerTitle]="'dashboard.dateInfoHeadline' | translate"
      [showOnlyOnMobile]="true"
    >
      <div class="flex-row margin-bottom">
        <div *ngIf="!!batch.approvalDate" class="flex-50">
          <p class="batch-info-label">{{ 'dashboard.release' | translate }}</p>
          <p class="batch-info-text">{{ batch.approvalDate | date: 'dd.MM.y' }}</p>
        </div>
        <div *ngIf="!!batch.examinationStartDate" class="flex-50">
          <p class="batch-info-label">{{ 'dashboard.completionTinting' | translate }}</p>
          <p class="batch-info-text">{{ batch.examinationStartDate | date: 'dd.MM.y' }}</p>
        </div>
      </div>
      <div
        *ngIf="batch.kpiLines"
        [ngClass]="!(batch?.approvalDate || batch?.examinationStartDate) ? 'added-top-margin' : 'no-gap'"
      >
        <div *ngFor="let line of batch.kpiLines; let i = index; let lastItem = last" class="aligned-divider padding-top-bottom">
          <div *ngIf="isMobile && (line.dockingDate || line.lastMeasurementDate)">
            <div>
              <p class="line-label">{{ 'dashboard.line' | translate }} {{ line | lineName }}</p>
            </div>
          </div>
          <div class="flex-row">
            <div *ngIf="!!line.dockingDate" class="flex-50">
              <p *ngIf="!isMobile" class="batch-info-label" [ngClass]="i === 0 ? 'first' : 'not-first'">
                {{ 'dashboard.dockedLine' | translate }}
              </p>
              <p *ngIf="isMobile" class="batch-info-label">{{ 'dashboard.dockedLine' | translate }}</p>
              <p class="batch-info-text">{{ line.dockingDate | date: 'dd.MM.y' }}</p>
            </div>
            <div *ngIf="!!line.lastMeasurementDate" class="flex-50">
              <p *ngIf="!isMobile" class="batch-info-label" [ngClass]="i === 0 ? 'first' : 'not-first'">
                {{ 'dashboard.lastMeasurement' | translate }}
              </p>
              <p *ngIf="isMobile" class="batch-info-label">{{ 'dashboard.lastMeasurement' | translate }}</p>
              <p class="batch-info-text">{{ line.lastMeasurementDate | date: 'dd.MM.y' }}</p>
            </div>
          </div>
          <mat-divider *ngIf="!lastItem"></mat-divider>
        </div>
      </div>
    </colimo-accordion-wrapper>
  </div>

  <div
    class="flex-column flex-51 flex-lt-sm-100"
    [ngClass]="
      (batch?.approvalDate || batch?.examinationStartDate) && batch.kpiLines?.length && !isMobile
        ? 'with-gap'
        : 'no-gap'
    "
  >
    <colimo-accordion-wrapper
      [headerTitle]="'KPIs'"
      [showOnlyOnMobile]="true"
      [openOnStart]="true"
      *ngIf="batch.kpiLines.length || batch.cpiForecast || batch.forecastAvailable"
    >
      <div *ngIf="!batch.forecastAvailable && batch.kpiLines">
        <div
          *ngFor="let kpiLine of batch.kpiLines; let i = index; let lastItem = last"
          [ngClass]="batch?.approvalDate || batch?.examinationStartDate ? 'kpi-line' : 'kpi-line more-margin'"
        >
          <colimo-kpi-line
            [forecastAvailable]="batch.forecastAvailable"
            [cpiForecast]="batch.cpiForecast"
            [kpiLine]="kpiLine"
            [batchId]="batch.batchId"
            [projectId]="projectId"
            [modelId]="modelId"
            [projectPage]="projectPage"
            [batchState]="batch.batchState"
            [isColorRoute]="isColorRoute"
            [batchLineSelections]="batchLineSelections"
          >
          </colimo-kpi-line>
          <mat-divider *ngIf="!lastItem"></mat-divider>
        </div>
      </div>
      <colimo-forecast
        *ngIf="batch.forecastAvailable && batch.referenceBatches"
        [referenceBatches]="batch.referenceBatches"
        [batchId]="batch.batchId"
        [selectedForecastMeasurement]="selectedForecastMeasurement"
        (eventForecastInit)="forecastInit($event)"
      >
      </colimo-forecast>
    </colimo-accordion-wrapper>
  </div>
</mat-card>
