<mat-card appearance="outlined" class="color-card flex-lt-sm-100 box-shadow" style="display: flex; flex-direction: column">
  <colimo-color
    [gradient]="project.gradient"
    [hasWarning]="project.warning"
    [imgJpeg]="project.imgJpeg"
    [alerts]="project.userAlertData"
    class="flex-column-1 flex-column-lt-sm-35"
  ></colimo-color>
  <div class="flex-column-1 flex-column-lt-sm-65">
    <span class="color-name" [ngClass]="isMobile === true ? 'mobile-color-name' : ''">
      {{ project.name }}
    </span>
    <div class="flex-row gap-30">
      <div class="flex-50"></div>
      <p class="flex-50 recent-details" [ngClass]="isMobile === true ? 'mobile-font-size' : ''">
        {{ 'dashboard.recentLine' | translate }}
      </p>
    </div>
    <div class="flex-row gap-50 detail-box">
      <div class="flex-column flex-50">
        <div *ngFor="let product of project.products | alignedProducts">
          <span
            *ngIf="product.type !== 'BODY_BUMPER'"
            class="flex-50 product-name recent-details" [ngClass]="isMobile === true ? 'mobile-font-size' : ''"
            [matTooltipDisabled]="!product.type"
            matTooltipPosition="above"
            matTooltipClass="product-tooltip"
            [matTooltip]="product.type"
          >
            {{ product.name }} <br />
          </span>
          <div
            *ngIf="product.type === 'BODY_BUMPER'"
            class="product-name"
            [ngClass]="isMobile === true ? 'mobile-font-size' : ''"
            [matTooltipDisabled]="!product.type"
            matTooltipPosition="above"
            matTooltipClass="product-tooltip"
            matTooltip="{{ 'dashboard.body' | translate }} {{ 'dashboard.bumper' | translate }}"
          >
            <span>{{ product.name }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="project.products | productBatches as productBatches" class="flex-50">
        <colimo-batch-list-with-tooltip
          [batches]="productBatches | state: 'IN_LINE':(project.products | alignedProducts)"
        ></colimo-batch-list-with-tooltip>
      </div>
    </div>
  </div>
</mat-card>
