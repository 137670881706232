import { Action } from '@ngrx/store';
import { type } from '../util';

/**
 * Action for changing the active dashboard
 */

export const ACTION_TYPES = {
  SET_ACTIVE_DASHBOARD: type<'[String] Set Active Dashboard'>('[String] Set Active Dashboard'),
};

export class UpdateActiveDashboardAction implements Action {
  public readonly type = ACTION_TYPES.SET_ACTIVE_DASHBOARD;
  constructor(public readonly payload: string) {}
}

export type Actions = UpdateActiveDashboardAction;
