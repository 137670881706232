import * as ActiveDashboardActions from '../actions/active-dashboard.actions';

const initialState: string = '';

/**
 * Reducer for adding and modifying the active dashboard information
 */
export function reducer(state: string = initialState, action: ActiveDashboardActions.Actions): string {
  switch (action.type) {
    case ActiveDashboardActions.ACTION_TYPES.SET_ACTIVE_DASHBOARD: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
