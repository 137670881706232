<div class="chart-pagination">
  <div class="area-sections">
    <div class="area-section" (click)="onAreaSectionClick($event, i)" [ngStyle]="areaSectionPageStyle"
         *ngFor="let areaSectionPageStyle of deltaEViewModel.areaSectionPagesStyles; let i = index;">
    </div>
    <span class="active-section" [ngStyle]="deltaEViewModel.activeAreaSectionPagesStyles"></span>
  </div>
  <div class="area-section-pages">
    <a href="#" class="area-section-page" (click)="onAreaSectionClick($event, i)"
      [ngStyle]="areaSectionPageStyle"
      *ngFor="let areaSectionPageStyle of deltaEViewModel.areaSectionPagesStyles; let i = index;">
      <span [ngClass]="{'is-active': deltaEViewModel.active === i}">{{ 'pagination.page' | translate }} {{i + 1}}</span>
      <!--<span [ngClass]="{'is-active': deltaEViewModel.active === i}">{{ 'pagination.page' | translate }} {{i + 1}} - {{ iFdgData?.pageEndDates[i] }}</span>-->
      <div *ngIf="iFdgData | areTinterAdditionsOnPage : tinterAdditions : i">
        <mat-icon class="is-solid" size="10">circle</mat-icon>
      </div>
      <div *ngIf="pageDockingDateColors && pageDockingDateColors[i] && pageDockingDateColors[i].length > 0" class="flex-row">
          <div *ngFor="let pageDockingDateColor of pageDockingDateColors[i]">
              <mat-icon class="docking-indicator" size="10" [ngStyle]="{'color': pageDockingDateColor.color }">square</mat-icon>
          </div>
      </div>
    </a>
  </div>

  <canvas height="50" #chartCanvasDEG class="chart-canvas"></canvas>

</div>
