import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentProfile } from 'src/app/models/environment-profile.enum';
import { Theme } from 'src/app/shared/enums/theme.enum';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private selectedTheme = '';
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public getSelectedTheme(): string {
    return this.selectedTheme;
  }

  public loadTheme(): void {
    const head = this.document.getElementsByTagName('head')[0];
    let themeBundleName: Theme;

    switch (environment.profile) {
      case EnvironmentProfile.DEVELOPMENT: {
        themeBundleName = Theme.ORANGE;
        break;
      }
      case EnvironmentProfile.QUAL: {
        themeBundleName = Theme.RED;
        break;
      }
      case EnvironmentProfile.PRODUCTION: {
        themeBundleName = Theme.BLUE;
        break;
      }
      default: {
        themeBundleName = Theme.BLUE;
      }
    }
    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = `${themeBundleName}`;

    head.appendChild(style);
    this.selectedTheme = themeBundleName;
  }
}
