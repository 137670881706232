import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Transforms a Date into the same format like it is used in the date picker.
 * Format depends on the locale that is used.
 */
@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private localeService: BsLocaleService, private datePipe: DatePipe) {}

  public transform(date: Date): Observable<string> {
    return this.localeService.localeChange.pipe(map((locale: string) => this.getFormattedDateForLocale(date, locale)));
  }

  private getFormattedDateForLocale(date: Date, locale: string): string {
    switch (locale) {
      case 'de':
        return this.datePipe.transform(date, 'dd.MM.yyyy');
      case 'en':
        return this.datePipe.transform(date, 'MM/dd/yyyy');
      case 'es':
        return this.datePipe.transform(date, 'dd/MM/yyyy');
      default:
        return null;
    }
  }
}
