import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlienCheckOptions } from '../../../models/alien-check-options.model';
import { Color } from '../../../models/color.model';
import { KeyText } from '../../../models/key-text.model';
import { CarService } from '../../../services/car/car.service';
import { ColorService } from '../../../services/color/color.service';
import { KeyTextService } from '../../../services/key-text/key-text.service';

@Component({
  selector: 'colimo-alien-check-filter',
  templateUrl: './alien-check-filter.component.html',
  styleUrls: ['./alien-check-filter.component.scss'],
})
export class AlienCheckFilterComponent implements OnInit {
  public tolerancesOn: boolean;
  public locations: KeyText[];
  public carTypes: KeyText[];
  public colors: Color[];
  public measurementFileName: string;
  public isLoading: boolean;

  @Input() public alienCheckOptions: AlienCheckOptions;

  @Output() public alienCheck: EventEmitter<AlienCheckOptions> = new EventEmitter();

  constructor(
    private keyTextService: KeyTextService,
    private colorService: ColorService,
    private carService: CarService,
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.tolerancesOn = this.alienCheckOptions.tolerancesOn;
    this.keyTextService.getLocations().subscribe((locations: KeyText[]) => {
      this.locations = locations.sort((a: KeyText, b: KeyText) => a.text.localeCompare(b.text));
      if (this.locations && this.locations.length !== 0) {
        this.onLocationChanged(this.locations[0]);
      }
      this.isLoading = false;
    });
  }

  public onLocationChanged(changedLocation: KeyText): void {
    this.alienCheckOptions.location = changedLocation;
    this.loadCarTypesForSelectedLocation();
    this.loadColorsForSelectedLocation();
  }

  public onCarTypeChanged(changedCarType: KeyText): void {
    this.alienCheckOptions.carType = changedCarType;
  }

  public onColorChanged(changedColor: Color): void {
    this.alienCheckOptions.color = changedColor;
  }

  public readLatestSelectedFile(event: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        this.measurementFileName = file.name;
        this.alienCheckOptions.measurementFile = reader.result;
      };
    }
  }

  public toggleToleranceSystem(enable: boolean): void {
    this.tolerancesOn = enable;
  }

  public areOptionsValidToSubmit(): boolean {
    return this.carService.areAlienCheckOptionsValid(this.alienCheckOptions);
  }

  public performAlienCheck(): void {
    this.alienCheckOptions.tolerancesOn = this.tolerancesOn;
    this.alienCheck.emit(this.alienCheckOptions);
  }

  private loadCarTypesForSelectedLocation(): void {
    this.keyTextService.getCarTypes(this.alienCheckOptions.location).subscribe((carTypes: KeyText[]) => {
      this.carTypes = carTypes.sort((a: KeyText, b: KeyText) => a.text.localeCompare(b.text));
      this.onCarTypeChanged(this.carTypes[0]);
    });
  }

  private loadColorsForSelectedLocation(): void {
    this.colorService.getColorsByLocation(this.alienCheckOptions.location).subscribe((colors: Color[]) => {
      this.colors = colors.sort((a: Color, b: Color) => a.colorName.localeCompare(b.colorName));
      this.onColorChanged(this.colors[0]);
    });
  }
}
