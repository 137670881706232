import { Pipe, PipeTransform } from '@angular/core';
import { IBatch } from 'src/app/models/batch.model';
import { Iproduct } from 'src/app/models/product.model';
import { ProductBatchesPipe } from '../product-batches/product-batches.pipe';

@Pipe({
  name: 'alignedProducts',
})
export class AlignedProductsPipe implements PipeTransform {
  constructor(private productBatchesPipe: ProductBatchesPipe) {}
  transform(products: Iproduct[]): Iproduct[] {
    const alignedProducts: Iproduct[] = [];
    if (products && products.length > 0) {
      // Get the list of batches that will be displayed after they are filtered
      const correspondingBatches: IBatch[] = this.productBatchesPipe.transform(products).filter((batch: IBatch) => {
        return batch.state === 'IN_LINE';
      });
      products.forEach((product: Iproduct) => {
        // Get count of batches with the same product names
        const matchCount = correspondingBatches.filter((batch: IBatch) => {
          return batch.productName === product.name;
        }).length;
        alignedProducts.push(product);
        if (matchCount > 1) {
          // Insert an empty product for each additional batch so that the products and batches align
          for (let index = 0; index < matchCount - 1; index++) {
            alignedProducts.push({ name: '', type: '' });
          }
        }
      });
    }
    return alignedProducts;
  }
}
