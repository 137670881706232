import { Component, Input, OnInit } from '@angular/core';
import { BackendInfo } from 'colimo-ui-library';

/**
 * Component responsible for displaying important information about the backend.
 */
@Component({
  selector: 'colimo-backend-info',
  templateUrl: './backend-info.component.html',
  styleUrls: ['./backend-info.component.scss'],
})
export class BackendInfoComponent implements OnInit {
  @Input() public backendInfo: BackendInfo;

  constructor() {}

  public ngOnInit(): void {}
}
