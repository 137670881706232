import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TrendAnalysisResult } from 'src/app/models/trend-analysis-result.model';
import { TrendIcon } from 'src/app/models/trend-icon';
import { TrendAnalysisService } from 'src/app/services/trend-analysis/trend-analysis.service';
import { environment } from 'src/environments/environment';
import * as ModalActions from '../../actions/modal.actions';
import { IKpiLine, IKpis } from '../../models/kpi.model';
import * as fromRoot from '../../reducers';
import { Kpis } from '../../shared/enums/kpis.enum';
import { TrendAnalysisResultComponent } from './trend-analysis-result/trend-analysis-result.component';

/**
 * Represents a block of KPI values.
 * Communicates with the SelectedKpiService to update its value on click on KPI.
 */
@Component({
  selector: 'colimo-kpi',
  styleUrls: ['kpi.component.scss'],
  templateUrl: 'kpi.component.html',
})
export class KpiComponent implements OnInit {
  @Input() public kpis: IKpis;
  @Input() public batchId: number;
  @Input() public kpiLine: IKpiLine;
  @Input() public isForecast = false;
  @Input() public batchState: string;

  public kpiEnum = Kpis;
  public trendAnalysisResult: TrendAnalysisResult;
  public forecastAvailable: boolean;
  public weeklyTrendThreshold: number;
  public trendIcon: TrendIcon;

  private trendIconUp: TrendIcon = { icon: "trending_up", cssClass: "up-arrow" };
  private trendIconFlat: TrendIcon = { icon: "trending_flat", cssClass: "flat-arrow" };
  private trendIconDown: TrendIcon = { icon: "trending_down", cssClass: "down-arrow" };

  constructor(
    public trendAnalysisDialog: MatDialog,
    private store: Store<fromRoot.State>,
    private trendAnalysisService: TrendAnalysisService,
  ) {
    this.weeklyTrendThreshold = environment.trendAnalysisWeeklyThreshold;
  }

  public ngOnInit(): void {
    // only call trend prediction if state is IN_LINE and it is not the average line (lineNumber === '*')
    if (this.batchState === 'IN_LINE' && this.batchId && this.kpiLine && this.kpiLine.lineNumber && !(this.kpiLine.lineNumber === '*') && environment.showTrendAnalysisResults) {
      this.trendAnalysisService
        .getTrendAnalysisResult(this.batchId, this.kpiLine.lineNumber, 'CPI')
        .subscribe((trendAnalysisResult: TrendAnalysisResult) => {
          this.trendAnalysisResult = trendAnalysisResult;
          this.forecastAvailable =
            trendAnalysisResult &&
            trendAnalysisResult.prediction &&
            trendAnalysisResult.prediction.weeklyTrend !== null &&
            trendAnalysisResult.prediction.weeklyTrend !== undefined;
          if (this.forecastAvailable) {
            this.trendIcon = this.evaluateTrendIcon(trendAnalysisResult.prediction.weeklyTrend);
          }
        });
    }
    if (!environment.showTrendAnalysisResults) {
      this.forecastAvailable = false;
    }
  }

  /**
   * Set selected KPI value in store
   */
  public kpiSelected(kpi: Kpis): void {
    this.store.dispatch(
      new ModalActions.SetModal({
        open: true,
        tab: kpi,
      }),
    );
  }

  public openTrendAnalysisDialog(): void {
    this.trendAnalysisDialog.open(TrendAnalysisResultComponent, {
      data: {
        batchId: this.batchId,
        kpiLine: this.kpiLine,
      },
    });
  }

  private evaluateTrendIcon(weeklyTrend: number): TrendIcon {
    if (weeklyTrend * 100 > this.weeklyTrendThreshold)
      return this.trendIconUp;

    if (weeklyTrend * 100 < this.weeklyTrendThreshold * -1)
      return this.trendIconDown;

    return this.trendIconFlat;
  }
}
