import { Component, Input, OnInit } from '@angular/core';

/**
 * Component responsible for displaying important information about the frontend.
 */
@Component({
  selector: 'colimo-frontend-info',
  templateUrl: './frontend-info.component.html',
  styleUrls: ['./frontend-info.component.scss'],
})
export class FrontendInfoComponent implements OnInit {
  @Input() public name: string;
  @Input() public version: string;

  constructor() {}

  public ngOnInit(): void {}
}
