import { Injectable } from '@angular/core';

/**
 * Service responsible for providing some general information about the frontend e.g. current version.
 */
@Injectable({
  providedIn: 'root',
})
export class FrontendInfoService {
  private frontendConfig = require('package.json');

  constructor() {}

  public getFrontendVersion(): string {
    return this.frontendConfig.version;
  }

  public getFrontendName(): string {
    return this.frontendConfig.name;
  }
}
