import * as BatchlineSelectionsActions from '../actions/batchline-selection.actions';
import { BatchlineSelection } from '../models/batchline-selection';

const initialState: BatchlineSelection = { batchId: null };

/**
 * Reducer for adding and modifying batch line selections
 */
export function reducer(
  state: BatchlineSelection = initialState,
  action: BatchlineSelectionsActions.Actions,
): BatchlineSelection {
  switch (action.type) {
    case BatchlineSelectionsActions.ACTION_TYPES.SET_SELECTION: {
      return action.payload;
    }

    default:
      return state;
  }
}