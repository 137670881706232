/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IFdgData } from 'src/app/models/chart-fdg.model';
import { ChartFilters } from 'src/app/models/chart-filters.model';
import { IFogData } from 'src/app/models/chart-fog.model';
import { ILpgData } from 'src/app/models/chart-lpg.model';
import { IMwgData } from 'src/app/models/chart-mwg.model';
import { ModelsComponents } from 'src/app/models/models-components.model';
import { Sidenav } from 'src/app/models/sidenav.model';
import { TinterAdditionsCount } from 'src/app/models/tinter-additions-count.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ColorDataService } from 'src/app/services/color-data/color-data.service';
import * as BatchHeaderActions from '../../actions/batch-header.actions';
import * as ProjectActions from '../../actions/project.actions';
import * as TinterAdditionsActions from '../../actions/tinter-additions.actions';
import { IKpiLine } from '../../models/kpi.model';
import { Iproject } from '../../models/project.model';
import { ScreenSize } from '../../models/screen-size.model';
import { TinterAdditions } from '../../models/tinter-additions.model';
import * as fromRoot from '../../reducers';
import { MobileCheck } from '../../services/mobile-check/mobile-check.service';
import { ProjectService } from '../../services/project/project.service';
import { TinterAdditionsService } from '../../services/tinter-additions/tinter-additions.service';
import { BaseComponent } from '../../shared/components/base/base.component';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { KeyTextService } from 'src/app/services/key-text/key-text.service';
import {ColimoPlotTooltipService} from '../../shared/services/colimo-plot-tooltip/colimo-plot-tooltip.service';
import { IBatchComment } from 'src/app/models/batch-comments.model';

/**
 * Represents batch page that shows batches from of a production line. Contains details that
 * can be navigated by tabs and contain different chart types
 */
@Component({
  selector: 'colimo-batch-page',
  styleUrls: ['batch-page.component.scss'],
  templateUrl: 'batch-page.component.html',
  providers: [ProjectService],
})
export class BatchPageComponent extends BaseComponent implements OnInit, OnDestroy {
  public project: Iproject;
  public headerKpiLine: IKpiLine;
  public tinterAdditionsCount: number;
  public selectedTab: number;

  public mwgActive: boolean;
  public fdgActive: boolean;
  public fogActive: boolean;
  public lpgActive: boolean;

  public showChartFilters: boolean;
  public showChartTable: boolean;

  public tabIndex: number;

  public colorCoordinates: boolean;
  public selectedAngles: boolean[];
  public bodyOn: boolean;
  public bumperOn: boolean;
  public preselectedModelId: string;
  public activeComponents: ComponentGroups;
  public componentsLegend: string[];
  public modelsComponents: ModelsComponents;
  public chartFilters: ChartFilters;
  public tolerancesOn: boolean;
  public projectPage: number;
  public chartData: IMwgData | IFdgData | IFogData | ILpgData;
  public chartColors: string[];
  public chartBorderColors: string[];
  public chartComponentColors: string[] = [];
  public chartComponentBorderColors: string[] = [];
  public chartComponentOutlierColor: string[] = [];
  public isColorRoute: boolean;
  public batchComments: IBatchComment[] = [];

  public componentTranslations: string[] = [];

  public batchCommentTooltip: string;

  public showVidChartComponent: boolean = false;

  private routeParams$: Observable<Params>;
  private headerInformation$: Observable<Iproject>;
  private tinterAdditions$: Observable<TinterAdditions>;
  private routeSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private projectService: ProjectService,
    private mobileCheck: MobileCheck,
    private tinterAdditionsService: TinterAdditionsService,
    private navigationService: NavigationService,
    private colorDataService: ColorDataService,
    private cd: ChangeDetectorRef,
    private keyTextService: KeyTextService
  ) {
    super();

    this.onProjectDataLoaded = this.onProjectDataLoaded.bind(this);
    this.onTestDataLoadError = this.onTestDataLoadError.bind(this);

    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras && currentNavigation.extras.state && currentNavigation.extras.state.selectedTab) {
      this.selectedTab = currentNavigation.extras.state.selectedTab;
    } else {
      this.selectedTab = 0;
    }
  }

  public ngOnInit(): void {
    this.tinterAdditionsCount = 0;
    this.chartColors = this.colorDataService.getChartColors();
    this.chartBorderColors = this.colorDataService.getChartBorderColors();
    this.selectedAngles = [];
    this.activeComponents = { bodyOn: true, bumperOn: true };
    // Setting componentsLegend to an empty array disables the lpg-chart presentation of the available components
    this.componentsLegend = [];

    this.routeParams$ = this.route.params;
    this.headerInformation$ = this.store.select(fromRoot.getBatchHeaderInfo);
    this.tinterAdditions$ = this.store.select(fromRoot.getTinterAdditions);
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.isColorRoute = sidenav.isColorRoute;
    });
    this.showChartFilters = true;
    this.showChartTable = true;

    this.chartFilters = {
      page: 0
    };
    // Subscribe to route changes
    this.routeParams$.pipe(takeUntil(this.stop$)).subscribe((route: Params) => {
      this.chartFilters.projectId = route.projectId;
      this.chartFilters.batchId = route.batchId;
      this.chartFilters.lineId = route.lineId;
      this.chartFilters.modelId = route.modelId;
      this.preselectedModelId = route.modelId;
      this.projectPage = route.projectPage;

      this.loadProject(this.chartFilters.projectId).pipe(take(1)).subscribe({
        next: (project: Iproject) => {
          this.onProjectDataLoaded(project);
        }, error: () => {
          this.onTestDataLoadError
        }
      });

      this.loadBatchHeader(this.chartFilters.batchId, this.chartFilters.lineId).pipe(take(1)).subscribe({
        next: (header: Iproject) => {
          this.onBatchHeaderDataLoaded(header);
        }, error: () => {
          this.onTestDataLoadError
        }
      });

      this.loadNumberOfTinterAdditions(this.chartFilters.batchId, this.chartFilters.lineId).subscribe({
        next: (tinterAdditionsCount: TinterAdditionsCount) => {
          this.onNumberOfTinterAdditionsLoaded(tinterAdditionsCount);
        }, error: () => {
          this.onTestDataLoadError;
        }
      });

    });

    this.headerInformation$.pipe(takeUntil(this.stop$)).subscribe((project: Iproject) => {
      this.project = project;
    });

    this.tinterAdditions$.pipe(takeUntil(this.stop$)).subscribe((tinterAdditions: TinterAdditions) => {
      this.tinterAdditionsCount = tinterAdditions.count;
    });

    this.mobileCheck
      .getScreenSize()
      .pipe(takeUntil(this.stop$))
      .subscribe((screenSize: ScreenSize) => {
        this.onScreenSizeChange(screenSize);
      });

    this.keyTextService.getComponentTranslationsObservable().pipe(takeUntil(this.stop$)).subscribe({
      next: (compTranslations: string[]) => {
        this.componentTranslations = compTranslations;
        ColimoPlotTooltipService.componentTranslations = this.componentTranslations;
      }, error: () => {
        //nothing
      }
    });
  }

  private getBatchCommentToolTip():string{
    if(!this.batchComments || this.batchComments.length === 0){
      return '';
    }
    let tooltip: string = '';
    for(let i = 0; i < this.batchComments.length; i++){
      tooltip += this.batchComments[i].commentTime + ':' + '\n' + this.batchComments[i].commentText + '\n';
    }
    return tooltip;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new BatchHeaderActions.RemoveHeaderInfo());
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  /**
   * Navigate to route with clicked tab that causes the according panel to activate
   * @param tab - tab event from the clicked tab
   */
  public onTabSelected(tab: MatTabChangeEvent): void {
    this.navigationService.navigateToGraphTab(
      this.chartFilters.projectId,
      this.chartFilters.batchId,
      this.chartFilters.lineId,
      this.preselectedModelId,
      tab.index,
      this.isColorRoute,
      this.projectPage,
    );
    this.selectedTab = tab.index;
    this.updateComponentLegend();
  }

  public tolerancesChanged(event: boolean): void {
    this.tolerancesOn = event;
  }

  public chartDataChanged(chartData: IMwgData | IFdgData | IFogData | ILpgData): void {
    this.chartData = chartData;
  }

  public updateSelectedAngles(updatedAngles: boolean[]): void {
    this.selectedAngles = [...updatedAngles];
    this.cd.detectChanges();
  }

  public updateActiveComponents(updatedActiveComponents: ComponentGroups): void {
    this.activeComponents = { ...updatedActiveComponents };
    this.updateComponentLegend();
  }

  public updateComponentLegend(): void {
    switch (this.selectedTab) {
      case 0:
        // mwg plot never show component colors
        this.componentsLegend = [];
        break;
      case 1:
      case 2:
        // diff and color space plot show component colors when not all body or bumper components are selected
        if (this.activeComponents.allBodySelected === false || this.activeComponents.allBumperSelected === false) {
          if (this.modelsComponents.bodyComponents && this.modelsComponents.bodyComponents.length > 0) {
            this.componentsLegend.push(...this.modelsComponents.bodyComponents);
          }
          if (this.modelsComponents.bumperComponents && this.modelsComponents.bumperComponents.length > 0) {
            this.componentsLegend.push(...this.modelsComponents.bumperComponents);
          }
        } else {
          this.componentsLegend = [];
        }
        break;

      default:
        // do nothing
        break;
    }
  }

  public updateModelsComponents(modelsComponents: ModelsComponents) {
    this.modelsComponents = { ...modelsComponents };
    this.initComponentColors();
  }

  public componentsLegendChanged(componentsLegend: string[]): void {
    this.componentsLegend = componentsLegend;
  }

  // Load the project details
  private loadProject(projectId: string): Observable<Iproject> {
    return this.projectService.getProject({
      projectId: projectId,
      lineId: this.chartFilters.lineId,
      modelId: this.chartFilters.modelId,
    });
  }

  // Load batch header Information
  private loadBatchHeader(batchID: number, lineNumber: string): Observable<Iproject> {
    return this.projectService.getBatchHeader({ batch: batchID, line: lineNumber });
  }

  private loadNumberOfTinterAdditions(batchId: number, line: string): Observable<TinterAdditionsCount> {
    return this.tinterAdditionsService.getNumberOfTinterAdditions(batchId, line);
  }

  // Dispatch project information
  private onProjectDataLoaded(project: Iproject): void {
    this.store.dispatch(new ProjectActions.CreateItemAction(project));
  }

  private onBatchHeaderDataLoaded(header: Iproject): void {
    this.batchComments = header.batchComments;
    this.batchCommentTooltip = this.getBatchCommentToolTip();
    // only display vid chart if product type BODY is present
    if (header && header.products && header.products.length > 0) {
      for (let i = 0; i < header.products.length; i++) {
        if (header.products[i] && header.products[i].type === 'BODY') {
          this.showVidChartComponent = true;
          break;
        }
      }
    }
    this.store.dispatch(new BatchHeaderActions.CreateHeaderInfo(header));
  }

  private onNumberOfTinterAdditionsLoaded(tinterAdditionsCount: TinterAdditionsCount): void {
    this.store.dispatch(new TinterAdditionsActions.CountTinterAdditionsAction(tinterAdditionsCount));
  }

  private onTestDataLoadError(error: HttpErrorResponse): void {
    this.navigationService.navigateOnHttpError(error);
  }

  private initComponentColors(): void {
    if (!this.modelsComponents) {
      return;
    }

    const allComponents: string[] = [];

    if (this.modelsComponents.bumperComponents && this.modelsComponents.bumperComponents.length > 0) {
      for (let i = 0; i < this.modelsComponents.bumperComponents.length; i++) {
        allComponents.push(this.modelsComponents.bumperComponents[i]);
      }
    }

    if (this.modelsComponents.bodyComponents && this.modelsComponents.bodyComponents.length > 0) {
      for (let j = 0; j < this.modelsComponents.bodyComponents.length; j++) {
        allComponents.push(this.modelsComponents.bodyComponents[j]);
      }
    }

    this.chartComponentColors = this.colorDataService.getChartComponentColors(allComponents);
    this.chartComponentBorderColors = this.colorDataService.getChartComponentBorderColors(allComponents);
    this.chartComponentOutlierColor = this.colorDataService.getChartComponentOutlierColors(allComponents);
  }

  private onScreenSizeChange(screenSize: ScreenSize): void {
    if (screenSize.isSmallTablet) {
      this.router.navigate(['/project/', this.chartFilters.projectId], { replaceUrl: true });
    }
  }
}
