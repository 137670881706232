import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ScreenSize } from '../../models/screen-size.model';
import { BreakPoints } from './breakpoints';

/**
 * Service to hold and provide a global screensize variables as observable.
 * Keeps track of the page beeing in tablet or phone width.
 *
 * Updated by {{AppComponent}}.
 */
@Injectable({
  providedIn: 'root',
})
export class MobileCheck {

  private screenSize$: Subject<ScreenSize> = new BehaviorSubject<ScreenSize>(null);
  private static readonly bp: BreakPoints = {
    phone: 600,
    smallTablet: 720
  };

  constructor() {
    observableFromEvent(window, 'resize').pipe(
      debounceTime(100))
      .subscribe(e => {
        const width = window.innerWidth;

        this.setScreenSize({
          isMobile: width < MobileCheck.bp.phone,
          isSmallTablet: width < MobileCheck.bp.smallTablet && width > MobileCheck.bp.phone
        } as ScreenSize);
      });
  }

  public setScreenSize(device: ScreenSize): void {
    this.screenSize$.next(device);
  }

  public getScreenSize(): Observable<ScreenSize> {
    return this.screenSize$.asObservable();
  }

  public getBreakpoints(): BreakPoints {
    return MobileCheck.bp;
  }
}
