import { Action } from '@ngrx/store';
import { ColorDashboard } from '../models/color-dashboard.model';
import { Iproject } from '../models/project.model';
import { type } from '../util';

/**
 * Actions for adding and removing dashboard and project models to/from store
 */

export const ACTION_TYPES = {
  ADD_ITEM: type<'[ColorDashboard] Add item'>('[ColorDashboard] Add item'),
  CREATE_ITEMS: type<'[ColorDashboard] Create items'>('[ColorDashboard] Create items'),
  TOGGLE: type<'[ColorDashboard] Toggle'>('[ColorDashboard] Toggle'),
};

export class CreateItemsAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_ITEMS;
  constructor(public readonly payload: ColorDashboard[]) {}
}

export class AddItemAction implements Action {
  public readonly type = ACTION_TYPES.ADD_ITEM;
  constructor(public readonly payload: Iproject) {}
}

export class ToggleAction implements Action {
  public readonly type = ACTION_TYPES.TOGGLE;
  constructor(public readonly payload: ColorDashboard) {}
}

export type Actions = AddItemAction | CreateItemsAction | ToggleAction;
