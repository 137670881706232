import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {AlertUserData} from '../../../models/alert-user-data';
import {KeyTextService} from '../../../services/key-text/key-text.service';

@Component({
  selector: 'colimo-color',
  templateUrl: 'color.component.html',
  styleUrls: ['color.component.scss'],
  })
export class ColorComponent implements OnInit {
  @Input() public gradient: { type: string; start: string; end: string };
  @Input() public hasWarning: boolean;
  @Input() public size: string;
  @Input() public removeCorner: boolean;
  @Input() public imgJpeg: string;
  @Input() public alerts: AlertUserData[];

  public sanitizer: DomSanitizer;
  public maxAlertLevel: number = 0;
  public alertToolTip: string = '';
  private componentTranslations: string[] = [];

  constructor(sanitizer: DomSanitizer,
              private readonly keyTextService: KeyTextService) {
    this.sanitizer = sanitizer;
  }

  public ngOnInit(): void {
    if (this.alerts && this.alerts.length > 0) {
      this.keyTextService.getComponentTranslationsObservable().subscribe({
        next: (compTranslations: string[]) => {
          this.componentTranslations = compTranslations;
          this.initAlertTooltip();
        }, error: () => {
          //nothing
        }
      });
    }
  }

  private initAlertTooltip(): void {
    this.alertToolTip = '';
    let maxLevel = 0;
    for (let i = 0; i < this.alerts.length; i++) {
      let infoText = this.alerts[i].info;
      if (this.alerts[i].typ === 'WP') {
        // translate component if its worst part alert
        const translatedComponent = this.componentTranslations[this.alerts[i].component] ? this.componentTranslations[this.alerts[i].component] : this.alerts[i].component;
        infoText = this.alerts[i].info.replace('{component}', translatedComponent);
      }
      this.alertToolTip += this.getAlertLevelIndicator(this.alerts[i].alertLevel) + ' ' + this.alerts[i].batchNr + ' - ' + this.alerts[i].productNr + ' - ' + this.alerts[i].line + " - " + infoText + '\n';
      if (this.alerts[i].alertLevel > maxLevel) {
        maxLevel = this.alerts[i].alertLevel;
      }
    }
    this.maxAlertLevel = maxLevel;
  }

  private getAlertLevelIndicator(level: number): string {
    if (level >= 2000) {
      // unicode for red circle
      return '\u{1F7E0}';
    } else {
      // unicode for yellow circle
      return '\u{1F7E1}';
    }
  }

  public trustedStyle(style: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
