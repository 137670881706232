import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenSize } from 'src/app/models/screen-size.model';
import { IKpiLine } from '../../models/kpi.model';
import { MobileCheck } from '../../services/mobile-check/mobile-check.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as BatchlineSelectionActions from '../../actions/batchline-selection.actions';
import { TinterAdditionsService } from '../../services/tinter-additions/tinter-additions.service';
import { TinterAdditionsCount } from '../../models/tinter-additions-count.model';
import {BatchlineSelection} from '../../models/batchline-selection';
import {MatCheckboxChange} from '@angular/material/checkbox';

/**
 * Represents a combination of a kpi-block, detail button and warnings.
 */
@Component({
  selector: 'colimo-kpi-line',
  styleUrls: ['kpi-line.component.scss'],
  templateUrl: 'kpi-line.component.html',
})
export class KpiLineComponent implements OnInit {
  @Input() public kpiLine: IKpiLine;
  @Input() public projectId: string;
  @Input() public batchId: number;
  @Input() public modelId: string;
  @Input() public forecastAvailable: boolean;
  @Input() public projectPage: number;
  @Input() public cpiForecast: {
    referenceBatch: string;
    value: number;
  };
  @Input() public batchState: string;
  @Input() public isColorRoute: boolean;
  @Input() public batchLineSelections: BatchlineSelection[];

  @Output() public reload: EventEmitter<any> = new EventEmitter();

  public isMobile: boolean;
  public hasTinterAdditions: boolean;

  constructor(
    private mobileCheck: MobileCheck,
    private router: Router,
    private store: Store<fromRoot.State>,
    private tinterAdditionsService: TinterAdditionsService,
  ) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().subscribe((deviceInfo: ScreenSize) => {
      this.isMobile = deviceInfo.isMobile;
    });
    this.hasTinterAdditions = false;
    this.loadTinterAdditionsCount();
  }

  public onReloadBtn(batchId: number): void {
    this.reload.emit({ id: batchId });
  }

  public openBatch(): void {
    let routerLink = ['/project', this.projectId];
    if (this.isColorRoute) {
      routerLink = routerLink.concat(['color']);
    }
    if (this.batchLineSelections.length <= 1) {
      // standard batch page with plots
      routerLink = routerLink.concat(['batch', this.batchId.toString(), 'line', this.kpiLine.lineNumber]);
    } else {
      // multi batch page with plots
      routerLink = routerLink.concat(['multi-batch', this.getMultiBatchParam(), 'line', this.getMultiLineParam()]);
    }
    if (this.modelId) {
      routerLink = routerLink.concat(['model', this.modelId]);
    }
    routerLink = routerLink.concat(['chart', 'mwg']);
    routerLink = routerLink.concat([this.projectPage.toString()]);
    this.router.navigate(routerLink);
    this.store.dispatch(new BatchlineSelectionActions.SetSelectionAction({ batchId: this.batchId }));
  }

  public batchChecked(event: MatCheckboxChange, batchId: number, kpiLine: IKpiLine): void {
    if (event.checked) {
      if (this.batchLineSelections.length >= 6) {
        // maximum of 6 batches should be selected
        // set checkbox to NOT checked
        event.source.checked = false;
        return;
      }
      // add to selection
      this.batchLineSelections.push({batchId: batchId, line: kpiLine.lineNumber, dockingDate: new Date(kpiLine.dockingDate)});
      this.batchLineSelections = this.batchLineSelections.sort((b, a) => b.dockingDate.getTime() - a.dockingDate.getTime());
    } else {
      // remove from selection
      const selectionToRemove: BatchlineSelection = this.batchLineSelections.find(sel => sel.batchId === batchId && sel.line === kpiLine.lineNumber);
      if (selectionToRemove) {
        this.batchLineSelections.splice(this.batchLineSelections.indexOf(selectionToRemove), 1);
      }
    }
  }

  private getMultiBatchParam(): string {
    let batches: string = '';
    for (let i = 0; i < this.batchLineSelections.length; i++) {
      batches += this.batchLineSelections[i].batchId + ','
    }
    return batches.slice(0, batches.length-1);
  }

  private getMultiLineParam(): string {
    let lines: string = '';
    for (let i = 0; i < this.batchLineSelections.length; i++) {
      lines += this.batchLineSelections[i].line + ','
    }
    return lines.slice(0, lines.length-1);
  }

  private loadTinterAdditionsCount(): void {
    // load tinteradds count for batches that are not an average line
    if (this.batchId && this.kpiLine && this.kpiLine.lineNumber && !(this.kpiLine.lineNumber === '*')) {
      this.tinterAdditionsService
        .getNumberOfTinterAdditions(this.batchId, this.kpiLine.lineNumber)
        .subscribe((tinterAdditionsCount: TinterAdditionsCount) => {
          if (tinterAdditionsCount && tinterAdditionsCount.count > 0) {
            this.hasTinterAdditions = true;
          }
        });
    }
  }
}
