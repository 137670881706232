import { Component, Input, OnInit } from '@angular/core';
import { ComponentStatus } from '../../../models/component-status.model';
import { DifferenceType } from '../../../shared/enums/difference-type.enum';

@Component({
  selector: 'colimo-alien-check-status-table',
  templateUrl: './alien-check-status-table.component.html',
  styleUrls: ['./alien-check-status-table.component.scss']
})
export class AlienCheckStatusTableComponent implements OnInit {
  @Input()
  public componentsStatus: ComponentStatus[];
  @Input()
  public tolerancesOn: boolean;

  public availableAngles: number[];
  public differenceTypeDE: DifferenceType;
  public differenceTypeDL = DifferenceType.DL;
  public differenceTypeDA = DifferenceType.DA;
  public differenceTypeDB = DifferenceType.DB;

  constructor() { }

  public ngOnInit(): void {
    this.setAvailableAngles();
    this.setDifferenceType();
  }

  private setAvailableAngles(): void {
    let listOfAngles: number[] = [];
    this.componentsStatus.forEach((componentStatus: ComponentStatus) => {
      listOfAngles = listOfAngles.concat(componentStatus.deltaValues.map(de => de.angle));
    });

    this.availableAngles = Array.from(new Set<number>(listOfAngles));
  }

  private setDifferenceType(): void {
    if (this.tolerancesOn) {
      this.differenceTypeDE = DifferenceType.S_DE;
    } else {
      this.differenceTypeDE = DifferenceType.D_DE;
    }
  }

}
