import { Injectable } from '@angular/core';
import { UnformattedBoxPlot } from 'src/app/models/chart-lpg.model';
import { ModelsComponents } from 'src/app/models/models-components.model';

@Injectable({
  providedIn: 'root',
})
export class LpgChartService {
  constructor() {}

  public getAngleComponentName(angleData: UnformattedBoxPlot[]): string {
    let angleComponent = '';
    if (angleData && angleData.length !== 0) {
      angleData.forEach((enrichedAngle: UnformattedBoxPlot) => {
        if (enrichedAngle && enrichedAngle.name) {
          angleComponent = enrichedAngle.name.match(/\[(.*?)\]/)[1];
        }
      });
    }
    return angleComponent;
  }

  public concatComponents(modelsComponents: ModelsComponents): string[] {
    let allModelsComponents = [];
    if (modelsComponents) {
      if (modelsComponents.bumperComponents && modelsComponents.bumperComponents.length !== 0) {
        allModelsComponents = allModelsComponents.concat(modelsComponents.bumperComponents);
      }
      if (modelsComponents.bodyComponents && modelsComponents.bodyComponents.length !== 0) {
        allModelsComponents = allModelsComponents.concat(modelsComponents.bodyComponents);
      }
    }
    return allModelsComponents;
  }
}
