import { Action } from '@ngrx/store';
import { PaintlineDashboard } from '../models/paintline-dashboard.model';
import { type } from '../util';

/**
 * Action for assigning paintlines to the store
 */

export const ACTION_TYPES = {
  CREATE_ITEMS: type<'[PaintlineDashboard] Create items'>('[PaintlineDashboard] Create items'),
};

export class CreateItemsAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_ITEMS;
  constructor(public readonly payload: PaintlineDashboard[]) {}
}

export type Actions = CreateItemsAction;
