import * as ForecastActions from '../actions/forecast.actions';
import { IBatchForecastMeasurement } from '../models/batch-forecast-measurement.model';

const initialState: IForecastReducer[] = [];
interface IForecastReducer {
  batchId: number;
  referenceBatch: string;
  value: number;
  reference: {
    id: number;
    batchNumber: string;
  },
  selectedForecastMeasurement: IBatchForecastMeasurement;
}

/**
 * Reducer for adding and updating calculated forecasts.
 */
export function reducer(state: IForecastReducer[] = initialState, action: ForecastActions.Actions): IForecastReducer[] {
  switch (action.type) {

    // if an item with the same batchId, reference and forecastMeasurement exists the item gets updated else it gets added
    case ForecastActions.ACTION_TYPES.ADD_OR_UPDATE_FORECAST: {

      let updated: boolean = false;

      let extendedState = state.map((item) => {
        if (item.batchId === action.payload.batchId
            && item.reference === action.payload.reference
            && item.selectedForecastMeasurement === action.payload.selectedForecastMeasurement) {
          updated = true;
          return action.payload;
        } else {
          return item;
        }
      });

      if (!updated) {
        extendedState = [
          ...state,
          action.payload
        ];
      }

      return extendedState;
    }

    default:
      return state;
  }
}
