import { Pipe, PipeTransform } from '@angular/core';
import {Iproject} from '../../models/project.model';
import {AlertUserData} from '../../models/alert-user-data';

@Pipe({
  name: 'alertMaxLevel'
})
export class AlertMaxLevelPipe implements PipeTransform {

  transform(projects: Iproject[]): number {
    if (!projects || projects.length < 1) {
      return 0;
    }
    return Math.max(...projects.map( (project): number => {
      // return max alert level for given projects if it has alerts
      if (project.userAlertData && project.userAlertData.length > 0) {
        return Math.max(...project.userAlertData.map((alert: AlertUserData) => alert.alertLevel));
      } else {
        return 0;
      }
    }));
  }
}
