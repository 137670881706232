import * as ChartsActions from '../actions/charts.actions';
import { IDegData } from '../models/chart-deg.model';
import { IFdgData } from '../models/chart-fdg.model';
import { IFogData } from '../models/chart-fog.model';
import { ILpgData } from '../models/chart-lpg.model';
import { IMwgData } from '../models/chart-mwg.model';
import { ModelsComponents } from '../models/models-components.model';

interface IChartReducer {
  modelsComponents: ModelsComponents;
  mwg: IMwgData;
  fdg: IFdgData;
  fog: IFogData;
  deg: IDegData;
  lpg: ILpgData;
}

const initialState: any = {
  modelsComponents: {},
  mwg: {},
  fdg: {},
  fog: {},
  deg: {},
  lpg: {}
};

/**
 * Reducer for adding and removing chart types data
 */
export function reducer(state: any = initialState, action: ChartsActions.Actions): IChartReducer {
  switch (action.type) {
    // Creates data model for models components
    case ChartsActions.ACTION_TYPES.CREATE_MODELS_COMPONENTS: {
      return Object.assign({}, state, {  modelsComponents: action.payload  });
    }

    // Creates data model for a MWG chart
    case ChartsActions.ACTION_TYPES.CREATE_MWG: {
      return Object.assign({}, state, {  mwg: action.payload  });
    }

    // Creates data model for a FDG chart
    case ChartsActions.ACTION_TYPES.CREATE_FDG: {
      return Object.assign({}, state, { fdg: action.payload });
    }

    // Creates data model for a DEG chart
    case ChartsActions.ACTION_TYPES.CREATE_DEG: {
      return Object.assign({}, state, { deg: action.payload });
    }

    // Creates data model for a FOG chart
    case ChartsActions.ACTION_TYPES.CREATE_FOG: {
      return Object.assign({}, state, { fog: action.payload });
    }

    // Creates data model for a LPG chart
    case ChartsActions.ACTION_TYPES.CREATE_LPG: {
      return Object.assign({}, state, { lpg: action.payload });
    }

    // Resets to initial empty state
    case ChartsActions.ACTION_TYPES.DELETE_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}
