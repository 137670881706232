import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * This service provides access to language parameter present in URL.
 *
 * URL looks like this:
 * <HOST>:<PORT>/#/?language=en-US
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageParameterSupplierService {
  private static readonly LANGUAGE_PARAMETER: string = environment.languageParameter;

  private languageParameterSubject: Subject<string> = new Subject<string>();
  private languageRegex: RegExp;

  constructor(private router: Router) {
    this.languageRegex = new RegExp(LanguageParameterSupplierService.LANGUAGE_PARAMETER + '=([^&]*)');
    this.router.events.subscribe(event => {
      // after navigation has processed the query parameters are overwritten,
      // so we have to look for the url at the beginning of navigation.
      if (event instanceof NavigationStart) {
        this.languageParameterSubject.next(this.getLanguageParameter(event.url));
      }
    });
  }

  /**
   * Reads the current url and extracts language parameter.
   */
  private getLanguageParameter(url: string): string {
    const matches = url.match(this.languageRegex);
    return matches ? matches[1] : null;
  }

  /**
   * Provides an Observable that notifies if url gets changed.
   */
  public get languageParameter$(): Observable<string> {
    return this.languageParameterSubject;
  }
}
