import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, DecodedJwt } from 'colimo-ui-library';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Language } from '../../models/language.model';
import { LanguageParameterSupplierService } from '../language-parameter-supplier/language-parameter-supplier.service';

/**
 * Service provides functionality for updating the current language used in the application.
 */
@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private static readonly availableLanguages: Language[] = [
    { id: 'de', localeCode: 'de-DE', name: 'Deutsch' },
    { id: 'en', localeCode: 'en-US', name: 'English' },
    { id: 'es', localeCode: 'es-ES', name: 'Español' },
    { id: 'cn', localeCode: 'zh-CN', name: '汉语' },
  ];
  private decodedJwt: DecodedJwt;
  private languagePresentInUrl = false;
  // Setting english as the initial language
  private currentLanguageSubject: Subject<Language> = new BehaviorSubject(LanguagesService.availableLanguages[1]);

  constructor(
    private translate: TranslateService,
    private localeService: BsLocaleService,
    private authenticationService: AuthenticationService,
    private languageParameterSupplierService: LanguageParameterSupplierService,
  ) {
    this.authenticationService.authentication.subscribe((decodedJwt: DecodedJwt) => {
      this.decodedJwt = decodedJwt;
      if (this.decodedJwt == null) {
        this.setDefaultLanguage();
      } else {
        this.setUsersLanguage();
      }
    });
    this.languageParameterSupplierService.languageParameter$.subscribe((languageCode: string) => {
      if (languageCode == null) {
        this.languagePresentInUrl = false;
      } else {
        this.languagePresentInUrl = true;
        const languageInUrl = this.findLanguageByLocaleCode(languageCode);
        this.setCurrentLanguage(languageInUrl);
      }
    });
  }

  //evtl. unnötig
  public getAvailableLanguages(): Language[] {
    return LanguagesService.availableLanguages;
  }

  public setCurrentLanguage(language: Language): void {
    if (language == null) {
      this.setDefaultLanguage();
    } else {
      this.useLanguage(language);
    }
  }

  public setDefaultLanguage(): void {
    const defaultLanguage = LanguagesService.availableLanguages.find(
      (l) => l.id.toLowerCase() === environment.defaultLanguage.toLowerCase(),
    );
    this.useLanguage(defaultLanguage);
  }

  public findLanguageByLocaleCode(localeCode: string): Language {
    return LanguagesService.availableLanguages.find((l) => l.localeCode.toLowerCase() === localeCode.toLowerCase());
  }

  public findLanguageById(id: string): Language {
    return LanguagesService.availableLanguages.find((l) => l.id.toLowerCase() === id.toLowerCase());
  }

  public get currentLanguage$(): Observable<Language> {
    return this.currentLanguageSubject;
  }

  /**
   * Updates the selected language to the user's language if logged in and the language is available.
   */
  private setUsersLanguage(): void {
    // only use the user's language if no language parameter is present in url
    if (this.languagePresentInUrl === false) {
      const mayFoundLanguage = this.findLanguageById(this.decodedJwt.language.toLowerCase());
      this.setCurrentLanguage(mayFoundLanguage);
    }
  }

  private useLanguage(language: Language): void {
    this.translate.use(language.id);
    this.localeService.use(language.id);
    this.currentLanguageSubject.next(language);
  }
}
