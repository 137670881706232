import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of as observableOf } from 'rxjs';

/**
 * This pipe is responsible for transforming some filter values for lines and products in a proper way.
 */
@Pipe({
  name: 'filterValuesTransformation',
})
export class FilterValuesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * Transforms given filter values to a comma separated list of these values.
   * An example:
   * filterValues: ['AB', 'CD', 'EF', '138546'] would return 'AB, CD, EF, 138546'.
   * If given filter values are not present a default text is translated and returned.
   * This is dependant from given filter type.
   */
  public transform(filterValues: string[], filterType: string): Observable<string> {
    if (filterValues == null || filterValues.length === 0) {
      return this.getPlaceholderForFilterType(filterType);
    }
    return observableOf(filterValues.join(', '));
  }

  private getPlaceholderForFilterType(filterType: string): Observable<string> {
    if (filterType === 'lines') {
      return this.translateService.stream('reports.filterLabel.selectLines');
    }
    if (filterType === 'products') {
      return this.translateService.stream('reports.filterLabel.selectProducts');
    }
    return observableOf(null);
  }
}
