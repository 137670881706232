import { Action } from '@ngrx/store';
import { Iproject } from '../models/project.model';
import { type } from '../util';

export const ACTION_TYPES = {
  CREATE_HEADERINFO: type<'[Header information] Create Headerinfo'>('[Header information] Create Headerinfo'),
  REMOVE_HEADERINFO: type<'[Header information] Remove Headerinfo'>('[Header information] Remove Headerinfo'),
};

export class CreateHeaderInfo implements Action {
  public readonly type = ACTION_TYPES.CREATE_HEADERINFO;

  constructor(public readonly payload: Iproject) {}
}

export class RemoveHeaderInfo implements Action {
  public readonly type = ACTION_TYPES.REMOVE_HEADERINFO;

  constructor() {}
}

export type Actions
  = CreateHeaderInfo
  | RemoveHeaderInfo;
