<div class="project-color"
     *ngIf="gradient && !imgJpeg"
     [style.background]="trustedStyle('linear-gradient(to bottom right, '+gradient.start+', '+gradient.end+')')"
     [ngClass]="{'is-small':size==='small', 'no-corner': removeCorner}">
     <div *ngIf="maxAlertLevel > 0"
          [ngClass]="maxAlertLevel >= 2000 ? 'color-warning-red' : 'color-warning-yellow'"
          [matTooltip]="alertToolTip"
          matTooltipPosition="above"
          matTooltipClass="multiline-tooltip-wide">
        <span class="warning-text">{{'dashboard.attention' | translate}}</span>
     </div>
</div>
<div class="project-color" *ngIf="imgJpeg">
    <img class="project-img" alt="No Image" [ngClass]="{'is-small':size==='small', 'no-corner': removeCorner}" src="{{'data:image/jpg;base64,' + imgJpeg}}"/>
    <div *ngIf="maxAlertLevel > 0"
         [ngClass]="maxAlertLevel >= 2000 ? 'color-warning-red' : 'color-warning-yellow'"
         [matTooltip]="alertToolTip"
         matTooltipPosition="above"
         matTooltipClass="multiline-tooltip-wide">
        <span class="warning-text">{{'dashboard.attention' | translate}}</span>
    </div>
</div>
