import { Injectable } from '@angular/core';
import { Kpis } from '../../shared/enums/kpis.enum';

@Injectable({
  providedIn: 'root',
})
export class KpisService {
  constructor() {}

  public getKpis(): string[] {
    let kpis: string[] = [];
    // just select the name of KPIs from corresponding Enum
    kpis = Object.keys(Kpis).filter(k => typeof Kpis[k as any] === 'number');
    // internally KPI is called 'BPI' but should be displayed as 'TPI'
    kpis = kpis.map((kpi: string) => kpi.replace('BPI', 'TPI'));
    return kpis;
  }
}
