import { Pipe, PipeTransform } from '@angular/core';
import { IFdgData } from '../../models/chart-fdg.model';
import { TinterAddition } from '../../models/tinter-addition.model';

@Pipe({
  name: 'areTinterAdditionsOnPage',
})
export class AreTinterAdditionsOnPagePipe implements PipeTransform {
  constructor() {}

  public transform(iFdgData: IFdgData, tinterAdditions: TinterAddition[], relevantPage: number): boolean {
    if (!(this.areIFdgDataValid(iFdgData) && this.areTinterAdditionsValid(tinterAdditions)) || relevantPage == null) {
      return false;
    }

    return this.displayTinterAdditionOnPage(iFdgData, tinterAdditions, relevantPage);
  }

  private areIFdgDataValid(iFdgData: IFdgData): boolean {
    if (iFdgData != null && iFdgData.pageEndDates != null && iFdgData.pageEndDates.length > 0) {
      return true;
    }
    return false;
  }

  private areTinterAdditionsValid(tinterAdditions: TinterAddition[]): boolean {
    if (tinterAdditions != null && tinterAdditions.length > 0) {
      return true;
    }
    return false;
  }

  private displayTinterAdditionOnPage(iFdgData: IFdgData, tinterAdditions: TinterAddition[], relevantPage: number): boolean {
    const endDateOfPreviousPage = this.getEndDateOfPreviousPage(iFdgData, relevantPage);
    const endDateOfRelevantPage = this.getEndDateOfRelevantPage(iFdgData, relevantPage);

    const numberOfTinterAdditionsOnRelevantPage = tinterAdditions
      .map(ta => new Date(ta.additionDate))
      .filter(additionDate => {
        return this.isAdditionDateBetweenBoth(endDateOfPreviousPage, endDateOfRelevantPage, additionDate);
      }).length;
    return numberOfTinterAdditionsOnRelevantPage === 0 ? false : true;
  }

  private getEndDateOfRelevantPage(iFdgData: IFdgData, relevantPage: number): Date {
    const endDateOfRelevantPage = new Date(iFdgData.pageEndDates[relevantPage]);
    endDateOfRelevantPage.setDate(endDateOfRelevantPage.getDate());
    return endDateOfRelevantPage;
  }

  private getEndDateOfPreviousPage(iFdgData: IFdgData, relevantPage: number): Date {
    if (relevantPage === 0) {
      // The first page has no previous page and therefore no end date.
      // A date way back in past is chosen to mock a really early end date.
      return new Date('1970-01-01');
    }
    return new Date(iFdgData.pageEndDates[relevantPage - 1]);
  }

  private isAdditionDateBetweenBoth(
    endDateOfPreviousPage: Date,
    endDateOfRelevantPage: Date,
    additionDate: Date,
  ): boolean {
    if (
      endDateOfPreviousPage.getTime() <= additionDate.getTime() &&
      additionDate.getTime() < endDateOfRelevantPage.getTime()
    ) {
      return true;
    }
    return false;
  }
}
