/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { AuthenticationService } from 'colimo-ui-library';
import { Jwt } from 'colimo-ui-library/lib/auth/authentication-rest/jwt';
import { Subject } from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import { IFdgData } from 'src/app/models/chart-fdg.model';
import { IFogData } from 'src/app/models/chart-fog.model';
import { ILpgData } from 'src/app/models/chart-lpg.model';
import { IMwgData } from 'src/app/models/chart-mwg.model';
import { ModelsComponents } from 'src/app/models/models-components.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartDataService } from 'src/app/services/chart-data/chart-data.service';
import { ChartFilters } from '../../../models/chart-filters.model';
import { AnglesFactoryService } from '../../../services/angles-factory/angles-factory.service';
import { ClipboardLinkResponse } from '../../../services/export-to-clipboard/clipboard-link-response';
import { ExportClipboardParameter } from '../../../services/export-to-clipboard/export-clipboard-parameter';
import { ExportToClipboardService } from '../../../services/export-to-clipboard/export-to-clipboard.service';
import { ModelsComponentsFactoryService } from '../../../services/models-components-factory/models-components-factory.service';
import { DisplayBlobService } from '../../services/display-blob/display-blob.service';
import {IUserSummary, IUserSummaryActor, ServiceType, UserManagementClient} from '@colorcare/user-management-client';
import {environment} from '../../../../environments/environment';
import {IKpiLine} from "../../../models/kpi.model";

/**
 * Renders customizable checkbox/buttons bar with filters for color system,
 * tolerances, body/bumper and angle selectors. Displays additional information.
 */
@Component({
  selector: 'colimo-chart-filters',
  styleUrls: ['chart-filters.component.scss'],
  templateUrl: 'chart-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartFiltersComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public illuminant: string;
  @Input() public angles: string[];
  @Input() public info: boolean;
  @Input() public bodyBumperFilterHidden: boolean;
  @Input() public hasSystem = true;
  @Input() public componentsLegend: string[];
  @Input() public bodyComponents: string[];
  @Input() public bumperComponents: string[];
  @Input() public models: string[];
  @Input() public chartData: IMwgData | IFdgData | IFogData | ILpgData;
  @Input() public chartFilters: ChartFilters;
  @Input() public tolerancesOn: boolean;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public chartColors: string[];
  @Input() public chartBorderColors: string[];
  @Input() public chartComponentColors: string[];
  @Input() public chartComponentBorderColors: string[];
  @Input() public preselectedModelId: string;
  @Input() public selectedTab: number;
  @Input() public componentTranslations: string[];
  @Input() public showExportButtons: boolean = true;
  @Input() public showVidChart: boolean;
  @Input() public kpiLine: IKpiLine;

  public exportClipboardParameter: ExportClipboardParameter;
  public labOn: boolean;
  public selectedModels: boolean[] = [];
  public selectedComponents: boolean[] = [];
  public modelsComponents: ModelsComponents;
  public selectedBodyComponents: boolean[] = [];
  public bodyComponentsFormControl: FormControl<string[]>;
  public selectedBumperComponents: boolean[] = [];
  public bumperComponentsFormControl: FormControl<string[]>;
  public ccToken: Jwt;
  public clipboardLoading: boolean;
  public allBodyComponentsSelected: boolean;
  public allBumperComponentsSelected: boolean;
  public contactPointsSelected: boolean;
  public noContactPointsSelected: boolean;
  public showBodyComponents: boolean;
  public showBumperComponents: boolean;
  public isLoading: boolean;

  private stop$: Subject<boolean> = new Subject();

  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  @Output() public selectedAnglesChange: EventEmitter<boolean[]> = new EventEmitter();
  @Output() public activeComponentsChange: EventEmitter<ComponentGroups> = new EventEmitter();
  @Output() public modelsComponentsChange: EventEmitter<ModelsComponents> = new EventEmitter();

  constructor(
    private exportToClipboardService: ExportToClipboardService,
    private displayBlobService: DisplayBlobService,
    private modelsComponentsFactoryService: ModelsComponentsFactoryService,
    private anglesFactoryService: AnglesFactoryService,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private chartDataService: ChartDataService,
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.showBodyComponents = true;
    this.showBumperComponents = true;
    this.clipboardLoading = false;
    this.contactPointsSelected = false;
    this.noContactPointsSelected = false;
    this.ccToken = this.authenticationService.getCCTokenForClipboardIfExists();
    if (!this.ccToken) {
      // new version of token has to check via service call if evolution actor is present
      this.initCCTokenIfPresent();
    }
    this.labOn = true;
    if (this.preselectedModelId) {
      this.chartFilters.models = this.preselectedModelId;
      this.getModelsAndComponentsForSelectedModels(this.preselectedModelId);
    }
    this.getModelsAndComponents();
  }

  private initCCTokenIfPresent(): void {

    const jwt = localStorage.getItem('JWT');
    if (!jwt) {
      return;
    }
    const mayBeEvolutionToken: string = jwt.substring(jwt.indexOf(' ') + 1);
    if (!mayBeEvolutionToken) {
      return;
    }
    const userManagementClient: UserManagementClient = new UserManagementClient({
      baseUrl: environment.colorCareEvoUserUrl,
      token: mayBeEvolutionToken,
    });

    userManagementClient.getCurrentUserSummary().pipe(take(1)).subscribe({next: (userSummary: IUserSummary) => {
      // check if the user has an evolution actor
      if (userSummary && userSummary.actors && userSummary.actors.length > 0) {
        const evoActor: IUserSummaryActor = userSummary.actors.find(actor => actor.client.service.type === ServiceType.EVOLUTION);
        if (evoActor) {
          this.ccToken = { token: jwt };
          this.changeDetectorRef.detectChanges();
        }
      }
    }, error: () => {
      // do nothing in case of error
    }});
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Fills the anglesSelected with the values emitted by the mwg chart list component
    if (changes.chartData && changes.chartData.currentValue && changes.chartData.currentValue.angles) {
      this.exportClipboardParameter = {
        batchId: this.chartData.batchId,
        line: this.chartData.lineNumber,
        startDate: this.chartData.startDate,
        endDate: this.chartData.endDate,
      };
      if (changes.chartData.currentValue.angles) {
        const angles = changes.chartData.currentValue.angles;
        this.selectedAngles = this.anglesFactoryService.createSelectedAnglesArrayFromInput(angles, this.selectedAngles);
        this.selectedAnglesChange.emit(this.selectedAngles);
      }
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public toggleColorSystem(enable: boolean): void {
    this.labOn = enable;
  }

  public exportFMCCTBClipboardMeanValues(): void {
    this.clipboardLoading = true;
    this.exportToClipboardService
      .getExportFMCCTBClipboardMeanValues(this.exportClipboardParameter)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (blob: Blob) => {
          this.displayBlobService.displayBlob(blob, 'export.cpb');
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public exportFMCCTBClipboardMeasurements(): void {
    this.clipboardLoading = true;
    this.exportToClipboardService
      .getExportFMCCTBClipboardMeasurements(this.exportClipboardParameter)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (blob: Blob) => {
          this.displayBlobService.displayBlob(blob, 'export.cpb');
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public linkToFMCCTBClipboardMeasurements(): void {
    if (!this.ccToken) {
      return;
    }
    this.clipboardLoading = true;
    this.exportToClipboardService
      .linkToFMCCTBClipboardMeasurements(this.exportClipboardParameter, this.ccToken)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (response: ClipboardLinkResponse) => {
          if (response && response.URL) {
            window.open(response.URL, '_blank');
          }
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public linkToFMCCTBClipboardMeanValues(): void {
    if (!this.ccToken) {
      return;
    }
    this.clipboardLoading = true;
    this.exportToClipboardService
      .linkToFMCCTBClipboardMeanValues(this.exportClipboardParameter, this.ccToken)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (response: ClipboardLinkResponse) => {
          if (response && response.URL) {
            window.open(response.URL, '_blank');
          }
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.clipboardLoading = false;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  public colorCoordinatesChanged(event: MatButtonToggleChange): void {
    this.labOn = event.value;
    this.updateSettings();
  }

  public tolerancesChanged(event: MatButtonToggleChange): void {
    this.tolerancesOn = event.value;
    this.chartFilters.tolerance = event.value;
    this.updateSettings();
  }

  public angleSelectionChanged(event: MatCheckboxChange, index: number): void {
    this.selectedAngles[index] = event.checked;
    this.selectedAnglesChange.emit(this.selectedAngles);
  }

  public modelSelectionChanged(event: MatCheckboxChange, index: number): void {
    this.selectedModels[index] = event.checked;
    const models = this.modelsComponentsFactoryService.getSelectedModelsAsList(
      this.selectedModels,
      this.modelsComponents.models,
    );
    this.getModelsAndComponentsForSelectedModels(models);
    this.updateSettings();
  }

  public contactPointsChange(): void {
    if (this.contactPointsSelected && !this.noContactPointsSelected) {
      const selected: string[] = this.modelsComponentsFactoryService.getContactPointsComponents(
        this.modelsComponents.bodyComponents,
        this.contactPointsSelected
      );
      this.bodyComponentSelectionChanged({value: selected} as any);
    } else if (this.contactPointsSelected && this.noContactPointsSelected) {
      this.allBodyComponentsSelected = true;
      this.selectAllBodyComponents();
    } else if (!this.contactPointsSelected && !this.noContactPointsSelected) {
      this.allBodyComponentsSelected = false;
      this.selectAllBodyComponents();
    } else if (!this.contactPointsSelected && this.noContactPointsSelected) {
      const selected: string[] = this.modelsComponentsFactoryService.getContactPointsComponents(
        this.modelsComponents.bodyComponents,
        this.contactPointsSelected
      );
      this.bodyComponentSelectionChanged({ value: selected } as any);
    }
  }

  public selectAllBodyComponents(event?: any): void {
    // only set contact points checkboxes to false when select all checkbox was used and this was not called over a trigger
    if (event instanceof MatCheckboxChange) {
      this.contactPointsSelected = false;
      this.noContactPointsSelected = false;
    }
    this.activeComponents.bodyOn = this.allBodyComponentsSelected;
    this.activeComponents.allBodySelected = this.allBodyComponentsSelected;
    this.activeComponentsChange.emit(this.activeComponents);
    this.selectedBodyComponents = this.modelsComponents.bodyComponents.map(() => this.allBodyComponentsSelected);
    if (this.allBodyComponentsSelected) {
      this.bodyComponentsFormControl.setValue(this.modelsComponents.bodyComponents);
    } else {
      this.bodyComponentsFormControl.setValue([]);
    }
    this.updateSettings();
  }

  public bodyComponentSelectionChanged(event: MatSelectChange): void {
    this.selectedBodyComponents = this.modelsComponentsFactoryService.updateSelectedComponentsArray(
      this.modelsComponents.bodyComponents,
      event.value,
    );
    this.bodyComponentsFormControl.setValue(event.value);
    this.activeComponents.bodyOn = this.selectedBodyComponents.includes(true);
    this.activeComponents.allBodySelected = this.checkAllComponentsSelected(
      this.modelsComponents.bodyComponents,
      this.selectedBodyComponents,
    );
    this.allBodyComponentsSelected = this.activeComponents.allBodySelected;
    this.activeComponentsChange.emit(this.activeComponents);
    this.updateSettings();
  }

  public selectAllBumperComponents(): void {
    this.activeComponents.bumperOn = this.allBumperComponentsSelected;
    this.activeComponents.allBumperSelected = this.allBumperComponentsSelected;
    this.activeComponentsChange.emit(this.activeComponents);
    this.selectedBumperComponents = this.modelsComponents.bumperComponents.map(() => this.allBumperComponentsSelected);
    if (this.allBumperComponentsSelected) {
      this.bumperComponentsFormControl.setValue(this.modelsComponents.bumperComponents);
    } else {
      this.bumperComponentsFormControl.setValue([]);
    }
    this.updateSettings();
  }

  public bumperComponentSelectionChanged(event: MatSelectChange): void {
    this.selectedBumperComponents = this.modelsComponentsFactoryService.updateSelectedComponentsArray(
      this.modelsComponents.bumperComponents,
      event.value,
    );
    this.activeComponents.bumperOn = this.selectedBumperComponents.includes(true);
    this.activeComponents.allBumperSelected = this.checkAllComponentsSelected(
      this.modelsComponents.bumperComponents,
      this.selectedBumperComponents,
    );
    this.allBumperComponentsSelected = this.activeComponents.allBumperSelected;
    this.activeComponentsChange.emit(this.activeComponents);
    this.updateSettings();
  }

  /**
   * Change settings for color system, tolerances etc.
   */
  public updateSettings(): void {
    this.chartFiltersChange.emit({
      batchId: this.chartFilters.batchId,
      batchIds: this.chartFilters.batchIds,
      lineId: this.chartFilters.lineId,
      lineIds: this.chartFilters.lineIds,
      projectId: this.chartFilters.projectId,
      page: this.chartFilters.page,
      labSystem: this.labOn ? 'LAB' : 'LCH',
      tolerance: this.chartFilters.tolerance,
      models: this.modelsComponentsFactoryService.getSelectedModelsAsList(
        this.selectedModels,
        this.modelsComponents.models,
      ),
      bodyComponents: this.modelsComponentsFactoryService.getSelectedComponentsAsList(
        this.selectedBodyComponents,
        this.modelsComponents.bodyComponents,
      ),
      bumperComponents: this.modelsComponentsFactoryService.getSelectedComponentsAsList(
        this.selectedBumperComponents,
        this.modelsComponents.bumperComponents,
      ),
    });
  }

  private checkAllComponentsSelected(availableComponents: string[], selectedComponents: boolean[]): boolean {
    if (!availableComponents || !selectedComponents) {
      return null;
    }
    return selectedComponents.filter((component: boolean) => component === true).length === availableComponents.length;
  }

  private getModelsAndComponents(): void {
    if (this.chartFilters) {
      this.chartDataService
        .getModelsAndComponents(this.chartFilters)
        .pipe(takeUntil(this.stop$))
        .subscribe((modelsComponents: ModelsComponents) => {
          this.modelsComponents =
            this.modelsComponentsFactoryService.checkAvailabilityOfComponentsAndUpdate(modelsComponents);
          this.modelsComponentsChange.emit(this.modelsComponents);
          this.bodyComponentsFormControl = new FormControl<string[]>(this.modelsComponents.bodyComponents);
          this.bumperComponentsFormControl = new FormControl<string[]>(this.modelsComponents.bumperComponents);
          this.selectedModels = this.modelsComponentsFactoryService.createSelectedModelsArrayFromInput(
            this.modelsComponents.models,
            this.selectedModels,
            this.preselectedModelId,
          );
          this.selectedBodyComponents = this.modelsComponentsFactoryService.createSelectedComponentsArrayFromInput(
            this.modelsComponents.bodyComponents,
            this.selectedBodyComponents,
          );
          this.allBodyComponentsSelected = this.checkAllComponentsSelected(
            this.modelsComponents.bodyComponents,
            this.selectedBodyComponents,
          );
          this.activeComponents.allBodySelected = this.allBodyComponentsSelected;
          this.selectedBumperComponents = this.modelsComponentsFactoryService.createSelectedComponentsArrayFromInput(
            this.modelsComponents.bumperComponents,
            this.selectedBumperComponents,
          );
          this.allBumperComponentsSelected = this.checkAllComponentsSelected(
            this.modelsComponents.bumperComponents,
            this.selectedBumperComponents,
          );
          this.activeComponents.allBumperSelected = this.allBumperComponentsSelected;
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  private getModelsAndComponentsForSelectedModels(models: string): void {
    this.chartDataService
      .getModelsAndComponentsForSelectedModels(this.chartFilters, models)
      .pipe(takeUntil(this.stop$))
      .subscribe((modelsComponents: ModelsComponents) => {
        this.showBodyComponents = true;
        this.showBumperComponents = true;
        if (modelsComponents) {
          if (modelsComponents.bodyComponents.length === 0) {
            this.showBodyComponents = false;
          }
          if (modelsComponents.bumperComponents.length === 0) {
            this.showBumperComponents = false;
          }
        }
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }
}
