import { Pipe, PipeTransform } from '@angular/core';
import { DifferenceType } from '../enums/difference-type.enum';

@Pipe({ name: 'labelByChartType' })
export class LabelChartType implements PipeTransform {
  /**
   * Converts chart types (DL, DA, DB etc.) into labels by following rules:
   * - dL* da* db* dC* dH* when tolerances disabled
   * - <dL> <da> <db> <dC> <dH> when tolerances enabled
   * @param type chart type
   * @param tolerances whether tolerances are enabled
   * @return label following syntax rules
   */
  public transform(type: string, tolerances: boolean): string {
    switch (type) {
      case DifferenceType.DL:
        return tolerances ? '<dL>' : 'dL*';
      case DifferenceType.DA:
        return tolerances ? '<da>' : 'da*';
      case DifferenceType.DB:
        return tolerances ? '<db>' : 'db*';
      case DifferenceType.DC:
        return tolerances ? '<dC>' : 'dC*';
      case DifferenceType.DH:
        return tolerances ? '<dH>' : 'dH*';
      case DifferenceType.D_DE:
        return tolerances ? '<dE>' : 'dE*';
      case DifferenceType.C_DE:
        return '<dE>c';
      case DifferenceType.S_DE:
        return '<dE>s';
      case DifferenceType.M_DE:
        return tolerances ? '<mdE>' : 'mdE*';
      default:
        return type;
    }
  }
}
