<mat-card
  appearance="outlined"
  *ngIf="{
    customers: dashboard$ | async,
    isMobile: isMobile$ | async,
    dashboardSelection: dashboardSelection$ | async
  } as colorDashboard"
  class="dashboard-card padding-16"
>
  <colimo-page-header [heading]="heading"> </colimo-page-header>
  <div class="flex-column gap-25">
    <mat-expansion-panel
      *ngFor="let customer of colorDashboard.customers"
      [expanded]="colorDashboard.dashboardSelection.customer === customer.customer"
    >
      <mat-expansion-panel-header
        class="flex-row align-start"
        [ngClass]="colorDashboard.isMobile.isMobile === true ? 'mobile-panel-header' : ''"
        (click)="customerSelected(customer.customer, colorDashboard.dashboardSelection.customer)"
      >
        <mat-panel-title class="flex-grow-0">
          <colimo-customer-cards
             class="flex-row gap-35 gap-lt-sm-0 start-center"
            [customer]="customer"
          ></colimo-customer-cards>
        </mat-panel-title>
        <mat-panel-description class="customer-panel flex-row spacebetween-center">
          <div *ngIf="!colorDashboard.isMobile.isMobile" class="flex-90 pipe-list">
            {{ customer.sites | arrayToPipeList: 'siteName' }}
          </div>
          <div class="flex-column flex-100px spacebetween-end flex-lt-sm-100">
            <div class="flex-row center-end bell-wrapper">
              <mat-icon *ngIf="alertsConfigured" class="bell-format"> notification_important </mat-icon>
              <mat-icon *ngIf="!alertsConfigured" class="bell-format"> notifications_off </mat-icon>
              <div *ngIf="alertsConfigured"
                class="notification-badge"
                [ngClass]="(customer.projects | warningsCount) !== 0 ? ((customer.projects | alertMaxLevel) >= 2000 ? 'red-bell' : 'yellow-bell') : 'green-bell'"
              >
                {{ customer.projects | warningsCount }}
              </div>
            </div>
            <div class="flex-row end-end gap-10 product-count-container">
              <span class="product-count"
                >{{ customer.projects | productCount }} {{ 'dashboard.products' | translate }}
              </span>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="flex-row flex-wrap column-gap-20 gap-lt-sm-0"
          [ngClass]="colorDashboard.isMobile.isMobile === true ? 'mobile-panel-content' : ''">
          
          <colimo-color-card
            *ngFor="let project of customer.projects"
            class="color-card"
            [ngClass]="colorDashboard.isMobile.isMobile === true ? 'mobile-color-card' : ''"
            [project]="project"
            [routerLink]="['/project/', project.id, 'color', 'page', 0]"
          ></colimo-color-card>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </div>
</mat-card>

<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="isLoading"></colimo-loader>
