<mat-card appearance="outlined" *ngIf="carBodyStyle" class="mat-elevation-z6 flex-1 flex-column padding-16 box-shadow">
  <mat-card-title class="section-heading">{{ 'reports.filterLabel.overview' | translate }}</mat-card-title>
  <ng-container *ngIf="image">
    <img [ngStyle]="{ 'height.px': imageHeight, 'width.px': imageWidth }" mat-card-image [src]="image" class="new-margin" /><ng-container
      *ngFor="let componentStatus of componentStatusToDisplay; let i = index"
    >
      <span
        class="circle-container"
        [ngStyle]="componentStatus | componentStatusPositionCssClass: carBodyStyle"
        #tooltip="matTooltip"
        matTooltip="{{ 'M_DE' | labelByChartType: filterOptions.tolerancesOn }} {{ componentStatus.mdE | number: '1.2-2' }}"
        [matTooltipPosition]="'above'"
        (mouseover)="selectComponentStatus(componentStatus)"
        (mouseout)="deselectComponentStatus()"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          [ngClass]="[componentStatus | componentStatusCssClass: filterOptions.tolerancesOn : car?.defaultShowDe]"
          class="status-circle"
        >
          lens
        </mat-icon>
        <div class="circle-count">
          {{ i + 1 }}
        </div>
      </span>
    </ng-container></ng-container
  >
  <p *ngIf="!image">{{ 'reports.filterLabel.noImageAvailable' | translate }}</p>
</mat-card>
