import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { KeyText } from '../../models/key-text.model';
import {LanguagesService} from '../languages/languages.service';
import {Language} from '../../models/language.model';

@Injectable({
  providedIn: 'root',
  })
export class KeyTextService {

  private baseUrl: string;
  private componentTranslations$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private positionTranslations$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public constructor(public http: HttpClient,
                     private languageService: LanguagesService) {
    this.baseUrl = environment.apiBasePath;
    setTimeout(() => {
      this.initComponentTranslations();
    }, 3000);
  }

  private initComponentTranslations(): void {
    this.languageService.currentLanguage$.subscribe({
      next: (currentLanguage: Language) => {
        this.getComponentTranslations(currentLanguage.id.toUpperCase()).subscribe({
          next: (keyTexts: KeyText[]) => {
            const compTranslations: string[] = [];
            for (let i = 0; i < keyTexts.length; i++) {
              compTranslations[keyTexts[i].key] = keyTexts[i].text;
            }
            this.componentTranslations$.next(compTranslations);
          }, error: () => {
          // nothing
          }
        });
        this.getPositionTranslations(currentLanguage.id.toUpperCase()).subscribe({
          next: (keyTexts: KeyText[]) => {
            const posTranslations: string[] = [];
            for (let j = 0; j < keyTexts.length; j++) {
              posTranslations[keyTexts[j].key] = keyTexts[j].text;
            }
            this.positionTranslations$.next(posTranslations);
          }, error: () => {
          // nothing
          }
        });
      }, error: () => {
        // nothing
      }});
  }

  public getComponentTranslationsObservable(): Observable<string[]> {
    return this.componentTranslations$.asObservable();
  }

  public getPositionTranslationsObservable(): Observable<string[]> {
    return this.positionTranslations$.asObservable();
  }

  public getCustomers(): Observable<KeyText[]> {

    const apiEndpoint = `${this.baseUrl}/${environment.reports.customers}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as KeyText[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getLocations(): Observable<KeyText[]> {

    const apiEndpoint = `${this.baseUrl}/${environment.reports.locations}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as KeyText[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getCarTypes(location: KeyText): Observable<KeyText[]> {

    const apiEndpoint = `${this.baseUrl}/${environment.reports.carTypes.replace('{lstaNr}', location.key)}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as KeyText[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  private getComponentTranslations(lang: string): Observable<KeyText[]> {

    const apiEndpoint = `${this.baseUrl}/${environment.reports.components.replace('{language}', lang)}`;
  
    return this.http.get(apiEndpoint).pipe(
      map(response => response as KeyText[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  private getPositionTranslations(lang: string): Observable<KeyText[]> {

    const apiEndpoint = `${this.baseUrl}/${environment.reports.position.replace('{language}', lang)}`;
  
    return this.http.get(apiEndpoint).pipe(
      map(response => response as KeyText[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }
}
