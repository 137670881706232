<mat-card appearance="outlined" class="mat-elevation-z6 flex-column padding-16 box-shadow">
  <mat-card-title *ngIf="!labchBumperAverageHeading" class="section-heading">{{ heading | translate }}</mat-card-title>
  <mat-card-title *ngIf="labchBumperAverageHeading" class="section-heading"
    >{{ heading | translate }} - {{ labchBumperAverageHeading | translate }}</mat-card-title
  >
  <div class="flexlayout">
    <table class="flex-1" mat-table [dataSource]="labchStatisticDataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.numberAbbr' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="component">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.component' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          <ng-container *ngIf="element.id">
            {{ componentTranslations[element.componentStatus.component] ? componentTranslations[element.componentStatus.component]  : element.componentStatus.component }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.position' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          <ng-container *ngIf="element.id">
            {{ positionTranslations[element.componentStatus.position] ? positionTranslations[element.componentStatus.position] : element.componentStatus.position }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.status' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          <ng-container *ngIf="element.id">
            <mat-icon
              *ngIf="element.componentStatus.component"
              [ngClass]="element.componentStatus | componentStatusCssClass: filterOptions.tolerancesOn : car?.defaultShowDe"
              class="status-circle"
            >
              lens
            </mat-icon>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="geometry">
        <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.geometry' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          {{ element.deltaValue.angle }}°
        </td>
      </ng-container>
      <ng-container matColumnDef="dl">
        <th mat-header-cell *matHeaderCellDef>{{ 'DL' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }" class="{{ 'text-' | kpiLabel: filterOptions.tolerancesOn ? null : element.deltaValue.dlColor }}">
          {{ element.deltaValue.dL | number: '1.2-2' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="da">
        <th mat-header-cell *matHeaderCellDef>{{ 'DA' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }" class="{{ 'text-' | kpiLabel: filterOptions.tolerancesOn ? null : element.deltaValue.daColor }}">
          {{ element.deltaValue.dA | number: '1.2-2' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="db">
        <th mat-header-cell *matHeaderCellDef>{{ 'DB' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }" class="{{ 'text-' | kpiLabel: filterOptions.tolerancesOn ? null : element.deltaValue.dbColor }}">
          {{ element.deltaValue.dB | number: '1.2-2' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dc">
        <th mat-header-cell *matHeaderCellDef>{{ 'DC' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }" class="{{ 'text-' | kpiLabel: filterOptions.tolerancesOn ? null : element.deltaValue.dcColor }}">
          {{ element.deltaValue.dC | number: '1.2-2' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dh">
        <th mat-header-cell *matHeaderCellDef>{{ 'DH' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }" class="{{ 'text-' | kpiLabel: filterOptions.tolerancesOn ? null : element.deltaValue.dhColor }}">
          {{ element.deltaValue.dH | number: '1.2-2' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="de">
        <th mat-header-cell *matHeaderCellDef>{{ 'D_DE' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          <span [ngClass]="element.deltaValue | deltaValuesCssClass: filterOptions.tolerancesOn">
            {{ element.deltaValue.dE | number: '1.2-2' }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="mde">
        <th mat-header-cell *matHeaderCellDef>{{ 'M_DE' | labelByChartType: filterOptions.tolerancesOn }}</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'row-divider': i !== 0 && element.id }">
          <b *ngIf="element.id">
            {{ element.componentStatus.mdE | number: '1.2-2' }}
          </b>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngStyle]="selectedComponentStatus === row.componentStatus && { 'background-color': 'lightgray' }"
      ></tr>
    </table>
  </div>
</mat-card>
