<mat-card
  appearance="outlined"
  *ngIf="!isLoading && locations && locations.length !== 0"
  class="mat-elevation-z6 flex-column padding-16 box-shadow"
  (clickOutside)="hideSearchResults()"
>
  <mat-card-title class="section-heading flex-1">{{
    'reports.filterLabel.reportConfiguration' | translate
  }}</mat-card-title>
  <form class="flex-row justify-start justify-end gap-15">
    <mat-form-field>
      <mat-label>{{ 'reports.filterLabel.site' | translate }}</mat-label>
      <mat-select [(value)]="selectedLocation" (selectionChange)="onLocationChanged($event)">
        <mat-option *ngFor="let location of locations" [value]="location">{{ location.text }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'reports.filterLabel.vehicleId' | translate }}</mat-label>
      <input matInput [formControl]="carId" (keyup)="searchCarsForLocation()" />
    </mat-form-field>
    <div class="vertical-align">
      <div class="button-toggle-font">
        {{ 'dashboard.tolerances' | translate }}
      </div>
      <mat-slide-toggle
        [(ngModel)]="filterOptions.tolerancesOn"
        name="tolerancesOn"
        color="primary"
        (change)="onFilterOptionsChanged($event)"
      ></mat-slide-toggle>
    </div>
    <div class="vertical-align">
      <div class="button-toggle-font">
        {{ 'reports.filterLabel.showBumper' | translate }}
      </div>
      <mat-slide-toggle
        [(ngModel)]="filterOptions.bumperOn"
        name="bumperOn"
        color="primary"
        (change)="onFilterOptionsChanged($event)"
      ></mat-slide-toggle>
    </div>
    <mat-form-field>
      <mat-label>{{ 'reports.filterLabel.typeOfReport' | translate }}</mat-label>
      <mat-select [(value)]="selectedTypeOfReport" (selectionChange)="onTypeOfReportChanged()">
        <mat-option *ngFor="let typeOfReport of typesOfReport" [value]="typeOfReport">{{ typeOfReport }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="vertical-align">
      <div class="button-toggle-font">
        {{ 'dashboard.colorSystem' | translate }}
      </div>
      <mat-button-toggle-group
        class="button-toggle-font"
        [(ngModel)]="filterOptions.labSystem"
        name="labSystem"
        (change)="onFilterOptionsChanged($event)"
        [disabled]="selectedTypeOfReport !== 'dLabCH with average plot'"
      >
        <mat-button-toggle
          [ngClass]="{ 'disabled-button': selectedTypeOfReport !== 'dLabCH with average plot' }"
          [value]="true"
          >Lab</mat-button-toggle
        >
        <mat-button-toggle
          [ngClass]="{ 'disabled-button': selectedTypeOfReport !== 'dLabCH with average plot' }"
          [value]="false"
          >LCH</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div class="tooltip-container flex-50px">
      <div
        class="tooltip-fill"
        *ngIf="!isCarReportExportLayout && carBodyStyleLoaded"
        #tooltip="matTooltip"
        matTooltip="{{ 'reports.filterLabel.carReportExport' | translate }}"
        [matTooltipPosition]="'above'"
        (click)="tooltip.hide(); exportReport()"
      ></div>
      <button mat-fab color="primary" class="export-button" [disabled]="!carBodyStyleLoaded">
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
  </form>
  <div id="search" *ngIf="!isHidden && !isSearchingCar && carsForLocation as results" colimoOutsideClick>
    <ul *ngIf="results.length; else noResult" class="result-list">
      <li class="result-list-item" *ngFor="let result of results">
        <div (click)="onCarSelected(result)" class="result-item-link">
          <div class="result-item">
            <span
              >{{ 'reports.reportTable.model' | translate }} {{ result.model }} - {{ result.measurementDate }} -
            </span>
            <span class="italic-font"> {{ result.vehicleId }} </span>
          </div>
          <span class="result-item">{{ result.colorCode }} - {{ result.colorName }}</span>
        </div>
      </li>
    </ul>
    <ng-template #noResult>
      <ul class="result-list">
        <li class="result-list-item">
          <div class="result-item-link">
            <span class="result-item small">{{ 'reports.filterLabel.noVehicleIdFound' | translate }}</span>
          </div>
        </li>
      </ul>
    </ng-template>
  </div>
  <div *ngIf="isSearchingCar">
    <colimo-loader [isRelative]="true"></colimo-loader>
  </div>
</mat-card>
<ng-container *ngIf="!isLoading && (!locations || locations.length === 0)">
  <span>
    {{ 'reports.reportTable.noData' | translate }}
  </span>
</ng-container>
