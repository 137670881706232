import { Pipe, PipeTransform } from '@angular/core';
import { IBatch } from '../../models/batch.model';
import { Iproduct } from '../../models/product.model';

/**
 * This pipe returns the batches of all given products.
 */
@Pipe({
  name: 'productBatches',
})
export class ProductBatchesPipe implements PipeTransform {
  public transform(products: Iproduct[]): IBatch[] {
    if (products == null) {
      return null;
    }
    return this.getBatchesOfAllProducts(products);
  }

  private getBatchesOfAllProducts(products: Iproduct[]): IBatch[] {
    let batchesTotal = [];
    products
      .filter((product: Iproduct) => product.batches)
      .forEach((product: Iproduct) => {
        const productBatches = product.batches.map((batch: IBatch) => {
          // some product information are needed for the dashboard so we add them here
          const batchWithProductInfo: IBatch = {
            batchNumber: batch.batchNumber,
            state: batch.state,
            productName: product.name,
            productType: product.type,
          };
          return batchWithProductInfo;
        });
        batchesTotal = batchesTotal.concat(productBatches);
      });
    return batchesTotal;
  }
}
