import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Chart from 'chart.js';
import {forkJoin, Observable, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsComponents } from 'src/app/models/models-components.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartDataService } from 'src/app/services/chart-data/chart-data.service';
import { ChartFilters } from '../../models/chart-filters.model';
import { IAngle, IFogData } from '../../models/chart-fog.model';
import { ChartScale } from '../../models/chart-scale.model';
import {ChartDefaults} from '../../shared/enums/chart-defaults.enum';

/**
 * Displays a list of FOG charts (FarbOrtGrafik), contains chart types and default labels
 */
@Component({
  selector: 'colimo-fog-chart-list-component',
  styleUrls: ['fog-chart-list.component.scss'],
  templateUrl: 'fog-chart-list.component.html',
})
export class FOGchartListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public chartFilters: ChartFilters;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public modelsComponents: ModelsComponents;
  @Input() public chartComponentColors: string[];
  @Input() public chartMultiBatchColors: string[];
  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  @Output() public chartDataChange: EventEmitter<IFogData> = new EventEmitter();
  @Output() public tolerancesChanged: EventEmitter<boolean> = new EventEmitter();
  public isLoading = true;
  // View representation of max 5 * 2 charts that will always be rendered regardless of data
  public chartSlots = [
    { labels: ['DL', 'DB', 'DA'] },
    { labels: ['DL', 'DB', 'DA'] },
    { labels: ['DL', 'DB', 'DA'] },
    { labels: ['DL', 'DB', 'DA'] },
    { labels: ['DL', 'DB', 'DA'] }, // OC 2019-04-24: max 6 * 2 charts (-15, 15, 25, 45, 75, 110)
    { labels: ['DL', 'DB', 'DA'] },
  ];
  // Data representation that will change over time regarding the filters and settings
  // Structure: [ 5°, 15°, 25°, 75°, 110° ] where 5° maps to [ dlSlot, dataSlot ]
  // OC 2019-04-24: max 6 * 2 charts (-15, 15, 25, 45, 75, 110)
  public chartData = [
    [[], []],
    [[], []],
    [[], []],
    [[], []],
    [[], []],
    [[], []],
  ];
  // API response
  public iFogData: IFogData;
  // Default labels
  public chartAngles: string[] = [];
  // Default scale
  public scale: ChartScale = {
    max: 3,
    min: -3,
  };

  private stop$: Subject<boolean> = new Subject();

  constructor(private cd: ChangeDetectorRef, private chartDataService: ChartDataService) {}

  public ngOnInit(): void {
    Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      asSquare: true,
      ignoreWidth: 52,
    });

    this.getFogData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartFilters && !changes.chartFilters.firstChange) {
      if (this.chartFilters) {
        this.getFogData();
      }
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  private getFogData(): void {
    const TIMEOUT_IN_MILLISECONDS = 30000;
    this.isLoading = true;
    if (this.chartFilters.batchIds && this.chartFilters.lineIds) {
      // fog chart is mutli batch
      const batchIds: string[] = this.chartFilters.batchIds.split(',');
      const lineIds: string[] = this.chartFilters.lineIds.split(',');
      // deep copy chart filters and chain request for all batch line selections
      const observables: Observable<IFogData>[] = [];
      const chartFiltersForMultiBatch: ChartFilters = JSON.parse(JSON.stringify(this.chartFilters));
      for (let i = 0; i < batchIds.length; i++) {
        chartFiltersForMultiBatch.batchId = parseInt(batchIds[i]);
        chartFiltersForMultiBatch.lineId = lineIds[i];
        observables.push(this.chartDataService.getFogData(chartFiltersForMultiBatch, this.modelsComponents));
      }
      forkJoin(observables).pipe(takeUntil(this.stop$)).subscribe({next: (iFogDatas: IFogData[]) => {
        this.isLoading = false;
        for(let i = 0; i < iFogDatas.length; i++) {
          const fogDataWithKey = this.setBatchLineKeys(iFogDatas[i]);
          this.handleFogData(fogDataWithKey, TIMEOUT_IN_MILLISECONDS, i === (iFogDatas.length - 1), i === 0);
        }
      }});
    } else {
      // single batch
      this.chartDataService.getFogData(this.chartFilters, this.modelsComponents).pipe(takeUntil(this.stop$)).subscribe((iFogData: IFogData) => {
        this.isLoading = false;
        this.handleFogData(iFogData, TIMEOUT_IN_MILLISECONDS, true, true);
      });
    }
  }

  private setBatchLineKeys(iFogData: IFogData): IFogData {
    if (!iFogData || !iFogData.data || iFogData.data.length === 0) {
      return iFogData;
    }

    const batchLineKey = iFogData.batchId + '-' + iFogData.lineNumber;
    for (let i = 0; i < iFogData.data.length; i++) {
      if (iFogData.data[i] && iFogData.data[i].dl && iFogData.data[i].dl.length > 0) {
        iFogData.data[i].dl.forEach((dataSet) => {
          dataSet.batchLineKey = batchLineKey;
        });
      }
      if (iFogData.data[i] && iFogData.data[i].dataSet && iFogData.data[i].dataSet.length > 0) {
        iFogData.data[i].dataSet.forEach((dataSet) => {
          dataSet.batchLineKey = batchLineKey;
        });
      }
    }
    return iFogData;
  }

  private handleFogData(iFogData: IFogData, timeOut: number, emitChanges: boolean, resetData?: boolean): void {
    if (!iFogData) {
      return;
    }

    if (iFogData.scale) {
      this.scale.min = iFogData.scale[0];
      this.scale.max = iFogData.scale[1];
    }

    if (iFogData.angles) {
      this.chartAngles = iFogData.angles;
    }

    if (iFogData && iFogData.batchNumber && iFogData.data) {
      this.iFogData = iFogData;
      this.populateChartCols(iFogData.data, resetData);
    }

    // Set global Chart options for toleranceLine Plugin
    Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: iFogData.tolerances});
    // scaleTolerance can be null in some cases but calculation of max and min fails with null values. Empty array is used instead.
    Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, iFogData.scaleTolerance ? iFogData.scaleTolerance : []);

    if (emitChanges) {
      // set tolerances according to last fogdata should be ok because all should have the same customer => same tolerances
      if (this.chartFilters.tolerance === null || this.chartFilters.tolerance === undefined) {
        this.tolerancesChanged.emit(iFogData.tolerances);
      }
      setTimeout(() => {
        this.isLoading = false;
      }, timeOut);
  
      this.chartDataChange.emit(iFogData);
      this.cd.detectChanges();
    }
  }

  private populateChartCols(data: IAngle[], resetData? : boolean): void {
    if (resetData) {
      this.chartSlots.forEach((slot, i) => {
        this.chartData[i][0] = [];
        this.chartData[i][1] = [];
      });
    }
    // Iterate chart angles [5°, 15°, 25°, etc.]
    this.chartSlots.forEach((slot, i) => {
      if (data[i] && data[i].dl && data[i].dataSet) {
        // Assign response nesting [i].dl to equivalent chartData slot
        for (let j = 0; j < data[i].dl.length; j++) {
          this.chartData[i][0].push({type: data[i].dl[j].type, data: data[i].dl[j].data, batchLineKey: data[i].dl[j].batchLineKey });
        }
        // Assign response nesting [i].dataSet to equivalent chartData slot
        for (let t = 0; t < data[i].dataSet.length; t++) {
          this.chartData[i][1].push({type: data[i].dataSet[t].type, data: data[i].dataSet[t].data, batchLineKey: data[i].dataSet[t].batchLineKey });
        }
      } else {
        // no data
      }
    });

  }
}
