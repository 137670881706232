import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { Event, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationResult, AuthenticationService } from 'colimo-ui-library';
import { DecodedJwt } from 'colimo-ui-library/lib/auth/authentication-rest/decoded-jwt';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import * as SidenavActions from 'src/app/actions/sidenav.actions';
import { ToggleSidenavCollapsedAction } from 'src/app/actions/sidenav.actions';
import { EnvironmentProfile } from 'src/app/models/environment-profile.enum';
import { Language } from 'src/app/models/language.model';
import { Sidenav } from 'src/app/models/sidenav.model';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { SidenavItem } from 'src/app/shared/enums/sidenav-item.enum';
import { NavigationService } from 'src/app/shared/services/navigation/navigation.service';
import { environment } from 'src/environments/environment';
import * as fromRoot from '../../reducers';
import {AlertCount} from '../../models/alert-count';
import {NotificationService} from '../../services/notification/notification.service';
import {AlertUserConfig} from '../../models/alert-user-config';

@Component({
  selector: 'colimo-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChild('sidenavContainer')
  sideNavContainer: MatSidenavContainer;

  public shouldRun: boolean;
  public events: string[] = [];
  public languages: Language[];
  public profile: EnvironmentProfile;
  public adminToolPath: string;
  public authenticated: Observable<DecodedJwt>;
  public currentLanguageSubscription: Subscription;
  public selectedLanguage: Language;
  public sidenavCollapsed: boolean;
  public isColorRoute: boolean;
  public isMobile: boolean;
  public alertCount: AlertCount;
  public alertsConfigured: boolean;

  private sidenavSubscription: Subscription;
  private screensizeSubscription: Subscription;

  constructor(
    private languagesService: LanguagesService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private mobileCheck: MobileCheck,
    private store: Store<fromRoot.State>,
    private navigationService: NavigationService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.languages = this.languagesService.getAvailableLanguages();
    this.profile = environment.profile;
    this.adminToolPath = environment.adminPath;
    this.authenticated = this.authenticationService.authentication;

    this.notificationService.getAlertCountObservable().subscribe({
      next: (count: AlertCount) => {
        this.alertCount = count;
      }
    });

    this.notificationService.getAlertUserConfigObservable().subscribe({
      next: (config: AlertUserConfig) => {
        this.alertsConfigured = this.notificationService.alertsConfigured(config);
      }
    });

    this.currentLanguageSubscription = this.languagesService.currentLanguage$.subscribe(
      (currentLanguage: Language) => (this.selectedLanguage = currentLanguage),
    );
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const activeSidenavItem: SidenavItem = this.navigationService.getActiveSidenavItem(
          event.url,
          this.isColorRoute,
        );
        this.store.dispatch(new SidenavActions.SetSelectedSidenavItemAction(activeSidenavItem));
        const isColorRoute = this.navigationService.isColorRoute(event.url);
        this.store.dispatch(new SidenavActions.SetIsColorRoute(isColorRoute));
      }
    });

    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.sidenavCollapsed = sidenav.sidenavCollapsed;
      this.isColorRoute = sidenav.isColorRoute;
      // We need to execute updateContentMargins() to reduce the margin that is inserted to the child components because of the "over" sidenav mode
      if (this.sideNavContainer && this.isMobile) {
        setTimeout(() => {
          this.sideNavContainer.updateContentMargins();
        }, 0);
      }
    });
    this.screensizeSubscription = this.mobileCheck.getScreenSize().subscribe((deviceInfo) => {
      this.isMobile = deviceInfo.isMobile;
    });
  }

  public ngOnDestroy(): void {
    if (this.screensizeSubscription) {
      this.screensizeSubscription.unsubscribe();
    }
    if (this.sidenavSubscription) {
      this.sidenavSubscription.unsubscribe();
    }
  }

  public logout(): void {
    this.authenticationService.logout().subscribe((authenticationResult: AuthenticationResult) => {
      this.router.navigate([authenticationResult.route2NavigateTo]);
    });
    this.resetStatus();
  }

  private resetStatus(): void {
    this.store.dispatch(new ToggleSidenavCollapsedAction(true));
  }
}
