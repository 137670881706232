import { Injectable } from '@angular/core';
import { IFdgData } from '../../models/chart-fdg.model';

/**
 * Provides functionality to check if color difference data is valid and there displayable.
 */
@Injectable({
  providedIn: 'root',
})
export class IfdgDataValidatorService {
  constructor() {}

  public isValid(fdgData: IFdgData): boolean {
    if (fdgData == null || fdgData.data == null || fdgData.angles == null) {
      return false;
    }
    // set standard scale if not present in response
    if (fdgData.scale === null) {
      fdgData.scale = [-3.0, 3.0];
    }
    return true;
  }
}
