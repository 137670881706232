<div class="row px-1">
  <div class="col-12">
    <h4 class="first">{{ name }}</h4>
  </div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.version' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ version }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.buildInfo' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">
    <a href="/assets/build.info" target="blank">{{ 'app.openBuildInfo' | translate }}</a>
  </div>
</div>
