import { Component, Input, OnInit } from '@angular/core';

/**
 * Wraps charts and shows the gradient according to color-system and quadrant.
 */
@Component({
  selector: 'colimo-canvas-wrapper',
  templateUrl: 'canvas-wrapper.component.html',
  styleUrls: ['canvas-wrapper.component.scss']
})
export class CanvasWrapperComponent implements OnInit {

  @Input() public chartType: string;
  @Input() public quadrant: number;
  @Input() public isFixedHeight: boolean;

  constructor() {
  }

  public ngOnInit(): void {
  }

  /**
   * Returns class based on chartType and quadrant value.
   */
  public getGradientClass(): string {
    let gradientClass: string = 'is-' + this.chartType;

    if (this.quadrant) {
      gradientClass += '-Q' + this.quadrant;
    }

    return gradientClass;
  }

}
