
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IBatch } from 'src/app/models/batch.model';
import * as ForecastActions from '../../actions/forecast.actions';
import * as fromRoot from '../../reducers';
import { ProjectService } from '../../services/project/project.service';
import { IBatchForecastMeasurement } from '../../models/batch-forecast-measurement.model';

/**
 * Represents the Forecast calculation field. Manages user inputs and shows CPI accordingly.
 */
@Component({
  selector: 'colimo-forecast',
  styleUrls: ['forecast.component.scss'],
  templateUrl: 'forecast.component.html'
})
export class ForecastComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public referenceBatches: any;
  @Input() public batchId: number;
  @Input() public selectedForecastMeasurement: IBatchForecastMeasurement;

  @Output() eventForecastInit: EventEmitter<number> = new EventEmitter();

  public calculatedCPI;
  public selectedReference;
  public isLoading = false;

  private storedForecasts: any[];

  private calculatedForecasts$: Observable<any>;
  private stop$: Subject<boolean> = new Subject();

  constructor(
    private projectService: ProjectService,
    private store: Store<fromRoot.State>,
  ) {}

  public ngOnInit(): void {
    // Fetch calculated forecasts from store
    this.calculatedForecasts$ = this.store.select(fromRoot.getCalculatedForecasts);
    this.calculatedForecasts$.pipe(takeUntil(this.stop$)).subscribe(forecasts => {
      this.initForecast(forecasts);
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  /**
   * Is called when the selected forecast measurement is changed
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedForecastMeasurement) {
      if (!changes.selectedForecastMeasurement.currentValue) {
        this.calculatedCPI = undefined;
      } else if (changes.selectedForecastMeasurement.currentValue && this.selectedReference) {
        this.showSelectedForecast();
      }
    }
  }

  public selectedReferenceChanged(event: any): void {
    if (event.value) {
      this.showSelectedForecast();
    }
  }

  public resetSelectedReference(): void {
    this.selectedReference = undefined;
    this.calculatedCPI = undefined;
  }

  /**
   * Handle calculation button click.
   */
  public onSubmit(): void {
    if (!this.selectedReference || !this.selectedForecastMeasurement) {
      return;
    }
    this.isLoading = true;

    this.loadCalculatedForecast().pipe(take(1)).subscribe((forecast: IBatch) => {
      const calculatedForecast = this.createForecastObject(forecast);
      this.addForecastToStore(calculatedForecast);
    });
  }

  /**
   * Check for existing calculated forecast and show accordingly.
   */
  private initForecast(forecasts: any[]): void {
    let forecastItem;
    this.storedForecasts = forecasts;
    if (!this.selectedReference) {
      const forecastItemForBatch = this.findForecastsByBatch(forecasts, this.batchId);
      // display the newest calculated forecast by default
      forecastItem = forecastItemForBatch[0];
    }

    if (forecasts.length && forecastItem) {
      // sets KPI value
      this.calculatedCPI = forecastItem.value;
      // sets selected reference
      this.selectedReference = this.referenceBatches.find(
        (item) => {
          return item.id === forecastItem.reference;
        });
      this.eventForecastInit.emit(forecastItem.selectedForecastMeasurement);
    }
  }

  /**
   * Call service and return newly calculated forecast.
   */
  private loadCalculatedForecast(): Observable<IBatch> {
    return this.projectService.getForecastBatchById({
      id: this.batchId,
      reference: this.selectedReference.id,
      forecastMeasurementId: this.selectedForecastMeasurement.id
    });
  }

  /**
   * Find calculatedForecast by unique batch ID in Array sort by timestamp.
   */
  private findForecastsByBatch(forecasts: any[], id: number): any[] {
    return forecasts.filter((item) => {
      return item.batchId === id;
    }).sort((a, b) => b.forecastCreatedTime - a.forecastCreatedTime);
  }

  /**
   * Create object which can be placed in store.
   */
  private createForecastObject(forecast: IBatch): any {
    return Object.assign(
      { batchId: forecast.batchId},
      forecast.cpiForecast,
      { reference: this.selectedReference.id, selectedForecastMeasurement: this.selectedForecastMeasurement.id, forecastCreatedTime: new Date().getTime() }
    );
  }

  /**
   * Displays a stored forecast for the current selection if available
   * @private
   */
  private showSelectedForecast() {
    if (this.storedForecasts) {
      const storedForecast = this.findForecast(this.storedForecasts, this.batchId, this.selectedReference, this.selectedForecastMeasurement);
      if (storedForecast) {
        this.calculatedCPI = storedForecast.value;
      } else {
        this.calculatedCPI = undefined;
      }
    }
  }

  /**
   * Find calculatedForecast by unique batch ID, reference batch and forecast measurement in Array.
   */
  private findForecast(forecasts: any[], id: number, selectedReference: any, selectedForecastMeasurement: IBatchForecastMeasurement): any {
    if (selectedReference && selectedForecastMeasurement) {
      return forecasts.find((item) => {
        return item.batchId === id && item.reference === selectedReference.id && item.selectedForecastMeasurement === selectedForecastMeasurement.id;
      });
    }
    return null;
  }

  private addForecastToStore(forecast: any): void {
    this.isLoading = false;

    this.store.dispatch(new ForecastActions.AddOrUpdateForecast(forecast));

    this.calculatedCPI = forecast.value;
  }
}
