import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SidenavItem } from '../../enums/sidenav-item.enum';

/**
 * Service responsible for navigating within the application.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  /**
   * Handles navigation when a http error occurs.
   * Navigates to login page if authentication is not valid.
   * Otherwise it will be navigated to an error page.
   */
  public navigateOnHttpError(error: HttpErrorResponse): void {
    if (this.isAuthenticationValid(error)) {
      this.navigateToErrorRoute(error.status);
    } else {
      this.navigateToLoginRoute();
    }
  }

  public navigateToLoginRoute(): void {
    this.router.navigate([environment.loginRoute], { replaceUrl: true });
  }

  public navigateToGraphTab(
    projectId: string,
    batchId: number,
    lineId: string,
    preselectedModelId: string,
    tabId: number,
    isColorRoute: boolean,
    projectPage?: number,
  ): void {
    let graphTab: string;
    switch (tabId) {
      case 0: {
        graphTab = 'mwg';
        break;
      }
      case 1: {
        graphTab = 'fdg';
        break;
      }
      case 2: {
        graphTab = 'fog';
        break;
      }
      case 3: {
        graphTab = 'lpg';
        break;
      }
      default: {
        graphTab = 'mwg';
        break;
      }
    }
    let routerLink = ['/project', projectId];
    if (isColorRoute) {
      routerLink = routerLink.concat(['color']);
    }
    routerLink = routerLink.concat(['batch', batchId.toString(), 'line', lineId]);
    if (preselectedModelId) {
      routerLink = routerLink.concat(['model', preselectedModelId]);
    }
    routerLink = routerLink.concat(['chart', graphTab, projectPage.toString()]);
    this.router.navigate(routerLink);
  }

  public navigateToGraphTabMultipage(
    projectId: string,
    batchIds: string,
    lineIds: string,
    preselectedModelId: string,
    tabId: number,
    isColorRoute: boolean,
    projectPage?: number,
  ): void {
    let graphTab: string;
    switch (tabId) {
      case 0: {
        graphTab = 'mwg';
        break;
      }
      case 1: {
        graphTab = 'fdg';
        break;
      }
      case 2: {
        graphTab = 'fog';
        break;
      }
      default: {
        graphTab = 'mwg';
        break;
      }
    }
    let routerLink = ['/project', projectId];
    if (isColorRoute) {
      routerLink = routerLink.concat(['color']);
    }
    routerLink = routerLink.concat(['multi-batch', batchIds, 'line', lineIds]);
    if (preselectedModelId) {
      routerLink = routerLink.concat(['model', preselectedModelId]);
    }
    routerLink = routerLink.concat(['chart', graphTab, projectPage.toString()]);
    this.router.navigate(routerLink);
  }

  public getActiveSidenavItem(route: string, isColorRoute: boolean): SidenavItem {
    if (!route || route === '') {
      return SidenavItem.COLOR;
    }
    const extractedRoute = route.substring(1);
    if (['color', 'paintline', 'model', 'car-report', 'report', 'alien-check', 'notification-center'].includes(extractedRoute)) {
      switch (extractedRoute) {
        case 'paintline':
          return SidenavItem.PAINTLINE;
        case 'model':
          return SidenavItem.MODEL;
        case 'car-report':
          return SidenavItem.CAR_REPORT;
        case 'report':
          return SidenavItem.REPORT;
        case 'alien-check':
          return SidenavItem.ALIEN_CHECK;
        case 'notification-center':
          return SidenavItem.NOTIFICATION;
        default:
          return SidenavItem.COLOR;
      }
    }
    if (extractedRoute.includes('app-info')) {
      return null;
    }
    if (route.includes('model') && !isColorRoute) {
      return SidenavItem.MODEL;
    }
    if (route.includes('line') && !isColorRoute) {
      return SidenavItem.PAINTLINE;
    }
    return SidenavItem.COLOR;
  }

  public isColorRoute(route: string): boolean {
    return route.includes('color');
  }

  private navigateToErrorRoute(status: number): void {
    this.router.navigate(['error/', status], { replaceUrl: true });
  }

  private isAuthenticationValid(error: HttpErrorResponse): boolean {
    return !(error.status === 401 || error.status === 403);
  }
}
