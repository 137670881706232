<div *ngIf="locations && locations.length !== 0 && !isLoading" class="row px-1 filter-row">
  <div class="col-md-2 col-sm-12 mr-1">
    <label class="filter-label">{{ 'reports.filterLabel.site' | translate }}</label>
    <div class="switcher__wrapper location-switcher__wrapper">
      <select
        name="location-switcher"
        id="locationSwitch"
        class="switcher"
        [(ngModel)]="alienCheckOptions.location"
        (ngModelChange)="onLocationChanged($event)"
      >
        <option *ngFor="let location of locations" [ngValue]="location">{{ location.text }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-2 col-sm-12 mr-1">
    <label class="filter-label">{{ 'reports.filterLabel.carType' | translate }}</label>
    <div class="switcher__wrapper carType-switcher__wrapper">
      <select
        name="carType-switcher"
        id="carTypeSwitch"
        class="switcher"
        [(ngModel)]="alienCheckOptions.carType"
        (ngModelChange)="onCarTypeChanged($event)"
      >
        <option *ngFor="let carType of carTypes" [ngValue]="carType">{{ carType.text }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-2 col-sm-12 mr-1">
    <label class="filter-label">{{ 'reports.reportTable.color' | translate }}</label>
    <div class="switcher__wrapper color-switcher__wrapper">
      <select
        name="color-switcher"
        id="colorSwitch"
        class="switcher"
        [(ngModel)]="alienCheckOptions.color"
        (ngModelChange)="onColorChanged($event)"
      >
        <option *ngFor="let color of colors" [ngValue]="color">{{ color.colorName }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-3 col-sm-12 mr-1">
    <label class="filter-label">{{ 'reports.filterLabel.measurement' | translate }}</label>
    <div class="input-group file">
      <label class="input-group-btn">
        <span class="btn btn-info">
          {{ 'reports.chooseFile' | translate }}
          <input type="file" accept=".asc" style="display: none" (change)="readLatestSelectedFile($event)" />
        </span>
      </label>
      <input type="text" class="form-control" name="fileName" [ngModel]="measurementFileName" readonly />
    </div>
  </div>
  <div class="col-md-1 col-6 tolerances">
    <label class="filter-label">
      {{ 'dashboard.tolerances' | translate }}
    </label>
    <div class="btn-group">
      <button class="btn is-normalcase" (click)="toggleToleranceSystem(true)" [class.is-active]="tolerancesOn">
        {{ 'missing.on' | translate }}
      </button>
      <button class="btn is-normalcase" (click)="toggleToleranceSystem(false)" [class.is-active]="!tolerancesOn">
        {{ 'missing.off' | translate }}
      </button>
    </div>
  </div>
  <div class="col-md-1 col-6 pr-0 upload">
    <button class="btn is-normalcase is-active" [disabled]="!areOptionsValidToSubmit()" (click)="performAlienCheck()">
      {{ 'reports.filterLabel.upload' | translate }}
    </button>
  </div>
</div>
<ng-container *ngIf="(!locations || locations.length === 0) && !isLoading">
  <span>
    {{ 'reports.reportTable.noData' | translate }}
  </span>
</ng-container>
