import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime, map, Subject, Subscription, takeUntil } from 'rxjs';
import { CarFilterOptions } from 'src/app/models/car-filter-options.model';
import { Car } from 'src/app/models/car.model';
import { KeyText } from 'src/app/models/key-text.model';
import { CarService } from 'src/app/services/car/car.service';
import { KeyTextService } from 'src/app/services/key-text/key-text.service';
import { TypeOfReport } from 'src/app/shared/enums/type-of-report.enum';

@Component({
  selector: 'colimo-car-report-configuration',
  templateUrl: './car-report-configuration.component.html',
  styleUrls: ['./car-report-configuration.component.scss'],
})
export class CarReportConfigurationComponent implements OnInit, OnDestroy {
  @Input() public filterOptions: CarFilterOptions;
  @Input() public isCarReportExportLayout: boolean;
  @Input() public carBodyStyleLoaded: boolean;
  @Input() public selectedTypeOfReport: string;
  @Output() public initiateSearch = new EventEmitter<void>();
  @Output() public hideComponentsForCarsSearch = new EventEmitter<void>();
  @Output() public displayTypeOfReport = new EventEmitter<string>();
  @Output() public exportCarReport = new EventEmitter<void>();
  @Output() public usageOfUntouchedFilters = new EventEmitter<boolean>();

  private static readonly DEBOUNCE_TIME_IN_MILLISECONDS = 1000;

  public carId = new FormControl<string>('');
  public vehicleTypes: string[];
  public charactersCount: number;
  public isHidden: boolean;
  public isLoading: boolean;
  public locations: KeyText[];
  public selectedLocation: KeyText;
  public carsForLocation: Car[];
  public isSearchingCar: boolean;
  public typesOfReport: string[];

  private stop$: Subject<boolean> = new Subject();
  private searchCarsForLocationSubscription: Subscription;

  private useUntouchedFilters: boolean = true;

  // This subject is needed as a wrapper in which the debounceTime operator can be used to delay the search
  private debouncedSearchSubject: Subject<string> = new Subject();

  constructor(private keyTextService: KeyTextService, private carService: CarService) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.charactersCount = 2;
    this.isHidden = true;
    this.isSearchingCar = false;
    this.typesOfReport = [TypeOfReport.DE, TypeOfReport.DLABCH, TypeOfReport.DLABCH_AVERAGE_PLOT];
    this.initializeDebouncedSearch();
    this.loadLocations();
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public searchCarsForLocation(): void {
    if (this.searchCarsForLocationSubscription) {
      this.searchCarsForLocationSubscription.unsubscribe();
    }
    // search should only performed if at least defined number of characters are typed in
    if (this.carId.value && this.carId.value.length >= this.charactersCount) {
      this.isSearchingCar = true;
      this.filterOptions.carId = this.carId.value;
      this.hideComponentsForCarsSearch.emit();
      // We need to pass any value that invokes the subject to start the search
      this.debouncedSearchSubject.next('START_PROCESSING');
    }
  }

  public onCarSelected(car: Car): void {
    this.hideSearchResults();
    this.filterOptions.carId = car.vehicleId;
    // the first time a car gets selected get additional filteroptions from the car
    if (this.useUntouchedFilters) {
      this.filterOptions.tolerancesOn = car.toleranceOn !== false;
    }
    this.initiateSearch.emit();
  }

  public hideSearchResults(): void {
    this.isHidden = true;
  }

  public loadLocations(): void {
    this.keyTextService.getLocations().subscribe((locations: KeyText[]) => {
      this.locations = locations.sort((a: KeyText, b: KeyText) => a.text.localeCompare(b.text));
      if (this.locations.length !== 0) {
        this.selectedLocation = this.locations[0];
        this.filterOptions.locationId = Number(this.locations[0].key);
      }
      this.isLoading = false;
    });
  }

  public onLocationChanged(changedLocationKeyEvent: MatSelectChange): void {
    const changedLocationKey = changedLocationKeyEvent.value.key;
    this.filterOptions.locationId = changedLocationKey;
    this.searchCarsForLocation();
    this.setUsageOfUntouchedFilters(true);
  }

  public onFilterOptionsChanged(event: any): void {
    this.initiateSearch.emit();
    // only the tolerance switch should be considered for filter option change (currently bumpers and in case of plot lab/ch switch are ignored)
    if (event && event.source && ('tolerancesOn' === event.source.name)) {
      this.setUsageOfUntouchedFilters(false);
    }
  }

  public onTypeOfReportChanged(): void {
    this.displayTypeOfReport.emit(this.selectedTypeOfReport);
    this.setUsageOfUntouchedFilters(false);
  }

  public exportReport(): void {
    this.exportCarReport.emit();
  }

  private initializeDebouncedSearch(): void {
    this.debouncedSearchSubject
      .pipe(
        takeUntil(this.stop$),
        debounceTime(CarReportConfigurationComponent.DEBOUNCE_TIME_IN_MILLISECONDS),
        map(() => {
          this.carService.searchCar(this.filterOptions).subscribe((cars: Car[]) => {
            this.carsForLocation = cars;
            this.isSearchingCar = false;
            this.isHidden = false;
          });
        }),
      )
      .subscribe();
  }

  private setUsageOfUntouchedFilters(value: boolean): void {
    this.useUntouchedFilters = value;
    this.usageOfUntouchedFilters.emit(value);
  }
}
