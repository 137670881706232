import { Pipe, PipeTransform } from '@angular/core';
import { DeltaValue } from 'src/app/models/delta-value.model';

@Pipe({
  name: 'deltaValuesCssClass',
})
export class DeltaValuesCssClassPipe implements PipeTransform {
  public transform(deltaValue: DeltaValue, tolerancesOn: boolean): string {
    if (deltaValue == null || deltaValue.dE == null || tolerancesOn == null) {
      return '';
    }

    // no status can be determined without tolerances
    if (tolerancesOn === false) {
      return 'gray';
    }

    return this.determineDeltaValuesCssClass(deltaValue);
  }

  private determineDeltaValuesCssClass(deltaValue: DeltaValue): string {
    if (deltaValue.dE > 1.7) {
      return 'red';
    }

    if (deltaValue.dE <= 1.4) {
      return 'green';
    }

    return 'orange';
  }
}
