import * as TinterAdditionsActions from '../actions/tinter-additions.actions';
import { TinterAdditions } from '../models/tinter-additions.model';

const initialState: TinterAdditions = {
  details: [],
  count: 0,
};

/**
 * Reducer for adding and removing tinter additions data.
 */
export function reducer(state: TinterAdditions = initialState, action: TinterAdditionsActions.Actions): TinterAdditions {
  switch (action.type) {
    case TinterAdditionsActions.ACTION_TYPES.CREATE: {
      const extendedState = Object.assign({}, state);
      extendedState.details = [...action.payload];
      return extendedState;
    }

    case TinterAdditionsActions.ACTION_TYPES.COUNT: {
      const extendedState = Object.assign({}, state);
      extendedState.count = action.payload.count;
      return extendedState;
    }

    // Resets to initial empty state
    case TinterAdditionsActions.ACTION_TYPES.DELETE_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}
