import { Component, Input, OnInit } from '@angular/core';
import { AverageKpis } from '../../../models/viewmodels/average-kpis.viewmodel';

/**
 * Component for displaying the average KPIs for each site.
 */
@Component({
  selector: 'colimo-average-kpis',
  templateUrl: './average-kpis.component.html',
  styleUrls: ['./average-kpis.component.scss'],
})
export class AverageKpisComponent implements OnInit {
  @Input()
  public averageKpis: AverageKpis[];

  public constructor() {}

  public ngOnInit(): void {}
}
