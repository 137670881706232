<div class="row px-2 filter-row">
  <div class="col-sm-12 customers-col">
    <label class="filter-label">{{ 'reports.filterLabel.customer' | translate }}</label>
    <div class="filter">
      <div class="switcher__wrapper customer-switcher__wrapper">
        <select name="customer-switcher" id="customerSwitch" class="switcher" (change)="onCustomerChanged($event)">
          <option *ngFor="let customer of customers" [value]="customer.key">{{ customer.text }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-4">
    <label class="filter-label">{{ 'reports.filterLabel.lines' | translate }}</label>
    <div class="dropdown bottom-left lines-dropdown" colimoOutsideClick (clickOutside)="closeLinesDropdown()">
      <div class="switcher__wrapper line-switcher__wrapper">
        <button type="button" class="dropdown-toggle switcher" (click)="toggleLinesDropdown()">
          {{ selectedLinesValues | filterValuesTransformation: 'lines' | async }}
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="col-md-12">
          <div *ngIf="locationWithLines && locationWithLines.length > 0; else noLinesAvailable">
            <ng-container *ngFor="let location of locationWithLines">
              <div class="info-text lines-part location-line-group">
                <clr-checkbox-wrapper class="checkbox-inline">
                  <input
                    type="checkbox"
                    class="color-checkbox"
                    clrCheckbox
                    [name]="location.locationName"
                    [(ngModel)]="location.selected"
                    (change)="onLocationChanged(location)"
                  />
                  <label
                    >{{ location.locationName }}
                    <colimo-icons
                      [icon]="'check'"
                      [width]="'12px'"
                      [height]="'12px'"
                      [top]="'2px'"
                      class="check-icon"
                    ></colimo-icons>
                  </label>
                </clr-checkbox-wrapper>
                <ng-container *ngFor="let line of location.lines; let i = index">
                  <div class="info-text line">
                    <clr-checkbox-wrapper class="checkbox-inline">
                      <input
                        type="checkbox"
                        class="line-checkbox"
                        clrCheckbox
                        [name]="line.name"
                        [(ngModel)]="line.selected"
                        (change)="onLineChanged(i, location)"
                      />
                      <label
                        >{{ line.name }}
                        <colimo-icons
                          [icon]="'check'"
                          [width]="'12px'"
                          [height]="'12px'"
                          [top]="'2px'"
                          class="check-icon"
                        ></colimo-icons>
                      </label>
                    </clr-checkbox-wrapper>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ng-template #noLinesAvailable
            ><p class="noLinesAvailable">{{ 'reports.filterLabel.noLines' | translate }}</p></ng-template
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 kpis-col">
    <label class="filter-label">KPIs</label>
    <div class="filter">
      <colimo-filter-checkboxes-part
        [partName]="'kpi'"
        [filterItems]="kpis"
        [filterItemsSelected]="kpiSelected"
        (filterItemChangedEvent)="onKpiChanged($event)"
      ></colimo-filter-checkboxes-part>
    </div>
  </div>
  <div class="col-sm-12 col-md-8 date-col form-group">
    <label class="filter-label">{{ 'reports.filterLabel.dateRange' | translate }}</label>
    <div class="row">
      <input
        type="text"
        placeholder="{{ 'reports.filterLabel.startDate' | translate }}"
        bsDatepicker
        class="form-control switcher col-5 datepicker"
        [bsConfig]="bsConfig"
        [bsValue]="startDate"
        (bsValueChange)="onStartDateChanged($event)"
      />
      <label>-</label>
      <input
        type="text"
        placeholder="{{ 'reports.filterLabel.endDate' | translate }}"
        bsDatepicker
        class="form-control switcher col-5 datepicker"
        [bsValue]="endDate"
        [bsConfig]="bsConfig"
        (bsValueChange)="onEndDateChanged($event)"
      />
    </div>
  </div>
  <div class="col-sm-12 col-md-12 products-col">
    <label class="filter-label">{{ 'reports.filterLabel.products' | translate }}</label>
    <div class="dropdown bottom-left products-dropdown" colimoOutsideClick (clickOutside)="closeProductsDropdown()">
      <div class="switcher__wrapper product-switcher__wrapper">
        <button type="button" class="dropdown-toggle switcher" (click)="toggleProductsDropdown()">
          {{ selectedProductsValues | filterValuesTransformation: 'products' | async }}
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="col-md-12">
          <div *ngIf="colorsWithProducts && colorsWithProducts.length > 0; else noProductsAvailable">
            <ng-container *ngFor="let color of colorsWithProducts">
              <div class="info-text products-part color-product-group">
                <clr-checkbox-wrapper class="checkbox-inline">
                  <input
                    type="checkbox"
                    class="color-checkbox"
                    clrCheckbox
                    [name]="color.colorName"
                    [(ngModel)]="color.selected"
                    (change)="onColorChanged(color)"
                  />
                  <label
                    >{{ color.colorName }}
                    <colimo-icons
                      [icon]="'check'"
                      [width]="'12px'"
                      [height]="'12px'"
                      [top]="'2px'"
                      class="check-icon"
                    ></colimo-icons>
                  </label>
                </clr-checkbox-wrapper>
                <ng-container *ngFor="let product of color.products">
                  <div class="info-text product">
                    <clr-checkbox-wrapper class="checkbox-inline">
                      <input
                        type="checkbox"
                        class="product-checkbox"
                        clrCheckbox
                        [name]="product.productName"
                        [(ngModel)]="product.selected"
                        (change)="onProductChanged(product)"
                      />
                      <label
                        >{{ product.productName }}
                        <colimo-icons
                          [icon]="'check'"
                          [width]="'12px'"
                          [height]="'12px'"
                          [top]="'2px'"
                          class="check-icon"
                        ></colimo-icons>
                      </label>
                    </clr-checkbox-wrapper>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ng-template #noProductsAvailable
            ><p class="noProductsAvailable">{{ 'reports.filterLabel.noProducts' | translate }}</p></ng-template
          >
        </div>
      </div>
    </div>
  </div>
</div>
