import { Component, Input } from '@angular/core';

@Component({
  selector: 'colimo-loader',
  providers: [],
  styleUrls: ['loader.component.scss'],
  templateUrl: 'loader.component.html'
})
export class LoaderComponent {
  @Input()
  public size = 32;

  @Input()
  public background = '#fff';

  @Input()
  public top = '50%';

  @Input()
  public isRelative = false;
}
