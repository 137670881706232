import { Pipe, PipeTransform } from '@angular/core';
import { Iproject } from '../../models/project.model';

/**
 * Returns total number of warnings of given projects.
 */
@Pipe({
  name: 'warningsCount',
})
export class WarningsCountPipe implements PipeTransform {
  public transform(projects: Iproject[]): number {
    if (projects == null) {
      return 0;
    }
    return projects.filter((project: Iproject) => (project.userAlertData && project.userAlertData.length > 0)).length;
  }
}
