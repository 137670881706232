import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'kpiLabel' })
export class KpiLabelPipe implements PipeTransform {
  /**
   * Adds a label string to a given class based on the given kpiColor.
   */
  public transform(className: string, kpiColor: string): string {
    if (!className || !kpiColor) {
      return '';
    }
    switch (true) {
      case kpiColor === 'red':
        return className + 'danger';
      case kpiColor === 'yellow':
        return className + 'warning';
      case kpiColor === 'green':
        return className + 'success';
      case kpiColor === 'gray':
        return className + 'gray';
      case kpiColor === 'black':
        return className + 'dark';
      default:
        return className + 'info';
    }
  }
}
