import * as ModelDashboardActions from '../actions/model-dashboard.actions';
import { ModelDashboard } from '../models/model-dashboard.model';

const initialState: ModelDashboard[] = [];

/**
 * Reducer for adding and modifying dashboard and project models
 */
export function reducer(
  state: ModelDashboard[] = initialState,
  action: ModelDashboardActions.Actions,
): ModelDashboard[] {
  switch (action.type) {
    case ModelDashboardActions.ACTION_TYPES.CREATE_ITEMS: {
      return action.payload;
    }

    default:
      return state;
  }
}
