import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DashboardSelection } from 'src/app/models/dashboard-selection.model';
import { PaintlineDashboard } from 'src/app/models/paintline-dashboard.model';
import { ScreenSize } from 'src/app/models/screen-size.model';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'colimo-paintline-dashboard',
  providers: [DashboardService],
  templateUrl: './paintline-dashboard.component.html',
  styleUrls: ['./paintline-dashboard.component.scss'],
})
export class PaintlineDashboardComponent implements OnInit {
  public heading: string;
  public isLoading = true;
  public dashboard$: Observable<PaintlineDashboard[]>;
  public dashboardSelection$: Observable<DashboardSelection>;
  public isMobile$: Observable<ScreenSize>;

  constructor(
    private dashboardService: DashboardService,
    private store: Store<fromRoot.State>,
    private mobileCheck: MobileCheck,
  ) {}

  ngOnInit(): void {
    this.heading = 'sidenav.paintline';
    this.dashboardService.getPaintlineDashboard().subscribe(() => {
      this.isLoading = false;
    });
    this.dashboard$ = this.store.select(fromRoot.getPaintlineDashboard);
    this.dashboardSelection$ = this.store.select(fromRoot.getDashboardSelection);
    this.isMobile$ = this.mobileCheck.getScreenSize();
  }

  public customerSelected(newCustomer: string, storedCustomer: string): void {
    if (newCustomer === storedCustomer) {
      this.dashboardService.setCurrentDashboardSelection({ customer: null, line: null, model: null });
    } else {
      this.dashboardService.setCurrentDashboardSelection({
        customer: newCustomer,
        line: null,
        model: null,
      });
    }
  }

  public paintlineSelected(customer: string, newPaintline: string, storedPaintline: string): void {
    if (newPaintline === storedPaintline) {
      this.dashboardService.setCurrentDashboardSelection({
        customer: customer,
        line: null,
        model: null,
      });
    } else {
      this.dashboardService.setCurrentDashboardSelection({
        customer: customer,
        line: newPaintline,
        model: null,
      });
    }
  }
}
