import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {MobileCheck} from '../../services/mobile-check/mobile-check.service';
import {NotificationService} from '../../services/notification/notification.service';
import {AlertUserConfig} from '../../models/alert-user-config';
import {AuthenticationService, DecodedJwt} from 'colimo-ui-library';
import {AlertUserDataPage} from '../../models/alert-user-data-page';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AlertUserData} from '../../models/alert-user-data';
import {AlertUserStatus} from '../../models/alert-user-status';
import {KeyTextService} from '../../services/key-text/key-text.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {PageSortParam} from '../../models/PageSortParam';

@Component({
  selector: 'colimo-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss']
})
export class NotificationPageComponent implements OnInit, OnDestroy {

  public isMobile: boolean;
  public alertConfig: AlertUserConfig;
  public alertsConfigured: boolean;
  public alertUserData: AlertUserDataPage;
  public displayedColumns: string[] = ['alertLevel', 'colorName', 'productNr', 'batchNr', 'line', 'model', 'info', 'createdAt', 'linkToPlot', 'hideAlert'];
  public pageSizeOptions: number[] = [10, 20, 50];
  public pageSize = 50;
  public currentPage = 0;
  public totalSize = 0;
  public selectedTab: number;
  public kpiWarnings = [ {name: 'CPI', value: 'kpiCpi'}, { name: 'TPI', value: 'kpiBpi'}, {name: 'LPI', value: 'kpiLpi'}, { name:'API', value: 'kpiApi'}, { name: 'CHI', value: 'kpiChi' }];

  private stop$: Subject<boolean> = new Subject();
  private userId: string;
  private componentTranslations: string[] = [];
  private sortParams: PageSortParam[] = [];

  constructor(private mobileCheck: MobileCheck,
              private notificationService: NotificationService,
              private authenticationService: AuthenticationService,
              private snackBar: MatSnackBar,
              private translateService: TranslateService,
              private keyTextService: KeyTextService,
              private router: Router) {
    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras && currentNavigation.extras.state && currentNavigation.extras.state.selectedTab) {
      this.selectedTab = currentNavigation.extras.state.selectedTab;
    } else {
      this.selectedTab = 0;
    }
  }

  ngOnInit(): void {
    this.keyTextService.getComponentTranslationsObservable().pipe(takeUntil(this.stop$)).subscribe({
      next: (compTranslations: string[]) => {
        this.componentTranslations = compTranslations;
        //this.loadAlerts();
      }, error: () => {
        // nothing
      }
    });

    this.notificationService.getAlertUserConfigObservable().pipe(takeUntil(this.stop$)).subscribe({
      next: (alertConfig: AlertUserConfig) => {
        this.alertConfig = alertConfig;
        this.alertsConfigured = this.notificationService.alertsConfigured(this.alertConfig);
        this.loadAlerts();
        this.showInfoMsg(this.translateService.instant('notification.configSavedSuccess'));
      }, error: () => {
        // nothing
        this.showInfoMsg(this.translateService.instant('notification.restError'));
      }
    });

    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
      if (this.isMobile) {
        // remove link to plots from displayed columns if mobile display
        this.displayedColumns = ['alertLevel', 'colorName', 'productNr', 'batchNr', 'line', 'model', 'info', 'createdAt', 'hideAlert'];
      } else {
        this.displayedColumns = ['alertLevel', 'colorName', 'productNr', 'batchNr', 'line', 'model', 'info', 'createdAt', 'linkToPlot', 'hideAlert'];
      }
    });

    this.authenticationService.authentication.pipe(takeUntil(this.stop$)).subscribe({next: (decodedToken: DecodedJwt) => {
      this.userId = decodedToken.sub;
    }});

    this.notificationService.updateAlertCount();
    this.alertUserData = {} as any;
    this.alertUserData.content = [];
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public saveAlertUserConfig() {
    // this will update the alert config and triggers the observable next so a reload via the observable change
    this.notificationService.saveAlertConfig(this.alertConfig);
  }

  public routeToAlertDetails(row: AlertUserData): void {
    let routerLink = ['/project', row.colorId];
    routerLink = routerLink.concat(['batch', row.batchId, 'line', row.line]);

    if (row.model) {
      routerLink = routerLink.concat(['model', row.model]);
    }
    routerLink = routerLink.concat(['chart', this.getChartForAlertType(row)]);

    routerLink = routerLink.concat(['0']);
    this.router.navigate(routerLink, { state: { selectedTab: this.getSelectedTabForAlertType(row) } });
  }

  private getChartForAlertType(data: AlertUserData): string {
    switch (data.typ) {
      case 'WP':
        return 'lpg';
      case 'CP':
        return 'mwg';
      default:
        return 'mwg';
    }
  }

  private getSelectedTabForAlertType(data: AlertUserData): number {
    switch (data.typ) {
      case 'WP':
        return 3;
      case 'CP':
        return 0;
      default:
        return 0;
    }
  }

  public markAsRead(row: AlertUserData): void {
    // status 1 is read
    if (row.readStatus === 0) {
      const alertUserStatus: AlertUserStatus = {userId: this.userId, status: 1, alertId: row.alertId};
      this.notificationService.updateAlertStatus(alertUserStatus).subscribe({
        next: (status: AlertUserStatus) => {
          row.readStatus = status.status;
          this.notificationService.updateAlertCount();
        },
        error: () => {
          // nothing
          this.showInfoMsg(this.translateService.instant('notification.restError'));
        }
      });
    }
  }

  public markAllAsRead(): void {
    // get the first id because that is the newest alertId
    if (this.alertUserData && this.alertUserData.content && this.alertUserData.content.length > 0) {
      // we want to mark all warnings all read so get the max alertId
      const alertId = Math.max(...this.alertUserData.content.map(alert => alert.alertId));
      this.notificationService.updateAllAlertStatusUntil(this.userId, alertId, 1).subscribe({
        next: (updated: number) => {
          this.loadAlerts();
          this.notificationService.updateAlertCount();
          this.showInfoMsg(updated + this.translateService.instant('notification.markedAsRead'));
        }, error: () => {
          // nothing
          this.showInfoMsg(this.translateService.instant('notification.restError'));
        }
      });
    }
  }

  public markAsHidden(alertId: number): void {
    const alertUserStatus: AlertUserStatus = { userId: this.userId, status: 2, alertId: alertId}
    this.notificationService.updateAlertStatus(alertUserStatus).subscribe({
      next: (status: AlertUserStatus) => {        
        // refresh alerts the hidden one should be gone
        this.loadAlerts();
        this.notificationService.updateAlertCount();
      },
      error: () => {
        // nothing
        this.showInfoMsg(this.translateService.instant('notification.restError'));
      }
    });
  }

  public pageEvent(event: any): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadAlerts();
  }

  public sortEvent(event: any) {
    if (event.direction === '') {
      // remove if present
      this.sortParams.splice(this.sortParams.findIndex(param => param.active === event.active));
    } else {
      const index = this.sortParams.findIndex(param => param.active === event.active)
      // already present then update direction
      if (index >= 0) {
        this.sortParams[index].direction = event.direction;
      }
      // add if not present
      else {
        this.sortParams.push({ active: event.active, direction: event.direction });
      }
    }
    this.loadAlerts();
  }

  public alertCheckboxChanged(event: MatCheckboxChange, name: string): void {
    this.alertConfig[name] = event.checked ? 2 : 0;
  }

  public alertKpiAllCheckboxChanged(event: MatCheckboxChange): void {
    if (event.checked) {
      this.alertConfig.kpiCpi = 2;
      this.alertConfig.kpiBpi = 2;
      this.alertConfig.kpiLpi = 2;
      this.alertConfig.kpiApi = 2;
      this.alertConfig.kpiChi = 2;
    } else {
      this.alertConfig.kpiCpi = 0;
      this.alertConfig.kpiBpi = 0;
      this.alertConfig.kpiLpi = 0;
      this.alertConfig.kpiApi = 0;
      this.alertConfig.kpiChi = 0;
    }
  }

  private loadAlerts(): void {
    this.notificationService.getAlerts(this.currentPage, this.pageSize, this.sortParams).subscribe({
      next: (response: AlertUserDataPage) => {
        this.alertUserData = this.translatePossibleInfos(response);
        this.totalSize = response.totalElements;
      },
      error: () => {
        this.showInfoMsg(this.translateService.instant('notification.restError'));
      }
    });
  }

  private translatePossibleInfos(data: AlertUserDataPage): AlertUserDataPage {
    if (data && data.content && data.content.length > 0) {
      for (let i = 0; i < data.content.length; i++) {
        // worst part components need translation
        if (data.content[i].typ === 'WP') {
          const translatedComponent = this.componentTranslations[data.content[i].component] ? this.componentTranslations[data.content[i].component] : data.content[i].component;
          data.content[i].info = data.content[i].info.replace('{component}', translatedComponent);
        }
      }
    }
    return data;
  }

  private showInfoMsg(msg: string): void {
    this.snackBar.open(msg, 'Close', { duration: 1500 });
  }

  protected readonly event = event;
}
