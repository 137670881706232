<mat-expansion-panel class="mat-elevation-z0 flex-column" [expanded]="isOpen">
  <mat-expansion-panel-header class="panel-header justify-space-between"
          (click)="toggle($event)"
          [hidden]="showOnlyOnMobile && !isMobile">
    <mat-panel-title class="panel-title">
      {{headerTitle}}
    </mat-panel-title>
  </mat-expansion-panel-header>
    <ng-content></ng-content>
</mat-expansion-panel>

