<div class="search-field">
  <input
    *ngIf="!sidenavCollapsed"
    #search
    type="search"
    class="search-field-input"
    placeholder="{{ 'global.search' | translate }}"
    (keyup)="onInputChange(search.value)"
    (keyup.enter)="onInputKeypress($event)"
  />

  <span class="search-field-icon" [class.disabled]="(searchTerms | async) === null">
    <mat-icon class="search-icon">search</mat-icon>
  </span>

  <div *ngIf="results && results.length" class="search-autocomplete">
    <ul>
      <li class="result-list-item flex-row" *ngFor="let result of results; let i = index">
        <a
          [routerLink]="['/project/', result.id]"
          [class.is-selected]="selectedResult === i"
          (click)="onProjectSelected(i, $event)"
          class="result-item-link flex-1 flex-column"
        >
          <span *ngIf="!result.batchNumber" class="result-item">{{ result.manufacturer }}</span>
          <span class="result-item flex-column">
            <ng-container *ngIf="result.batchNumber">{{ result.manufacturer }} - </ng-container>
            {{ result.projectName }}
            <ng-container *ngIf="result.productName"> - {{ result.productName }}</ng-container>
          </span>
          <span *ngIf="result.batchNumber" class="result-item">Charge {{ result.batchNumber }}</span>
        </a>

        <colimo-dashboard-controls
          [resultRow]="i"
          [resultId]="result.id"
          [isInDashboard]="result.inDashboard"
          (operationDone)="onProjectSelected($event.index, $event.evt)"
        >
        </colimo-dashboard-controls>
      </li>
    </ul>
  </div>
</div>
