import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChartSlot, IDataSets, IFdgData, Iline } from 'src/app/models/chart-fdg.model';
import { TinterAddition } from 'src/app/models/tinter-addition.model';
import { ZoomDir } from 'src/app/shared/enums/zoom-directions.enum';
import { CorrespondingTinterAdditionsService } from '../corresponding-tinter-additions/corresponding-tinter-additions.service';
import { IfdgDataValidatorService } from '../ifdg-data-validator/ifdg-data-validator.service';

@Injectable({
  providedIn: 'root',
})
export class FdgChartService {
  public scaleDirections$: Subject<ZoomDir>[] = [];

  public chartSlots: ChartSlot[] = [
    // View representation if 3 times 10 charts that will always be rendered regardless of data
    {
      label: 'none',
      quadrant: null,
      zoomInfo: {
        zoomOutEnd: true,
        zoomInEnd: false,
      },
      row: [[], [], [], [], [], [], [], [], [], []],
    },
    {
      label: 'none',
      quadrant: null,
      zoomInfo: {
        zoomOutEnd: true,
        zoomInEnd: false,
      },
      row: [[], [], [], [], [], [], [], [], [], []],
    },
    {
      label: 'none',
      quadrant: null,
      zoomInfo: {
        zoomOutEnd: true,
        zoomInEnd: false,
      },
      row: [[], [], [], [], [], [], [], [], [], []],
    },
  ];

  public chartData: Iline[][][] = [
    // Data representation that will change over time regarding the filters and settings
    [[], [], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], [], []],
  ];

  constructor(
    private correspondingTinterAdditionsService: CorrespondingTinterAdditionsService,
    private ifdgDataValidatorService: IfdgDataValidatorService,
  ) {}

  // create subjects for chartSlot rows
  public initializeScaleDirections(): Subject<ZoomDir>[] {
    this.chartSlots.forEach(() => {
      this.scaleDirections$.push(new Subject());
    });
    return this.scaleDirections$;
  }

  public scaleRow(direction: ZoomDir, row: string, chartSlots: ChartSlot[]): void {
    if (direction === null || direction === undefined || !row || !chartSlots) {
      throw new TypeError('Invalid arguments!');
    }
    const currentRow = chartSlots.findIndex((item) => item.label === row);
    this.scaleDirections$[currentRow].next(direction);
  }

  public populateChartSlots(data: IDataSets): void {
    if (!data) {
      throw new TypeError('Invalid argument!');
    }
    const keyNames = Object.keys(data);

    // Iterate chart slots, assign real data to each slot i.e. DL, DA etc.
    this.chartSlots.forEach((slot, i) => {
      // Convert keys (DL, DA, etc.) into array position numbers (0, 1, etc.)
      const chartType = keyNames[i];
      this.chartSlots[i].label = chartType;
      // Add quadrant number if provided in data
      if (data[chartType] && data[chartType].chQuadrant !== undefined) {
        this.chartSlots[i].quadrant = data[chartType].chQuadrant;
      } else {
        this.chartSlots[i].quadrant = null;
      }
    });
  }

  public populateChartData(data: IDataSets): void {
    if (!data) {
      throw new TypeError('Invalid argument!');
    }
    const keyNames = Object.keys(data);
    // Iterate chart slots, assign real data to each slot i.e. DL, DA etc.
    this.chartSlots.forEach((slot, i) => {
      // Convert keys (DL, DA, etc.) into array position numbers (0, 1, etc.)
      const chartType = keyNames[i];
      // Iterate chart rows [0, ... , 9]
      slot.row.forEach((chart, j) => {
        // reset data here - sometimes old data was displayed which was not congruent to the current chart filter
        this.chartData[i][j] = [];
        if (data[chartType] && data[chartType].dataSets) {
          // Check if response data contains a dataSet in [i][j] nesting
          if (data[chartType].dataSets[j]) {
            // Assign response nesting [i][j] to equivalent chartSlot
            this.chartData[i][j] = data[chartType].dataSets[j];
          }
        }
      });
    });
  }

  public getTinterAdditionsCorrespondingToDates(
    startDateString: string,
    dateRange: string[],
    tinterAdditions: TinterAddition[],
  ): TinterAddition[][] {
    return this.correspondingTinterAdditionsService.getTinterAdditionsCorrespondingToDates(
      startDateString,
      dateRange,
      tinterAdditions,
    );
  }

  public isFdgDataValid(fdgData: IFdgData): boolean {
    return this.ifdgDataValidatorService.isValid(fdgData);
  }
}
