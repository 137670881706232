import * as DashboardSelectionsActions from '../actions/dashboard-selection.actions';
import { DashboardSelection } from '../models/dashboard-selection.model';

const initialState: DashboardSelection = { customer: null, line: null, model: null };

/**
 * Reducer for adding and modifying dashboard and project models
 */
export function reducer(
  state: DashboardSelection = initialState,
  action: DashboardSelectionsActions.Actions,
): DashboardSelection {
  switch (action.type) {
    case DashboardSelectionsActions.ACTION_TYPES.SET_SELECTION: {
      return action.payload;
    }

    default:
      return state;
  }
}
