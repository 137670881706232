import { Pipe, PipeTransform } from '@angular/core';
import { IFdgData } from 'src/app/models/chart-fdg.model';
import { IFogData } from 'src/app/models/chart-fog.model';
import { ILpgData } from 'src/app/models/chart-lpg.model';
import { IMwgData } from 'src/app/models/chart-mwg.model';

@Pipe({
  name: 'tabIlluminant',
})
export class TabIlluminantPipe implements PipeTransform {
  transform(
    chartName: string,
    chartData: IMwgData | IFdgData | IFogData | ILpgData,
    selectedTab: number,
    actualTab: number,
  ): string {
    if (chartData && chartData.lightSource && selectedTab !== null && actualTab !== null && selectedTab === actualTab) {
      return chartName + ' - ' + chartData.lightSource;
    }
    return chartName;
  }
}
