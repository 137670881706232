import { Injectable } from '@angular/core';
import { ColorWithProducts, Product } from '../../models/viewmodels/color-with-products.viewmodel';
import { Line } from '../../models/viewmodels/line.viewmodel';
import { LocationWithLines } from '../../models/viewmodels/location-with-lines.viewmodel';

/**
 * This service is responsible for toggling HTML elements for the kpi report filter.
 */
@Injectable({
  providedIn: 'root',
})
export class KpiReportFilterToggleService {
  constructor() {}

  /**
   * Toggles a dropdown with given class name.
   */
  public toggleDropdownByClassName(className: string): void {
    this.interactWithDropdownByClassName(className, true);
  }

  /**
   * Closes a dropdown with given class name.
   */
  public closeDropdownByClassName(className: string): void {
    this.interactWithDropdownByClassName(className, false);
  }

  /**
   * Toggles corresponding lines of given location.
   */
  public toggleCorrespondingLines(location: LocationWithLines): void {
    if (location && location.lines && location.locationId) {
      const isChecked = location.selected;
      location.lines.forEach((line: Line) => {
        if (isChecked === true) {
          line.selected = true;
        }
        if (isChecked === false) {
          line.selected = false;
        }
      });
    }
  }

  /**
   * Toggles corresponding location of given line.
   */
  public toggleCorrespondingLocation(changedLineIndex: number, locationWithLines: LocationWithLines): void {
    // check location if it is not checked yet
    if (locationWithLines.lines[changedLineIndex].selected && !locationWithLines.selected) {
      locationWithLines.selected = true;
    }

    // uncheck location if all lines are unchecked
    let count = 0;
    locationWithLines.lines.forEach((line, index) => {
      if (!locationWithLines.lines[index].selected) {
        count++;
      }
    });
    if (count === locationWithLines.lines.length) {
      locationWithLines.selected = false;
    }
  }

  /**
   * Toggles corresponding products of given color.
   */
  public toggleCorrespondingProducts(color: ColorWithProducts): void {
    if (color && color.products) {
      const isChecked = color.selected;
      color.products.forEach(product => {
        if (isChecked === true) {
          product.selected = true;
        }
        if (isChecked === false) {
          product.selected = false;
        }
      });
    }
  }

  /**
   * Toggles corresponding color of given product.
   */
  public toggleCorrespondingColor(product: Product, colorsWithProducts: ColorWithProducts[]): void {
    // find corresponding color of given product
    const filteredColor = colorsWithProducts.find((colorWithProducts: ColorWithProducts) => {
      const mayFoundProduct = colorWithProducts.products.find(p => {
        return p.productId === product.productId;
      });
      if (mayFoundProduct != null) {
        return true;
      }
    });

    // check color if it is not checked yet
    if (product.selected && !filteredColor.selected) {
      filteredColor.selected = true;
    }

    // uncheck color if all products are unchecked
    let count = 0;
    filteredColor.products.forEach(p => {
      if (p.selected === false) {
        count++;
      }
    });
    if (count === filteredColor.products.length) {
      filteredColor.selected = false;
    }
  }

  /**
   * Interacts with dropdown by given class name.
   * @param shouldOpen determines if the dropdown should be opened
   */
  private interactWithDropdownByClassName(className: string, shouldOpen: boolean): void {
    const dropdownElements = document.getElementsByClassName(className);
    if (dropdownElements != null && dropdownElements[0] != null) {
      if (dropdownElements[0].classList.contains('open')) {
        dropdownElements[0].classList.remove('open');
      } else {
        if (shouldOpen) {
          dropdownElements[0].classList.add('open');
        }
      }
    }
  }
}
