import * as ProjectActions from '../actions/project.actions';
import { Iproject } from '../models/project.model';

const initialState: Iproject = null;

/**
 * Reducer for adding and modifying dashboard and project models
 */
export function reducer(state: Iproject = initialState, action: ProjectActions.Actions): Iproject {
  switch (action.type) {
    // Creates instance of the current project model
    case ProjectActions.ACTION_TYPES.CREATE_ITEM: {
      return Object.assign({}, action.payload);
    }

    // Creates instance of the current project model
    case ProjectActions.ACTION_TYPES.DELETE_ITEM: {
      return initialState;
    }

    // Updates instance of the current project model
    case ProjectActions.ACTION_TYPES.UPDATE_ITEM: {
      return Object.assign({}, state, action.payload);
    }

    // Inserts or appends batches into the current project model
    case ProjectActions.ACTION_TYPES.INSERT_BATCHES: {

      const extendedState = Object.assign({}, state, {
        batches: [
          ...(state.batches ? state.batches : []),
          ...action.payload
        ]
      });

      return extendedState;
    }

    // Updates the one batch matched by id of the current project model
    case ProjectActions.ACTION_TYPES.UPDATE_BATCH: {

      const extendedState = Object.assign({}, state, {
        batches: state.batches.map((batch) => {
          // Replace the batch found by batchId
          if (batch.batchId === action.payload.batchId) {
            return action.payload;
          }
          return batch;
        })
      });
      return extendedState;
    }

    // Updates inDashboard property of the current project model
    case ProjectActions.ACTION_TYPES.TOGGLE_VISIBILITY: {
      return Object.assign({}, state, { inDashboard: action.payload });
    }

    default:
      return state;
  }
}
