import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import Chart, {TooltipItem} from 'chart.js/auto';
import { ChartFilters } from '../../models/chart-filters.model';
import {ScriptableScaleContext} from 'chart.js';

@Component({
  selector: 'colimo-lpg-chart-component',
  styleUrls: ['lpg-chart.component.scss'],
  templateUrl: 'lpg-chart.component.html'
})
export class LPGchartComponent implements OnInit, AfterViewInit {
  @Input() public boxplotChild;
  @Input() public iterationIndex: number;
  @Input() public filters: ChartFilters;
  @Input() public chartType: string;
  @Input() public quadrant: number;
  @Input() public key: string;
  @Input() public angles: string[];

  public boxplotId: string;
  public isRendering = true;
  public lineChartAngle: string;

  constructor() {
  }

  public ngOnInit(): void {
    this.boxplotId = 'canvas-' + Math.random().toString(36).substring(2, 12);
  }

  public ngAfterViewInit(): void {
    const canvas: any = document.getElementById(this.boxplotId);
    const ctx = canvas.getContext('2d');

    const canvasWrapper = document.getElementsByTagName('colimo-canvas-wrapper')[0];
    const canvasWrapperWidth = canvasWrapper.clientWidth - 30;
    const dlElWidth = 37;
    const boxplotRowWidth = canvasWrapperWidth - dlElWidth;

    ctx.canvas.width = (boxplotRowWidth / this.angles.length) - 25;
    ctx.canvas.height = 250;

    const boxplot = new Chart(ctx, {
      type: 'boxplot',
      data: this.boxplotChild,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false
          },
          title: {
            display: false,
            text: 'Chart.js Box Plot Chart'
          },
          tooltip: {
            callbacks: {
              label(tooltipItem: TooltipItem<any>): string | string[] | void {
                return tooltipItem.dataset.label;
              }
            }
          }
        },
        scales: {
          y: {
            grid: {
              color: (line: ScriptableScaleContext) => (line.tick.value === 0) ? '#7992AF' : 'transparent',
              lineWidth: 2,
            },
            ticks: {
              stepSize: 0.5,
              autoSkip: false,
            },
            beginAtZero: false
          }
        },
        layout: {
          padding: {
            left: 5,
            right: 0,
            top: 20,
            bottom: 0
          }
        },
      }
    });
    this.lineChartAngle = this.angles[this.iterationIndex];
    this.isRendering = false;
  }
}
