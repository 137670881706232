import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'colimo-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  public contactEmail: string;
  public isMobile: boolean;
  private stop$: Subject<boolean> = new Subject();

  constructor(private mobileCheck: MobileCheck) { }

  ngOnInit(): void {
    this.contactEmail = environment.contactPage.contactEmail;
    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

}
