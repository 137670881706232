import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationResult, LoginCredentials } from 'colimo-ui-library';
import * as UpdateActiveDashboardActions from 'src/app/actions/active-dashboard.actions';
import { environment } from 'src/environments/environment';
import * as fromRoot from '../../reducers';
import { LoginService } from './login.service';

@Component({
  selector: 'colimo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public loginCredentials: LoginCredentials = {};
  public authenticationResult: AuthenticationResult;
  public redirectUrl: string;
  public redirectLogin: boolean;

  constructor(
    private loginService: LoginService,
    private store: Store<fromRoot.State>,
    private router: ActivatedRoute,
  ) {
    const showLegacy = this.router.snapshot.paramMap.get('show');
    if (environment.loginRedirection.enabled === true && !(showLegacy === 'true')) {
      this.redirectLogin = true;
      this.redirectUrl = environment.colorCareLoginUrl;
      if (environment.loginRedirection.auto) {
        setTimeout(() => {
          window.open(environment.colorCareLoginUrl, '_self'); //_blank (new tab) _self (same tab)
        }, 3000);
      }
    }
  }

  public onSubmit(): void {
    this.loginService.authenticate(this.loginCredentials).subscribe((authenticationResult: AuthenticationResult) => {
      this.authenticationResult = authenticationResult;
      if (authenticationResult.successful) {
        this.store.dispatch(new UpdateActiveDashboardActions.UpdateActiveDashboardAction('color'));
      }
    });
  }
}
