import { Pipe, PipeTransform } from '@angular/core';
import { IKpiLine } from 'src/app/models/kpi.model';

/**
 * Pipe responsible for returning proper name of a line.
 */
@Pipe({
  name: 'lineName',
})
export class LineNamePipe implements PipeTransform {
  public transform(kpiLine: IKpiLine): string {
    if (kpiLine == null || kpiLine.lineNumber == null) {
      return '';
    }
    return this.getLineName(kpiLine);
  }

  private getLineName(kpiLine: IKpiLine): string {
    if (kpiLine.site) {
      if (kpiLine.lineNumber === '*') {
        return kpiLine.site;
      }
      return kpiLine.site + ' ' + kpiLine.lineNumber;
    }
    // fallback: return abbreviation of line, e.g. 'REG01'
    return kpiLine.lineNumber;
  }
}
