import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ErrorNotificationService } from '../../services/error-notification/error-notification.service';

/**
 * This component is responsible for displaying error messages.
 */
@Component({
  selector: 'colimo-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public displayError: boolean;
  public errorMessage: string;

  constructor(private errorNotificationService: ErrorNotificationService, private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.displayError = false;
    // Change detection stops working after uncaught exception (sometimes) so we have to do it manually.
    // Otherwise no error message is displayed and the user has no clue what's going on.
    this.cd.detach();
    this.errorNotificationService.errors$.subscribe((errorMessage: string) => {
      this.errorMessage = errorMessage;
      this.displayError = true;
      this.cd.detectChanges();
    });
  }
}
