import * as PaintlineDashboardActions from '../actions/paintline-dashboard.actions';
import { PaintlineDashboard } from '../models/paintline-dashboard.model';

const initialState: PaintlineDashboard[] = [];

/**
 * Reducer for adding and modifying dashboard and project paintlines
 */
export function reducer(
  state: PaintlineDashboard[] = initialState,
  action: PaintlineDashboardActions.Actions,
): PaintlineDashboard[] {
  switch (action.type) {
    case PaintlineDashboardActions.ACTION_TYPES.CREATE_ITEMS: {
      return action.payload;
    }

    default:
      return state;
  }
}
