import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColorDashboard } from 'src/app/models/color-dashboard.model';
import { ModelDashboard } from 'src/app/models/model-dashboard.model';
import { PaintlineDashboard } from 'src/app/models/paintline-dashboard.model';
import { ScreenSize } from 'src/app/models/screen-size.model';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';

@Component({
  selector: 'colimo-customer-logo',
  templateUrl: './customer-logo.component.html',
  styleUrls: ['./customer-logo.component.scss'],
})
export class CustomerLogoComponent implements OnInit {
  public isMobile$: Observable<ScreenSize>;

  @Input() public dashboard: ColorDashboard | ModelDashboard | PaintlineDashboard;

  constructor(private mobileCheck: MobileCheck) {}

  public ngOnInit(): void {
    this.isMobile$ = this.mobileCheck.getScreenSize();
  }
}
