import { Pipe, PipeTransform } from '@angular/core';
import { Iproduct } from 'src/app/models/product.model';
import { IBatch } from '../../models/batch.model';

/**
 * Used to filter batches by their state attribute.
 */
@Pipe({ name: 'state' })
export class StatePipe implements PipeTransform {
  public transform(batches: IBatch[], filter: string, alignedProducts: Iproduct[]): IBatch[] {
    if (!batches || !filter) {
      return batches;
    }
    const alignedBatches: IBatch[] = [];
    batches
      .filter((item) => item.state === filter) // apply filter for state
      .forEach((batch: IBatch, batchIndex: number) => {
        if (alignedProducts) {
          const productIndex = alignedProducts.indexOf(
            alignedProducts.filter((product: Iproduct) => product.name === batch.productName)[0],
          );
          // BCH-2732: Push empty items in the batch list if the batch is not in the same position as the corresponding product
          // Also only push empty items if the length of the aligned batches don't equal the product index
          if (productIndex && productIndex > batchIndex) {
            for (let index = 0; index < productIndex - batchIndex; index++) {
              if (productIndex !== alignedBatches.length) {
                alignedBatches.push({ batchNumber: '', productName: '', productType: '', state: '' });
              }
            }
          }
        }
        alignedBatches.push(batch);
      });
    if (alignedBatches.length === 0) {
      return null;
    }
    return alignedBatches;
  }
}
