/* eslint-disable max-lines */
import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { EnvironmentColors } from 'src/app/models/environment-colors.model';
import { Sidenav } from 'src/app/models/sidenav.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ColorDataService } from 'src/app/services/color-data/color-data.service';
import { ChartFilters } from '../../models/chart-filters.model';
import { ChartLineData } from '../../models/chart-line-data.model';
import { Iline } from '../../models/chart-mwg.model';
import { LineChartOptions } from '../../models/line-chart-options.model';
import * as fromRoot from '../../reducers';
import { ZoomDir } from '../../shared/enums/zoom-directions.enum';
import { chartPanScale } from '../../shared/plugins/chart-pan-scale.plugin';
import { Scale } from './scale';
import {Color, ScriptableScaleContext} from 'chart.js';
import Chart from 'chart.js/auto';
import {ChartType} from 'chart.js/dist/types';

/**
 * Contains default configuration and default options like labels, colors and behaviour of MWG chart.
 * Implements scaling of y axis and rendering.
 *
 * @see
 * www.chartjs.org/docs/latest/charts/line.html
 *
 * @example The input dataset for this chart type is:
 * [
 *   {
 *     type: 'BUMPER'
 *     data: [0.1, 1, 1.8, 2.5, 3]
 *   },
 *   { ... }
 * ]
 */
@Component({
  selector: 'colimo-mwg-chart-component',
  templateUrl: 'mwg-chart.component.html',
  styleUrls: ['mwg-chart.component.scss'],
  })
export class MWGchartComponent implements OnInit, AfterViewInit, OnChanges {
  // Colors definitions

  private static readonly BORDER_WIDTH = 2;
  private static readonly POINT_RADIUS = 4;
  private static readonly POINT_HOVER_RADIUS = 6;

  @Input() public dataSet: Iline[];
  @Input() public dataLabels: string[];
  @Input() public dataAngles: string[];
  @Input() public dataScale: Scale;
  @Input() public filters: ChartFilters;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public chartType: string;
  @Input() public quadrant: number;
  @Input() public showChartFilters: boolean;
  @Input() public showChartTable: boolean;

  // Directive to get chart access
  @ViewChild('chartMWG') public chartCanvas: ElementRef;
  private renderContext: CanvasRenderingContext2D;
  private chartInstance: Chart;

  public isRendering = true;
  public disableZoomIn: boolean;
  public disableZoomOut: boolean;
  public isZoomIn: boolean;
  public lineChartData: ChartLineData[] = [
    {
      // [0]: BODY
      data: [] as number[],
      component: '' as string,
      type: 'line' as ChartType
    },
    {
      // [1]: BUMPER
      data: [] as number[],
      component: '' as string,
      type: 'line' as ChartType
    }
  ];

  // make enum accessible
  public ZoomDir = ZoomDir;

  public lineChartColors: Color[];
  //public multiLineChartColors: Color[];
  public lineChartLabels: string[] = ['15°', '25°', '45°', '75°', '110°'];
  public lineChartAngles: string[] = ['15', '25', '45', '75', '110'];

  public lineChartOptions: LineChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    // Layout options www.chartjs.org/docs/latest/configuration/layout.html
    layout: {
      padding: {
        top: 20,
        right: 25,
        bottom: 10,
        left: 10,
      },
    },
    // Axes styling www.chartjs.org/docs/latest/axes/styling.html
    scales: {
      y:
        {
          grid: {
            color: (line: ScriptableScaleContext) => (line.tick.value === 0) ? '#7992AF' : 'transparent',
            lineWidth: 1,
          },
          beginAtZero: true,
          max: 2,
          min: -2,
          type:'linear',
          ticks: {
            stepSize: 1.0,
            autoSkip: false,
            callback(value: string | number): string {
              const valueAsString: string = String(value);
              if ((value > 0 && value < 10) || (value < 0 && value > -10)) {
                // Add reserved space for sign, set to one decimal place
                return ' ' + Number.parseFloat(valueAsString).toFixed(1);
              } else {
                return Number.parseFloat(valueAsString).toFixed(1);
              }
            },
          },
          title: {
            display: false,
          },
        },
      x:
        {
          grid: {
            color: '#bbb',
            lineWidth: 1,
          },
          border: {
            display: false
          },
          ticks: {
            autoSkip: false
          },
        },
    },
    animation: {
      duration: 500,
    },
    // ChartPanZoomPlugin options
    pan: {
      rangeMin: {
        // init rangeMin, will be set to actual Range via lineChartData
        y: null,
      },
      rangeMax: {
        // init rangeMin, will be set to actual Range via lineChartData
        y: null,
      },
    },
  };

  public sidenavCollapsed: boolean;

  private bodyData: ChartLineData = {
    label: 'Body',
    data: [],
    borderWidth: MWGchartComponent.BORDER_WIDTH,
    pointRadius: MWGchartComponent.POINT_RADIUS,
    pointHoverRadius: MWGchartComponent.POINT_HOVER_RADIUS
  };

  private bumperData: ChartLineData = {
    label: 'Bumper',
    data: [],
    borderWidth: MWGchartComponent.BORDER_WIDTH,
    pointRadius: MWGchartComponent.POINT_RADIUS,
    pointHoverRadius: MWGchartComponent.POINT_HOVER_RADIUS
  };

  /*
  // this might be needed in the future multiple batches should be displayed in one chart
  private batchData: ChartLineData = {
    label: 'Batch',
    fill: false,
    lineTension: 0,
    spanGaps: false,
    data: [],
  };*/

  constructor(private colorDataService: ColorDataService, private store: Store<fromRoot.State>) {}

  public ngOnInit(): void {
    // Overwrite default labels
    if (this.dataLabels.length) {
      this.lineChartLabels = this.dataLabels;
    }
    const environmentColors: EnvironmentColors = this.colorDataService.getEnvironmentColors();

    if (!this.lineChartColors) {
      this.lineChartColors = [];
    }
    this.lineChartColors.push(environmentColors.lightPrimaryColor);
    this.lineChartColors.push(environmentColors.primaryColor);
    //this.multiLineChartColors = this.getLineColors();

    this.updateChartOptions();
    this.checkZoomInVars();

    this.isRendering = false;
  }

  public ngAfterViewInit(): void {
    this.renderContext = this.chartCanvas.nativeElement.getContext('2d');
    this.chartInstance = new Chart(this.renderContext, {
      type: 'line',
      data: {
        datasets: this.lineChartData as any
      },
      options: this.lineChartOptions
    });

    this.nativeUpdateChartData(null);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Overwrite default angles
    if (this.dataAngles.length) {
      this.lineChartAngles = this.dataAngles;
    }

    // BCH-607: center data point if only one is present
    if (this.dataLabels.length === 1) {
      /*if (this.chartInstance) {
        const middle = this.chartInstance.canvas.width / 2;
        this.lineChartOptions.scales.y.grid.tickLength = middle - 50;
      } else {
        this.lineChartOptions.scales.y.grid.tickLength = 375;
      }*/
      this.lineChartOptions.scales.y.grid.tickLength = 375 - 150;
      // Checking showChartFilters and showChartTable because this calculation is specific for the car report
      if (!this.showChartFilters && !this.showChartTable) {
        const windowWidth = document.documentElement.clientWidth;
        // Subtracting 1166 as this is the width of the (width of browser window - width of canvas)
        // Dividing by 2 because the angle is centered and subtracting 35 because that is the width needed for the scale to show up
        this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
          // Offset because of the side menu width
          let sidenavWidth = 130;
          if (sidenav.sidenavCollapsed) {
            sidenavWidth = 0;
          }
          this.lineChartOptions.scales.y.grid.tickLength = (windowWidth - 1275) / 2 - 27 - sidenavWidth;
        });
      }
    } else {
      this.lineChartOptions.scales.y.grid.tickLength = 5;
    }

    if (changes.activeComponents) {
      this.filters.body = this.activeComponents.bodyOn;
      this.filters.bumper = this.activeComponents.bumperOn;
    }

    this.nativeUpdateChartData(changes);
    this.checkZoomInVars();
  }

  /**
   * Scale charts by using the scalePan plugin.
   * Called from +/- buttons.
   */
  public scaleChart(direction: ZoomDir): void {
    chartPanScale(this.chartInstance, direction, 1, this.lineChartOptions, () => {
      this.checkZoomInVars();
    });
  }

  /**
   * Filter out individual dates in line arrays by angle filter leaving blanks
   *
   * @param data single chart line i.e. data: [1, 2, 3, 2, 1]
   * @return filtered chart line,
   *   i.e. [1, 2, 3, , ] if filters are set to angles: [true, true, true, false, false]
   */
  private filterAnglesData(data: number[]): number[] {
    if (this.selectedAngles && !this.selectedAngles.length) {
      return data;
    }
    const filtered = [];
    data.forEach((date, i) => {
      if (this.selectedAngles[i]) {
        // nth filter is enabled
        filtered.push(date);
      } else {
        // nth filter is disabled
        filtered.push(null);
      }
    });
    return filtered;
  }

  /**
   * Overwrite default chart options like min/max range, y-axis stepSize, pan-ranges etc.
   */
  private updateChartOptions(): void {
    // Set custom scale options for y axis, set label string
    this.lineChartOptions.scales.y.max = this.dataScale.max;
    this.lineChartOptions.scales.y.min = this.dataScale.min;

    // Change stepSize for large scales
    const yTicks = this.lineChartOptions.scales.y.ticks as any;
    yTicks.stepSize = this.calculateYAxisStepSize();

    // Set plugin pan ranges
    this.lineChartOptions.pan.rangeMin.y = this.dataScale.min;
    this.lineChartOptions.pan.rangeMax.y = this.dataScale.max;
  }

  /**
   * Create or update LineChartData Arrays and apply filters for BODY and BUMPER
   */
  private updateDataSets(): void {
    this.dataSet.forEach((set: Iline) => {
      const type = set ? set.type.toLowerCase() : 'no-data';

      // Merge BODY and BUMPER data with default configuration objects
      switch (type) {
        case 'body': {
          if (this.activeComponents.bodyOn) {
            // Add data to position [1]
            this.lineChartData[1] = Object.assign(
              {},
              this.bodyData,
              {
                data: this.filterAnglesData(set.data),
                pointBackgroundColor: this.lineChartColors[1],
                pointBorderColor: this.lineChartColors[1],
                borderColor: this.lineChartColors[1]
              },
              this.lineChartColors[1],
            );
          } else {
            // Remove data to position [1]
            this.lineChartData[1].data = [];
          }
          break;
        }
        case 'bumper': {
          if (this.activeComponents.bumperOn) {
            this.lineChartData[0] = Object.assign(
              {},
              this.bumperData,
              {
                data: this.filterAnglesData(set.data),
                pointBackgroundColor: this.lineChartColors[0],
                pointBorderColor: this.lineChartColors[0],
                borderColor: this.lineChartColors[0]
              },
              this.lineChartColors[0],
            );
          } else {
            this.lineChartData[0].data = [];
          }
          break;
        }
        default: {
          // no chart data
        }
      }
    });

    /*
    // this might be needed in the future multiple batches should be displayed in one chart
    const index = this.dataSet.indexOf(set);

    if (type === 'body') {
      if (this.activeComponents.bodyOn) {
        // Add data to position [1]
        this.lineChartData[1] = Object.assign({}, this.bodyData, { data: this.filterAnglesData(set.data) }, this.lineChartColors[1]);
      } else {
        // Remove data to position [1]
        this.lineChartData[1].data = [];
      }
    } else if (type === 'bumper') {
      if (this.activeComponents.bumperOn) {
        this.lineChartData[0] = Object.assign({}, this.bumperData, { data: this.filterAnglesData(set.data) }, this.lineChartColors[0]);
      } else {
        this.lineChartData[0].data = [];
      }
    } else if (type.startsWith('body-')) {
      if (this.activeComponents.bodyOn) {
        const batch = this.batchData;
        batch.label = set.type;
        let colorIndex = index;
        if (colorIndex >= this.multiLineChartColors.length) {
          colorIndex = colorIndex % this.multiLineChartColors.length;
        }
        //this.lineChartData.push(Object.assign({}, batch, {data: this.filterAnglesData(set.data)}, this.multiLineChartColors[colorIndex]));
        this.lineChartData[index] = (Object.assign({}, batch, {data: this.filterAnglesData(set.data)}, this.multiLineChartColors[colorIndex]));
      } else {
        this.lineChartData[index].data = [];
      }
    } else if (type.startsWith('bumper-')) {
      if (this.activeComponents.bumperOn) {
        const batch = this.batchData;
        batch.label = set.type;
        let colorIndex = index;
        if (colorIndex >= this.multiLineChartColors.length) {
          colorIndex = colorIndex % this.multiLineChartColors.length;
        }
        //this.lineChartData.push(Object.assign({}, batch, {data: this.filterAnglesData(set.data)}, this.multiLineChartColors[colorIndex]));
        this.lineChartData[index] = (Object.assign({}, batch, {data: this.filterAnglesData(set.data)}, this.multiLineChartColors[colorIndex]));
      } else {
        this.lineChartData[index].data = [];
      }
    } else {
      // nothing
    }*/
  }

  /**
   * Populates chart data, labels and ticks on the native chartjs object, enables plugins.
   * Uses chartjs' update() method.
   */
  private nativeUpdateChartData(changes: SimpleChanges): void {
    if (!this.chartInstance) {
      return;
    }

    const config = this.chartInstance.config;

    this.updateDataSets();

    if (changes && (changes.dataScale || changes.dataSet || changes.dataLabels)) {
      // update scales
      config.options.scales.y.min = this.dataScale.min;
      config.options.scales.y.max = this.dataScale.max;
      this.lineChartOptions.scales.y.max = this.dataScale.max;
      this.lineChartOptions.scales.y.min = this.dataScale.min;
    }

    // only reset scales if dataAngles change
    if (changes && changes.dataAngles) {
      // reset chart scales
      this.chartInstance.scales.y.max = this.dataScale.max;
      this.chartInstance.scales.y.min = this.dataScale.min;
    }

    this.updateChartLabels();

    // Trigger chart update via API
    this.chartInstance.update();
  }

  private updateChartLabels(): void {
    if (this.lineChartAngles && this.lineChartAngles.length > 0) {
      this.chartInstance.data.labels = [];
      for (let i = 0; i < this.lineChartAngles.length; i++) {
        this.chartInstance.data.labels.push(this.lineChartAngles[i] + '°');
      }
    }
  }

  /**
   * Check zoom status.
   */
  private checkZoomInVars(): void {
    const maxMiddle = this.lineChartOptions.scales.y.max;
    this.disableZoomIn = maxMiddle <= 1;
    this.disableZoomOut = maxMiddle >= this.lineChartOptions.pan.rangeMax.y;
    this.isZoomIn = !this.disableZoomOut;
  }

  /**
   * Returns y-axis labelling step size (i.e. 0.5, 1, 2 etc.) as configuration options
   * @return stepSize to be used under the stepSize key in ticks
   */
  private calculateYAxisStepSize(): number {
    const delta = Math.round(Math.abs(this.dataScale.min) + Math.abs(this.dataScale.max));

    if (delta <= 4) {
      return 0.5;
    } else if (delta > 4 && delta <= 10) {
      return 1;
    } else if (delta > 10 && delta <= 16) {
      return 2.005; // there seems to be a chart internal rounding error for step size = 2 or 2.0 which leads to 0 line not showing => use 2.005
    } else {
      return 5;
    }
  }

  /*private getLineColors(): Color[] {
    const chartColors: string[] = [];//this.colorDataService.getMultiBatchColors();
    const lineColors: Color[] = [];
    for (let i = 0; i < chartColors.length; i++) {
      lineColors.push({
        borderWidth: MWGchartComponent.BORDER_WIDTH,
        pointRadius: MWGchartComponent.POINT_RADIUS,
        pointHoverRadius: MWGchartComponent.POINT_HOVER_RADIUS,
        borderColor: chartColors[i],
        pointBackgroundColor: chartColors[i],
        pointBorderColor: chartColors[i],
        pointHoverBackgroundColor: chartColors[i],
        pointHoverBorderColor: chartColors[i],
      });
    }
    return lineColors;
  }*/

  // eslint-disable-next-line max-lines
}
