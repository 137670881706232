<div class="container pb-2">
  <colimo-modal [size]="'md'"> </colimo-modal>
  <div class="row">
    <div class="col-md-12">
      <div class="report-headline">
        <colimo-page-header [heading]="heading"> </colimo-page-header>
      </div>
      <div class="background-well report">
        <colimo-kpi-report-filter
          (selectedCustomerChanged)="onSelectedCustomerChanged($event)"
          (selectedLinesChanged)="onSelectedLinesChanged($event)"
          (selectedProductsChanged)="onSelectedProductsChanged($event)"
          (selectedKpisChanged)="onSelectedKpisChanged($event)"
          (selectedStartDateChanged)="onSelectedStartDateChanged($event)"
          (selectedEndDateChanged)="onSelectedEndDateChanged($event)"
        ></colimo-kpi-report-filter>
        <colimo-average-kpis [averageKpis]="averageKpis"></colimo-average-kpis>
        <div *ngIf="kpiReports && kpiReports.length">
          <div *ngFor="let kpiReport of kpiReports">
            <colimo-kpi-report-table
              [kpiReportDatasets]="kpiReport.details"
              [selectedKpis]="selectedKpis"
            ></colimo-kpi-report-table>
          </div>
          <div class="row px-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-icon btn-primary btn-export" (click)="exportKpiReport()">
                <mat-icon class="position">save_alt</mat-icon>
                {{ 'reports.export' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="(!kpiReports || kpiReports.length === 0) && !isLoading">
          <div class="row px-2">
            <div class="col-md-12 new-font-family-size">
              {{ 'reports.reportTable.noData' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="isLoading"></colimo-loader>
