import { Injectable } from '@angular/core';
import { IMwgData } from '../../models/chart-mwg.model';

/**
 * Provides functionality to check if average graph data is valid and there displayable.
 */
@Injectable({
  providedIn: 'root'
})
export class ImwgDataValidatorService {
  constructor() { }

  public isValid(mwgData: IMwgData): boolean {
    if (!mwgData || !mwgData.data) {
      return false;
    }
    return true;
  }
}
