import {ChartDefaults} from '../enums/chart-defaults.enum';
import Chart from 'chart.js/auto';

/**
 * Draws tolerance lines on the provided Chart instance.
 * Use Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: true/false }) to show or hide lines.
 * Requires Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, [-1, 1]) to be set to Array<number>, representing the lines.
 * Use Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {}) to submit configuration data:
 *
 * @params {object} ChartDefaults.TOLERANCE_OPTIONS - toleranceOptions - plugin configuration object
 * @params {string} toleranceOptions.color - line color as HEX, i.e. '#35A775'
 * @params {number} toleranceOptions.lineWidth - line width in pixel, i.e. 1
 * @params {boolean} toleranceOptions.dashed - flag for dashed lines
 * @params {Array<number>} toleranceOptions.lineDash - values for dashes and spacing, i.e. [5,5]
 * @params {boolean} toleranceOptions.asSquare - draws tolerance lines as square
 * @params {number} toleranceOptions.xOffset - x-offset value for each line in pixel , i.e. 20
 * @params {number} toleranceOptions.yOffset - y-offset value for each line in pixel , i.e. 20
 * @params {number} toleranceOptions.ignoreWidth - charts smaller than this value (pixel width) should be ignored
 */
export const ChartToleranceLinePlugin = {
  id: ChartDefaults.PLUGIN_TOLERANCE_LINE,
  beforeDatasetsDraw(chart: Chart<any>, args: { cancelable: true }, options: 0): boolean | void {
    if (!chart) {
      return true;
    }

    const toleranceOptions = Chart.defaults.get(ChartDefaults.TOLERANCE_OPTIONS);
    const ignoreWidth = toleranceOptions.ignoreWidth ? toleranceOptions.ignoreWidth : 0;
    const canvas = chart.canvas;
    const ctx = chart.ctx;
    const toleranceScales = Chart.defaults.get(ChartDefaults.TOLERANCE_SCALE);
    let lines = (toleranceScales && toleranceScales.length && toleranceScales.length > 0) ? toleranceScales as number[] : [ -1.0, 1.0 ]; // tolerance scales default at -1 and 1

    // special case for chart deg component with a single line for tolerance e.g. [1.7] - this option is then not set globally but in the chartInstance itself
    if (chart.config && chart.config.options) {
      const customOptions = chart.config.options as any;
      if (customOptions.scaleTolerance && customOptions.scaleTolerance.length && customOptions.scaleTolerance.length > 0) {
        lines = customOptions.scaleTolerance as number[];
      }
    }

    const color = toleranceOptions.color || '#fff';
    const yScale = chart.scales.y;
    const xScale = chart.scales.x;

    // start coords for the line
    let xStartPoint;
    let yStartPoint;

    // end coords for the line
    let xEndPoint;
    let yEndPoint;

    if (Chart.defaults.get(ChartDefaults.TOLERANCE_SHOW).show && lines.length) {

      // set color and width for lines
      ctx.strokeStyle = color;
      ctx.lineWidth = toleranceOptions.lineWidth ? toleranceOptions.lineWidth : 1; // Default tolerance line width 1
      // set dash for lines
      if (toleranceOptions.dashed) {
        const dash = toleranceOptions.lineDash ? toleranceOptions.lineDash : [5, 5]; // Default dashed to [5, 5]
        ctx.setLineDash(dash);
      }

      // iterate over given lines
      for (const line of lines) {
        // draws a square
        if (toleranceOptions.asSquare) {

          // set values for horizontal line
          xStartPoint = 0;
          yStartPoint = yScale.getPixelForValue(line);
          xEndPoint = canvas.clientWidth;
          yEndPoint = yStartPoint;

          // draw horizontal line
          drawLine(xStartPoint, yStartPoint, xEndPoint, yEndPoint);

          // ignore dl canvas
          if (canvas.width > ignoreWidth) {
            // set values for vertical line
            xStartPoint = xScale.getPixelForValue(line);
            yStartPoint = 0;
            xEndPoint = xStartPoint;
            yEndPoint = canvas.clientHeight;

            // draw vertical line
            drawLine(xStartPoint, yStartPoint, xEndPoint, yEndPoint);
          }
        } else {

          // set values for horizontal line
          xStartPoint = toleranceOptions.xOffset;
          yStartPoint = yScale.getPixelForValue(line);
          xEndPoint = canvas.clientWidth - toleranceOptions.yOffset;
          yEndPoint = yStartPoint;

          // draw horizontal line
          drawLine(xStartPoint, yStartPoint, xEndPoint, yEndPoint);
        }
      }

      // reset line dash
      ctx.setLineDash([0, 0]);

      //chart.update(); // this seems to be not needed anymore and causes maximum stack size exceeded

      return true;
    }

    // draws line by given coordinates
    function drawLine(xStart: any, yStart: any, xEnd: any, yEnd: any): void {
      ctx.beginPath();
      ctx.moveTo(xStart, yStart);
      ctx.lineTo(xEnd, yEnd);
      ctx.stroke();
    }

    return true;
  }
    
}
