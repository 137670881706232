import { Pipe, PipeTransform } from '@angular/core';
import { Iproject } from '../../models/project.model';

/**
 * Returns total number of products of given projects.
 */
@Pipe({
  name: 'productCount',
})
export class ProductCountPipe implements PipeTransform {
  public transform(projects: Iproject[]): number {
    if (projects == null || projects.length === 0) {
      return 0;
    }

    let sumOfProducts = 0;
    for (const p of projects) {
      if (p != null && p.products != null && p.products.length > 0) {
        sumOfProducts += p.products.length;
      }
    }
    return sumOfProducts;
  }
}
