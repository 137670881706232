import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Iproject} from '../../../models/project.model';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {ProjectService} from '../../../services/project/project.service';
import {Sidenav} from '../../../models/sidenav.model';
import {forkJoin, Observable} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ScreenSize} from '../../../models/screen-size.model';
import {ChartFilters} from '../../../models/chart-filters.model';
import {MobileCheck} from '../../../services/mobile-check/mobile-check.service';
import {take, takeUntil} from 'rxjs/operators';
import {KeyTextService} from '../../../services/key-text/key-text.service';
import {IMwgData} from '../../../models/chart-mwg.model';
import {IFdgData} from '../../../models/chart-fdg.model';
import {IFogData} from '../../../models/chart-fog.model';
import {ILpgData} from '../../../models/chart-lpg.model';
import {ComponentGroups} from '../../../models/viewmodels/component-groups.model';
import {ColorDataService} from '../../../services/color-data/color-data.service';
import {ModelsComponents} from '../../../models/models-components.model';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TinterAdditionsService} from '../../../services/tinter-additions/tinter-additions.service';
import {TinterAdditionsCount} from '../../../models/tinter-additions-count.model';
import {NavigationService} from '../../../shared/services/navigation/navigation.service';
import {ColimoPlotTooltipService} from '../../../shared/services/colimo-plot-tooltip/colimo-plot-tooltip.service';
import {BatchHeaderInfo} from '../../../models/batch-header-info';
import {BatchlineSelection} from '../../../models/batchline-selection';
import {ChartVidComponent} from "../../../components/chart-vid/chart-vid.component";

@Component({
  selector: 'colimo-multi-batch-page',
  templateUrl: './multi-batch-page.component.html',
  styleUrls: ['./multi-batch-page.component.scss'],
  providers: [ProjectService]
})
export class MultiBatchPageComponent extends BaseComponent implements OnInit, OnDestroy{
  public project: Iproject;
  public projects: Iproject[] = [];
  public chartFilters: ChartFilters;
  public chartData: IMwgData | IFdgData | IFogData | ILpgData;
  public tolerancesOn: boolean;
  public selectedAngles: boolean[];
  public activeComponents: ComponentGroups;
  public chartColors: string[];
  public chartBorderColors: string[];
  public chartComponentColors: string[] = [];
  public chartComponentBorderColors: string[] = [];
  public chartComponentOutlierColor: string[] = [];
  public chartMultiBatchColors: string[] = [];
  public componentsLegend: string[];
  public modelsComponents: ModelsComponents;
  public preselectedModelId: string;
  public selectedTab: number;
  public showChartFilters: boolean;
  public showChartTable: boolean;

  public batchLineSelections: BatchlineSelection[];
  public batchHeaderInfos: BatchHeaderInfo[];
  public batchCommentTooltips: string[] = [];
  public tinterAddInfos: any[];
  public tinterAdditionsCountTotal: number;

  public componentTranslations: string[] = [];
  public projectPage: number;
  public isColorRoute: boolean;

  public chartLoading: boolean;

  private routeParams$: Observable<Params>;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<fromRoot.State>,
              private projectService: ProjectService,
              private tinterAdditionsService: TinterAdditionsService,
              private mobileCheck: MobileCheck,
              private keyTextService: KeyTextService,
              private colorDataService: ColorDataService,
              private navigationService: NavigationService,
              private cd: ChangeDetectorRef) {
    super();
  }
  public ngOnInit(): void {
    this.initColors();
    this.selectedTab = 0;
    this.tinterAdditionsCountTotal = 0;
    this.batchLineSelections = [];
    this.batchHeaderInfos = [];
    this.tinterAddInfos = [];
    this.chartColors = this.colorDataService.getChartColors();
    this.chartBorderColors = this.colorDataService.getChartBorderColors();
    //this.outlierColor = this.colorDataService.getOutlierColor();
    this.selectedAngles = [];
    this.activeComponents = { bodyOn: true, bumperOn: true };
    // Setting componentsLegend to an empty array disables the lpg-chart presentation of the available components
    this.componentsLegend = [];

    this.routeParams$ = this.route.params;
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.isColorRoute = sidenav.isColorRoute;
    });

    this.showChartFilters = true;
    this.showChartTable = true;

    this.chartFilters = {
      page: 0
    };

    // Subscribe to route changes -- changed to take 1 instead of takeUntil(this.stop$) -- this seems to work better for multi batch view
    this.routeParams$.pipe(take(1)).subscribe((route: Params) => {
      // this params currently are in the url
      this.chartFilters.projectId = route.projectId;
      this.chartFilters.modelId = route.modelId;
      this.preselectedModelId = route.modelId;
      this.chartFilters.batchIds = route.batches;
      this.chartFilters.lineIds = route.lines;
      this.projectPage = route.projectPage;

      const batches: string [] = route.batches.split(',');
      const lines: string [] = route.lines.split(',');
      // only add if not present -- could also be checked by index but currently works fine because of take(1)
      if (this.batchLineSelections && this.batchLineSelections.length === 0) {
        for (let i = 0; i < batches.length; i++) {
          this.batchLineSelections.push({batchId: parseInt(batches[i]), line: lines[i]});
        }
      }
      this.chartFilters.batchId = parseInt(batches[0]);
      this.chartFilters.lineId = lines[0];

      this.loadProject(this.chartFilters.projectId);
      this.loadBatchHeaders();
      this.loadTinterAddCounts();
    });

    this.keyTextService.getComponentTranslationsObservable().pipe(takeUntil(this.stop$)).subscribe({
      next: (compTranslations: string[]) => {
        this.componentTranslations = compTranslations;
        ColimoPlotTooltipService.componentTranslations = this.componentTranslations;
      }, error: () => {
        //nothing
      }
    });

    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((screenSize: ScreenSize) => {
      this.onScreenSizeChange(screenSize);
    });
  }

  public updateSelectedAngles(updatedAngles: boolean[]): void {
    this.selectedAngles = [...updatedAngles];
    this.cd.detectChanges();
  }

  public updateActiveComponents(updatedActiveComponents: ComponentGroups): void {
    this.activeComponents = { ...updatedActiveComponents };
  }

  public updateModelsComponents(modelsComponents: ModelsComponents) {
    this.modelsComponents = { ...modelsComponents };
  }

  public tolerancesChanged(event: boolean): void {
    this.tolerancesOn = event;
  }

  public chartDataChanged(chartData: IMwgData | IFdgData | IFogData | ILpgData): void {
    this.chartData = chartData;
  }

  public chartLoadingChanged(event: boolean): void {
    setTimeout(() => {
      this.chartLoading = event;
    }, 100)
  }

  public onTabSelected(tab: MatTabChangeEvent): void {
    this.navigationService.navigateToGraphTabMultipage(
      this.chartFilters.projectId,
      this.chartFilters.batchIds,
      this.chartFilters.lineIds,
      this.preselectedModelId,
      tab.index,
      this.isColorRoute,
      this.projectPage,
    );
    this.selectedTab = tab.index;
  }

  private loadProject(projectId: string): void {
    this.projectService.getProject({projectId: projectId}).pipe(takeUntil(this.stop$)).subscribe({ next: (project: Iproject) => {
      project.inDashboard = undefined;
      this.project = project;
    }})
  }

  private loadBatchHeaders(): void {
    if (!this.batchLineSelections || this.batchLineSelections.length < 1) {
      return;
    }
    const observablesBatches: Observable<Iproject>[] = [];
    for (let i = 0; i < this.batchLineSelections.length; i++) {
      observablesBatches.push(this.projectService.getBatchHeader({ batch: this.batchLineSelections[i].batchId, line: this.batchLineSelections[i].line }))
    }
    forkJoin(observablesBatches).pipe(takeUntil(this.stop$)).subscribe({next: (projects: Iproject[]) => {
      this.projects = projects;
      for (let i = 0; i < projects.length; i++) {
        const batchHeaderInfo = {
          batchId: projects[i].id,
          batchNr: projects[i].batchNumber,
          kpiLine: projects[i].kpiLine,
          batchComments: projects[i].batchComments,
          isBody: this.productTypeIsBody(projects[i])
        };
        const key = projects[i].id + '-' + projects[i].kpiLine.lineNumber;
        this.batchHeaderInfos[key] = batchHeaderInfo;
        if (batchHeaderInfo.batchComments && batchHeaderInfo.batchComments.length > 0) {
          this.batchCommentTooltips[projects[i].id] = this.generateBatchCommentTooltip(batchHeaderInfo);
        }
      }
      this.initBatchColors(this.batchLineSelections);
    }});
  }

  private productTypeIsBody(project: Iproject): boolean {
    if (project && project.products && project.products.length > 0) {
      for (let i = 0; i < project.products.length; i++) {
        if (project.products[i] && project.products[i].type === 'BODY') {
          return true;
        }
      }
    }
    return false;
  }

  private loadTinterAddCounts(): void {
    if (!this.batchLineSelections || this.batchLineSelections.length < 1) {
      return;
    }

    const observablesTinterAdds: Observable<TinterAdditionsCount>[] = [];
    for (let i = 0; i < this.batchLineSelections.length; i++) {
      observablesTinterAdds.push(this.tinterAdditionsService.getNumberOfTinterAdditions(this.batchLineSelections[i].batchId, this.batchLineSelections[i].line));
    }
    forkJoin(observablesTinterAdds).pipe(takeUntil(this.stop$)).subscribe({next: (tinterAddCounts: TinterAdditionsCount[]) => {
      for (let i = 0; i < tinterAddCounts.length; i++) {
        const tinterAddCount = { count: tinterAddCounts[i].count };
        const key = tinterAddCounts[i].batchId + '-' + tinterAddCounts[i].line;
        this.tinterAddInfos[key] = (tinterAddCount);
        this.tinterAdditionsCountTotal += tinterAddCounts[i].count;
      }
    }});
  }

  private initColors(): void {
    if (!this.modelsComponents) {
      return;
    }

    const allComponents: string[] = [];

    if (this.modelsComponents.bumperComponents && this.modelsComponents.bumperComponents.length > 0) {
      for (let i = 0; i < this.modelsComponents.bumperComponents.length; i++) {
        allComponents.push(this.modelsComponents.bumperComponents[i]);
      }
    }

    if (this.modelsComponents.bodyComponents && this.modelsComponents.bodyComponents.length > 0) {
      for (let j = 0; j < this.modelsComponents.bodyComponents.length; j++) {
        allComponents.push(this.modelsComponents.bodyComponents[j]);
      }
    }

    this.chartComponentColors = this.colorDataService.getChartComponentColors(allComponents);
    this.chartComponentBorderColors = this.colorDataService.getChartComponentBorderColors(allComponents);
    this.chartComponentOutlierColor = this.colorDataService.getChartComponentOutlierColors(allComponents);
  }

  private initBatchColors(batchheaderInfos: BatchlineSelection[]): void {
    this.chartMultiBatchColors = this.colorDataService.getMultiBatchColors(batchheaderInfos);
  }

  private generateBatchCommentTooltip(batchHeaderInfo: BatchHeaderInfo): string {
    if(!batchHeaderInfo.batchComments || batchHeaderInfo.batchComments.length === 0){
      return '';
    }
    let tooltip: string = '';
    for(let i = 0; i < batchHeaderInfo.batchComments.length; i++){
      tooltip += batchHeaderInfo.batchComments[i].commentTime + ':' + '\n' + batchHeaderInfo.batchComments[i].commentText + '\n';
    }
    return tooltip;
  }

  private onScreenSizeChange(screenSize: ScreenSize): void {
    if (screenSize.isSmallTablet) {
      this.router.navigate(['/project/', this.chartFilters.projectId], { replaceUrl: true });
    }
  }
}
