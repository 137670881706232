import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is responsible for providing proper css class
 * for displaying and styling the legend of FDG chart.
 */
@Pipe({
  name: 'fdgLegendCss'
})
export class FdgLegendCssPipe implements PipeTransform {

  public transform(rowIndex: number, dataAngles: string[]): string {
    // Display legend only above one chart per row.
    if (rowIndex === 1) {
      // BCH-610: Display legend and corresponding data point
      // centered if there is only one angle present.
      if (dataAngles && dataAngles.length === 1) {
        return 'legend-box centered';
      }
      return 'legend-box';
    }

    // Display no legend.
    return 'no-legend-box';
  }

}
