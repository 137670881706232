/* eslint-disable max-lines */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Chart from 'chart.js';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ModelsComponents } from 'src/app/models/models-components.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartDataService } from 'src/app/services/chart-data/chart-data.service';
import { FdgChartService } from 'src/app/services/fdg-chart/fdg-chart.service';
import { TinterAdditionsService } from 'src/app/services/tinter-additions/tinter-additions.service';
import { ChartSlot, IDataSets, IFdgData, Iline } from '../../models/chart-fdg.model';
import { ChartFilters } from '../../models/chart-filters.model';
import { ChartZoom } from '../../models/chart-zoom.model';
import { TinterAddition } from '../../models/tinter-addition.model';
import { ExportClipboardParameter } from '../../services/export-to-clipboard/export-clipboard-parameter';
import { ZoomDir } from '../../shared/enums/zoom-directions.enum';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import {Iproject} from '../../models/project.model';
import {ChartDefaults} from '../../shared/enums/chart-defaults.enum';

/**
 * Displays a list of FDG charts (FarbDifferenzGrafik), contains chart types and default labels
 * Because of performance reasons the change detection for this branch is set to 'OnPush'.
 */
@Component({
  selector: 'colimo-fdg-chart-list-component',
  styleUrls: ['fdg-chart-list.component.scss'],
  templateUrl: 'fdg-chart-list.component.html',
})
export class FDGchartListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public chartFilters: ChartFilters;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public modelsComponents: ModelsComponents;
  @Input() public chartComponentColors: string[];
  @Input() public chartComponentBorderColors: string[];
  @Input() public chartMultiBatchColors: string[];
  @Input() public projects: Iproject[];
  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  @Output() public chartDataChange: EventEmitter<IFdgData> = new EventEmitter();
  @Output() public tolerancesChanged: EventEmitter<boolean> = new EventEmitter();
  public scaleDirections$: Subject<ZoomDir>[] = [];
  public tinterAdditions$: Observable<TinterAddition[]>;
  public chartDataFDG$: Observable<IFdgData>;
  public isLoading = true;
  public isRenderingCharts = true;
  public page = 0;
  public exportClipboardParameter: ExportClipboardParameter;

  public chartSlots: ChartSlot[] = [];
  public chartData: Iline[][][] = [];

  public fdgData: IFdgData;
  public tinterAdditions: TinterAddition[][];
  // Default labels
  public lineChartAngles: string[] = [];
  // Default scale
  public scale = {
    max: 3,
    min: 3,
  };
  public ZoomDir = ZoomDir;
  public dataAvailable = false;

  private stop$: Subject<boolean> = new Subject();
  private timer: NodeJS.Timeout;
  public isSmallTablet: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private fdgChartService: FdgChartService,
    private chartDataService: ChartDataService,
    private tinterAdditionsService: TinterAdditionsService,
    private mobileCheck: MobileCheck,
  ) {}

  public ngOnInit(): void {
    this.scaleDirections$ = this.fdgChartService.initializeScaleDirections();
    this.chartSlots = this.fdgChartService.chartSlots;
    this.chartData = this.fdgChartService.chartData;
    // Declare options for toleranceLine Plugin
    Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      asSquare: false,
      xOffset: 0,
      yOffset: 0,
    });

    this.chartFilters.page = 0;
    this.getFdgData();

    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isSmallTablet = device.isSmallTablet;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartFilters && !changes.chartFilters.firstChange) {
      if (this.chartFilters) {
        this.getFdgData();
      }
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timer);
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public updateChartFilters(chartFilters: ChartFilters): void {
    this.chartFilters = chartFilters;
    this.chartFiltersChange.emit(this.chartFilters);
    this.getFdgData();
  }

  public onPageChange(page: number): void {
    this.updatePage(page);
  }

  /**
   * Scales each chart in a specific row based on the direction.
   */
  public scaleRow(direction: ZoomDir, row: string): void {
    this.fdgChartService.scaleRow(direction, row, this.chartSlots);
  }

  /**
   * Listen to charts updated event and set zoomInfo values.
   */
  public onChartsUpdated(event: ChartZoom): void {
    this.chartSlots[event.slotIndex].zoomInfo = event.zoomInfo;
    this.cd.detectChanges();
  }

  public onLastChartRendered(hasRendered: boolean): void {
    this.isRenderingCharts = false;
  }

  private getFdgData(): void {
    const TIMEOUT_IN_MILLISECONDS = 30000;
    this.isLoading = true;
    if (this.chartFilters.batchIds && this.chartFilters.lineIds) {
      this.chartDataFDG$ = this.chartDataService.getFdgDataMultiBatch(this.chartFilters, this.modelsComponents);
      this.tinterAdditions$ = this.tinterAdditionsService.getTinterAdditionsMultiBatch(
        this.chartFilters.batchIds,
        this.chartFilters.lineIds,
      );
      combineLatest([this.chartDataFDG$, this.tinterAdditions$])
        .pipe(
          takeUntil(this.stop$),
          map(([fdgData, tinterAdditions]) => {
            return {fdgData, tinterAdditions};
          }),
        )
        .subscribe((response: { fdgData: IFdgData; tinterAdditions: TinterAddition[] }) => {
          this.isLoading = false;
          if (response && this.fdgChartService.isFdgDataValid(response.fdgData) && response.tinterAdditions) {
            // Set global Chart options for toleranceLine Plugin
            Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: response.fdgData.tolerances });
            Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, response.fdgData.scaleTolerance ? response.fdgData.scaleTolerance : []);

            this.dataAvailable = true;

            this.scale.min = response.fdgData.scale[0];
            this.scale.max = response.fdgData.scale[1];

            this.lineChartAngles = response.fdgData.angles;

            this.fdgData = response.fdgData;

            if (this.chartFilters.tolerance === null || this.chartFilters.tolerance === undefined) {
              this.tolerancesChanged.emit(response.fdgData.tolerances);
            }

            this.buildExportClipboardParameter(response.fdgData);
            this.populateChartRows(response.fdgData.data);

            // Groups tinter additions by dates. Makes it easier to displaying the tinter additions.
            this.tinterAdditions = this.fdgChartService.getTinterAdditionsCorrespondingToDates(
              response.fdgData.startDate,
              response.fdgData.dates,
              response.tinterAdditions,
            );

            this.chartDataChange.emit(response.fdgData);
            this.cd.detectChanges();
          } else if (!this.timer) {
            this.timer = setTimeout(() => {
              this.isLoading = false;
              this.cd.detectChanges();
            }, TIMEOUT_IN_MILLISECONDS);
          }
        });

    } else {

      this.chartDataFDG$ = this.chartDataService.getFdgData(this.chartFilters, this.modelsComponents);
      this.tinterAdditions$ = this.tinterAdditionsService.getTinterAdditions(
        this.chartFilters.batchId,
        this.chartFilters.lineId,
      );
      combineLatest([this.chartDataFDG$, this.tinterAdditions$])
        .pipe(
          takeUntil(this.stop$),
          map(([fdgData, tinterAdditions]) => {
            return {fdgData, tinterAdditions};
          }),
        )
        .subscribe((response: { fdgData: IFdgData; tinterAdditions: TinterAddition[] }) => {
          this.isLoading = false;
          if (response && this.fdgChartService.isFdgDataValid(response.fdgData) && response.tinterAdditions) {
            // Set global Chart options for toleranceLine Plugin
            Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: response.fdgData.tolerances });
            Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, response.fdgData.scaleTolerance ? response.fdgData.scaleTolerance : []);

            this.dataAvailable = true;

            this.scale.min = response.fdgData.scale[0];
            this.scale.max = response.fdgData.scale[1];

            this.lineChartAngles = response.fdgData.angles;

            this.fdgData = response.fdgData;

            if (this.chartFilters.tolerance === null || this.chartFilters.tolerance === undefined) {
              this.tolerancesChanged.emit(response.fdgData.tolerances);
            }

            this.buildExportClipboardParameter(response.fdgData);
            this.populateChartRows(response.fdgData.data);

            // Groups tinter additions by dates. Makes it easier to displaying the tinter additions.
            this.tinterAdditions = this.fdgChartService.getTinterAdditionsCorrespondingToDates(
              response.fdgData.startDate,
              response.fdgData.dates,
              response.tinterAdditions,
            );

            this.chartDataChange.emit(response.fdgData);
            this.cd.detectChanges();
          } else if (!this.timer) {
            this.timer = setTimeout(() => {
              this.isLoading = false;
              this.cd.detectChanges();
            }, TIMEOUT_IN_MILLISECONDS);
          }
        });
    }
  }

  /**
   * Constructs 2-dim array of 3*10 slots for charts. Populates this 2-dim-array
   * with actual response data.
   *
   * @param data response containing data Object
   */
  private populateChartRows(data: IDataSets): void {
    this.fdgChartService.populateChartSlots(data);
    this.fdgChartService.populateChartData(data);
  }

  private buildExportClipboardParameter(response: IFdgData): void {
    this.exportClipboardParameter = {
      batchId: response.batchId,
      line: response.lineNumber,
      startDate: response.startDate,
      endDate: response.endDate,
    };
  }

  private updatePage(page: number): void {
    this.isLoading = true;
    this.chartFilters.page = page;
    this.getFdgData();
    this.cd.detectChanges();
  }
}
