/**
 * Available sidenav items.
 */
export enum SidenavItem {
  COLOR = 'color',
  PAINTLINE = 'paintline',
  MODEL = 'model',
  CAR_REPORT = 'car-report',
  REPORT = 'report',
  ALIEN_CHECK = 'alien-check',
  NOTIFICATION = 'notification-center'
}
