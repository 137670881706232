import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { KeyText } from '../../models/key-text.model';
import { LocationLines } from '../../models/location-lines.model';
import { Line } from '../../models/viewmodels/line.viewmodel';
import { LocationWithLines } from '../../models/viewmodels/location-with-lines.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class LocationLinesService {
  private baseUrl: string;

  public constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath;
  }

  public getLocationWithLinesByCustomer(customer: KeyText): Observable<LocationWithLines[]> {
    // Returns empty observable if mandatory parameter missing
    if (customer == null || customer.key == null) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.reports.locationLines.replace('{customerId}', customer.key)}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => {
        const locationLines = response as LocationLines[];
        return this.convertToLocationWithLines(locationLines);
      }),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  private convertToLocationWithLines(locationLines: LocationLines[]): LocationWithLines[] {
    const locationWithLines: LocationWithLines[] = [];
    let locationIndex: number;

    locationLines.forEach((locationLine: LocationLines) => {
      // check if location is already present
      const mayFoundLocation = locationWithLines.find((l, index) => {
        locationIndex = index;
        return l.locationId === locationLine.lstaNr;
      });

      // if not, push the location
      if (mayFoundLocation == null) {
        const locationWithLine: LocationWithLines = {
          locationId: locationLine.lstaNr,
          locationName: locationLine.lstaName,
          selected: true,
          lines: [],
        };
        locationIndex = locationWithLines.push(locationWithLine) - 1;
      }

      // add the current line to corresponding location
      const line: Line = {
        name: locationLine.line,
        selected: true,
      };
      locationWithLines[locationIndex].lines.push(line);
    });

    return locationWithLines;
  }
}
