/* eslint-disable max-lines */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChartFilters } from 'src/app/models/chart-filters.model';
import { environment } from '../../../environments/environment';
import { IDegData } from '../../models/chart-deg.model';
import { IFdgData } from '../../models/chart-fdg.model';
import { IFogData } from '../../models/chart-fog.model';
import { ILpgData } from '../../models/chart-lpg.model';
import { IMwgData } from '../../models/chart-mwg.model';
import { ModelsComponents } from '../../models/models-components.model';
import {IVidData} from "../../models/chart-vid.model";

@Injectable({
  providedIn: 'root',
})
export class ChartDataService {
  private baseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath + environment.apiVersionPath;
  }

  /**
   * Get models and components.
   */
  public getModelsAndComponents(chartFilters: ChartFilters): Observable<ModelsComponents> {
    // Returns empty observable if mandatory pars missing
    if ((!chartFilters.batchId || !chartFilters.lineId) && (!chartFilters.batchIds || !chartFilters.lineIds)) {
      return EMPTY;
    }

    let apiEndpoint;
    if ((!chartFilters.batchIds || !chartFilters.lineIds)) {
      apiEndpoint = `${this.baseUrl}/${environment.charts.modelsComponents
        .replace('{batchId}', chartFilters.batchId.toString())
        .replace('{lineNumber}', chartFilters.lineId)}`;
    } else {
      apiEndpoint = `${this.baseUrl}/${environment.charts.modelsComponents
        .replace('{batchId}', chartFilters.batchIds)
        .replace('{lineNumber}', chartFilters.lineIds)}`;
      apiEndpoint += '-multi-batch';
    }

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as ModelsComponents),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getModelsAndComponentsForSelectedModels(
    chartFilters: ChartFilters,
    models: string,
  ): Observable<ModelsComponents> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchId || !chartFilters.lineId) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}/${environment.charts.modelsComponents
      .replace('{batchId}', chartFilters.batchId.toString())
      .replace('{lineNumber}', chartFilters.lineId)}`;

    if (models) {
      apiEndpoint += '?models=' + models;
    }

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as ModelsComponents),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Get data for MWG (Mittelwertgrafik) charts
   */
  public getMwgData(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<IMwgData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchId || !chartFilters.lineId) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = `${this.baseUrl}/${environment.charts.mwg
      .replace('{batchId}', chartFilters.batchId.toString())
      .replace('{lineId}', chartFilters.lineId)}`;

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IMwgData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Get data for FDG (Farbdifferenzgrafik) charts
   */
  public getFdgData(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<IFdgData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchId || !chartFilters.lineId) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = `${this.baseUrl}/${environment.charts.fdg
      .replace('{batchId}', chartFilters.batchId.toString())
      .replace('{lineId}', chartFilters.lineId)}`;

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IFdgData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getFdgDataMultiBatch(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<IFdgData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchIds || !chartFilters.lineIds) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = this.baseUrl + '/batch/' + chartFilters.batchIds + '/line/' + chartFilters.lineIds + '/data-diff-multi-batch';

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IFdgData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Get data for FOG (FarbOrtgrafik) charts
   */
  public getFogData(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<IFogData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchId || !chartFilters.lineId) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = `${this.baseUrl}/${environment.charts.fog
      .replace('{batchId}', chartFilters.batchId.toString())
      .replace('{lineNumber}', chartFilters.lineId)}`;

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IFogData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Get data for DEG (deltaE-Grafik) chart
   */
  public getDegData(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<IDegData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchId || !chartFilters.lineId) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = `${this.baseUrl}/${environment.charts.deg
      .replace('{batchId}', chartFilters.batchId.toString())
      .replace('{lineNumber}', chartFilters.lineId)}`;

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IDegData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getDegDataMultiBatch(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<IDegData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchIds || !chartFilters.lineIds) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = this.baseUrl + '/batch/' + chartFilters.batchIds + '/line/' + chartFilters.lineIds + '/data-diff-abstractde-multi-batch';

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IDegData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getLpgData(chartFilters: ChartFilters, modelsComponents: ModelsComponents): Observable<ILpgData> {
    // Returns empty observable if mandatory pars missing
    if (!chartFilters.batchId || !chartFilters.lineId) {
      return EMPTY;
    }

    const params = this.buildRequestParameters(chartFilters, modelsComponents);

    const apiEndpoint = `${this.baseUrl}/${environment.charts.lpg
      .replace('{batchId}', chartFilters.batchId.toString())
      .replace('{lineNumber}', chartFilters.lineId)}`;

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as ILpgData),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getVidData(chartFilters: ChartFilters, startDate: string, endDate: string): Observable<IVidData[]> {
    if (!chartFilters) {
      return EMPTY;
    }

    const apiEndpoint = String(environment.apiBasePath + environment.apiUserPath + '/' + environment.charts.vid);
    let params = new HttpParams();

    // params for batchIds and lineIds can be single or comma separated multiple
    if (chartFilters.batchIds && chartFilters.lineIds) {
      params = params.set('batchIds', chartFilters.batchIds);
      params = params.set('lines', chartFilters.lineIds);
    } else {
      params = params.set('batchIds', chartFilters.batchId);
      params = params.set('lines', chartFilters.lineId);
    }

    // selected models as params
    params = params.set('models', this.getChartParamModelComponentFilter(chartFilters.models));

    // set start and end date
    params = params.set('startDate', startDate);
    params = params.set('endDate', endDate);

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IVidData[]),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  private buildRequestParameters(chartFilters: ChartFilters, modelsComponents?: ModelsComponents): HttpParams {
    let params = new HttpParams();

    if (chartFilters.tolerance !== undefined) {
      params = params.set('tolerance', chartFilters.tolerance);
    }
    if (chartFilters.labSystem) {
      params = params.set('system', chartFilters.labSystem);
    }
    if (chartFilters.page !== undefined) {
      params = params.set('page', String(chartFilters.page));
    }

    params = params.set('models', this.getChartParamModelComponentFilter(chartFilters.models));

    if (modelsComponents && chartFilters.lineId === '*') {
      params = this.buildAverageRequestParameters(params, chartFilters, modelsComponents);
    } else {
      // If body components are not set all (*) body components should be used.
      params = params.set('bodyComponents', this.getChartParamModelComponentFilter(chartFilters.bodyComponents));

      // If bumper components are not set all (*) bumper components should be used.
      params = params.set('bumperComponents', this.getChartParamModelComponentFilter(chartFilters.bumperComponents));
    }

    return params;
  }

  private getChartParamModelComponentFilter(comps: string ): string {
    if (comps === undefined || comps === null) {
      return '*';
    }
    if (comps === '') {
      return '-';
    }
    return comps;
  }

  private buildAverageRequestParameters(
    params: HttpParams,
    chartFilters: ChartFilters,
    modelsComponents: ModelsComponents,
  ): HttpParams {
    // Body and bumper components selected
    if (chartFilters.bodyComponents && chartFilters.bumperComponents) {
      if (chartFilters.bodyComponents !== '*' && chartFilters.bumperComponents !== '*') {
        params = params.set('bodyComponents', chartFilters.bodyComponents + ',' + chartFilters.bumperComponents);
      }
      // Only bodyComponents partially selected
      if (chartFilters.bodyComponents !== '*' && chartFilters.bumperComponents === '*') {
        modelsComponents.bumperComponents && modelsComponents.bumperComponents.length !== 0
          ? (params = params.set(
              'bodyComponents',
              chartFilters.bodyComponents + ',' + modelsComponents.bumperComponents.toString(),
            ))
          : (params = params.set('bodyComponents', chartFilters.bodyComponents));
      }
      // Only bumperComponents partially selected
      if (chartFilters.bodyComponents === '*' && chartFilters.bumperComponents !== '*') {
        modelsComponents.bodyComponents && modelsComponents.bodyComponents.length !== 0
          ? (params = params.set(
              'bodyComponents',
              modelsComponents.bodyComponents.toString() + ',' + chartFilters.bumperComponents,
            ))
          : (params = params.set('bodyComponents', chartFilters.bumperComponents));
      }
      // All components are selected
      if (chartFilters.bodyComponents === '*' && chartFilters.bumperComponents === '*') {
        params = params.set('bodyComponents', '*');
      }
    }
    // Only bodyComponents selected
    if (chartFilters.bodyComponents && !chartFilters.bumperComponents) {
      params = params.set('bodyComponents', chartFilters.bodyComponents);
    }
    // Only bumperComponents selected
    if (!chartFilters.bodyComponents && chartFilters.bumperComponents) {
      params = params.set('bodyComponents', chartFilters.bumperComponents);
    }
    return params;
  }
  // eslint-disable-next-line max-lines
}
