<mat-card
  appearance="outlined"
  *ngIf="{
    customers: dashboard$ | async,
    isMobile: isMobile$ | async,
    dashboardSelection: dashboardSelection$ | async
  } as paintlineDashboard"
  class="dashboard-card padding-16"
>
  <colimo-page-header [heading]="heading"> </colimo-page-header>
  <div class="flex-column gap-25">
    <mat-expansion-panel
      *ngFor="let customer of paintlineDashboard.customers"
      [expanded]="paintlineDashboard.dashboardSelection.customer === customer.customer"
    >
      <mat-expansion-panel-header
        class="customer-panel flex-row justify-start"
        [ngClass]="paintlineDashboard.isMobile.isMobile === true ? 'mobile-panel' : ''"
        (click)="customerSelected(customer.customer, paintlineDashboard.dashboardSelection.customer)"
      >
        <mat-panel-title class="flex-1 flex-row gap-35 gap-lt-sm-0 start-center flex-grow-0">
          <colimo-customer-cards
            [customer]="customer" 
          ></colimo-customer-cards>
        </mat-panel-title>
        <mat-panel-description
          *ngIf="!paintlineDashboard.isMobile.isMobile"
          class="panel-description flex-row start-center"
        >
          <div class="pipe-list flex-1">
            {{ customer.lines | arrayToPipeList: 'line' }}
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <mat-accordion>
          <mat-expansion-panel
            [ngClass]="paintlineDashboard.isMobile.isMobile === true ? 'mobile-expansion-panel' : ''"
            *ngFor="let site of customer.lines"
            hideToggle
            [expanded]="paintlineDashboard.dashboardSelection.line === site.line"
          >
            <mat-expansion-panel-header
              class="paintline-panel flex-row start-center"
              [ngClass]="paintlineDashboard.isMobile.isMobile === true ? 'mobile-panel' : ''"
              (click)="paintlineSelected(customer.customer, site.line, paintlineDashboard.dashboardSelection.line)"
            >
              <mat-panel-title class="flex-170px">
                <span>
                  {{ site.line }}
                </span>
              </mat-panel-title>
              <mat-panel-description
                *ngIf="!paintlineDashboard.isMobile.isMobile"
                class="panel-description flex-row start-center"
              >
                <div class="pipe-list flex-1">
                  {{ site.projects | arrayToPipeList: 'name' }}
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
            <div class="flex-row flex-wrap column-gap-15 gap-lt-sm-0" [ngClass]="paintlineDashboard.isMobile.isMobile === true ? 'mobile-expansion-panel' : ''">
              <colimo-color-card
                *ngFor="let project of site.projects"
                class="color-card"
                [ngClass]="paintlineDashboard.isMobile.isMobile === true ? 'mobile-color-card' : ''"
                [project]="project"
                [routerLink]="['/project/', project.id, 'line', site.line, 'page', 0]"
              ></colimo-color-card>
            </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-template>
    </mat-expansion-panel>
  </div>
</mat-card>

<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="isLoading"></colimo-loader>
