import { Component, Input, OnInit } from '@angular/core';
import { Car } from '../../models/car.model';

@Component({
  selector: 'colimo-car-info-box',
  templateUrl: './car-info-box.component.html',
  styleUrls: ['./car-info-box.component.scss'],
})
export class CarInfoBoxComponent implements OnInit {
  @Input()
  public car: Car;

  constructor() {}

  public ngOnInit(): void {}
}
