<div class="container pb-2">
  <div class="row">
    <div class="col-md-12">
      <div class="report-headline">
        <colimo-page-header [heading]="heading"> </colimo-page-header>
      </div>
      <div class="background-well report new-font-family">
        <div class="row no-margin">
          <div class="col-md-12 col-sm-12">
            <colimo-alien-check-filter
              [alienCheckOptions]="alienCheckOptions"
              (alienCheck)="uploadAndAlienCheck()"
            ></colimo-alien-check-filter>
          </div>
          <ng-container *ngIf="car">
            <div class="col-md-12 col-sm-12 m-1 car-info-box">
              <colimo-car-info-box [car]="car"></colimo-car-info-box>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="car">
          <div class="row px-2 component-status">
            <div class="col-md-10 col-xl-10">
              <colimo-alien-check-status-table
                [componentsStatus]="car.componentsStatus"
                [tolerancesOn]="alienCheckOptions.tolerancesOn"
              ></colimo-alien-check-status-table>
            </div>
            <div class="col-md-2 col-xl-2 export">
              <label class="filter-label">
                {{ 'dashboard.export' | translate }}
              </label>
              <button class="btn is-normalcase is-active" (click)="exportAlienCheck()">
                {{ 'reports.downloadExcel' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="car === null">
          <div class="row px-2 mt-1">
            <div class="col-md-12">
              {{ 'reports.reportTable.noData' | translate }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isLoading">
          <div class="row px-2 mt-2">
            <div class="col-12">
              <span class="spinner spinner-md"></span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
