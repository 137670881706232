import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable, Subscription, throwError, timer} from 'rxjs';
import {AlertCount} from '../../models/alert-count';
import {catchError, map} from 'rxjs/operators';
import {AlertUserConfig} from '../../models/alert-user-config';
import {AlertUserDataPage} from '../../models/alert-user-data-page';
import {AlertUserStatus} from '../../models/alert-user-status';
import {PageSortParam} from '../../models/PageSortParam';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  private baseUrl: string;
  private alertCount$ = new BehaviorSubject<AlertCount>({ newYellow: 0, newRed: 0 });
  private alertUserConfig$ = new BehaviorSubject<AlertUserConfig>( {userId: '', worstPart: 2, cpiCp: 2, kpiCpi: 2, kpiBpi: 0, kpiLpi: 0, kpiApi: 0, kpiChi: 0});

  private appVisible: boolean;
  private alertCountUpdateMissed: boolean;
  private timerRunning: boolean;
  private timerSubscription: Subscription;
  private timerInterval: number = 60 * 1000; // every minute update timer
  private pollingInterval: number = 30 * 60 * 1000; // max every 30 minutes update alert count
  private timeElapsed: number = 0;
  private initialTimeElapsed: number = 0;
  private timer$: BehaviorSubject<number> = new BehaviorSubject(this.initialTimeElapsed);

  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath;
    this.appVisible = true;
    this.alertCountUpdateMissed = false;
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public startPolling(): void {
    this.initVisibilityListener();
    this.startTimer();
    setTimeout(() => {
      // initial load of notification config after app startup with 3 seconds delay (tokenInit etc.) - get config endpoint creates a default config if none is present for the user
      this.initAlertConfig();
    }, 3000);
  }

  private startTimer(): void {
    if (this.timerRunning) {
      return;
    }
    this.timerSubscription = timer(0, this.timerInterval)
      .pipe(map((value: number): number => {
        const val = (value + this.timeElapsed) * this.timerInterval;
        if (val >= this.pollingInterval) {
          if (this.appVisible) {
            this.updateAlertCount();
            this.resetTimer(true);
          } else {
            this.alertCountUpdateMissed = true;
            this.resetTimer(false);
          }
        }
        return val;
      }))
      .subscribe(this.timer$);
    this.timerRunning = true;
  }

  private resetTimer(withRestart: boolean): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timeElapsed = this.initialTimeElapsed;
    this.timer$.next(this.initialTimeElapsed);
    this.timerRunning = false;
    if (withRestart) {
      this.startTimer();
    }
  }

  private initVisibilityListener(): void {
    document.addEventListener('visibilitychange', () => {
      this.appVisible = 'visible' === document.visibilityState;
      if (this.appVisible && this.alertCountUpdateMissed) {
        this.alertCountUpdateMissed = false;
        this.updateAlertCount();
        this.startTimer();
      }
    });
  }

  public updateAlertCount(): void {
    // update alert count instantly on request
    this.getAlertCount().subscribe({next: (count: AlertCount) => {
      this.alertCount$.next(count);
    }, error: () => {
      // do nothing
    }});
  }

  public alertsConfigured(alertConfig: AlertUserConfig): boolean {
    return alertConfig.worstPart > 0 || alertConfig.cpiCp > 0 || alertConfig.kpiCpi > 0 || alertConfig.kpiBpi > 0 || alertConfig.kpiLpi > 0 || alertConfig.kpiApi > 0 || alertConfig.kpiChi > 0;
  }

  private getAlertCount(): Observable<AlertCount> {
    const apiEndpoint = `${this.baseUrl}/u/alert-count`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as AlertCount),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  private initAlertConfig(): void {
    this.getAlertConfig().subscribe({
      next: (config: AlertUserConfig) => {
        this.alertUserConfig$.next(config);
        this.updateAlertCount();
      }, error: () => {
        // do nothing
      }
    });
  }

  private getAlertConfig(): Observable<AlertUserConfig> {
    const apiEndpoint = `${this.baseUrl}/u/alert-config`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as AlertUserConfig),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  private updateAlertConfig(alertConfig: AlertUserConfig): Observable<AlertUserConfig> {
    const apiEndpoint = `${this.baseUrl}/u/alert-config`;

    return this.http.post(apiEndpoint, alertConfig) as Observable<AlertUserConfig>;
  }

  public saveAlertConfig(alertConfig: AlertUserConfig): void {
    this.updateAlertConfig(alertConfig).subscribe({
      next: (config: AlertUserConfig) => {
        this.alertUserConfig$.next(config);
        this.updateAlertCount();
      }, error: () => {
        // nothing
      }
    });
  }

  public getAlerts(page: number, pageSize: number, sort?: PageSortParam[]): Observable<AlertUserDataPage> {
    let apiEndpoint = `${this.baseUrl}/u/alerts?size=${pageSize}&page=${page}`;

    if (sort && sort.length > 0) {
      for (let i = 0; i < sort.length; i++) {
        apiEndpoint += '&sort=' + sort[i].active + ',' + sort[i].direction;
      }
    }

    return this.http.get(apiEndpoint).pipe(
      map(response => response as AlertUserDataPage),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public updateAlertStatus(status: AlertUserStatus): Observable<AlertUserStatus> {
    const apiEndpoint = `${this.baseUrl}/u/alert-status`;

    return this.http.post(apiEndpoint, status) as Observable<AlertUserStatus>;
  }

  public updateAllAlertStatusUntil(userId: string, alertId: number, status: number): Observable<number> {
    // status=1 all read, status=2 all hidden, status =-1 all hidden -> all-read
    const apiEndpoint = `${this.baseUrl}/u/alert-status-set-all-until?userId=${userId}&alertId=${alertId}&status=${status}`;
    return this.http.get(apiEndpoint).pipe(
      map(response => response as number),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getAlertCountObservable(): Observable<AlertCount> {
    return this.alertCount$.asObservable();
  }

  public getAlertUserConfigObservable(): Observable<AlertUserConfig> {
    return this.alertUserConfig$.asObservable();
  }
}
