
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, takeUntil } from 'rxjs/operators';
import { Iproject } from 'src/app/models/project.model';
import * as ColorDashboardActions from '../../actions/color-dashboard.actions';
import * as ProjectActions from '../../actions/project.actions';
import { ColorDashboard } from '../../models/color-dashboard.model';
import * as fromRoot from '../../reducers';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { BaseComponent } from '../../shared/components/base/base.component';
import { of as observableOf } from 'rxjs';

/**
 * Controls for adding and removing projects to/from dashboard.
 */
@Component({
  selector: 'colimo-dashboard-controls',
  providers: [DashboardService],
  styleUrls: ['dashboard-controls.component.scss'],
  templateUrl: 'dashboard-controls.component.html'
})
export class DashboardControlsComponent extends BaseComponent {

  @Input() public isHeader = false;
  @Input() public resultRow: number;
  @Input() public resultId: string;
  @Input() public isInDashboard: boolean;

  @Output() public operationDone: EventEmitter<{ index: number, evt: Event }> = new EventEmitter();

  public isLoading = false;

  constructor(
    private dashboardService: DashboardService,
    private store: Store<fromRoot.State>
  ) {
    super();
  }

  /**
   * Request dashboard service for project with the id from the selected search result
   * @param event - click event
   */

  public onButtonClick(event: Event) {
    if(this.isInDashboard === true) {
      this.removeFromDashboard(event);
    } else {
      this.addToDashboard();
    }
  }

  public addToDashboard(): void {
    this.isLoading = true;
    this.dashboardService.addProjectToDashboard({ id: this.resultId }).pipe(
      takeUntil(this.stop$),
      catchError((error) => {
        console.log(error);
        return observableOf<Iproject[]>([]);
      }),
    )
      .subscribe((project: Iproject) => {
        if (project && project.id) {
          this.store.dispatch(new ColorDashboardActions.AddItemAction(project));
          // Toggle button add/remove state over store
          this.store.dispatch(new ProjectActions.ToggleVisibilityAction(true));
          // This is needed to load the updated dashboard.
          // It is necessary if the dashboard was empty before.
          // Otherwise the added color won't be displayed.
          this.dashboardService.getColorDashboard().subscribe((data: ColorDashboard[]) => {
            this.store.dispatch(new ColorDashboardActions.CreateItemsAction(data));
          });
          this.isInDashboard = true;
        }
        this.isLoading = false;
      }
      )
  }

  /**
   * Remove project with the id by requesting dashboard service
   * @param event - click event
   */
  public removeFromDashboard(event: Event): void {
    this.isLoading = true;
    this.dashboardService.removeProjectFromColorDashboard({ id: this.resultId }).pipe(
      takeUntil(this.stop$),
      catchError((error) => {
      console.log(error);
      return observableOf<Iproject[]>([]);
      }),
    )
      .subscribe((dashboard: ColorDashboard[]) => {

        if (dashboard) {
          this.store.dispatch(new ColorDashboardActions.CreateItemsAction(dashboard));
          // Toggle button add/remove state over store
          this.store.dispatch(new ProjectActions.ToggleVisibilityAction(false));
          // Notify parent component (here: search) which row has updated
          this.operationDone.emit({ index: this.resultRow, evt: event });
          this.isInDashboard = false;
        }
        this.isLoading = false;
      }
      );
  }
}
