<ng-container *ngFor="let item of filterItems; let i = index">
  <div class="info-text" [ngClass]="partName">
    <clr-checkbox-wrapper class="checkbox-inline">
      <input
        type="checkbox"
        class="site-checkbox"
        clrCheckbox
        [name]="item"
        [(ngModel)]="filterItemsSelected[i]"
        (change)="onFilterItemChange(i)"
      />
      <label>{{ item }}</label>
    </clr-checkbox-wrapper>
  </div>
</ng-container>
