<mat-card
  appearance="outlined"
  *ngIf="{
    customers: dashboard$ | async,
    isMobile: isMobile$ | async,
    dashboardSelection: dashboardSelection$ | async
  } as modelDashboard"
  class="dashboard-card padding-16"
>
  <colimo-page-header [heading]="heading"> </colimo-page-header>
  <div class="flex-column gap-25">
    <mat-expansion-panel
      *ngFor="let customer of modelDashboard.customers"
      [expanded]="modelDashboard.dashboardSelection.customer === customer.customer"
    >
      <mat-expansion-panel-header
        class="customer-panel flex-row align-start"
        [ngClass]="modelDashboard.isMobile.isMobile === true ? 'mobile-panel' : ''"
        (click)="customerSelected(customer.customer, modelDashboard.dashboardSelection.customer)"
      >
        <mat-panel-title class="flex-1 flex-grow-0">
          <colimo-customer-cards
            class="flex-row gap-35 gap-lt-sm-0 start-center"
            [customer]="customer"
          ></colimo-customer-cards>
        </mat-panel-title>
        <mat-panel-description
          *ngIf="!modelDashboard.isMobile.isMobile"
          class="panel-description flex-row start-center"
        >
          <div class="pipe-list flex-1">
            {{ customer.models | arrayToPipeList: 'model' }}
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let model of customer.models"
            hideToggle
            [ngClass]="modelDashboard.isMobile.isMobile === true ? 'mobile-expansion-panel' : ''"
            [expanded]="modelDashboard.dashboardSelection.model === model.model"
          >
            <mat-expansion-panel-header
              class="model-panel flex-row start-center"
              [ngClass]="modelDashboard.isMobile.isMobile === true ? 'mobile-panel' : ''"
              (click)="modelSelected(customer.customer, model.model, modelDashboard.dashboardSelection.model)"
            >
              <mat-panel-title class="flex-170px">
                <span class="model-name">
                  {{ model.modelName }}
                </span>
              </mat-panel-title>
              <mat-panel-description
                *ngIf="!modelDashboard.isMobile.isMobile"
                class="panel-description flex-row start-center"
              >
                <div class="pipe-list flex-1">
                  {{ model.lines | arrayToPipeList: 'line' }}
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
            <div class="flex-column gap-15" [ngClass]="modelDashboard.isMobile.isMobile === true ? 'mobile-expansion-panel' : ''">
              <div *ngFor="let site of model.lines">
                <span class="line-name">{{ site.line }}</span>
                <div class="flex-row flex-wrap column-gap-15 gap-lt-sm-0">
                  <colimo-color-card
                    *ngFor="let project of site.projects"
                    class="color-card"
                    [ngClass]="modelDashboard.isMobile.isMobile === true ? 'mobile-color-card' : ''"
                    [project]="project"
                    [routerLink]="['/project/', project.id, 'line', site.line, 'model', model.model, 'page', 0]"
                  ></colimo-color-card>
                </div>
              </div>
            </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-template>
    </mat-expansion-panel>
  </div>
</mat-card>

<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="isLoading"></colimo-loader>
