import {Injectable} from '@angular/core';
import {EnvironmentColors} from 'src/app/models/environment-colors.model';
import {EnvironmentProfile} from 'src/app/models/environment-profile.enum';
import {environment} from 'src/environments/environment';
import {BatchlineSelection} from '../../models/batchline-selection';

@Injectable({
  providedIn: 'root',
})
export class ColorDataService {
  // factor for shading component colors if more than 16 somponents should be displayed
  private componentShadeFactor: number = 30;

  private chartColors = [
    '#B369DE',
    '#F9C80E',
    '#35A775',
    '#4297CF',
    '#F49900',
    '#8CD1FF',
    '#FF99C9',
    '#3DCCC7',
    '#E15886',
    '#8EA604',
    '#C1BDDB',
    '#E3E3E3',
    '#FFFF00',
    '#9370DB',
    '#FF6666',
    '#339933',
  ];

  private chartBorderColors = [
    '#6B3B86',
    '#786210',
    '#196946',
    '#1A4A69',
    '#785720',
    '#3F5B6D',
    '#834461',
    '#175B59',
    '#7F2D48',
    '#3D4802',
    '#4C4866',
    '#626262',
    '#5B3B86',
    '#686210',
    '#834461',
    '#336600',
  ];

  private outlierColor = [
    '#6B3B86',
    '#786210',
    '#196946',
    '#1A4A69',
    '#785720',
    '#3F5B6D',
    '#834461',
    '#175B59',
    '#7F2D48',
    '#3D4802',
    '#4C4866',
    '#626262',
    '#5B3B86',
    '#686210',
    '#834461',
    '#336600',
  ];

  private devColors: EnvironmentColors = {
    primaryColor: '#f39500',
    lightPrimaryColor: '#ffe700',
    primaryLineColor: '#eeb150',
    lightPrimaryLineColor: '#f3e458',
  };

  private qualColors: EnvironmentColors = {
    primaryColor: '#c50022',
    lightPrimaryColor: '#ee6e84',
    primaryLineColor: '#d72a47',
    lightPrimaryLineColor: '#ff9eaf',
  };

  private prodColors: EnvironmentColors = {
    primaryColor: '#004a96',
    lightPrimaryColor: '#4ea6ff',
    primaryLineColor: '#4777a8',
    lightPrimaryLineColor: '#92c8ff',
  };

  private multiBatchColors = [
    '#004a96',
    '#f39500',
    '#c50022',
    '#339933',
    '#B369DE',
    '#7F2D48',
  ]

  constructor() {}

  public getChartColors(): string[] {
    return this.chartColors;
  }

  public getChartBorderColors(): string[] {
    return this.chartBorderColors;
  }

  public getOutlierColor(): string[] {
    return this.outlierColor;
  }

  public getEnvironmentColors(): EnvironmentColors {
    switch (environment.profile) {
      case EnvironmentProfile.DEVELOPMENT:
        return this.devColors;
      case EnvironmentProfile.QUAL:
        return this.qualColors;
      default:
        return this.prodColors;
    }
  }

  public getChartComponentColors(allComponents: string[]): string[] {
    const compColors: string[] = [];
    if (!allComponents || allComponents.length === 0) {
      return compColors;
    }

    for (let i = 0; i < allComponents.length; i++) {
      if (i < this.chartColors.length) {
        compColors[allComponents[i]] = this.chartColors[i];
      } else {
        compColors[allComponents[i]] = this.shadeColor(this.chartColors[i % this.chartColors.length], (Math.floor(i / this.chartColors.length) * this.componentShadeFactor));
      }
    }

    return compColors;
  }

  public getChartComponentBorderColors(allComponents: string[]): string[] {
    const compColors: string[] = [];
    if (!allComponents || allComponents.length === 0) {
      return compColors;
    }

    for (let i = 0; i < allComponents.length; i++) {
      if (i < this.chartBorderColors.length) {
        compColors[allComponents[i]] = this.chartBorderColors[i];
      } else {
        compColors[allComponents[i]] = this.shadeColor(this.chartBorderColors[i % this.chartBorderColors.length], (Math.floor(i / this.chartBorderColors.length) * this.componentShadeFactor));
      }
    }

    return compColors;
  }

  public getChartComponentOutlierColors(allComponents: string[]): string[] {
    const compColors: string[] = [];
    if (!allComponents || allComponents.length === 0) {
      return compColors;
    }

    for (let i = 0; i < allComponents.length; i++) {
      if (i < this.outlierColor.length) {
        compColors[allComponents[i]] = this.outlierColor[i];
      } else {
        compColors[allComponents[i]] = this.shadeColor(this.outlierColor[i % this.outlierColor.length], (Math.floor(i / this.outlierColor.length) * this.componentShadeFactor));
      }
    }

    return compColors;
  }

  public getMultiBatchColors(batchLineSelections: BatchlineSelection[]): string[] {
    const multiBatchColors: string[] = [];
    if (!batchLineSelections || batchLineSelections.length === 0) {
      return multiBatchColors;
    }

    batchLineSelections.forEach((batchLineSelection: BatchlineSelection, index: number) => {
      if (index < this.multiBatchColors.length) {
        multiBatchColors[batchLineSelection.batchId + '-' + batchLineSelection.line] = this.multiBatchColors[index];
      } else {
        multiBatchColors[batchLineSelection.batchId + '-' + batchLineSelection.line] = "#ffffff";
      }
    });

    return multiBatchColors;
  }

  /*
  // method is currently not used but will perhaps in the future
  private addAlphaToColor(color: string, alpha: number): string {
    // Convert hex color to RGB values
    const red = parseInt(color.slice(1, 3), 16);
    const green = parseInt(color.slice(3, 5), 16);
    const blue = parseInt(color.slice(5, 7), 16);

    // Create RGBA color string
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  }*/

  private shadeColor(color: string, percent: number): string {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = (R * (100 + percent) / 100);
    G = (G * (100 + percent) / 100);
    B = (B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    var RR = ((R.toString(16).length === 1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length === 1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length === 1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }
}
