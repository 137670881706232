<button 
  [disabled]="isLoading"
  (click)="onButtonClick($event)"
  class="btn dashboard-toggle-btn"
  [class.is-search]="!isHeader"
>
  <span class="sr-only">Zum Dashboard Hinzufügen</span>
  <colimo-icons [hidden]="isLoading || isInDashboard" [icon]="'plus'" [width]="'17px'" [height]="'17px'" [top]="'1px'"></colimo-icons>
  <colimo-icons [hidden]="isLoading || !isInDashboard" [icon]="'project-added'" [width]="'19px'" [height]="'17px'"></colimo-icons>
  <div [hidden]="!isLoading" class="loader"></div>
</button>
