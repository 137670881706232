import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColorDashboard } from 'src/app/models/color-dashboard.model';
import { ModelDashboard } from 'src/app/models/model-dashboard.model';
import { PaintlineDashboard } from 'src/app/models/paintline-dashboard.model';
import { MobileCheck } from '../../services/mobile-check/mobile-check.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'colimo-customer-cards',
  templateUrl: './customer-cards.component.html',
  styleUrls: ['./customer-cards.component.scss'],
})
export class CustomerCardsComponent implements OnInit, OnDestroy {
  @Input() public customer: PaintlineDashboard | ModelDashboard | ColorDashboard;

  public isMobile: boolean;
  private stop$: Subject<boolean> = new Subject();

  constructor(private mobileCheck: MobileCheck) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }
}
