import { Action } from '@ngrx/store';
import { IDegData } from '../models/chart-deg.model';
import { IFdgData } from '../models/chart-fdg.model';
import { IFogData } from '../models/chart-fog.model';
import { ILpgData } from '../models/chart-lpg.model';
import { IMwgData } from '../models/chart-mwg.model';
import { ModelsComponents } from '../models/models-components.model';
import { type } from '../util';

export const ACTION_TYPES = {
  CREATE_MWG: type<'[Charts] Create MWG'>('[Charts] Create MWG'),
  CREATE_MODELS_COMPONENTS: type<'[Charts] Create ModelsComponents'>('[Charts] Create ModelsComponents'),
  CREATE_FDG: type<'[Charts] Create FDG'>('[Charts] Create FDG'),
  CREATE_DEG: type<'[Charts] Create DEG'>('[Charts] Create DEG'),
  CREATE_FOG: type<'[Charts] Create FOG'>('[Charts] Create FOG'),
  CREATE_LPG: type<'[Charts] Create LPG'>('[Charts] Create LPG'),
  DELETE_ALL: type<'[Charts] Delete all'>('[Charts] Delete all')
};

export class CreateModelsComponentsAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_MODELS_COMPONENTS;

  constructor(public readonly payload: ModelsComponents) {}
}

export class CreateMWGAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_MWG;

  constructor(public readonly payload: IMwgData) {}
}

export class CreateFDGAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_FDG;

  constructor(public readonly payload: IFdgData) {}
}

export class CreateDEGAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_DEG;

  constructor(public readonly payload: IDegData) {}
}

export class CreateFOGAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_FOG;

  constructor(public readonly payload: IFogData) {}
}

export class CreateLPGAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_LPG;

  constructor(public readonly payload: ILpgData) {}
}

export class DeleteAllAction implements Action {
  public readonly type = ACTION_TYPES.DELETE_ALL;
}

export type Actions
  = CreateModelsComponentsAction
  | CreateMWGAction
  | CreateFDGAction
  | CreateDEGAction
  | CreateFOGAction
  | CreateLPGAction
  | DeleteAllAction;
