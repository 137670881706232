import { Action } from '@ngrx/store';
import { TinterAddition } from '../models/tinter-addition.model';
import { TinterAdditionsCount } from '../models/tinter-additions-count.model';
import { type } from '../util';

/**
 * Actions for creating and filtering tinting additions data for their visualization.
 */
export const ACTION_TYPES = {
  CREATE: type<'[Tinter Additions] Create'>('[Tinter Additions] Create'),
  COUNT: type<'[Tinter Additions] Count'>('[Tinter Additions] Count'),
  DELETE_ALL: type<'[Tinter Additions] Delete all'>('[Tinter Additions] Delete all'),
};

export class CreateTinterAdditionsAction implements Action {
  public readonly type = ACTION_TYPES.CREATE;
  constructor(public readonly payload: TinterAddition[]) {}
}

export class CountTinterAdditionsAction implements Action {
  public readonly type = ACTION_TYPES.COUNT;
  constructor(public readonly payload: TinterAdditionsCount) {}
}

export class DeleteAllAction implements Action {
  public readonly type = ACTION_TYPES.DELETE_ALL;
}

export type Actions = CreateTinterAdditionsAction | CountTinterAdditionsAction | DeleteAllAction;
