import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DashboardSelection } from 'src/app/models/dashboard-selection.model';
import { ModelDashboard } from 'src/app/models/model-dashboard.model';
import { PaintlineDashboard } from 'src/app/models/paintline-dashboard.model';
import { environment } from '../../../environments/environment';
import * as ColorDashboardActions from '../../actions/color-dashboard.actions';
import * as DashboardSelectionActions from '../../actions/dashboard-selection.actions';
import * as ModelDashboardActions from '../../actions/model-dashboard.actions';
import * as PaintlineDashboardActions from '../../actions/paintline-dashboard.actions';
import { ColorDashboard } from '../../models/color-dashboard.model';
import { Iproject } from '../../models/project.model';
import * as fromRoot from '../../reducers';

@Injectable()
export class DashboardService {
  private static readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  });

  private baseUrl: string;

  constructor(public http: HttpClient, private store: Store<fromRoot.State>) {
    this.baseUrl = environment.apiBasePath + environment.apiVersionPath;
  }

  /**
   * Get inital dashboard with all current projects
   */
  public getColorDashboard(): Observable<ColorDashboard[]> {
    const apiEndpoint = `${this.baseUrl}/${environment.dashboard.init}`;

    return this.http.get<ColorDashboard[]>(apiEndpoint).pipe(
      map((response: ColorDashboard[]) => {
        this.store.dispatch(new ColorDashboardActions.CreateItemsAction(response));
        return response;
      }),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getPaintlineDashboard(): Observable<PaintlineDashboard[]> {
    const apiEndpoint = `${this.baseUrl}/${environment.dashboard.customerLines}?lines=*`;

    return this.http.get<PaintlineDashboard[]>(apiEndpoint).pipe(
      map((response: PaintlineDashboard[]) => {
        this.store.dispatch(new PaintlineDashboardActions.CreateItemsAction(response));
        return response;
      }),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getModelDashboard(): Observable<ModelDashboard[]> {
    // This endpoint will be adjusted through BCH-2307
    const apiEndpoint = `${this.baseUrl}/${environment.dashboard.customerModels}?lines=*&models=*`;

    return this.http.get<ModelDashboard[]>(apiEndpoint).pipe(
      map((response: ModelDashboard[]) => {
        this.store.dispatch(new ModelDashboardActions.CreateItemsAction(response));
        return response;
      }),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Add a project to the user's dashboard
   * @param parameters - holds project id
   * @param id - id of the project that should be added
   * @return successfully added project model
   */
  public addProjectToDashboard(parameters: { id: string }): Observable<Iproject> {
    if (!parameters.id) {
      return observableOf<Iproject>();
    }

    const apiEndpoint = `${this.baseUrl}/${environment.dashboard.connectProject}`;

    const params = this.buildHttpParams(parameters);

    return this.http
      .put<Iproject>(apiEndpoint, null, {
        headers: DashboardService.HEADERS,
        params,
      })
      .pipe(catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')));
  }

  /**
   * Remove a project from the user's dashboard
   * @param parameters - holds project id
   * @param id - id of the project that should be added
   * @return complete updated dashboard model
   */
  public removeProjectFromColorDashboard(parameters: { id: string }): Observable<ColorDashboard[]> {
    if (!parameters.id) {
      return observableOf<ColorDashboard[]>();
    }

    const apiEndpoint = `${this.baseUrl}/${environment.dashboard.disconnectProject}`;

    const params = this.buildHttpParams(parameters);

    return this.http
      .put<ColorDashboard[]>(apiEndpoint, null, {
        headers: DashboardService.HEADERS,
        params,
      })
      .pipe(catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')));
  }

  public setCurrentDashboardSelection(currentDashboardSelection: DashboardSelection): void {
    if (currentDashboardSelection) {
      this.store.dispatch(new DashboardSelectionActions.SetSelectionAction(currentDashboardSelection));
    }
  }

  private buildHttpParams(parameters: { id: string }): HttpParams {
    let params = new HttpParams();
    // There is only one dashboard with id 1
    params = params.append('dashboardId', '1');
    params = params.append('projectId', parameters.id);
    return params;
  }
}
