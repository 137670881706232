import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CarBodyStyle } from 'src/app/models/car-body-style';
import { environment } from '../../../environments/environment';
import { AlienCheckOptions } from '../../models/alien-check-options.model';
import { CarFilterOptions } from '../../models/car-filter-options.model';
import { Car } from '../../models/car.model';

@Injectable({
  providedIn: 'root',
})
export class CarService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBasePath + environment.apiUserPath;
  }

  public getCar(filterOptions: CarFilterOptions): Observable<Car> {
    // Returns empty observable if mandatory parameters missing
    if (
      filterOptions == null ||
      filterOptions.locationId == null ||
      filterOptions.carId == null ||
      filterOptions.carId.length === 0 ||
      filterOptions.bumperOn == null
    ) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}/${environment.reports.carStatus
      .replace('{lstaNr}', filterOptions.locationId.toString())
      .replace('{carId}', encodeURIComponent(filterOptions.carId))}`;
    if (filterOptions.tolerancesOn === false) {
      apiEndpoint += '&tolerance=false';
    }
    if (filterOptions.tolerancesOn === true) {
      apiEndpoint += '&tolerance=true';
    }
    if (filterOptions.bumperOn) {
      apiEndpoint += '&bumper=true';
    }

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as Car),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public searchCar(filterOptions: CarFilterOptions): Observable<Car[]> {
    // Returns empty observable if mandatory parameters missing
    if (
      filterOptions == null ||
      filterOptions.locationId == null ||
      filterOptions.carId == null ||
      filterOptions.carId.length === 0
    ) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.reports.carStatusSearch
      .replace('{lstaNr}', filterOptions.locationId.toString())
      .replace('{carId}', encodeURIComponent(filterOptions.carId))}`;

    // some special characters like '%' malform the URI and that leads therefore to an error
    try {
      decodeURI(apiEndpoint);
    } catch (e) {
      return EMPTY;
    }

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as Car[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getCarBodyStyle(carBodyStyleId: number): Observable<CarBodyStyle> {
    if (!carBodyStyleId) {
      return EMPTY;
    }
    const apiEndpoint = `${this.baseUrl}/${environment.reports.carBodyStyle.replace(
      '{carBodyStyleId}',
      carBodyStyleId.toString(),
    )}`;

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as CarBodyStyle),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public uploadSingleCar(options: AlienCheckOptions): Observable<Car> {
    // Returns empty observable if mandatory parameters missing
    if (!this.areAlienCheckOptionsValid(options)) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}/${environment.reports.uploadSingleCar
      .replace('{cartype}', encodeURIComponent(options.carType.key))
      .replace('{lstaNr}', options.location.key)
      .replace('{colorId}', options.color.id.toString())}`;
    if (!options.tolerancesOn) {
      apiEndpoint += '&tolerance=false';
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      }),
    };

    return this.http.post(apiEndpoint, options.measurementFile, httpOptions).pipe(
      map((response) => response as Car),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public uploadSingleCarAndExportToExcel(options: AlienCheckOptions): Observable<Blob> {
    // Returns empty observable if mandatory parameters missing
    if (!this.areAlienCheckOptionsValid(options)) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}/${environment.reports.uploadSingleCarDownloadXlsx
      .replace('{cartype}', encodeURIComponent(options.carType.key))
      .replace('{lstaNr}', options.location.key)
      .replace('{colorId}', options.color.id.toString())}`;
    if (!options.tolerancesOn) {
      apiEndpoint += '&tolerance=false';
    }

    return this.http
      .post(apiEndpoint, options.measurementFile, {
        headers: new HttpHeaders({
          'Content-Type': 'text/plain',
          ['Accept']: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        responseType: 'blob',
      })
      .pipe(
        map((response: any) => new Blob([response])),
        catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
      );
  }

  public areAlienCheckOptionsValid(alienCheckOptions: AlienCheckOptions): boolean {
    if (
      alienCheckOptions == null ||
      alienCheckOptions.carType == null ||
      alienCheckOptions.carType.key == null ||
      alienCheckOptions.color == null ||
      alienCheckOptions.color.id == null ||
      alienCheckOptions.location == null ||
      alienCheckOptions.location.key == null ||
      alienCheckOptions.tolerancesOn == null ||
      alienCheckOptions.measurementFile == null
    ) {
      return false;
    }
    return true;
  }
}
