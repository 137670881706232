import { Component, OnInit } from '@angular/core';
import { AlienCheckOptions } from '../../models/alien-check-options.model';
import { Car } from '../../models/car.model';
import { CarService } from '../../services/car/car.service';
import { DisplayBlobService } from '../../shared/services/display-blob/display-blob.service';

@Component({
  selector: 'colimo-alien-check-page',
  templateUrl: './alien-check-page.component.html',
  styleUrls: ['./alien-check-page.component.scss'],
})
export class AlienCheckPageComponent implements OnInit {
  public alienCheckOptions: AlienCheckOptions;
  public car: Car;
  public isLoading: boolean;
  public heading: string;

  constructor(private carService: CarService, private displayBlobService: DisplayBlobService) {}

  public ngOnInit(): void {
    this.heading = 'reports.carCheck';
    this.alienCheckOptions = {
      location: null,
      color: null,
      carType: null,
      tolerancesOn: true,
      measurementFile: null,
    };
  }

  public uploadAndAlienCheck(): void {
    this.isLoading = true;
    // just to be sure that no old status is saved. Otherwise old data would be displayed while long taking requests.
    this.car = undefined;
    this.carService.uploadSingleCar(this.alienCheckOptions).subscribe((car: Car) => {
      this.car = car;
      this.isLoading = false;
    });
  }

  public exportAlienCheck(): void {
    this.carService.uploadSingleCarAndExportToExcel(this.alienCheckOptions).subscribe((blob: Blob) => {
      this.displayBlobService.displayBlob(blob, 'AlienReport.xlsx');
    });
  }
}
