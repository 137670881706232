import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'colimo-filter-checkboxes-part',
  templateUrl: './filter-checkboxes-part.component.html',
  styleUrls: ['./filter-checkboxes-part.component.scss']
})
export class FilterCheckboxesPartComponent implements OnInit {

  @Input()
  public partName: string;

  @Input()
  public filterItems: string[];

  @Input()
  public filterItemsSelected: boolean[];

  @Output()
  public filterItemChangedEvent: EventEmitter<number> = new EventEmitter();

  public constructor() { }

  public ngOnInit(): void {
  }

  public onFilterItemChange(changedFilterItemIndex: number): void {
    this.filterItemChangedEvent.emit(changedFilterItemIndex);
  }

}
