import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'colimo-error-page',
  styleUrls: ['error-page.component.scss'],
  templateUrl: 'error-page.component.html',
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  public title: string;
  public errorCode: number;
  public errorText: string;
  public hintListHeadline: string;
  public hintList: string[];
  private routeSubscriber: Subscription;

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.routeSubscriber = this.route.params.subscribe((params: Params) => {
      if (params.errorCode) {
        if (params.errorCode === '404') {
          this.set404();
        } else if (params.errorCode === '500') {
          this.set500();
        } else {
          this.setDefault();
        }
      } else {
        this.setDefault();
      }
    });
  }

  public ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }

  private reset(): void {
    this.title = '';
    this.errorCode = null;
    this.errorText = '';
    this.hintListHeadline = '';
    this.hintList = [];
    // changed error code is not detected otherwise
    this.cdr.detectChanges();
  }

  private setDefault(): void {
    this.set404();
  }

  private set404(): void {
    this.reset();
    this.errorCode = 404;
    this.errorText = 'error.siteNotFound';
    this.hintListHeadline = 'error.possibleReasons';
    this.hintList = ['error.brokenOrOutdated', 'error.restrictedAccess'];
  }

  private set500(): void {
    this.reset();
    this.errorCode = 500;
    this.errorText = 'error.sorry';
    this.hintListHeadline = 'error.contact';
  }
}
