/* eslint-disable max-lines */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Color, ScriptableScaleContext} from 'chart.js';
import Chart from 'chart.js/auto';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvironmentColors } from 'src/app/models/environment-colors.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ColorDataService } from 'src/app/services/color-data/color-data.service';
import { Iline } from '../../models/chart-fdg.model';
import { ChartLineData } from '../../models/chart-line-data.model';
import { ChartZoom, ZoomInfo } from '../../models/chart-zoom.model';
import { LineChartOptions } from '../../models/line-chart-options.model';
import { ZoomDir } from '../../shared/enums/zoom-directions.enum';
import {ColimoPlotTooltipService} from '../../shared/services/colimo-plot-tooltip/colimo-plot-tooltip.service';
import {ModelsComponents} from '../../models/models-components.model';
import {ChartType} from 'chart.js/dist/types';
import {chartPanScale} from '../../shared/plugins/chart-pan-scale.plugin';

/**
 * Contains default configuration default options like labels, colors and behaviour of FDG chart.
 * Because of performance reasons the change detection for this branch is set to 'OnPush'.
 *
 * @see
 * www.chartjs.org/docs/latest/charts/line.html
 *
 * @example The input dataset for this chart type is:
 * [
 *   {
 *     id: 123
 *     type: 'BODY'
 *     data: [0.1, 1, 1.8, 2.5, 3]
 *   },
 *   { ... }
 * ]
 */
@Component({
  selector: 'colimo-fdg-chart-component',
  templateUrl: 'fdg-chart.component.html',
  styleUrls: ['fdg-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FDGchartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() public dataSet: Iline[];
  @Input() public dataAngles: string[];
  @Input() public dataScale: { min: number; max: number };
  @Input() public slotIndex: number;
  @Input() public rowIndex: number;
  @Input() public rowLength = 0;
  @Input() public scaleDirection: Subject<ZoomDir>;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public chartComponentColors: string[];
  @Input() public chartMultiBatchColors: string[];
  @Input() public chartComponentBorderColors: string[];
  @Input() public modelsComponents: ModelsComponents;
  @Output() public chartZoomed: EventEmitter<ChartZoom> = new EventEmitter();
  @Output() public hasRendered: EventEmitter<boolean> = new EventEmitter();

  // Directive to get chart access
  @ViewChild('chartFDG') public chartCanvas: ElementRef;
  private renderContext: CanvasRenderingContext2D;
  private chartInstance: Chart;

  public isRendering = true;
  public lineChartData: ChartLineData[] = []; //[{ data: [] as number[], component: '' as string, type: 'line' as ChartType }]
  public lineChartAngles: string[] = ['15', '25', '45', '75', '110'];
  public lineChartOptions: LineChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      // Tooltip styling www.chartjs.org/docs/latest/configuration/tooltip.html
      tooltip: {
        enabled: false,
        external: function(tooltipModel: any) {
          ColimoPlotTooltipService.handleFDGCustomTooltip(tooltipModel, document, this.chart);
        }
      }
    },
    // Axes styling www.chartjs.org/docs/latest/axes/styling.html
    scales: {
      y:
        {
          grid: {
            color: (line: ScriptableScaleContext) => (line.tick.value === 0) ? '#7992AF' : 'transparent',
            lineWidth: 2,
          },
          border: {
            display: false
          },
          beginAtZero: true,
          max: 2,
          min: -2,
          ticks: {
            display: false,
            autoSkip: false,
            stepSize: 1.0,
            callback(value: string | number): string {
              const valueAsString: string = String(value);
              if ((value > 0 && value < 10) || (value < 0 && value > -10)) {
                // Add reserved space for sign, set to one decimal place
                return ' ' + Number.parseFloat(valueAsString).toFixed(1);
              } else {
                return Number.parseFloat(valueAsString).toFixed(1);
              }
            },
          },
          title: {
            display: false,
          },
        },
      x:
        {
          // no display for x-axis for fdg chart
          display: false,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
    },
    animation: {
      duration: 200, // general animation time
    },
    hover: {
      mode: null,
    },
    pan: {
      rangeMin: {
        // init rangeMin, will be set to actual Range via lineChartData
        y: null,
      },
      rangeMax: {
        // init rangeMin, will be set to actual Range via lineChartData
        y: null,
      },
    },
  };

  private stop$: Subject<boolean> = new Subject();

  private bodyData: ChartLineData = {
    label: 'Body',
    data: [],
    borderWidth: 0.5,
    pointRadius: 3,
    pointHoverRadius: 3
  };

  private bumperData: ChartLineData = {
    label: 'Bumper',
    data: [],
    borderWidth: 0.5,
    pointRadius: 3,
    pointHoverRadius: 3
  };

  private bumperColors: Color = '';
  private bodyColors: Color = '';

  constructor(private colorDataService: ColorDataService) {}

  public ngOnInit(): void {
    this.initializeColors();
    this.adjustChartOptions();
    this.isRendering = false;

    // subscribe to scale events and scale chart
    this.scaleDirection.pipe(takeUntil(this.stop$)).subscribe((response: ZoomDir) => {
      chartPanScale(this.chartInstance, response, 1, this.lineChartOptions, (zoomInfo: ZoomInfo) => {
        this.chartZoomed.emit({ zoomInfo, slotIndex: this.slotIndex });
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Overwrite default labels
    if (this.dataAngles.length) {
      this.lineChartAngles = this.dataAngles;
    }
    this.nativeUpdateChartData(changes);
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public ngAfterViewInit(): void {
    if (this.rowIndex === this.rowLength - 1) {
      this.hasRendered.emit(true);
    }

    this.renderContext = this.chartCanvas.nativeElement.getContext('2d');
    this.chartInstance = new Chart(this.renderContext, {
      type: 'line',
      data: {
        labels: this.lineChartAngles,
        datasets: []
      },
      options: this.lineChartOptions
    });
  }

  /**
   * Populates chart data, labels and ticks on the native chartjs object, enables plugins.
   * Uses chartjs' update() method.
   */
  private nativeUpdateChartData(changes: SimpleChanges): void {
    if (!this.chartInstance) {
      return;
    }

    const config = this.chartInstance.config;

    this.adjustChartOptions();
    this.updateDataSets();

    this.lineChartData.forEach((name, i) => {
      // Update the prepared data directly in the chartjs config object
      this.chartInstance.data.datasets[i] = this.lineChartData[i] as any;
      this.chartInstance.data.datasets[i].data = [...this.lineChartData[i].data];
      this.chartInstance.data.datasets[i].label = this.lineChartData[i].component;
    });

    this.chartInstance.data.labels = this.lineChartAngles;

    if (changes && changes.dataAngles) {
      if (changes.dataAngles.currentValue.length === 1) {
        const middle = this.chartInstance.canvas.width / 2;
        config.options.scales.y.grid.tickLength = middle - 20;
        // Only show ticks on all charts
        config.options.scales.y.ticks.display = true;
      } else {
        // Only show ticks on the first chart
        config.options.scales.y.grid.tickLength = (this.rowIndex === 0) ? 3 : 0;
        config.options.scales.y.ticks.display = this.rowIndex === 0;
      }
    }

    if ((changes && changes.dataScale) || changes.dataSet || changes.dataLabels) {
      this.lineChartOptions.scales.y.max = this.dataScale.max;
      this.lineChartOptions.scales.y.min = this.dataScale.min;
      config.options.scales.y.min = this.dataScale.min;
      config.options.scales.y.max = this.dataScale.max;
      const yTicks = config.options.scales.y.ticks as any;
      yTicks.stepSize = this.calculateYAxisStepSize();
    }

    // Trigger chart update via API
    this.chartInstance.update();
  }

  /**
   * Create or update LineChartData Arrays and apply chartFilters for BODY and BUMPER
   */
  private updateDataSets(): void {
    // reset data
    for (let i = 0; i < this.lineChartData.length; i++) {
      this.lineChartData[i] = Object.assign({}, this.bodyData, { data: [] });
    }

    this.dataSet.forEach((set, i) => {
      const types = set ? set.type.toLowerCase() : 'no-data';

      // Merge BODY and BUMPER data with default configuration and color objects
      switch (types) {
        case 'body': {
          if (this.activeComponents.bodyOn) {
            // Add data to position [i]
            this.lineChartData[i] = Object.assign(
              {},
              this.bodyData,
              {
                data: this.filterAnglesData(set.data),
                component: set.component,
                pointBackgroundColor: this.getBodyColors(set.component, set.batchLineKey),
                pointBorderColor: this.getBodyColors(set.component, set.batchLineKey),
                borderColor: this.getBodyColors(set.component, set.batchLineKey)
              },
            );
          } else {
            // Remove data to position [i]
            this.lineChartData[i] = { data: [], type: 'line'as ChartType };
          }
          break;
        }
        case 'bumper': {
          if (this.activeComponents.bumperOn) {
            this.lineChartData[i] = Object.assign(
              {},
              this.bumperData,
              {
                data: this.filterAnglesData(set.data),
                component: set.component,
                pointBackgroundColor: this.getBumperColors(set.component, set.batchLineKey),
                pointBorderColor: this.getBumperColors(set.component, set.batchLineKey),
                borderColor: this.getBumperColors(set.component, set.batchLineKey)
              },
            );
          } else {
            this.lineChartData[i] = { data: [], type: 'line'as ChartType };
          }
          break;
        }
        default: {
          // no chart data
        }
      }
    });
  }

  /**
   * Filter out individual dates in line arrays by angle filter leaving blanks
   *
   * @param data single chart line i.e. data: [1, 2, 3, 2, 1]
   * @return filtered chart line,
   *   i.e. [1, 2, 3, , ] if chartFilters are set to angles: [true, true, true, false, false]
   */
  private filterAnglesData(data: number[]): number[] {
    if (this.selectedAngles && !this.selectedAngles.length) {
      return data;
    }
    const filtered = [];
    data.forEach((date, i) => {
      if (this.selectedAngles[i]) {
        // nth filter is enabled
        filtered.push(date);
      } else {
        // nth filter is disabled
        filtered.push(null);
      }
    });
    return filtered;
  }

  /**
   * Overwrite default chart options like min/max range, y-axis stepSize, pan-ranges etc.
   */
  private adjustChartOptions(): void {
    // Set plugin pan ranges
    this.lineChartOptions.pan.rangeMin.y = this.dataScale.min;
    this.lineChartOptions.pan.rangeMax.y = this.dataScale.max;
  }

  /**
   * Returns y-axis labeling step size (i.e. 0.5, 1, 2 etc.) as configuration options
   * @return stepSize to be used under the stepSize key in ticks
   */
  private calculateYAxisStepSize(): number {
    const delta = Math.round(Math.abs(this.dataScale.min) + Math.abs(this.dataScale.max));

    switch (true) {
      case delta <= 3:
        return 0.5;
      case delta > 3 && delta <= 6:
        return 1;
      case delta > 6 && delta <= 16:
        return 3;
      case delta > 16 && delta <= 31:
        return 6;
      case delta > 31 && delta <= 61:
        return 12;
      case delta > 61 && delta <= 121:
        return 18;
      default:
        return 26;
    }
  }

  private initializeColors(): void {
    const environmentColors: EnvironmentColors = this.colorDataService.getEnvironmentColors();
    this.bodyColors = environmentColors.primaryColor;
    this.bumperColors = environmentColors.lightPrimaryColor;
  }

  private getBodyColors(component: string, batchLineKey: string): Color {
    if (this.chartMultiBatchColors && this.chartMultiBatchColors[batchLineKey]) {
      // multi batch mode -> use multi batch colors
      return this.chartMultiBatchColors[batchLineKey];
    }
    if (this.chartComponentColors && this.chartComponentColors[component] && (this.activeComponents.allBodySelected === false || this.activeComponents.allBumperSelected === false)) {
      // component mode -> use component colors
      return this.chartComponentColors[component];
    }

    // use standard body colors
    return this.bodyColors;
  }

  private getBumperColors(component: string, batchLineKey: string): Color {
    if (this.chartMultiBatchColors && this.chartMultiBatchColors[batchLineKey]) {
      // multi batch mode -> use multi batch colors
      return this.chartMultiBatchColors[batchLineKey];
    }
    if (this.chartComponentColors && this.chartComponentColors[component] && (this.activeComponents.allBodySelected === false || this.activeComponents.allBumperSelected === false)) {
      // component mode -> use component colors
      return this.chartComponentColors[component];
    }
    // use standard bumper colors
    return this.bumperColors;
  }
}
