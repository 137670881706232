<div class="full-height flex-column gap-25">
  <div class="flex-column gap-10">
    <img src="assets/icon/colimo-logo.png" alt="CoLiMo" class="logo align-self-center"/>
    <div class="clickable flexlayout neg-topmargin-10px" (click)="openView(sidenavItems.NOTIFICATION)"
         [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.NOTIFICATION }">
        <mat-icon *ngIf="alertsConfigured">notification_important</mat-icon>
        <mat-icon *ngIf="!alertsConfigured">notifications_off</mat-icon>
        <span [matBadgeHidden]="alertCount?.newRed < 1" matBadge="&#8288;"
              matBadgeSize="small" class="mat-badge-RED badge-adjust"></span> <!-- this '&#8288;' is for not displaying a number in the batch -->
        <span [matBadgeHidden]="alertCount?.newYellow < 1" matBadge="&#8288;"
              matBadgeSize="small" class="mat-badge-YELLOW badge-adjust"></span>
    </div>
    <div class="clickable flexlayout" (click)="toggleSidenavCollapsed()">
      <mat-icon>search</mat-icon>
    </div>
    <div
      class="clickable flexlayout"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.COLOR }"     
      (click)="openView(sidenavItems.COLOR)"
    >
      <mat-icon>palette</mat-icon>
    </div>
    <div
      class="clickable flexlayout"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.PAINTLINE }"
      (click)="openView(sidenavItems.PAINTLINE)"
    >
      <mat-icon>precision_manufacturing</mat-icon>
    </div>
    <div
      class="clickable flexlayout"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.MODEL }"
      (click)="openView(sidenavItems.MODEL)"
    >
      <mat-icon>directions_car</mat-icon>
    </div>
  </div>
  <mat-divider class="sidenav-divider"></mat-divider>
  <div class="flex-column gap-10 center-center">
    <div
      class="clickable flexlayout"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.CAR_REPORT }"
      (click)="openView(sidenavItems.CAR_REPORT)"
    >
      <mat-icon>fact_check</mat-icon>
    </div>
    <div
      class="clickable flexlayout"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.REPORT }"
      (click)="openView(sidenavItems.REPORT)"
    >
      <mat-icon>ballot</mat-icon>
    </div>
    <div
      class="clickable flexlayout"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.ALIEN_CHECK }"
      (click)="openView(sidenavItems.ALIEN_CHECK)"
    >
      <mat-icon>playlist_add_check</mat-icon>
    </div>
    <div class="flexlayout">
      <colimo-settings [alertsConfigured]="alertsConfigured"></colimo-settings>
    </div>
  </div>
  <div class="full-height flex-column gap-10 justify-end">
    <div class="clickable flexlayout" (click)="logout()">
      <mat-icon>logout</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="clickable flexlayout" (click)="toggleSidenavCollapsed()">
      <mat-icon>keyboard_double_arrow_right</mat-icon>
    </div>
  </div>
</div>
