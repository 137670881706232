import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as ModalActions from '../../../actions/modal.actions';
import * as fromRoot from '../../../reducers';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { Kpis } from '../../../shared/enums/kpis.enum';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { Subject } from 'rxjs';


/**
 * Represents a Modal Dialogue.
 */
@Component({
  selector: 'colimo-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input()
  public size: string;

  public selectedKpi: Kpis;
  public modalOpen = false;
  public isMobile: boolean;

  private modalOpened$: Observable<any>;

  constructor(
    private store: Store<fromRoot.State>,
    private mobileCheck: MobileCheck
  ) {
    super();
  }

  @HostListener('body:keyup.escape')
  public close(): void {
    if (this.modalOpen === false) { return; }

    this.modalOpen = false;
    this.store.dispatch(new ModalActions.RemoveModal());
  }

  public ngOnInit(): void {
    this.modalOpened$ = this.store.select(fromRoot.getModalOpened);

    // Subscribe to clicked KPI
    this.modalOpened$.pipe(takeUntil(this.stop$)).subscribe((response) => {
      if (response.open) {
        this.selectedKpi = response.tab;
        this.modalOpen = response.open;
      }
    });

    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
    });
  }

  public ngOnDestroy(): void {
    this.close();
  }
}
