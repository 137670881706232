import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TrendAnalysisResult } from 'src/app/models/trend-analysis-result.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrendAnalysisService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiTrendAnalysisPath;
  }

  public getTrendAnalysisResult(batchId: number, lineId: string, kpi: string): Observable<TrendAnalysisResult> {
    if (!batchId || !lineId || !kpi) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.trendAnalysis
      .replace('{batchId}', batchId.toString())
      .replace('{lineId}', lineId.toString())
      .replace('{indicator}', kpi)}`;

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as TrendAnalysisResult),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public calculateDaysUntilNewKpi(trendAnalysisResult: TrendAnalysisResult): number {
    if (trendAnalysisResult.prediction.dateOfTheChange) {
      // Set hours of the dates to 0 for a more precise calculation
      const dateOfTheChange = new Date(trendAnalysisResult.prediction.dateOfTheChange).setHours(0, 0, 0);
      const todaysDate = new Date().setHours(0, 0, 0);
      const oneDayMilliseconds = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs((todaysDate.valueOf() - dateOfTheChange.valueOf()) / oneDayMilliseconds));
    }
    return 0;
  }

  public extractChartData(trendAnalysisResult: TrendAnalysisResult): number[] {
    const chartData = [];
    if (trendAnalysisResult && trendAnalysisResult.prediction && trendAnalysisResult.prediction.ratings) {
      Object.keys(trendAnalysisResult.prediction.ratings).forEach((key) => {
        if (trendAnalysisResult.prediction.ratings[key] !== null || undefined) {
          chartData.push(Number(trendAnalysisResult.prediction.ratings[key]) * 100);
        }
      });
    }
    return chartData;
  }
}
