import { Action } from '@ngrx/store';
import { type } from '../util';

export const ACTION_TYPES = {
  ADD_OR_UPDATE_FORECAST: type<'[Forecast Information] Add or Update Forecast'>('[Forecast Information] Add or Update Forecast')
};

export class AddOrUpdateForecast implements Action {
  public readonly type = ACTION_TYPES.ADD_OR_UPDATE_FORECAST;

  constructor(public readonly payload: any) {}
}

export type Actions
  = AddOrUpdateForecast;
