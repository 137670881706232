<div class="modal kpi-modal" [ngClass]="isMobile === true ? 'mobile-kpi-modal' : ''" *ngIf="modalOpen">

  <div
    class="modal-dialog"
    role="dialog"
    aria-hidden="true"
    [ngClass]="{
      'modal-sm': size === 'sm',
      'modal-md': size === 'md',
      'modal-lg': size === 'lg',
      'modal-xl': size === 'xl'
    }"
  >
    <div class="modal-outer-wrapper">
      <div class="modal-content-wrapper">
        <div class="modal-content">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="close()"
          >
            <colimo-icons
              [icon]="'close'"
              [width]="'23px'"
              [height]="'24px'"
            ></colimo-icons>
          </button>
          <div class="modal-body">
            <colimo-kpi-help
              [selectedTab]="selectedKpi"
            ></colimo-kpi-help>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div  class="modal-backdrop"
       aria-hidden="true"
       (click)="close()">
  </div>
</div>

