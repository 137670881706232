import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive for checking if the user has clicked outside the given element.
 * Idea from here: https://www.bizstream.com/blog/november-2017/angular-click-elsewhere-directive
 */
@Directive({
  selector: '[colimoOutsideClick]',
})
export class OutsideClickDirective {
  @Output() public clickOutside = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Check if the click was outside the element
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit(event);
    }
  }
}
