import { Injectable } from '@angular/core';
import { ComponentStatus } from 'src/app/models/component-status.model';
import { DeltaValue } from 'src/app/models/delta-value.model';
import { LabchComponentStatusView } from 'src/app/models/labch-component-status-view.model';

@Injectable({
  providedIn: 'root',
})
export class CarReportService {
  constructor() {}

  public buildLabchStatisticDataSource(
    componentsStatus: ComponentStatus[],
    componentStatusCount: number,
  ): LabchComponentStatusView[] {
    const labchStatisticDataSource: LabchComponentStatusView[] = [];
    let componentStatusEntries = 0;
    if (!componentsStatus || componentsStatus.length === 0) {
      return labchStatisticDataSource;
    }
    if (componentStatusCount) {
      componentStatusEntries = componentStatusCount;
    }
    componentsStatus.forEach((componentStatus: ComponentStatus, tableIndex: number) => {
      componentStatus.deltaValues.forEach((deltaValue: DeltaValue, index: number) => {
        let initialLabchComponentStatusItem: LabchComponentStatusView = {
          id: tableIndex + componentStatusEntries + 1,
          componentStatus: componentStatus,
          deltaValue: deltaValue,
        };
        if (index !== 0) {
          initialLabchComponentStatusItem.id = null;
        }
        labchStatisticDataSource.push(initialLabchComponentStatusItem);
      });
    });
    return labchStatisticDataSource;
  }
}
