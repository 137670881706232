import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Color } from '../../models/color.model';
import { KeyText } from '../../models/key-text.model';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBasePath;
  }

  public getColorsByLocation(location: KeyText): Observable<Color[]> {
    // Returns empty observable if mandatory parameter missing
    if (location == null || location.key == null) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.reports.colors.replace('{locationId}', location.key)}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as Color[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }
}
