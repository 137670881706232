<div class="container pb-2">
  <div class="row">
    <div class="col-md-12 flex-row gap-lt-sm-20">
      <div *ngIf="isMobile">
        <button
          mat-button
          class="burger-menu margin-top"
          (click)="openSidenav()"
        >
          <mat-icon class="menu-icon">menu</mat-icon>
        </button>
      </div>
      <div class="flex-column gap-10 left-column">
        <div class="flex-row justify-start">
          <h2 [ngClass]="isMobile === true ? 'heading mobile-heading' : 'heading'">{{ 'app.info' | translate }}</h2>
        </div>
      </div>
      <div [ngClass]="isMobile === true ? 'svg-wrap mobile-svg' : 'svg-wrap'">
        <div class="logo"></div>
      </div>
    </div>
  </div>
  <colimo-modal [size]="'md'"> </colimo-modal>
  <div class="row" *ngIf="backendInfo">
    <div class="col-md-12">
      <div class="background-well server-info">
        <div class="row px-1">
          <div class="col-sm-4 col-md-3 col-lg-2 patent">{{ 'app.patentNr' | translate }}:</div>
          <div class="col-sm-8 col-md-9 col-lg-10">WO 2019/068828 A1</div>
        </div>
        <colimo-frontend-info [version]="appVersion" [name]="appName"></colimo-frontend-info>
        <colimo-backend-info [backendInfo]="backendInfo"></colimo-backend-info>
      </div>
    </div>
  </div>
</div>

<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="isLoading"></colimo-loader>
