<div class="button-position flex-row gap-10">
  <button
    class="mat-elevation-z0"
    mat-mini-fab
    matRipple
    matRippleColor="white"
    color="primary"
    [disabled]="disableZoomIn"
    (click)="scaleChart(ZoomDir.In)"
  >
    <mat-icon>add</mat-icon>
  </button>
  <button
    class="mat-elevation-z0"
    mat-mini-fab
    matRipple
    matRippleColor="white"
    color="primary"
    [disabled]="disableZoomOut"
    (click)="scaleChart(ZoomDir.Out)"
  >
    <mat-icon>remove</mat-icon>
  </button>
</div>

<colimo-canvas-wrapper *ngIf="!isRendering && lineChartData" [chartType]="chartType" [quadrant]="quadrant">
    <canvas #chartMWG height="160"></canvas>
</colimo-canvas-wrapper>
