import { Injectable } from '@angular/core';
import { ColorWithProducts, Product } from '../../models/viewmodels/color-with-products.viewmodel';
import { Line } from '../../models/viewmodels/line.viewmodel';
import { LocationWithLines } from '../../models/viewmodels/location-with-lines.viewmodel';

/**
 * This service is responsible for transforming data that is needed for filter the KPI report.
 */
@Injectable({
  providedIn: 'root',
})
export class KpiReportFilterDataTransformerService {
  constructor() {}

  /**
   * Creates and returns an array for KPIs that manages which KPIs are selected by the user.
   * @param kpis contains the available KPIs
   */
  public createSelectedKpisArray(kpis: string[]): boolean[] {
    const kpiSelected = [];
    if (kpis) {
      kpis.forEach((item, index) => (kpiSelected[index] = true));
    }
    return kpiSelected;
  }

  /**
   * Returns the name of selected lines as an array.
   * @param locationsWithLines contains the available lines
   */
  public getSelectedLines(locationsWithLines: LocationWithLines[]): string[] {
    const selectedLines = [];
    if (locationsWithLines) {
      locationsWithLines.forEach((locationWithLines: LocationWithLines) => {
        if (locationWithLines && locationWithLines.lines) {
          locationWithLines.lines.forEach((line: Line) => {
            if (line.selected) {
              selectedLines.push(line.name);
            }
          });
        }
      });
    }
    return selectedLines;
  }

  /**
   * Returns the name of selected products as an array.
   * @param colorsWithProducts contains the available products
   */
  public getSelectedProducts(colorsWithProducts: ColorWithProducts[]): string[] {
    const selectedProducts = [];
    if (colorsWithProducts) {
      colorsWithProducts.forEach((colorWithProducts: ColorWithProducts) => {
        if (colorWithProducts && colorWithProducts.products) {
          colorWithProducts.products.forEach((product: Product) => {
            if (product.selected) {
              selectedProducts.push(product.productName);
            }
          });
        }
      });
    }
    return selectedProducts;
  }

  /**
   * Returns the name of selected KPIs as an array.
   * @param kpiSelected manages which KPIs are selected by the user
   * @param kpis contains the available KPIs
   */
  public getSelectedKpis(kpiSelected: boolean[], kpis: string[]): string[] {
    const selectedKpis = [];
    if (kpiSelected) {
      kpiSelected.forEach((selected: boolean, index: number) => {
        if (selected) {
          selectedKpis.push(kpis[index]);
        }
      });
    }
    return selectedKpis;
  }
}
