import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToPipeList',
})
export class ArrayToPipeListPipe implements PipeTransform {
  transform<T>(valueList: T[], property: string): string {
    let pipeList = '';
    if (valueList && valueList.length !== 0) {
      valueList.forEach((value: T, index: number) => {
        if (index !== 0) {
          pipeList += ' | ';
        }
        pipeList += value[property];
      });
    }
    return pipeList;
  }
}
