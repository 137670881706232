<div class="error background-well">
  <div class="error-head">
    <h1 class="error-code">{{ errorCode }}</h1>
    <h2 class="error-title">{{ errorText | translate }}</h2>
  </div>

  <div class="error-body">
    <h3 class="error-hint-headline">{{ hintListHeadline | translate }}</h3>
    <ul class="error-hint-list">
      <li class="error-hint-listitem" *ngFor="let item of hintList">
        <span class="error-hint-listitem__inner">{{ item | translate }}</span>
      </li>
    </ul>
  </div>
</div>
