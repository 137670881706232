<form #loginForm="ngForm" class="login clr-form" (ngSubmit)="onSubmit()" *ngIf="!redirectLogin">
  <div class="clr-form-control">
    <label class="clr-control-label" for="usernameInput">Username</label>
    <input
      type="email"
      class="clr-input full-width"
      id="usernameInput"
      placeholder="Please enter your username"
      [(ngModel)]="loginCredentials.user"
      name="username"
      required
      autofocus
    />
  </div>
  <div class="clr-form-control">
    <label class="clr-control-label" for="passwordInput">Password</label>
    <input
      type="password"
      class="clr-input full-width"
      id="passwordInput"
      placeholder="Please enter your password"
      [(ngModel)]="loginCredentials.pass"
      name="password"
      required
    />
  </div>
  <div class="clr-form-control">
    <button type="submit" class="btn btn-primary full-width" [disabled]="!loginForm.form.valid">Login</button>
  </div>
  <div class="clr-form-control">
    <ng-container *ngIf="authenticationResult?.successful === false">
      <clr-alert
        *ngIf="(authenticationResult?.apiError)['error'].apierror?.message; else noErrorMessage"
        [clrAlertType]="'danger'"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            {{ (authenticationResult?.apiError)['error'].apierror?.message }}
          </span>
        </clr-alert-item>
      </clr-alert>
    </ng-container>
    <ng-template #noErrorMessage>
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text">
            Something went wrong during login. Please try to refresh the page.
          </span>
        </clr-alert-item>
      </clr-alert>
    </ng-template>
  </div>
</form>

<div *ngIf="redirectLogin" class="redirect-wrapper">
  <div> CoLiMo Login was moved to:</div>
  <p></p>
    <a href="{{ redirectUrl }}" target="_blank">
      <mat-icon class="material-icons-outlined link-external">open_in_new</mat-icon>
      {{ redirectUrl }}
    </a>
</div>
