import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * This service provides functionality to handle occurred error and exception situations.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorNotificationService {
  private errorSubject: Subject<string>;

  constructor() {
    this.errorSubject = new Subject();
  }

  public notifyError(errorMessage: string): void {
    this.errorSubject.next(errorMessage);
  }

  /**
   * All errors are propagated through this Observable.
   */
  public get errors$(): Observable<string> {
    return this.errorSubject;
  }
}
