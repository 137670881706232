import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TinterAddition } from 'src/app/models/tinter-addition.model';

@Pipe({
  name: 'tinterAdditionTooltip',
})
export class TinterAdditionTooltipPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(tinterAdditions: TinterAddition[]): string {
    let tinterAdditionTooltip = '';
    if (tinterAdditions && tinterAdditions.length !== 0) {
      tinterAdditions.forEach((tinterAddition: TinterAddition) => {
        tinterAdditionTooltip +=
          this.datePipe.transform(tinterAddition.additionDate, 'dd.MM.y') +
          '\n' +
          [
            tinterAddition.matName,
            tinterAddition.matNr,
            tinterAddition.amountRel,
            tinterAddition.unitRel,
            tinterAddition.amountAbs,
            tinterAddition.unitAbs,
          ]
            .filter(Boolean)
            .join(' ') +
          '\n';
      });
    }
    return tinterAdditionTooltip;
  }
}
