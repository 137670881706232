<div *ngIf="car.vehicleId" class="row mt-1">
  <div class="col-12">
    <h3 class="audit-headline">{{ 'reports.filterLabel.vehicleId' | translate }} {{ car.vehicleId }}</h3>
  </div>
</div>
<div class="row car-status">
  <div class="col-md-2 col-4 new-font-style">
    <label>{{ 'reports.reportTable.batch' | translate }}</label>
    <p>{{ car.batchNr }}</p>
  </div>
  <div class="col-md-2 col-4 new-font-style">
    <ng-container *ngIf="car.line; else refMeasurements">
      <label>{{ 'reports.reportTable.line' | translate }}</label>
      <p>{{ car.line }}</p>
    </ng-container>
    <ng-template #refMeasurements>
      <label>#{{ 'reports.reportTable.refMeasurements' | translate }}</label>
      <p>{{ car.avgMeasurements }}</p>
    </ng-template>
  </div>
  <div class="col-md-2 col-4" new-font-style>
    <label>{{ 'reports.reportTable.model' | translate }}</label>
    <p>{{ car.model }}</p>
  </div>
  <div class="col-md-2 col-4 new-font-style">
    <label>{{ 'reports.reportTable.product' | translate }}</label>
    <p>{{ car.product }}</p>
  </div>
  <div class="col-md-2 col-4 new-font-style">
    <label>{{ 'reports.reportTable.color' | translate }}</label>
    <p>{{ car.colorName }}</p>
  </div>
  <div class="col-md-2 col-4 new-font-style">
    <label>{{ 'reports.reportTable.dateMeasurement' | translate }}</label>
    <p>{{ car.measurementDate }}</p>
  </div>
</div>
