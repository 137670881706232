<div class="chart-row is-lab-color-space" *ngFor="let keyValuePair of populatedDataSet | iterable">
  <p class="chart-empty" *ngIf="keyValuePair.value.colorSet.length === 0">
    {{ keyValuePair.key }}: {{ 'dashboard.missingData' | translate }}
  </p>
  <colimo-canvas-wrapper
    [chartType]="keyValuePair.key"
    [quadrant]="keyValuePair.value.chQuadrant"
    ngClass="is-lpg-right"
    *ngIf="keyValuePair.value.colorSet.length > 0"
  >
    <div class="chart-inner-wrapper">
      <div
        class="boxplot-wrapper"
        *ngFor="let boxplot of keyValuePair.value.colorSet; let i = index"
        [ngClass]="{ 'is-hidden': !boxplot.display, 'is-visible': boxplot.display }"
      >
        <div class="type-label">{{ keyValuePair.key | labelByChartType: dataSet.tolerances }}</div>
        <colimo-lpg-chart-component
          [boxplotChild]="boxplot"
          [iterationIndex]="i"
          [key]="keyValuePair.key"
          [angles]="lineChartAngles"
        ></colimo-lpg-chart-component>
      </div>
    </div>
  </colimo-canvas-wrapper>
</div>

<colimo-loader [background]="'transparent'" [top]="'40%'" *ngIf="isLoading"></colimo-loader>
