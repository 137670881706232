import { Component, Input, OnInit } from '@angular/core';
import { KpiReportDataset } from '../../../models/kpi-report-data-set.model';
import { Kpis } from '../../../shared/enums/kpis.enum';

@Component({
  selector: 'colimo-kpi-report-table',
  templateUrl: './kpi-report-table.component.html',
  styleUrls: ['./kpi-report-table.component.scss'],
})
export class KpiReportTableComponent implements OnInit {
  @Input()
  public kpiReportDatasets: KpiReportDataset[];

  @Input()
  public selectedKpis: string[];

  constructor() {}

  public ngOnInit(): void {}

  public isCpiSelected(): boolean {
    return this.isKpiSelected(Kpis.CPI);
  }

  public isBpiSelected(): boolean {
    return this.isKpiSelected(Kpis.BPI);
  }

  public isLpiSelected(): boolean {
    return this.isKpiSelected(Kpis.LPI);
  }

  public isApiSelected(): boolean {
    return this.isKpiSelected(Kpis.API);
  }

  public isChiSelected(): boolean {
    return this.isKpiSelected(Kpis.CHI);
  }

  private isKpiSelected(kpi: Kpis): boolean {
    let kpiName = Kpis[kpi];
    // internally KPI is called 'BPI' but is displayed as 'TPI'
    kpiName = kpiName.replace('BPI', 'TPI');
    const mayFoundKpi = this.selectedKpis.find(selectedKpi => selectedKpi === kpiName);
    if (mayFoundKpi) {
      return true;
    }
    return false;
  }
}
