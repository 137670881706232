import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Chart from 'chart.js/auto';
import { IKpiLine } from 'src/app/models/kpi.model';
import { TrendAnalysisResult } from 'src/app/models/trend-analysis-result.model';
import { ColorDataService } from 'src/app/services/color-data/color-data.service';
import { TrendAnalysisService } from 'src/app/services/trend-analysis/trend-analysis.service';
import { environment } from 'src/environments/environment';
import {ChartDefaults} from '../../../shared/enums/chart-defaults.enum';

@Component({
  selector: 'colimo-trend-analysis-result',
  styleUrls: ['trend-analysis-result.component.scss'],
  templateUrl: 'trend-analysis-result.component.html',
})
export class TrendAnalysisResultComponent implements OnInit, AfterViewInit {
  @ViewChild('chartCanvasTrends') public chartCanvas: ElementRef;

  public daysUntilNewKpi: number;
  public isLoading: boolean;
  public chartDataAvailable = true;
  public maxPredictionDays: number;

  public trendAnalysisResult: TrendAnalysisResult;
  private renderContext: CanvasRenderingContext2D;
  private chartInstance: Chart;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      batchId: number;
      kpiLine: IKpiLine;
    },
    private trendAnalysisService: TrendAnalysisService,
    private colorDataService: ColorDataService,
    private cd: ChangeDetectorRef,
  ) {
    this.maxPredictionDays = environment.trendAnalysisMaxPredictionDays;
  }

  public ngOnInit(): void {
    this.isLoading = false;
    this.chartDataAvailable = true;
    Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: false });
    Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      xOffset: 0,
      yOffset: 0,
    });
  }

  public ngAfterViewInit(): void {
    // Creating the Chart
    this.renderContext = this.chartCanvas.nativeElement.getContext('2d');
    this.chartInstance = new Chart(this.renderContext, {
      type: 'bar',
      data: {
        datasets: [
          {
            backgroundColor: this.colorDataService.getChartColors(),
            borderColor: this.colorDataService.getChartBorderColors(),
            borderWidth: 1,
            data: []
          },
        ],
      },
      options:  {
        plugins : {
          legend: {
            display: false,
          }
        },
        font: {
          size: 15
        },
        scales: {
          y:
            {
              title: {
                display: true,
                text: 'Probability in %',
              },
              beginAtZero: true,
            },
          x:
            {
              title: {
                display: true,
                text: 'CPI Value',
              },
            },

        },
      },
    });
    this.isLoading = true;
    this.cd.detectChanges();
    this.loadTrendAnalysisResult();
  }

  private loadTrendAnalysisResult(): void {
    this.trendAnalysisService
      .getTrendAnalysisResult(this.data.batchId, this.data.kpiLine.lineNumber, 'CPI')
      .subscribe((trendAnalysisResult: TrendAnalysisResult) => {
        this.isLoading = false;
        this.trendAnalysisResult = trendAnalysisResult;
        this.daysUntilNewKpi = this.trendAnalysisService.calculateDaysUntilNewKpi(trendAnalysisResult);
        this.chartInstance.data.datasets[0].data = this.trendAnalysisService.extractChartData(trendAnalysisResult);
        if (this.chartInstance.data.datasets[0].data.length !== 0) {
          this.chartInstance.data.labels = Object.keys(trendAnalysisResult.prediction.ratings);
        }
        if (!trendAnalysisResult.prediction || !trendAnalysisResult.prediction.ratings) {
          this.chartDataAvailable = false;
        }
        this.chartInstance.update();
        this.cd.detectChanges();
      });
  }
}
