import { Action, ActionReducerMap } from '@ngrx/store';
import { IDegData } from '../models/chart-deg.model';
import { IFdgData } from '../models/chart-fdg.model';
import { IFogData } from '../models/chart-fog.model';
import { ILpgData } from '../models/chart-lpg.model';
import { IMwgData } from '../models/chart-mwg.model';
// Models
import { BatchlineSelection } from '../models/batchline-selection';
import { ColorDashboard } from '../models/color-dashboard.model';
import { DashboardSelection } from '../models/dashboard-selection.model';
import { ModelDashboard } from '../models/model-dashboard.model';
import { ModelsComponents } from '../models/models-components.model';
import { PaintlineDashboard } from '../models/paintline-dashboard.model';
import { Iproject } from '../models/project.model';
import { TinterAdditions } from '../models/tinter-additions.model';
import { Sidenav } from '../models/sidenav.model';
// Reducer
import * as fromActiveDashboard from './active-dashboard.reducer';
import * as fromBatchHeader from './batch-header.reducer';
import * as fromBatchlineSelection from './batchline-selection.reducer';
import * as fromCharts from './charts.reducer';
import * as fromColorDashboard from './color-dashboard.reducer';
import * as fromDashboardSelection from './dashboard-selection.reducer';
import * as fromForecast from './forecast.reducer';
import * as fromModal from './modal.reducer';
import * as fromModelDashboard from './model-dashboard.reducer';
import * as fromPaintlineDashboard from './paintline-dashboard.reducer';
import * as fromProject from './project.reducer';
import * as fromSidenav from './sidenav.reducer';
import * as fromTinterAdditions from './tinter-additions.reducer';

export interface State {
  colorDashboard: ColorDashboard[];
  modelDashboard: ModelDashboard[];
  paintlineDashboard: PaintlineDashboard[];
  currentProject: Iproject;
  charts: {
    modelsComponents: ModelsComponents;
    mwg: IMwgData;
    fdg: IFdgData;
    fog: IFogData;
    deg: IDegData;
    lpg: ILpgData;
  };
  batchHeader: Iproject;
  calculatedForecasts: any;
  modalOpened: any;
  tinterAdditions: TinterAdditions;
  activeDashboard: string;
  dashboardSelection: DashboardSelection;
  batchLineSelection: BatchlineSelection;
  sidenav: Sidenav;
}

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export const reducers: ActionReducerMap<State> = {
  colorDashboard: fromColorDashboard.reducer,
  modelDashboard: fromModelDashboard.reducer,
  paintlineDashboard: fromPaintlineDashboard.reducer,
  currentProject: fromProject.reducer,
  charts: fromCharts.reducer,
  batchHeader: fromBatchHeader.reducer,
  calculatedForecasts: fromForecast.reducer,
  modalOpened: fromModal.reducer,
  tinterAdditions: fromTinterAdditions.reducer,
  activeDashboard: fromActiveDashboard.reducer,
  dashboardSelection: fromDashboardSelection.reducer,
  batchLineSelection: fromBatchlineSelection.reducer,
  sidenav: fromSidenav.reducer,
};

// Selects
export const getColorDashboard = (state: State) => state.colorDashboard;
export const getModelDashboard = (state: State) => state.modelDashboard;
export const getPaintlineDashboard = (state: State) => state.paintlineDashboard;
export const getProject = (state: State) => state.currentProject;
export const getModelsComponents = (state: State) => state.charts.modelsComponents;
export const getChartDataMWG = (state: State) => state.charts.mwg;
export const getChartDataFDG = (state: State) => state.charts.fdg;
export const getChartDataDEG = (state: State) => state.charts.deg;
export const getChartDataFOG = (state: State) => state.charts.fog;
export const getChartDataLPG = (state: State) => state.charts.lpg;
export const getBatchHeaderInfo = (state: State) => state.batchHeader;
export const getCalculatedForecasts = (state: State) => state.calculatedForecasts;
export const getModalOpened = (state: State) => state.modalOpened;
export const getTinterAdditions = (state: State) => state.tinterAdditions;
export const getActiveDashboard = (state: State) => state.activeDashboard;
export const getDashboardSelection = (state: State) => state.dashboardSelection;
export const getBatchlineSelection = (state: State) => state.batchLineSelection;
export const getSidenav = (state: State) => state.sidenav;
