<div class="row px-1">
  <div class="col-12">
    <h4>colimo-ws-user</h4>
  </div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.artifact' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">
    {{ backendInfo.buildProperties.group }}.{{ backendInfo.buildProperties.artifact }}
  </div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.version' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.buildProperties.version }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.buildTime' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.buildProperties.time }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.host' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.hostname }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.activeProfiles' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.properties.activeProfiles }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.dataSourceUrl' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.properties.datasourceUrl }}</div>
</div>
<div class="row px-1">
  <div class="col-12">
    <h4>FM-API</h4>
  </div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.url' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.properties.fmapiUrl }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.pingResult' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.fmapiPing }}</div>
</div>
<div class="row px-1">
  <div class="col-12">
    <h4>KPI Service</h4>
  </div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.url' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.properties.kpiUrl }}</div>
</div>
<div class="row px-1">
  <div class="col-sm-4 col-md-3 col-lg-2">{{ 'app.pingResult' | translate }}:</div>
  <div class="col-sm-8 col-md-9 col-lg-10">{{ backendInfo.kpiPing }}</div>
</div>
