import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IBatchForecastMeasurement } from '../../models/batch-forecast-measurement.model';
import { IBatch } from '../../models/batch.model';
import { MobileCheck } from '../../services/mobile-check/mobile-check.service';
import { ProjectService } from '../../services/project/project.service';
import {BatchlineSelection} from '../../models/batchline-selection';

@Component({
  selector: 'colimo-project-batch',
  styleUrls: ['project-batch.component.scss'],
  templateUrl: 'project-batch.component.html',
})
export class ProjectBatchComponent implements OnInit {
  @Input() public projectId: string;
  @Input() public batch: IBatch;
  @Input() public modelId: string;
  @Input() public projectPage: number;
  @Input() public isColorRoute: boolean;
  @Input() public batchLineSelections: BatchlineSelection[];

  @Output() public reload: EventEmitter<any> = new EventEmitter();

  public isMobile: boolean;
  public isExpired: boolean;
  public selectedForecastMeasurement: IBatchForecastMeasurement;
  public isForecastMeasurementLoading = false;
  public forecastMeasurementResult: string;
  public batchCommentTooltip: string;

  constructor(
    private projectService: ProjectService,
    private mobileCheck: MobileCheck,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().subscribe((deviceInfo) => {
      this.isMobile = deviceInfo.isMobile;
    });
    this.batchCommentTooltip = this.getBatchCommentToolTip();
    this.checkIsExpired();
  }

  public updateForecastMeasurementsByBatchId(): void {
    this.isForecastMeasurementLoading = true;
    this.projectService.updateForecastByBatchId({ batchId: this.batch.batchId }).subscribe(
      (numberOfNewMeasurements: number) => {
        if (numberOfNewMeasurements > 0) {
          this.reloadForecastMeasurements();
        }
        this.forecastMeasurementResult = this.getUpdateForecaseMeasurementsResultText(numberOfNewMeasurements);
        setTimeout(() => {
          this.forecastMeasurementResult = undefined;
        }, 10000);
      },
      () => {
        this.isForecastMeasurementLoading = false;
      },
      () => {
        this.isForecastMeasurementLoading = false;
      },
    );
  }

  public forecastInit(selectedForecastMeasurementId: number): void {
    setTimeout(() => {
      this.selectedForecastMeasurement = this.batch.forecastMeasurements.find((item: IBatchForecastMeasurement) => {
        return item.id === selectedForecastMeasurementId;
      });
    });
  }

  private getBatchCommentToolTip(): string{
    if (!this.batch || !this.batch.batchComments || this.batch.batchComments.length === 0) {
      return '';
    }

    let tooltip = '';
    for (let i = 0; i < this.batch.batchComments.length; i++) {
      tooltip += this.batch.batchComments[i].commentTime + ':' + '\n' +this.batch.batchComments[i].commentText + '\n';
    }
    return tooltip;
  }

  private checkIsExpired(): void {
    if(this.batch && this.batch.expirationDate && this.batch.kpiLines.length > 0) {
      let lmDate  = new Date("2000-01-01");
      for(let i= 0; i < this.batch.kpiLines.length; i++) {
        let compDate = new Date(this.batch.kpiLines[i].lastMeasurementDate);
        if(compDate > lmDate){
          lmDate = compDate;
        }
      }
      let expDate = new Date(this.batch.expirationDate);
      if(lmDate > expDate) {
        this.isExpired = true;
      } else {
        this.isExpired = false;
      }
    }
  }

  private getUpdateForecaseMeasurementsResultText(numberOfNew: number): string {
    if (numberOfNew >= 0) {
      return ' - ' + numberOfNew + ' ' + this.translate.instant('dashboard.updatedForecastMeasurements');
    } else {
      return ' - ' + this.translate.instant('dashboard.errorUpdatingForecastMeasurements');
    }
  }

  private reloadForecastMeasurements(): void {
    this.isForecastMeasurementLoading = true;
    this.projectService.getForecastMeasurementsByBatchId({ batchId: this.batch.batchId }).subscribe(
      (forecastMeasurements: IBatchForecastMeasurement[]) => {
        if (forecastMeasurements) {
          // reset current selection after update
          this.selectedForecastMeasurement = null;
          this.batch.forecastMeasurements = forecastMeasurements;
        }
      },
      () => {
        this.isForecastMeasurementLoading = false;
      },
      () => {
        this.isForecastMeasurementLoading = false;
      },
    );
  }
}
