<div class="full-height flex-column gap-25">
  <div class="flex-row gap-10 center-start">
    <h1 class="app-name">Color Line Monitor</h1>
    <img src="assets/icon/colimo-logo.png" class="logo" />
  </div>
  <div class="flex-row gap-20 ">
    <div
      class="top-button flex-column start-center flex-82px"
      (click)="openView(sidenavItems.COLOR)"
    >
      <div mat-icon-button class="info-button">
        <mat-icon>other_houses</mat-icon>
      </div>
      <span>{{ 'sidenav.home' | translate }}</span>
    </div>
    <div class="top-button flex-column start-center flex-82px">
      <div mat-icon-button class="info-button">
        <mat-icon>shopping_cart</mat-icon>
      </div>
      <div>{{ 'reports.filterLabel.products' | translate }}</div>
    </div>
    <div class="top-button flex-column start-center flex-82px">
      <div mat-icon-button class="info-button" (click)="openView(sidenavItems.NOTIFICATION)"
           [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.NOTIFICATION }">
          <mat-icon *ngIf="alertsConfigured">notification_important</mat-icon>
          <mat-icon *ngIf="!alertsConfigured">notifications_off</mat-icon>
          <span [matBadgeHidden]="alertCount?.newRed < 1" matBadge="&#8288;"
                matBadgeSize="small" class="mat-badge-RED"></span> <!-- this '&#8288;' is for not displaying a number in the batch -->
          <span [matBadgeHidden]="alertCount?.newYellow < 1" matBadge="&#8288;"
                matBadgeSize="small" class="mat-badge-YELLOW"></span>
      </div>
      <div>{{ 'sidenav.alerts' | translate }}</div>
    </div>
  </div>
  <colimo-search></colimo-search>
  <mat-divider></mat-divider>
  <div class="flex-column gap-10">
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.COLOR }"
      (click)="openView(sidenavItems.COLOR)"
    >
      <mat-icon>palette</mat-icon>
      <span>{{ 'reports.reportTable.color' | translate }}</span>
    </div>
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.PAINTLINE }"
      (click)="openView(sidenavItems.PAINTLINE)"
    >
      <mat-icon>precision_manufacturing</mat-icon>
      <span>{{ 'sidenav.paintline' | translate }}</span>
    </div>
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.MODEL }"
      (click)="openView(sidenavItems.MODEL)"
    >
      <mat-icon>directions_car</mat-icon>
      <span>{{ 'reports.reportTable.model' | translate }}</span>
    </div>
  </div>
  <mat-divider class="sidenav-divider"></mat-divider>
  <div class="flex-column gap-10">
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.CAR_REPORT }"
      (click)="openView(sidenavItems.CAR_REPORT)"
    >
      <mat-icon>fact_check</mat-icon>
      <span>{{ 'reports.carReport' | translate }}</span>
    </div>
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.REPORT }"
      (click)="openView(sidenavItems.REPORT)"
    >
      <mat-icon>ballot</mat-icon>
      <span>{{ 'reports.kpiReport' | translate }}</span>
    </div>
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.ALIEN_CHECK }"
      (click)="openView(sidenavItems.ALIEN_CHECK)"
    >
      <mat-icon>playlist_add_check</mat-icon>
      <span>{{ 'reports.carCheck' | translate }}</span>
    </div>
    <div>
      <colimo-settings [alertsConfigured]="alertsConfigured"></colimo-settings>
    </div>
  </div>
  <div class="full-height flex-column justify-end gap-10">
    <div class="clickable flexlayout justify-space-between" (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>{{ 'sidenav.logout' | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="clickable flexlayout justify-space-between" (click)="toggleSidenavCollapsed()">
      <span>{{ 'sidenav.collapseSidebar' | translate }}</span>
      <mat-icon>keyboard_double_arrow_left</mat-icon>
    </div>
  </div>
</div>
