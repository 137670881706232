<div class="bigger-font-size">{{ 'dashboard.vidPlotTitle' | translate }}</div>
<div class="flex-row vid-component-wrapper">
  <div class="canvas-dimensions">
    <canvas #chartVID></canvas>
  </div>
  <div class="flex-column">
    <mat-form-field subscriptSizing="dynamic" class="background-color-whitesmoke">
      <mat-label>{{ 'dashboard.enterDateRange' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="carBodyPicker" [min]="initMinimumDate" [max]="initMaximumDate">
        <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
        <input matEndDate placeholder="End date" [(ngModel)]="endDate" (dateChange)="dateRangeChange()">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="carBodyPicker"></mat-datepicker-toggle>
      <mat-date-range-picker #carBodyPicker></mat-date-range-picker>
    </mat-form-field>
    <p class="cars-total bigger-font-size">#{{ 'dashboard.vidPlotTitle' | translate }} {{ totalCars }}</p>
  </div>
</div>
