import { Injectable } from '@angular/core';
import { defineLocale, deLocale, esLocale, zhCnLocale } from 'ngx-bootstrap/chronos';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor() {}

  /**
   * Defines used locales in the application.
   * This is needed for using them in the datepicker.
   * See here: https://valor-software.com/ngx-bootstrap/#/datepicker#locales .
   */
  public defineUsedLocales(): void {
    defineLocale('de', deLocale);
    defineLocale('es', esLocale);
    defineLocale('cn', zhCnLocale);
  }
}
