import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ApiError } from 'colimo-ui-library';
import { ErrorNotificationService } from '../error-notification/error-notification.service';
import { NavigationService } from '../navigation/navigation.service';
import {environment} from '../../../../environments/environment';

/**
 * This service provides functionality to handle all errors and
 * exceptions that are thrown in the application.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(
    private errorNotificationService: ErrorNotificationService,
    private navigationService: NavigationService,
  ) {
    super();
  }

  public handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    } else {
      this.handleGeneralError(error);
    }
    super.handleError(error);
  }

  /**
   * Handles http errors.
   * Navigation to login route is performed if authentication is not valid.
   * Otherwise it will just be notified about the error.
   */
  private handleHttpError(error: HttpErrorResponse): void {
    if (error.status === 401 || error.status === 403) {
      this.navigationService.navigateToLoginRoute();
    } else if (
      // do not show an error if the trends collector prediction is not available or evolution link cannot be determined
      error.url &&
        (error.url.includes('colimoWsTrendsCollector/predictions/batch/') || error.url.includes(environment.colorCareEvoUserUrl))
    ) {
      return;
    } else if (error.error && error.error.apierror) {
      const apiError: ApiError = error.error.apierror;
      this.errorNotificationService.notifyError(apiError.message);
    } else {
      this.errorNotificationService.notifyError(error.message);
    }
  }

  private handleGeneralError(error: Error): void {
    this.errorNotificationService.notifyError(error.message);
  }
}
