import { Injectable } from '@angular/core';
import { KpiReport } from '../../models/kpi-report.model';
import { IKpi } from '../../models/kpi.model';
import { AverageKpis } from '../../models/viewmodels/average-kpis.viewmodel';

/**
 * This service is responsible for filtering the average KPIs data from KPI reports.
 */
@Injectable({
  providedIn: 'root',
})
export class AverageKpisFilterService {
  constructor() {}

  /**
   * Filters the average KPIs from given KPI reports.
   */
  public filterAverageKpis(kpiReports: KpiReport[]): AverageKpis[] {
    if (kpiReports == null) {
      return null;
    }
    return this.createAverageKpisFromKpiReports(kpiReports);
  }

  private createAverageKpisFromKpiReports(kpiReports: KpiReport[]): AverageKpis[] {
    const averageKpis: AverageKpis[] = [];
    kpiReports.forEach((kpiReport: KpiReport, index: number) => {
      const averageKpi: AverageKpis = {
        site: undefined,
        kpis: {},
      };

      averageKpi.site = kpiReport.site;
      averageKpi.kpis.API = this.setAndReturnKpi(kpiReport.api);
      averageKpi.kpis.BPI = this.setAndReturnKpi(kpiReport.tpi);
      averageKpi.kpis.CHI = this.setAndReturnKpi(kpiReport.chi);
      averageKpi.kpis.CPI = this.setAndReturnKpi(kpiReport.cpi);
      averageKpi.kpis.LPI = this.setAndReturnKpi(kpiReport.lpi);

      averageKpis[index] = averageKpi;
    });
    return averageKpis;
  }

  private setAndReturnKpi(kpi: IKpi): IKpi {
    if (kpi && kpi.kpiValue !== 0) {
      return kpi;
    }
    return undefined;
  }
}
