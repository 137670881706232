import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, zip } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Sidenav } from 'src/app/models/sidenav.model';
import { environment } from '../../../environments/environment';
import * as ProjectActions from '../../actions/project.actions';
import { IbatchPagination } from '../../models/batch-pagination.model';
import { IBatch } from '../../models/batch.model';
import { Iproject } from '../../models/project.model';
import * as fromRoot from '../../reducers';
import { ProjectService } from '../../services/project/project.service';
import { BaseComponent } from '../../shared/components/base/base.component';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import {BatchlineSelection} from '../../models/batchline-selection';

/**
 * Represents project page that shows one project with an array of batches
 */
@Component({
  selector: 'colimo-project-page',
  styleUrls: ['project-page.component.scss'],
  templateUrl: 'project-page.component.html',
  providers: [ProjectService],
})
export class ProjectPageComponent extends BaseComponent implements OnInit, OnDestroy {
  public project: Iproject;
  public isLastPage = true;
  public batchesLoaded = false;
  public moreBatchesLoaded = true;
  public projectId: string;
  public lineId: string;
  public modelId: string;
  public currentPage: number = 0;
  public isColorRoute: boolean;
  public displayBatches: IBatch[] = [];
  public batchLineSelections: BatchlineSelection[] = [];

  private pageSize: number;
  private selectedBatchId: number;
  private routeParams$: Observable<Params>;
  private project$: Observable<Iproject>;
  private projectAndBatches$: Observable<{
    p: Iproject;
    b: IbatchPagination;
  }>;
  private projectAndBatchSubscription: Subscription;
  private projectSubscription: Subscription;
  private routeSubscription: Subscription;

  private excludedProducts: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private store: Store<fromRoot.State>,
    private navigationService: NavigationService,
  ) {
    super();
    this.pageSize = environment.project.projectPageSize;
    this.onProjectDataLoaded = this.onProjectDataLoaded.bind(this);
    this.onBatchesDataLoaded = this.onBatchesDataLoaded.bind(this);
    this.onTestDataLoadError = this.onTestDataLoadError.bind(this);
    this.onForecastBatchLoaded = this.onForecastBatchLoaded.bind(this);
  }

  public ngOnInit(): void {
    this.routeParams$ = this.route.params;
    this.project$ = this.store.select(fromRoot.getProject);

    this.store
      .select(fromRoot.getBatchlineSelection)
      .pipe(takeUntil(this.stop$))
      .subscribe((selection) => {
        this.selectedBatchId = selection.batchId;
      });

    // Subscribe to route changes
    this.routeSubscription = this.routeParams$.pipe(takeUntil(this.stop$)).subscribe((route) => {
      this.projectId = route.projectId;
      this.lineId = route.lineId;
      this.modelId = route.modelId;

      // if the page param is greater than 0 load corresponding amount of batches
      let initPageSize = this.pageSize;
      if (route.projectPage && route.projectPage > 0) {
        const pageNumber = parseInt(route.projectPage);
        initPageSize = (pageNumber + 1) * this.pageSize;
        this.currentPage = pageNumber;
      }

      // Zip batches and project routes so that their order does not matter
      this.projectAndBatches$ = zip(
        this.loadProject(this.projectId),
        this.loadBatches({
          projectId: this.projectId,
          lineId: this.lineId,
          modelId: this.modelId,
          page: 0, // initPage always 0
          size: initPageSize,
        }),
      ).pipe(
        map(([p, b]) => {
          return { p, b };
        }),
      );

      this.projectAndBatchSubscription = this.projectAndBatches$.pipe(takeUntil(this.stop$)).subscribe((response) => {
        this.onProjectDataLoaded(response.p);
        this.onBatchesDataLoaded(response.b);
      }, this.onTestDataLoadError);
    });
    this.store
      .select(fromRoot.getSidenav)
      .pipe(takeUntil(this.stop$))
      .subscribe((sidenav: Sidenav) => {
        this.isColorRoute = sidenav.isColorRoute;
      });

    this.projectSubscription = this.project$.pipe(takeUntil(this.stop$)).subscribe((project) => {
      this.project = project;
    });
  }

  public ngOnDestroy(): void {
    // Empty current project with all it's batches on destroy
    this.store.dispatch(new ProjectActions.DeleteItemAction());

    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  /**
   * Reloads another set of batches on a click on the more button
   */
  public loadMore(): void {
    this.moreBatchesLoaded = false;
    this.currentPage += 1;
    this.loadBatches({
      projectId: this.projectId,
      lineId: this.lineId,
      modelId: this.modelId,
      page: this.currentPage,
      size: this.pageSize,
    })
      .pipe(take(1))
      .subscribe(this.onBatchesDataLoaded);
  }

  public productSelectionChanged(excludedProducts: string[]): void {
    this.excludedProducts = excludedProducts;
    if (this.excludedProducts && this.excludedProducts.length > 0) {
      // filter for excluded products
      this.displayBatches = this.filterForProducts(this.excludedProducts);
    } else {
      // all products
      this.displayBatches = this.project.batches;
    }
  }

  private filterForProducts(excludedProducts: string[]): IBatch[] {
    const filtered = [];
    for (let i = 0; i < this.project.batches.length; i++) {
      if (excludedProducts.indexOf(this.project.batches[i].productName) === -1) {
        filtered.push(this.project.batches[i]);
      }
    }
    return filtered;
  }

  // Load the project details
  private loadProject(projectId: string): Observable<Iproject> {
    return this.projectService.getProject({ projectId });
  }

  // Load batches for the project
  private loadBatches(query: {
    projectId: string;
    lineId: string;
    modelId: string;
    page: number;
    size: number;
  }): Observable<IbatchPagination> {
    return this.projectService.getBatches(query);
  }

  // Dispatch project information
  private onProjectDataLoaded(project: Iproject): void {
    this.store.dispatch(new ProjectActions.CreateItemAction(project));
  }

  /**
   * Dispatch the batches, check if the response is the last page
   */
  private onBatchesDataLoaded(response: IbatchPagination): void {
    if (response.content && response.content.length) {
      this.store.dispatch(new ProjectActions.InsertBatchesAction(response.content));
      this.isLastPage = response.last;
      this.productSelectionChanged(this.excludedProducts);
    }
    this.batchesLoaded = true;
    this.moreBatchesLoaded = true;
    setTimeout(() => this.scrollToBatch());
  }

  private scrollToBatch() {
    if (this.selectedBatchId) {
      const selectedBatch = document.getElementById(String(this.selectedBatchId));
      if (selectedBatch) {
        selectedBatch.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }

  // Dispatch fetched batch
  private onForecastBatchLoaded(batch: IBatch): void {
    if (batch && batch.batchId) {
      this.store.dispatch(new ProjectActions.UpdateBatchAction(batch));
    }
  }

  private onTestDataLoadError(error: HttpErrorResponse): void {
    this.navigationService.navigateOnHttpError(error);
  }
}
