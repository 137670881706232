<div *ngIf="deltaEViewModel.BODY || deltaEViewModel.BUMPER" class="flex-row gap-2">
  <div class="legend flex-column start-end">
    <div>max {{ differenceType | labelByChartType: !!deltaEViewModel.scaleTolerances }}</div>
    <div>0</div>
  </div>

  <colimo-chart-deg-graphic
    class="deg-graphic"
    [chartFilters]="chartFilters"
    (chartFiltersChange)="updateChartFilters($event)"
    [deltaEViewModel]="deltaEViewModel"
    [chartMultiBatchColors]="chartMultiBatchColors"
    [projects]="projects"
    [activePage]="activePage"
    [iFdgData]="chartDataFDG$ | async"
    [tinterAdditions]="tinterAdditions$ | async"
  >
  </colimo-chart-deg-graphic>
</div>
