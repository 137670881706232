<mat-sidenav-container fullscreen autosize #sidenavContainer>
  <mat-sidenav
    [mode]="isMobile && !sidenavCollapsed ? 'over' : 'side'"
    *ngIf="(authenticated | async) !== null"
    [ngClass]="isMobile ? 'mobile-sidenav-menu' : 'sidenav-menu'"
    #sidenav
    id="sidenav"
    [opened]="true"
    [disableClose]="true"
  >
    <ng-container *ngIf="!isMobile">
      <colimo-extended-sidenav *ngIf="!sidenavCollapsed" [alertCount]="alertCount" [alertsConfigured]="alertsConfigured" (initiateLogout)="logout()"></colimo-extended-sidenav>
      <colimo-collapsed-sidenav *ngIf="sidenavCollapsed" [alertCount]="alertCount" [alertsConfigured]="alertsConfigured" (initiateLogout)="logout()"></colimo-collapsed-sidenav>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <colimo-mobile-sidenav *ngIf="!sidenavCollapsed" [alertCount]="alertCount" [alertsConfigured]="alertsConfigured" (initiateLogout)="logout()"></colimo-mobile-sidenav>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content class="background">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
