import { Pipe, PipeTransform } from '@angular/core';
import { ComponentStatus } from '../../models/component-status.model';
import {DeltaValue} from '../../models/delta-value.model';

/**
 * Determines the css class of given component status.
 */
@Pipe({
  name: 'componentStatusCssClass',
})
export class ComponentStatusCssClassPipe implements PipeTransform {
  public transform(componentStatus: ComponentStatus, tolerancesOn: boolean, defaultShowDe?: boolean): string {
    if (componentStatus == null || componentStatus.deltaValues == null || tolerancesOn == null) {
      return '';
    }

    if (defaultShowDe === false && tolerancesOn === false) {
      //BCH-206974 Coloring of the measurement points in the Car Report for MB colors
      return this.determineComponentStatusCssClassNotDe(componentStatus);
    }

    // no status can be determined without tolerances
    if (tolerancesOn === false) {
      return 'gray';
    }

    return this.determineComponentStatusCssClass(componentStatus);
  }

  private determineComponentStatusCssClass(componentStatus: ComponentStatus): string {
    const notOkayAngles = componentStatus.deltaValues.filter((de) => de.dE > 1.7);
    const okayAngles = componentStatus.deltaValues.filter((de) => de.dE <= 1.4);

    // if at least one angle is not okay, the whole component is defined as not okay
    if (notOkayAngles.length > 0) {
      return 'red';
    }

    // only if all angles are okay, the whole component is also defined as okay
    if (okayAngles.length === componentStatus.deltaValues.length) {
      return 'green';
    }

    return 'orange';
  }

  private determineComponentStatusCssClassNotDe(componentStatus: ComponentStatus): string {
    const notOkMeasurements = this.countRedMeasurementPoints(componentStatus.deltaValues);

    // max 1 of 15 points is red => point green
    if (notOkMeasurements <= 1) {
      return 'green';
    }

    // more than or euqal to 4 of 15 points are red => point red
    if (notOkMeasurements >= 4) {
      return 'red';
    }

    // between 2 and 3 of 15 the points are red => point orange
    return 'orange';
  }

  private countRedMeasurementPoints(deltaValues: DeltaValue[]): number {
    let count = 0;
    // count red measurement points for (dL*da*db for angles 15-110)
    for (let i = 0; i < deltaValues.length; i++) {
      if (deltaValues[i].angle >= 15 && deltaValues[i].angle <= 110) {
        if (deltaValues[i].dlColor === 'red') {
          count++;
        }
        if (deltaValues[i].daColor === 'red') {
          count++;
        }
        if (deltaValues[i].dbColor === 'red') {
          count++;
        }
      }
    }

    return count;
  }
}
