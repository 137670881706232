import { Action } from '@ngrx/store';
import { BatchlineSelection } from '../models/batchline-selection';
import { type } from '../util';

/**
 * Actions for adding and removing a batchline selection.
 */

export const ACTION_TYPES = {
  SET_SELECTION: type<'[BatchlineSelection] Set Batchline Selection'>('[BatchlineSelection] Set Batchline Selection'),
};

export class SetSelectionAction implements Action {
    public readonly type = ACTION_TYPES.SET_SELECTION;
    constructor(public readonly payload: BatchlineSelection) {}
}
export type Actions = SetSelectionAction;