import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'colimo-icons',
  styleUrls: ['icons.component.scss'],
  templateUrl: 'icons.component.html',
})
export class IconsComponent {
  @Input() public icon = '';

  @Input() public direction = 'up';

  @Input() public altText = 'icon';

  @HostBinding('style.width')
  @Input()
  public width = '16px';

  @HostBinding('style.height')
  @Input()
  public height = '16px';

  @HostBinding('style.top')
  @Input()
  public top = '0';

  constructor() {}

}
