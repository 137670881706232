import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartFilters } from '../../models/chart-filters.model';
import { IDataSets, Itable } from '../../models/chart-mwg.model';
import { inverseMatrix } from '../../shared/helpers/inverse-matrix';

/**
 * Represents a table list view for body and bumper values. Used in the MWG.
 */
@Component({
  selector: 'colimo-chart-table',
  templateUrl: 'chart-table.component.html',
  styleUrls: ['chart-table.component.scss'],
})
export class ChartTableComponent implements OnChanges {
  @Input() public dataSet: IDataSets;
  @Input() public table: Itable;
  @Input() public angles: string[];
  @Input() public selectedAngles: boolean[];
  @Input() public tolerance: boolean;
  @Input() public batchType: 'BODY' | 'BUMPER';
  @Input() public filters: ChartFilters;

  public tableData: number[][];
  public tableColors: string[][];
  public tableHighlights: boolean[][];
  public correspondingTableData: number[][];
  public correspondingTableColors: string[][];
  public correspondingTableHighlights: boolean[][];
  public typeLabels: string[];

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateTableData();
  }

  /**
   * Returns the data for each color value (dl, da, db, ...)
   */
  private getColorValueData(dataSet: IDataSets, index: number): number[][] {
    return Object.keys(dataSet).map((key) => {
      return dataSet[key].dataSets[index].data;
    });
  }

  private getColorValueColors(dataSet: IDataSets, index: number): string[][] {
    return Object.keys(dataSet).map((key) => {
      return dataSet[key].dataSets[index].colors;
    });
  }

  private getColorValueHighlights(dataSet: IDataSets, index: number): boolean[][] {
    return Object.keys(dataSet).map((key) => {
      return dataSet[key].dataSets[index].highlights;
    });
  }

  /**
   * Updates the transposition matrix for body and bumper.
   * Updates also the labels.
   */
  private updateTableData(): void {
    // Update Labels
    this.typeLabels = Object.keys(this.dataSet);

    // Update table data
    if (this.batchType === 'BODY') {
      this.tableData = inverseMatrix(this.getColorValueData(this.dataSet, 1));
      this.tableColors = inverseMatrix(this.getColorValueColors(this.dataSet, 1));
      this.tableHighlights = inverseMatrix(this.getColorValueHighlights(this.dataSet, 1));
      this.correspondingTableData = inverseMatrix(this.getColorValueData(this.dataSet, 0));
      this.correspondingTableColors = inverseMatrix(this.getColorValueColors(this.dataSet, 0));
      this.correspondingTableHighlights = inverseMatrix(this.getColorValueHighlights(this.dataSet, 0));
    }
    if (this.batchType === 'BUMPER') {
      this.tableData = inverseMatrix(this.getColorValueData(this.dataSet, 0));
      this.tableColors = inverseMatrix(this.getColorValueColors(this.dataSet, 0));
      this.tableHighlights = inverseMatrix(this.getColorValueHighlights(this.dataSet, 0));
      this.correspondingTableData = inverseMatrix(this.getColorValueData(this.dataSet, 1));
      this.correspondingTableColors = inverseMatrix(this.getColorValueColors(this.dataSet, 1));
      this.correspondingTableHighlights = inverseMatrix(this.getColorValueHighlights(this.dataSet, 1));
    }
  }
}
