<div *ngIf="averageKpis">
  <div class="row px-2">
    <ng-container *ngFor="let averageKpi of averageKpis">
      <div class="col-md-5 col-lg-4">
        <div class="row">
          <div class="col-md-12">
            <label class="average-label">{{ 'reports.averageKPIs' | translate }} {{ averageKpi.site }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12"><colimo-kpi [kpis]="averageKpi.kpis"></colimo-kpi></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
