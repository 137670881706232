import { Component, Input, OnInit } from '@angular/core';
import { CarFilterOptions } from 'src/app/models/car-filter-options.model';
import { Car } from 'src/app/models/car.model';
import { ComponentStatus } from 'src/app/models/component-status.model';
import { DifferenceType } from 'src/app/shared/enums/difference-type.enum';

@Component({
  selector: 'colimo-car-report-statistics',
  templateUrl: './car-report-statistics.component.html',
  styleUrls: ['./car-report-statistics.component.scss'],
})
export class CarReportStatisticsComponent implements OnInit {
  @Input() public car: Car;
  @Input() public filterOptions: CarFilterOptions;
  @Input() public selectedComponentStatus: ComponentStatus;
  @Input() public componentTranslations: string[];
  @Input() public positionTranslations: string[];

  public availableAngles: string[];
  public differenceType: DifferenceType;
  public displayedColumns: string[];
  public displayedColumnsDE: string[];

  constructor() {}

  public ngOnInit(): void {
    this.setAvailableAngles();
    this.setDisplayedColumns();
    this.setDifferenceType();
  }

  private setAvailableAngles(): void {
    let listOfAngles: string[] = [];
    this.car.componentsStatus.forEach((componentStatus: ComponentStatus) => {
      listOfAngles = listOfAngles.concat(componentStatus.deltaValues.map((de) => de.angle.toString()));
    });
    this.availableAngles = Array.from(new Set<string>(listOfAngles));
  }

  private setDisplayedColumns(): void {
    this.displayedColumns = ['index', 'component', 'position', 'status'];
    this.displayedColumns = this.displayedColumns.concat(this.availableAngles);
    this.displayedColumnsDE = ['span', 'dE'];
  }

  private setDifferenceType(): void {
    if (this.filterOptions.tolerancesOn) {
      this.differenceType = DifferenceType.S_DE;
    } else {
      this.differenceType = DifferenceType.D_DE;
    }
  }
}
