<div class="header flex-row gap-70 gap-lt-sm-20 spacebetween-end">

  <div *ngIf="isMobile">
    <button
      mat-button
      class="burger-menu"
      (click)="openSidenav()"
    >
        <mat-icon class="menu-icon">menu</mat-icon>
        <span [matBadgeHidden]="alertCount?.newRed < 1" matBadge="&#8288;"
              matBadgeSize="small" class="mat-badge-RED"></span> <!-- this '&#8288;' is for not displaying a number in the batch -->
        <span [matBadgeHidden]="alertCount?.newYellow < 1" matBadge="&#8288;"
              matBadgeSize="small" class="mat-badge-YELLOW"></span>
    </button>
  </div>
  <div class="flex-column gap-10 left-column">
    <div class="flex-row justify-start">
      <h2 [ngClass]="isMobile === true ? 'heading mobile-heading' : 'heading'">{{ heading | translate }}</h2>
    </div>
    <mat-divider class="heading-divider flex-1"></mat-divider>
  </div>
    <!-- -webkit-print-color-adjust: exact for export car report which uses window print and is needed to export the logo correctly-->
  <div [ngClass]="isMobile === true ? 'svg-wrap mobile-svg' : 'svg-wrap'" style="-webkit-print-color-adjust: exact">
    <div class="logo"></div>
  </div>
</div>
