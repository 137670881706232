import {Component, Input, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthenticationService, DecodedJwt } from 'colimo-ui-library';
import { Observable } from 'rxjs';
import { Language } from 'src/app/models/language.model';
import { Sidenav } from 'src/app/models/sidenav.model';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { environment } from 'src/environments/environment';
import * as fromRoot from '../../reducers';
import {Router} from '@angular/router';

@Component({
  selector: 'colimo-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Input() public alertsConfigured: boolean;
  public adminToolPath: string;
  public languages: Language[];
  public sidenavCollapsed: boolean;
  public authenticated: Observable<DecodedJwt>;

  constructor(
    private authenticationService: AuthenticationService,
    private languagesService: LanguagesService,
    private store: Store<fromRoot.State>,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.adminToolPath = environment.adminPath;
    this.authenticated = this.authenticationService.authentication;
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.sidenavCollapsed = sidenav.sidenavCollapsed;
    });
    this.languages = this.languagesService.getAvailableLanguages();
  }

  public updateLanguage(language: Language): void {
    this.languagesService.setCurrentLanguage(language);
  }

  public goToAlertSettings(): void {
    this.router.navigate(['/notification-center'], { state: { selectedTab: 1 } });
  }
}
