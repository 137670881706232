import { AfterViewInit, Component } from '@angular/core';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { Subject, takeUntil } from 'rxjs';
import { OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'colimo-privacy-policy-en',
  templateUrl: './privacy-policy-en.component.html',
  styleUrls: ['./privacy-policy-en.component.scss'],
})
export class PrivacyPolicyEnComponent implements AfterViewInit, OnInit, OnDestroy {
  
  public isMobile: boolean;
  private stop$: Subject<boolean> = new Subject();

  constructor(private mobileCheck: MobileCheck) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if ((window as any).OneTrust) {
        (window as any).OneTrust.initializeCookiePolicyHtml();
        (window as any).OneTrust.InitializeBanner();
      }
    });
  }

  public openCookieBanner(): void {
    (window as any).OneTrust.ToggleInfoDisplay();
  }
}
