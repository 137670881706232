import * as ColorDashboardActions from '../actions/color-dashboard.actions';
import { ColorDashboard } from '../models/color-dashboard.model';

const initialState: ColorDashboard[] = [];

/**
 * Reducer for adding and modifying dashboard and project models
 */
export function reducer(state: ColorDashboard[] = initialState, action: ColorDashboardActions.Actions): ColorDashboard[] {
  switch (action.type) {
    // Creates first instance of dashboard model(s) with projects
    case ColorDashboardActions.ACTION_TYPES.CREATE_ITEMS: {
      return prepareVisibility(state, action.payload);
    }

    // Adds dashboard model with projects to existing store list
    case ColorDashboardActions.ACTION_TYPES.ADD_ITEM: {
      const manufacturer = action.payload.manufacturer;

      const extendedState: ColorDashboard[] = state.map((category: ColorDashboard) => {
        if (category.customer === manufacturer) {
          const projects = [...category.projects, action.payload];

          // Sort projects by name
          projects.sort((prev, next) => {
            const namePrev = prev.name.toUpperCase();
            const nameNext = next.name.toUpperCase();

            if (namePrev < nameNext) {
              return -1;
            }

            if (namePrev > nameNext) {
              return 1;
            }

            // Names are equal
            return 0;
          });

          return {
            customer: manufacturer,
            logo: category.logo,
            projects,
            sites: category.sites,
            visible: category.visible,
          };
        } else {
          return category;
        }
      });

      return prepareVisibility(state, extendedState);
    }

    case ColorDashboardActions.ACTION_TYPES.TOGGLE: {
      const dashboard2Display: ColorDashboard = action.payload;
      state
        .filter((dm: ColorDashboard) => dm.customer === dashboard2Display.customer)
        .forEach(dm => {
          dm.visible = !dm.visible;
        });
      return state;
    }

    default:
      return state;
  }
}

function prepareVisibility(previousState: ColorDashboard[], payload: ColorDashboard[]): ColorDashboard[] {
  if (previousState.length === 0 || payload.length === 1) {
    return prepareInitialVisibility(payload);
  }
  return prepareSucceedingVisibility(previousState, payload);
}

function prepareInitialVisibility(payload: ColorDashboard[]): ColorDashboard[] {
  const extendedState = [...payload];

  if (extendedState.length === 1) {
    extendedState[0].visible = true;
  } else {
    extendedState.forEach((dm: ColorDashboard) => {
      dm.visible = false;
    });
  }
  return extendedState;
}

function prepareSucceedingVisibility(previousState: ColorDashboard[], payload: ColorDashboard[]): ColorDashboard[] {
  const extendedState = [...payload];

  extendedState.forEach(dm => {
    const previousValue = previousState.find(p => p.customer === dm.customer);
    if (previousValue != null) {
      dm.visible = previousValue.visible;
    } else {
      dm.visible = false;
    }
  });

  return extendedState;
}
