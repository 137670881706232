import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ``
})
export class BaseComponent implements OnDestroy {

  protected readonly stop$: Subject<boolean> = new Subject();

  constructor() {}

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }
}
