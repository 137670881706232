import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IbatchPagination } from '../../models/batch-pagination.model';
import { IBatch } from '../../models/batch.model';
import { Iproject } from '../../models/project.model';
import { IBatchForecastMeasurement } from '../../models/batch-forecast-measurement.model';

@Injectable()
export class ProjectService {
  private baseUrl: string;
  private simpleBaseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath + environment.apiVersionPath;
    this.simpleBaseUrl = environment.apiBasePath;
  }

  /**
   * Gets header information for a specific batch
   */
  public getBatchHeader(parameters: { batch: number; line: string }): Observable<Iproject> {
    if (!parameters.batch || !parameters.line) {
      return EMPTY;
    }
    const apiEndpoint = `${this.baseUrl}/${environment.batches.header
      .replace('{batchId}', parameters.batch.toString())
      .replace('{lineNumber}', parameters.line)}`;

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as Iproject),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Gets project information with array of products by query parameter
   *
   * @example
   * {
   *   "id": 4,
   *   "name": "Jatoba",
   *   "manufacturer": "BMW",
   *   "products": [{
   *     "name": "FW60-8B65",
   *     "type": "BODY"
   *   }]
   * }
   */
  public getProject(parameters: { projectId: string; lineId?: string; modelId?: string }): Observable<Iproject> {
    if (!parameters.projectId) {
      return EMPTY;
    }
    let apiEndpoint = `${this.baseUrl}/${environment.project.headerById.replace('{projectId}', parameters.projectId)}`;

    if (parameters.lineId) {
      apiEndpoint += '?lines=' + parameters.lineId;
    }

    if (parameters.lineId && parameters.modelId) {
      apiEndpoint += '&models=' + parameters.modelId;
    }

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as Iproject),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Gets pagination object with array of batches for a specified project id
   *
   * @example
   * {
   *   "totalElements":5,
   *   "totalPages":2,
   *   ...
   *   "content":[
   *      {
   *         "batch":{
   *            "id":11,
   *            "batchNumber":"0231658312",
   *            "kpis":[],
   *            "state":"IN_EXAMINATION",
   *            "lastMeasurementDate":null,
   *            "dockingDate":null
   *         },
   *         "productType":"BUMPER",
   *         "productName":"FW60-7B41",
   *         "productId":5
   *      }
   * ]}
   *
   * @params {String} projectId - productId
   * @params {String} lineId - lineId
   * @params {String} modelId - modelId
   * @params {Number} page - pagination index
   * @params {Number} size - amount of items per page
   */
  public getBatches(parameters: {
    projectId: string;
    lineId: string;
    modelId: string;
    page: number;
    size: number;
  }): Observable<IbatchPagination> {
    if (!parameters.projectId) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}/${environment.batches.byProjectId.replace('{projectId}', parameters.projectId)}`;

    if (parameters.lineId) {
      apiEndpoint += '?lines=' + parameters.lineId;
    }

    if (parameters.lineId && parameters.modelId) {
      apiEndpoint += '&models=' + parameters.modelId;
    }

    let params = new HttpParams();

    params = params.set('page', String(parameters.page));
    params = params.set('size', String(parameters.size));

    const requestOptions = {
      params,
    };
    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IbatchPagination),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  /**
   * Gets forecast calculation for a batch combined with a reference batch and a measurement as parameter.
   */
  public getForecastBatchById(parameters: { id: number; reference: number; forecastMeasurementId: number }): Observable<IBatch> {
    if (!parameters.id || !parameters.reference || !parameters.forecastMeasurementId) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.batches.forecastById.replace(
      '{batchId}',
      parameters.id.toString(),
    )}`;

    let params = new HttpParams();

    params = params.set('referenceBatchId', String(parameters.reference));
    params = params.set('forecastMeasurementId', String(parameters.forecastMeasurementId));

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as IBatch),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public updateForecastByBatchId(parameters: { batchId: number }): Observable<number> {
    if (!parameters.batchId) {
      return EMPTY;
    }

    const apiEndpoint = `${this.simpleBaseUrl}/${environment.batches.updateForecastByBatchId}`;

    let params = new HttpParams();

    params = params.set('batchId', String(parameters.batchId));
    params = params.set('maxRows', String(99));

    const requestOptions = {
      params,
    };

    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as number),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }

  public getForecastMeasurementsByBatchId(parameters: { batchId: number }): Observable<IBatchForecastMeasurement[]> {
    if (!parameters.batchId) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.batches.forecastMeasurementsByBatchId.replace(
      '{batchId}',
      parameters.batchId.toString(),
    )}`;

    return this.http.get(apiEndpoint).pipe(
      map((response) => response as IBatchForecastMeasurement[]),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')),
    );
  }
}
