<clr-tabs class="tab-panel" *ngIf="selectedTab">
  <clr-tab>
    <button clrTabLink>{{ "abbr.cpi" | translate }}</button>
    <ng-template [clrIfActive]="selectedTab === kpis.CPI">
      <clr-tab-content>
        <div class="kpi-content">
          <h3 class="kpi-headline">{{ "abbr.cpiHeadline" | translate }}</h3>
          <p class="kpi-text">{{ "abbr.cpiAdditional" | translate }}</p>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>

  <clr-tab>
    <button clrTabLink>{{ "abbr.bpi" | translate }}</button>
    <ng-template [clrIfActive]="selectedTab === kpis.BPI">
      <clr-tab-content>
        <div class="kpi-content">
          <h3 class="kpi-headline">{{ "abbr.bpiHeadline" | translate }}</h3>
          <p class="kpi-text">{{ "abbr.bpiAdditional" | translate }}</p>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>

  <clr-tab>
    <button clrTabLink>{{ "abbr.lpi" | translate }}</button>
    <ng-template [clrIfActive]="selectedTab === kpis.LPI">
      <clr-tab-content>
        <div class="kpi-content">
          <h3 class="kpi-headline">{{ "abbr.lpiHeadline" | translate }}</h3>
          <p class="kpi-text">{{ "abbr.lpiAdditional" | translate }}</p>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>

  <clr-tab>
    <button clrTabLink>{{ "abbr.api" | translate }}</button>
    <ng-template [clrIfActive]="selectedTab === kpis.API">
      <clr-tab-content>
        <div class="kpi-content">
          <h3 class="kpi-headline">{{ "abbr.apiHeadline" | translate }}</h3>
          <p class="kpi-text">{{ "abbr.apiAdditional" | translate }}</p>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>

  <clr-tab>
    <button clrTabLink>{{ "abbr.chi" | translate }}</button>
    <ng-template [clrIfActive]="selectedTab === kpis.CHI">
      <clr-tab-content>
        <div class="kpi-content">
          <h3 class="kpi-headline">{{ "abbr.chiHeadline" | translate }}</h3>
          <p class="kpi-text">{{ "abbr.chiAdditional" | translate }}</p>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
