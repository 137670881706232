<div class="row px-2">
  <div class="col-md-12">
    <clr-datagrid>
      <clr-dg-column [clrDgField]="'startDate'">{{ 'reports.reportTable.dateStart' | translate }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'site'">{{ 'reports.reportTable.site' | translate }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'line'">{{ 'reports.reportTable.line' | translate }}</clr-dg-column>
      <clr-dg-column *ngIf="isCpiSelected()" [clrDgField]="'cpi'">CPI</clr-dg-column>
      <clr-dg-column *ngIf="isBpiSelected()" [clrDgField]="'tpi'">TPI</clr-dg-column>
      <clr-dg-column *ngIf="isLpiSelected()" [clrDgField]="'lpi'">LPI</clr-dg-column>
      <clr-dg-column *ngIf="isApiSelected()" [clrDgField]="'api'">API</clr-dg-column>
      <clr-dg-column *ngIf="isChiSelected()" [clrDgField]="'chi'">CHI</clr-dg-column>
      <clr-dg-column [clrDgField]="'color'">{{ 'reports.reportTable.color' | translate }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'product'">{{ 'reports.reportTable.product' | translate }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'batch'">{{ 'reports.reportTable.batch' | translate }}</clr-dg-column>

      <clr-dg-row *clrDgItems="let kpiReportDataset of kpiReportDatasets">
        <clr-dg-cell>{{ kpiReportDataset.startDate | dateFormat | async }}</clr-dg-cell>
        <clr-dg-cell>{{ kpiReportDataset.site }}</clr-dg-cell>
        <clr-dg-cell>{{ kpiReportDataset.line }}</clr-dg-cell>
        <clr-dg-cell *ngIf="isCpiSelected()" class="kpi {{ 'text-' | kpiLabel: kpiReportDataset.cpi?.kpiColor }}">
          {{ kpiReportDataset.cpi?.kpiValue }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="isBpiSelected()" class="kpi {{ 'text-' | kpiLabel: kpiReportDataset.tpi?.kpiColor }}">
          {{ kpiReportDataset.tpi?.kpiValue }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="isLpiSelected()" class="kpi {{ 'text-' | kpiLabel: kpiReportDataset.lpi?.kpiColor }}">
          {{ kpiReportDataset.lpi?.kpiValue }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="isApiSelected()" class="kpi {{ 'text-' | kpiLabel: kpiReportDataset.api?.kpiColor }}">
          {{ kpiReportDataset.api?.kpiValue }}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="isChiSelected()" class="kpi {{ 'text-' | kpiLabel: kpiReportDataset.chi?.kpiColor }}">
          {{ kpiReportDataset.chi?.kpiValue }}
        </clr-dg-cell>
        <clr-dg-cell>{{ kpiReportDataset.color }}</clr-dg-cell>
        <clr-dg-cell>{{ kpiReportDataset.product }}</clr-dg-cell>
        <clr-dg-cell>{{ kpiReportDataset.batch }}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10">
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50]">Datasets per page</clr-dg-page-size>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ pagination.totalItems }} datasets
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
</div>
