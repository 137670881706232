import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DashboardSelection } from 'src/app/models/dashboard-selection.model';
import { ScreenSize } from 'src/app/models/screen-size.model';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { ColorDashboard } from '../../models/color-dashboard.model';
import * as fromRoot from '../../reducers';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import {AlertUserConfig} from '../../models/alert-user-config';
import {NotificationService} from '../../services/notification/notification.service';

@Component({
  selector: 'colimo-color-dashboard',
  providers: [DashboardService],
  templateUrl: 'color-dashboard.component.html',
  styleUrls: ['color-dashboard.component.scss'],
})
export class ColorDashboardComponent implements OnInit {
  public heading: string;
  public isLoading = true;
  public dashboard$: Observable<ColorDashboard[]>;
  public dashboardSelection$: Observable<DashboardSelection>;
  public isMobile$: Observable<ScreenSize>;
  public alertsConfigured: boolean;

  constructor(
    private dashboardService: DashboardService,
    private store: Store<fromRoot.State>,
    private mobileCheck: MobileCheck,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.heading = 'reports.reportTable.color';
    this.dashboardService.getColorDashboard().subscribe(() => {
      this.isLoading = false;
    });
    this.dashboard$ = this.store.select(fromRoot.getColorDashboard);
    this.dashboardSelection$ = this.store.select(fromRoot.getDashboardSelection);
    this.isMobile$ = this.mobileCheck.getScreenSize();
    this.notificationService.getAlertUserConfigObservable().subscribe({
      next: (config: AlertUserConfig) => {
        this.alertsConfigured = this.notificationService.alertsConfigured(config);
      }
    });
    // start notification/alert polling with service
    this.notificationService.startPolling();
  }

  public customerSelected(newCustomer: string, storedCustomer: string): void {
    if (newCustomer === storedCustomer) {
      this.dashboardService.setCurrentDashboardSelection({ customer: null, line: null, model: null });
    } else {
      this.dashboardService.setCurrentDashboardSelection({
        customer: newCustomer,
        line: null,
        model: null,
      });
    }
  }
}
