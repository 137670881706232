import Chart from 'chart.js/auto';
import {LineChartOptions} from '../../models/line-chart-options.model';
import {ZoomDir} from '../enums/zoom-directions.enum';

// this is just a scale function for a chart instance and it is not registered as global plugin because its only used for mwg and fdg graph
export const chartPanScale = (chart: Chart, direction: ZoomDir, zoomStep: number, chartOptions: LineChartOptions, callback?): void => {

  const config = chart.config;
  const scales = config.options.scales;

  let maxMiddle: number = scales.y.max as any;
  let minMiddle: number = scales.y.min as any;

  // get current max/min ticks
  let max = scales.y.max;
  let min = scales.y.min;

  // calculate
  maxMiddle += direction === ZoomDir.In ? -zoomStep : zoomStep as any;
  maxMiddle = maxMiddle > chartOptions.pan.rangeMax.y ? chartOptions.pan.rangeMax.y : maxMiddle;
  minMiddle += direction === ZoomDir.In ? zoomStep : -zoomStep as any;
  minMiddle = minMiddle < chartOptions.pan.rangeMin.y ? chartOptions.pan.rangeMin.y : minMiddle;

  // calc delta from maxMiddle/minMiddle (more accurate)
  const delta = maxMiddle - minMiddle;
  // reduce/increase max/min (current min/max ticks)
  max += direction === ZoomDir.In ? -zoomStep : zoomStep as any;
  min += direction === ZoomDir.In ? zoomStep : -zoomStep as any;

  // check if max is over rangeMax
  if (max > chartOptions.pan.rangeMax.y) {
    max = chartOptions.pan.rangeMax.y;
    min = max - delta;
  }

  // check if min is over rangeMin
  if (min < chartOptions.pan.rangeMin.y) {
    min = chartOptions.pan.rangeMin.y;
    max = min + delta;
  }

  // Only zoom if current step is > 0
  if (maxMiddle > 0) {
    // set new chart ticks in options and chartInstance
    chartOptions.scales.y.max = maxMiddle;
    chartOptions.scales.y.min = minMiddle;
    scales.y.max = max;
    scales.y.min = min;

    // get yTicks as any object in order to set the stepsize
    const yTicks = scales.y.ticks as any;

    if (delta <= 4) {
      yTicks.stepSize = 0.5;
    } else if (delta > 4 && delta <= 10) {
      yTicks.stepSize = 1;
    } else if (delta > 10 && delta <= 16) {
      yTicks.stepSize = 2.005; // there seems to be a chart internal rounding error for step size = 2 or 2.0 which leads to 0 line not showing => use 2.005
    } else {
      yTicks.stepSize = 5;
    }

    // fire chart change for update chart
    chart.update();
  }

  // provide callback
  if (typeof callback === 'function') {
    callback({
      isZoomed: !(maxMiddle >= chartOptions.pan.rangeMax.y),
      zoomInEnd: maxMiddle <= zoomStep,
      zoomOutEnd: maxMiddle >= chartOptions.pan.rangeMax.y
    });
  }
}
