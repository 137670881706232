import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Sidenav } from 'src/app/models/sidenav.model';
import * as BatchlineSelectionActions from '../../actions/batchline-selection.actions';
import { BatchlineSelection } from '../../models/batchline-selection';
import { IKpiLine } from '../../models/kpi.model';
import { Iproject } from '../../models/project.model';
import * as fromRoot from '../../reducers';
import { MobileCheck } from '../../services/mobile-check/mobile-check.service';

/**
 * Represents the project header.
 * Handles back-button logic.
 */
@Component({
  selector: 'colimo-project-header',
  styleUrls: ['project-header.component.scss'],
  templateUrl: 'project-header.component.html',
})
export class ProjectHeaderComponent implements OnInit {
  @Input() public project: Iproject;
  @Input() public lineId: string;
  @Input() public modelId: string;
  @Input() public kpis: IKpiLine;
  @Input() public showProductSelection: boolean = false;

  @Output() public productSelectionEvent: EventEmitter<string[]> = new EventEmitter();

  public excludedProducts: string[] = [];

  public arrowColor: string;

  public routeParams$: Observable<any>;
  public currentRouteParams;

  public isMobile: boolean;
  public isColorRoute: boolean;

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    // call back method also for browser back events
    this.goBack();
  }

  private static readonly colors = {
    desktop: '#2272A5',
    mobile: '#2272A5',
  };
  private activeDashboard: string;

  constructor(
    private mobileCheck: MobileCheck,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
  ) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().subscribe((device) => {
      this.arrowColor = device.isMobile ? ProjectHeaderComponent.colors.mobile : ProjectHeaderComponent.colors.desktop;
      this.isMobile = device.isMobile;
    });

    this.routeParams$ = this.route.params;
    this.routeParams$.subscribe((route) => {
      this.currentRouteParams = route;
    });
    this.store.select(fromRoot.getActiveDashboard).subscribe((activeDashboard) => {
      this.activeDashboard = activeDashboard;
    });
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.isColorRoute = sidenav.isColorRoute;
    });
  }

  public productSelectionChanged(event, productName: string): void {
    if (event.checked) {
      const idx = this.excludedProducts.indexOf(productName);
      if (idx > -1) {
        this.excludedProducts.splice(idx, 1);
      }
    } else {
      this.excludedProducts.push(productName);
    }

    this.productSelectionEvent.emit(this.excludedProducts);
  }

  public productExluded(productNr: string): boolean {
    return this.excludedProducts.indexOf(productNr) > -1;
  }

  public goBack(): void {
    if (this.currentRouteParams.chartType) {
      let routerLink = ['/project', this.currentRouteParams.projectId];
      if (this.isColorRoute) {
        routerLink = routerLink.concat(['color']);
      }
      if (this.lineId && !this.isColorRoute) {
        routerLink = routerLink.concat(['line', this.lineId]);
      }
      if (this.modelId && !this.isColorRoute) {
        routerLink = routerLink.concat(['model', this.modelId]);
      }
      if (this.currentRouteParams.projectPage) {
        routerLink = routerLink.concat(['page', this.currentRouteParams.projectPage]);
      }
      this.router.navigate(routerLink);
    } else {
      // reset batch line selection when going back to the dashboards
      this.store.dispatch(new BatchlineSelectionActions.SetSelectionAction({} as BatchlineSelection));
      if (this.modelId && !this.isColorRoute) {
        this.router.navigate(['/model']);
      } else if (this.lineId && !this.isColorRoute) {
        this.router.navigate(['/paintline']);
      } else {
        this.router.navigate(['/color']);
      }
    }
  }
}
