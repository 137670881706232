import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MobileCheck } from '../../../services/mobile-check/mobile-check.service';
import { BaseComponent } from '../base/base.component';

/**
 * Wraps content in an accordion panel and handles accordion logic.
 */
@Component({
  selector: 'colimo-accordion-wrapper',
  templateUrl: 'accordion-wrapper.component.html',
  styleUrls: ['accordion-wrapper.component.scss']
})

export class AccordionWrapperComponent extends BaseComponent implements OnInit {
  @Input()
  public headerTitle: string;
  @Input()
  public showOnlyOnMobile = false;
  @Input()
  public openOnStart = false;

  public isOpen = true;
  public isMobile = false;
  private hasChanged = false;
  private firstLoad = true;

  constructor(private mobileCheck: MobileCheck) {
    super();
  }

  public ngOnInit(): void {
    if (this.showOnlyOnMobile) {
      // subscribe to mobileCheck and update accordion state
      this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe(screenSize => {
        this.hasChanged = this.isMobile !== screenSize.isMobile;
        this.isMobile = screenSize.isMobile;

        // Renew the open state only if device has changed or it was loaded the first time.
        if (!screenSize.isMobile) {
          this.isOpen = true;
        } else if (this.firstLoad || this.hasChanged) {
          this.isOpen = this.openOnStart;
        }
      });
    } else {
      this.isOpen = true;
    }

    this.firstLoad = false;
  }

  public toggle(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isOpen = !this.isOpen;
  }
}
