import { Component, OnInit } from '@angular/core';
import { KeyText } from '../../models/key-text.model';
import { KpiReport } from '../../models/kpi-report.model';
import { AverageKpis } from '../../models/viewmodels/average-kpis.viewmodel';
import { AverageKpisFilterService } from '../../services/average-kpis-filter/average-kpis-filter.service';
import { KpiReportService } from '../../services/kpi-report/kpi-report.service';
import { DisplayBlobService } from '../../shared/services/display-blob/display-blob.service';

@Component({
  selector: 'colimo-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
})
export class ReportPageComponent implements OnInit {
  public isLoading: boolean;
  public selectedCustomerLoaded: boolean;
  public heading: string;
  public selectedCustomer: KeyText;
  public selectedLines: string[];
  public selectedProducts: string[];
  public selectedKpis: string[];
  public startDate: Date;
  public endDate: Date;
  public kpiReports: KpiReport[];
  public averageKpis: AverageKpis[];

  constructor(
    private kpiReportService: KpiReportService,
    private averageKpisFiltererService: AverageKpisFilterService,
    private displayBlobService: DisplayBlobService,
  ) {}

  public ngOnInit(): void {
    this.heading = 'reports.kpiReport';
    this.isLoading = true;
    this.selectedCustomerLoaded = false;
  }

  public onSelectedCustomerChanged(selectedCustomer: KeyText): void {
    this.selectedCustomerLoaded = true;
    this.selectedCustomer = selectedCustomer;
    this.loadKpiReport();
  }

  public onSelectedLinesChanged(selectedLines: string[]): void {
    this.selectedLines = selectedLines;
    this.loadKpiReport();
  }

  public onSelectedProductsChanged(selectedProducts: string[]): void {
    this.selectedProducts = selectedProducts;
    this.loadKpiReport();
  }

  public onSelectedKpisChanged(selectedKpis: string[]): void {
    this.selectedKpis = selectedKpis;
    this.loadKpiReport();
  }

  public onSelectedStartDateChanged(selectedStartDate: Date): void {
    this.startDate = selectedStartDate;
    this.loadKpiReport();
  }

  public onSelectedEndDateChanged(selectedEndDate: Date): void {
    this.endDate = selectedEndDate;
    this.loadKpiReport();
  }

  public exportKpiReport(): void {
    this.isLoading = true;
    if (this.selectedCustomer == null) {
      this.kpiReports = null;
      this.isLoading = false;
    } else {
      this.kpiReportService
        .getKpiReportExcelExport(
          Number(this.selectedCustomer.key),
          this.selectedLines,
          this.selectedProducts,
          this.selectedKpis,
          this.startDate,
          this.endDate,
        )
        .subscribe((kpiReportBlob: Blob) => {
          this.displayBlobService.displayBlob(kpiReportBlob, this.getReportFileName());
          this.isLoading = false;
        });
    }
  }

  private loadKpiReport(): void {
    this.isLoading = true;
    if (this.selectedCustomer == null) {
      this.kpiReports = null;
      if (this.selectedCustomerLoaded) {
        this.isLoading = false;
      }
    } else {
      this.kpiReportService
        .getKpiReport(
          Number(this.selectedCustomer.key),
          this.selectedLines,
          this.selectedProducts,
          this.selectedKpis,
          this.startDate,
          this.endDate,
        )
        .subscribe((kpiReports: KpiReport[]) => {
          this.kpiReports = kpiReports;
          this.averageKpis = this.averageKpisFiltererService.filterAverageKpis(kpiReports);
          this.isLoading = false;
        });
    }
  }

  /**
   * Returns proper file name for exported KPI report.
   */
  private getReportFileName(): string {
    return 'KPI_Report_' + this.selectedCustomer.text + '.xlsx';
  }
}
