import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Chart } from 'chart.js';
import { IFdgData } from 'src/app/models/chart-fdg.model';
import { ChartFilters } from 'src/app/models/chart-filters.model';
import { DeltaEViewModel } from '../../models/viewmodels/delta-e.viewmodel';
import { TinterAddition } from '../../models/tinter-addition.model';
import {Iproject} from '../../models/project.model';

@Component({
  selector: 'colimo-chart-deg-graphic',
  templateUrl: './chart-deg-graphic.component.html',
  styleUrls: ['./chart-deg-graphic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDegGraphicComponent implements OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('chartCanvasDEG') public chartCanvas: ElementRef;

  @Input() public deltaEViewModel: DeltaEViewModel;

  @Input() public activePage: number;

  @Input() public iFdgData: IFdgData;

  @Input() public tinterAdditions: TinterAddition[];
  @Input() public chartFilters: ChartFilters;
  @Input() public chartMultiBatchColors: string[];
  @Input() public projects: Iproject[];

  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();

  public pageDockingDateColors: any[][] = [];

  private renderContext: CanvasRenderingContext2D;

  private chartInstance: Chart;

  constructor() {}

  public ngAfterViewInit(): void {
    this.renderContext = this.chartCanvas.nativeElement.getContext('2d');
    this.chartInstance = new Chart(this.renderContext, this.deltaEViewModel.chartConfig);
  }

  private initBatchDockingDates(): void {
    if (!this.projects || this.projects.length < 1 || !this.chartMultiBatchColors) {
      return;
    }

    // reset docking dates
    this.pageDockingDateColors = [];

    for (let i = 0; i < this.projects.length; i++) {
      if (this.projects[i] && this.projects[i].kpiLine) {
        const index = this.findPageIndex(new Date(this.projects[i].kpiLine.dockingDate));
        if (index >= 0) {
          if (!this.pageDockingDateColors[index]) {
            this.pageDockingDateColors[index] = [];
          }
          this.pageDockingDateColors[index].push({ color: this.chartMultiBatchColors[this.projects[i].id + '-' + this.projects[i].kpiLine.lineNumber] });
        }
      }
    }
  }

  private findPageIndex(date: Date): number {
    if (!this.iFdgData || !this.iFdgData.pageEndDates || this.iFdgData.pageEndDates.length < 1) {
      return -1;
    }

    if (date.getTime() <= new Date(this.iFdgData.pageEndDates[0]).getTime()) {
      return 0;
    }

    for (let j = 0; j < this.iFdgData.pageEndDates.length; j++) {
      if (j + 1 === this.iFdgData.pageEndDates.length) {
        return -1;
      }

      if (this.isDateBetweenBoth(new Date(this.iFdgData.pageEndDates[j]), new Date(this.iFdgData.pageEndDates[j+1]), date)) {
        return j + 1;
      }
    }
    return -1;
  }

  private isDateBetweenBoth(endDateOfPreviousPage: Date, endDateOfRelevantPage: Date, additionDate: Date): boolean {
    return endDateOfPreviousPage.getTime() <= additionDate.getTime() && additionDate.getTime() < endDateOfRelevantPage.getTime();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.activePage) {
      this.deltaEViewModel.active = changes.activePage.currentValue || 0;
      this.update();
    }
    if (changes.iFdgData && changes.iFdgData.currentValue) {
      this.initBatchDockingDates();
    }
  }

  public ngOnDestroy(): void {
    this.chartInstance = null;
  }

  public update(): void {
    if (this.chartInstance) {
      this.chartInstance.config.data.datasets = this.deltaEViewModel.chartConfig.data.datasets;
      // settings for tolerance line plugin are done in the customOptions.scaleTolerance
      this.chartInstance.update();
    }
  }

  public onAreaSectionClick(event: Event, page: number): void {
    event.preventDefault();
    this.chartFilters.page = page;
    this.chartFiltersChange.emit(this.chartFilters);
    this.deltaEViewModel.active = page;
  }
}
