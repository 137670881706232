import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ExportClipboardParameter } from './export-clipboard-parameter';
import { Jwt } from 'colimo-ui-library/lib/auth/authentication-rest/jwt';
import { ClipboardLinkResponse } from './clipboard-link-response';

/**
 * Service responsible for providing access to export to clipboard functionality.
 */
@Injectable({
  providedIn: 'root',
})
export class ExportToClipboardService {
  private endpointMeanValues: string;
  private endpointMeasurements: string;
  private endpointLinkToMeasurements: string;
  private endpointLinkToMeanValues: string;

  constructor(private http: HttpClient) {
    const host = environment.apiBasePath + environment.apiUserPath;
    this.endpointMeanValues = `${host}/${environment.exportToClipboard.exportFMCCTBClipboardMeanValues}`;
    this.endpointMeasurements = `${host}/${environment.exportToClipboard.exportFMCCTBClipboardMeasurements}`;
    this.endpointLinkToMeasurements = `${host}/${environment.linkToClipboard.linkToFMCCTBClipboardMeasurements}`;
    this.endpointLinkToMeanValues = `${host}/${environment.linkToClipboard.linkToFMCCTBClipboardMeanValues}`;
  }

  public getExportFMCCTBClipboardMeanValues(exportClipboardParameter: ExportClipboardParameter): Observable<Blob> {
    const endpointWithQueryParameter = `${this.endpointMeanValues
      .replace('{batchId}', String(exportClipboardParameter.batchId))
      .replace('{line}', exportClipboardParameter.line)}`;

    return this.http.get(endpointWithQueryParameter, { responseType: 'arraybuffer' }).pipe(
      map((response: any) => this.createBlobByAny(response)),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getExportFMCCTBClipboardMeasurements(exportClipboardParameter: ExportClipboardParameter): Observable<Blob> {
    const endpointWithQueryParameter = `${this.endpointMeasurements
      .replace('{batchId}', String(exportClipboardParameter.batchId))
      .replace('{line}', exportClipboardParameter.line)
      .replace('{startDate}', exportClipboardParameter.startDate)
      .replace('{endDate}', exportClipboardParameter.endDate)}`;

    return this.http.get(endpointWithQueryParameter, { responseType: 'arraybuffer' }).pipe(
      map((response: any) => this.createBlobByAny(response)),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public linkToFMCCTBClipboardMeasurements(exportClipboardParameter: ExportClipboardParameter, jwt: Jwt): Observable<ClipboardLinkResponse> {
    const endpointWithQueryParameter = `${this.endpointLinkToMeasurements
      .replace('{batchId}', String(exportClipboardParameter.batchId))
      .replace('{line}', exportClipboardParameter.line)
      .replace('{startDate}', exportClipboardParameter.startDate)
      .replace('{endDate}', exportClipboardParameter.endDate)}`;

    return this.http.post(endpointWithQueryParameter, jwt);
  }

  public linkToFMCCTBClipboardMeanValues(exportClipboardParameter: ExportClipboardParameter, jwt: Jwt): Observable<ClipboardLinkResponse> {
    const endpointWithQueryParameter = `${this.endpointLinkToMeanValues
      .replace('{batchId}', String(exportClipboardParameter.batchId))
      .replace('{line}', exportClipboardParameter.line)}`;

    return this.http.post(endpointWithQueryParameter, jwt);
  }

  private createBlobByAny(response: any): Blob {
    return new Blob([response]);
  }
}
