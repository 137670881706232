import * as BatchHeaderInformationActions from '../actions/batch-header.actions';
import { Iproject } from '../models/project.model';

const initialState: any = {};

/**
 * Reducer for adding and deleting batch header information
 */
export function reducer(state: any = initialState, action: BatchHeaderInformationActions.Actions): Iproject {
  switch (action.type) {
    // Creates instance of the current project model
    case BatchHeaderInformationActions.ACTION_TYPES.CREATE_HEADERINFO: {
      return Object.assign({}, state, action.payload);
    }

    // Creates instance of the current project model
    case BatchHeaderInformationActions.ACTION_TYPES.REMOVE_HEADERINFO: {
      return initialState;
    }

    default:
      return state;
  }
}
