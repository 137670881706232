import { Pipe, PipeTransform } from '@angular/core';

/**
 * Determines the css class of given probability.
 */
@Pipe({
  name: 'probabilityCssClass',
})
export class ProbabilityCssClassPipe implements PipeTransform {
  public transform(probability: number): string {
    if (probability == null) {
      return '';
    }

    // Boundaries also documented here:
    // eslint-disable-next-line max-len
    // https://jira.basf.net/browse/BCH-837?focusedCommentId=1314294&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-1314294
    if (probability > 0.6) {
      return 'green';
    }

    if (probability < 0.3) {
      return 'red';
    }

    return 'orange';
  }
}
