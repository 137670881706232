/* eslint-disable max-lines */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Chart from 'chart.js';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsComponents } from 'src/app/models/models-components.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartDataService } from 'src/app/services/chart-data/chart-data.service';
import { LpgChartService } from 'src/app/services/lpg-chart/lpg-chart.service';
import { ChartFilters } from '../../models/chart-filters.model';
import { AngleDataSet, DeltaData, ILpgData, UnformattedBoxPlot } from '../../models/chart-lpg.model';
import { ChartScale } from '../../models/chart-scale.model';
import {ChartDefaults} from '../../shared/enums/chart-defaults.enum';

/**
 * Displays a list of LPG charts (Line Performance Graphic), contains chart types and default labels
 */
@Component({
  selector: 'colimo-lpg-chart-list-component',
  styleUrls: ['lpg-chart-list.component.scss'],
  templateUrl: 'lpg-chart-list.component.html',
})
export class LPGchartListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedAngles: boolean[];
  @Input() public chartFilters: ChartFilters;
  @Input() public activeComponents: ComponentGroups;
  @Input() public chartColors: string[];
  @Input() public chartBorderColors: string[];
  @Input() public chartComponentColors: string[];
  @Input() public chartComponentBorderColors: string[];
  @Input() public outlierColor: string[];
  @Input() public modelsComponents: ModelsComponents;

  @Input() public componentTranslations: string[];

  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  @Output() public chartDataChange: EventEmitter<ILpgData> = new EventEmitter();
  @Output() public componentsLegendChange: EventEmitter<string[]> = new EventEmitter();
  @Output() public tolerancesChanged: EventEmitter<boolean> = new EventEmitter();

  public isLoading = true;
  public dataSet: ILpgData;
  public deepExtendedData: DeltaData;
  public populatedDataSet: DeltaData;
  public lineChartLabels: string[] = [];
  public lineChartAngles: string[];
  public components: string[] = [];
  public scale: ChartScale = {
    max: 1.5,
    min: -1.5,
  };

  private stop$: Subject<boolean> = new Subject();
  private translationSubscription: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    public translate: TranslateService,
    private chartDataService: ChartDataService,
    private lpgChartService: LpgChartService,
  ) {
    this.translationSubscription = translate.onLangChange.subscribe(() => {
      this.updateCharts();
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    this.ngOnInit();
  }

  public ngOnInit(): void {
    Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      asSquare: false,
      xOffset: 32,
      yOffset: 20,
    });

    this.getLpgData();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartFilters && !changes.chartFilters.firstChange) {
      if (this.chartFilters) {
        this.getLpgData();
      }
    }
    if (changes.selectedAngles && !changes.selectedAngles.firstChange && this.populatedDataSet) {
      const deepExtendedData = { ...this.populatedDataSet };
      Object.keys(deepExtendedData).forEach((key) => {
        for (let i = 0; i < deepExtendedData[key].colorSet.length; i++) {
          if (this.selectedAngles[i] === false) {
            deepExtendedData[key].colorSet[i].display = false;
          } else {
            deepExtendedData[key].colorSet[i].display = true;
          }
        }
      });
      this.populatedDataSet = deepExtendedData;
      this.cd.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
    this.translationSubscription.unsubscribe();
  }

  private getLpgData(): void {
    const TIMEOUT_IN_MILLISECONDS = 30000;
    this.isLoading = true;
    this.chartDataService
      .getLpgData(this.chartFilters, this.modelsComponents)
      .pipe(takeUntil(this.stop$))
      .subscribe((iLpgData: ILpgData) => {
        this.isLoading = false;

        // Set global Chart options for toleranceLine Plugin
        Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: iLpgData.tolerances});
        // scaleTolerance can be null in some cases but calculation of max and min fails with null values. Empty array is used instead.
        Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, iLpgData.scaleTolerance ? iLpgData.scaleTolerance : []);

        if (iLpgData && iLpgData.batchNumber && iLpgData.data) {
          this.dataSet = JSON.parse(JSON.stringify(iLpgData)) as ILpgData;
          this.populateDataSet();
        }

        if (iLpgData.scale) {
          this.scale.min = iLpgData.scale[0];
          this.scale.max = iLpgData.scale[1];
        }

        if (iLpgData.angles) {
          this.lineChartLabels = iLpgData.angles;
        }

        if (iLpgData.angles) {
          this.lineChartAngles = iLpgData.angles;
        }

        if (iLpgData.components) {
          this.componentsLegendChange.emit(iLpgData.components);
        }

        if (this.chartFilters.tolerance === null || this.chartFilters.tolerance === undefined) {
          this.tolerancesChanged.emit(iLpgData.tolerances);
        }

        if (iLpgData.data && iLpgData.angles && iLpgData.components) {
          this.isLoading = false;
        }

        setTimeout(() => {
          this.isLoading = false;
        }, TIMEOUT_IN_MILLISECONDS);
        this.chartDataChange.emit(iLpgData);
        this.cd.detectChanges();
      });
  }

  private populateDataSet(): void {
    this.deepExtendedData = { ...this.dataSet.data };
    this.iterateDataSet(this.deepExtendedData);
    this.populatedDataSet = this.deepExtendedData;
  }

  private iterateDataSet(deltaData: DeltaData): void {
    const modelArray = this.dataSet.models;
    const componentArray = this.dataSet.components;

    Object.keys(deltaData).forEach((key) => {
      // Access one single light angle out of DA, DB and DL
      for (let i = 0; i < deltaData[key].colorSet.length; i++) {
        const singleAngle: AngleDataSet = deltaData[key].colorSet[i];

        // Sometimes empty dataSets {} are provided.
        // We have to add the keys manually here because the library for displaying data as box plots can not handle empty data.
        const enrichedAngleData: UnformattedBoxPlot[][] = [];
        singleAngle.dataSets.forEach((dataSet: UnformattedBoxPlot[]) => {
          const boxPlotData: UnformattedBoxPlot[] = [];
          dataSet.forEach((boxPlot: UnformattedBoxPlot) => {
            let enrichedBoxPlot = boxPlot;
            if (Object.keys(boxPlot).length === 0) {
              enrichedBoxPlot = {
                name: undefined,
                median: undefined,
                outliers: [],
                q1: undefined,
                q3: undefined,
                whiskerMax: undefined,
                whiskerMin: undefined,
                max: undefined,
                min: undefined,
              };
            }
            boxPlotData.push(enrichedBoxPlot);
          });
          enrichedAngleData.push(boxPlotData);
        });
        singleAngle.labels = modelArray;
        if (this.selectedAngles[i] === false) {
          singleAngle.display = false;
        } else {
          singleAngle.display = true;
        }

        singleAngle.datasets = [];

        // Reorganize data in a single light angle data field
        for (let j = 0; j < enrichedAngleData.length; j++) {
          singleAngle.datasets[j] = {};
          singleAngle.datasets[j].data = enrichedAngleData[j];

          // If components are unselected in the filters then the colors of the filter-components and chart-components differ.
          singleAngle.datasets[j].backgroundColor = this.chartComponentColors[componentArray[j]];
          singleAngle.datasets[j].borderColor = this.chartComponentBorderColors[componentArray[j]];
          singleAngle.datasets[j].outlierColor = this.outlierColor[componentArray[j]];

          singleAngle.datasets[j].label = this.componentTranslations[componentArray[j]] ? this.componentTranslations[componentArray[j]] : componentArray[j];

          // Set max and min value
          for (const boxplotData of singleAngle.datasets[j].data) {
            this.manipulateBoxplotObj(boxplotData);
          }
        }
      }
    });
  }

  private manipulateBoxplotObj(boxplotData: UnformattedBoxPlot): void {
    let outlierMax: number;
    let outlierMin: number;
    // Go through each single data object
    if (boxplotData && Object.keys(boxplotData).length > 0) {
      const outlierArr = boxplotData.outliers;

      const chartDefaultsScaleTolerances = Chart.defaults.get(ChartDefaults.TOLERANCE_SCALE) as number[];
      const scales = (chartDefaultsScaleTolerances && chartDefaultsScaleTolerances.length && chartDefaultsScaleTolerances.length > 0) ? chartDefaultsScaleTolerances : [-1, 1];

      // calc max for yAxis
      outlierMax = Math.max(+1, ...outlierArr, ...scales, boxplotData.whiskerMax);
      // calc min for yAxis
      outlierMin = Math.min(-1, ...outlierArr, ...scales, boxplotData.whiskerMin);

      // set max & min for yAxis, give it 0.5 more space and round it
      boxplotData.max = Math.round((outlierMax + 0.5) * 100) / 100;
      boxplotData.min = Math.round((outlierMin - 0.5) * 100) / 100;

      // Exception management for one empty/undefined data value
      if (isNaN(boxplotData.min)) {
        boxplotData.min = 0;
      }
      if (isNaN(boxplotData.max)) {
        boxplotData.max = 0;
      }
      if (boxplotData.median == null || undefined) {
        boxplotData.median = 0;
      }
      if (boxplotData.q1 == null || undefined) {
        boxplotData.q1 = 0;
      }
      if (boxplotData.q3 == null || undefined) {
        boxplotData.q3 = 0;
      }
      if (boxplotData.whiskerMax == null || undefined) {
        boxplotData.whiskerMax = 0;
      }
      if (boxplotData.whiskerMin == null || undefined) {
        boxplotData.whiskerMin = 0;
      }
    } else if (Object.keys(boxplotData).length === 0) {
      // OC 2019-04-23 TODO: nonsense
      // Exception management for empty data object
      boxplotData.median = 0;
      boxplotData.min = 0;
      boxplotData.max = 0;
      boxplotData.q1 = 0;
      boxplotData.q3 = 0;
      boxplotData.whiskerMax = 0;
      boxplotData.whiskerMin = 0;
      boxplotData.outliers = [];
    }
  }

  private updateCharts(): void {
    this.ngOnInit();
  }
}
