import { Action } from '@ngrx/store';
import { Kpis } from '../shared/enums/kpis.enum';
import { type } from '../util';

export const ACTION_TYPES = {
  SET_MODAL: type<'[Modal] Set Modal'>('[Modal] Set Modal'),
  REMOVE_MODAL: type<'[Modal] Remove Modal'>('[Modal] Remove Modal'),
};

export class SetModal implements Action {
  public readonly type = ACTION_TYPES.SET_MODAL;

  constructor(
    public readonly payload: {
      open: true;
      tab: Kpis;
    },
  ) {}
}
export class RemoveModal implements Action {
  public readonly type = ACTION_TYPES.REMOVE_MODAL;

  constructor() {}
}

export type Actions = SetModal | RemoveModal;
