import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges, ViewChild
} from '@angular/core';
import {ChartFilters} from "../../models/chart-filters.model";
import {ChartDataService} from "../../services/chart-data/chart-data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {IVidData} from "../../models/chart-vid.model";
import Chart from "chart.js/auto";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {IKpiLine} from "../../models/kpi.model";
import {FormsModule} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ColimoPlotTooltipService} from "../../shared/services/colimo-plot-tooltip/colimo-plot-tooltip.service";
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'colimo-chart-vid',
  templateUrl: './chart-vid.component.html',
  styleUrls: ['./chart-vid.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatDatepickerModule, FormsModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartVidComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public chartFilters: ChartFilters;
  @Input() public kpiLine: IKpiLine;

  public totalCars: number = 0;
  public startDate: string;
  public endDate: string;
  private startDateFormatted: string;
  private endDateFormatted: string;
  public initMinimumDate: string;
  public initMaximumDate: string;

  // Directive to get chart access
  @ViewChild('chartVID') public chartCanvas: ElementRef;
  private renderContext: CanvasRenderingContext2D;
  private chartInstance: Chart;
  private stop$: Subject<boolean> = new Subject();

  public chartOptions = {
    type: 'doughnut' as any,
    responsive: true,
    data: {
      datasets: [{
        label: '# ',
        data: [],
        backgroundColor: ["Green", "Yellow", "Red"]
      }]
    },
    plugins: [ChartDataLabels],
    options: {
      plugins: {
        'toleranceLinePlugin': false,
        datalabels: {
          color: function (context) {
            if (context && context.dataIndex === 1) {
              // display data label should be black for yellow background which is index 1
              return '#000';
            } else {
              // white otherwise
              return '#fff';
            }
          },
          font: {
            weight: 'bold',
          },
          display: function(context) {
            if (context && context.dataset && context.dataset.data && context.dataset.data.length && context.dataIndex < context.dataset.data.length) {
              return context.dataset.data[context.dataIndex] > 0 ? 'auto' : false; // display only labels greater than zero
            } else {
              return 'auto';
            }
          }
        },
        tooltip: {
          enabled: false,
          external: function(tooltipModel: any) {
            ColimoPlotTooltipService.handleVIDCustomTooltip(tooltipModel, document, this.chart);
          }
        }
      }
    }
  }

  constructor(private readonly chartDataService: ChartDataService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly datePipe: DatePipe) {
  }

  public ngAfterViewInit(): void {
    this.renderContext = this.chartCanvas.nativeElement.getContext('2d');
    this.chartInstance = new Chart(this.renderContext, this.chartOptions);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.loadVidDataIfChangesRelevant(changes);
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public dateRangeChange(): void {
    if (this.startDate && this.endDate && this.chartFilters) {
      this.startDateFormatted = this.datePipe.transform(this.startDate, 'yyyy-MM-dd')
      this.endDateFormatted = this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
      this.loadVidData();
    }
  }

  private loadVidDataIfChangesRelevant(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (changes.kpiLine && changes.kpiLine.currentValue) {
      this.startDate = changes.kpiLine.currentValue.dockingDate;
      this.endDate = changes.kpiLine.currentValue.lastMeasurementDate;
      this.startDateFormatted = changes.kpiLine.currentValue.dockingDate;
      this.endDateFormatted = changes.kpiLine.currentValue.lastMeasurementDate;
      this.initMinimumDate = changes.kpiLine.currentValue.dockingDate;
      this.initMaximumDate = changes.kpiLine.currentValue.lastMeasurementDate;
    }

    if (changes.chartFilters
      && changes.chartFilters.previousValue
      && changes.chartFilters.currentValue
      && (changes.chartFilters.previousValue.models === changes.chartFilters.currentValue.models)) {
      return;
    }

    if (changes.kpiLine
      && changes.kpiLine.previousValue
      && changes.kpiLine.currentValue
      && (changes.kpiLine.previousValue.dockingDate === changes.kpiLine.currentValue.dockingDate)
      && (changes.kpiLine.previousValue.lastMeasurementDate === changes.kpiLine.currentValue.lastMeasurementDate)) {
      return;
    }

    if (this.startDateFormatted && this.endDateFormatted && this.chartFilters) {
      this.loadVidData();
    }
  }

  private loadVidData(): void {
    this.chartDataService.getVidData(this.chartFilters, this.startDateFormatted, this.endDateFormatted).pipe(takeUntil(this.stop$)).subscribe({
      next: (response: IVidData[]) => {
        this.updateChartData(response);
      },
      error: () => {
        // handle error
        this.updateChartData([]);
      },
    })
  }

  private updateChartData(datas: IVidData[]): void {
    if (!this.chartInstance) {
      return;
    }

    const config = this.chartInstance.config;
    // init with 1st green:0 2nd yellow:0 3rd red:0
    const chartData: number[] = [0, 0, 0];
    this.totalCars = 0;
    for (let i = 0; i < datas.length; i++) {
      chartData[0] += datas[i].greenCars;
      chartData[1] += datas[i].yellowCars;
      chartData[2] += datas[i].redCars;
      this.totalCars += datas[i].totalCars;
    }

    config.data.datasets[0].data = chartData;
    // Trigger chart update via API and resolve on push changes
    this.chartInstance.update();
    this.changeDetectorRef.detectChanges();
  }
}
