<div
  class="settings-wrapper flexlayout justify-space-between"
  [ngClass]="sidenavCollapsed ? 'collapsed-box' : 'extended-box'"
  (click)="menuTrigger.openMenu()"
>
  <mat-icon>settings</mat-icon>
  <span *ngIf="!sidenavCollapsed">{{ 'sidenav.settings' | translate }}</span>
  <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="(authenticated | async)?.admin">
    <a [href]="adminToolPath" target="_blank">
      <mat-icon>admin_panel_settings</mat-icon>
      <span>{{ 'sidenav.administration' | translate }}</span>
    </a>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="languageSelector">
    <mat-icon>language</mat-icon>
    <span>{{ 'sidenav.language' | translate }}</span>
  </button>
  <button mat-menu-item (click)="goToAlertSettings()">
      <mat-icon *ngIf="alertsConfigured">notification_important</mat-icon>
      <mat-icon *ngIf="!alertsConfigured">notifications_off</mat-icon>
    <span>{{ 'sidenav.alerts' | translate }}</span>
  </button>
  <button mat-menu-item [routerLink]="['/data-protection']">
    <mat-icon class="material-icons-outlined">privacy_tip</mat-icon>
    <span>{{ 'sidenav.privacyPolicy' | translate }}</span>
  </button>
  <button mat-menu-item [routerLink]="['/contact-page']">
    <mat-icon class="material-icons-outlined">mail</mat-icon>
    <span>{{ 'sidenav.contactPage' | translate }}</span>
  </button>
  <button mat-menu-item [routerLink]="['/app-info']">
    <mat-icon>info</mat-icon>
    <span>{{ 'app.version' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #languageSelector="matMenu">
  <button mat-menu-item *ngFor="let language of languages" (click)="updateLanguage(language)">
    {{ language.name }}
  </button>
</mat-menu>
