import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ToggleSidenavCollapsedAction } from 'src/app/actions/sidenav.actions';
import { Sidenav } from 'src/app/models/sidenav.model';
import { SidenavItem } from 'src/app/shared/enums/sidenav-item.enum';
import { environment } from 'src/environments/environment';
import * as fromRoot from '../../../reducers';
import {AlertCount} from '../../../models/alert-count';

@Component({
  selector: 'colimo-extended-sidenav',
  templateUrl: './extended-sidenav.component.html',
  styleUrls: ['./extended-sidenav.component.scss'],
})
export class ExtendedSidenavComponent implements OnInit {
  @Input() public alertCount: AlertCount;
  @Input() public alertsConfigured: boolean;
  @Output() public initiateLogout = new EventEmitter<void>();
  public sidenavCollapsed: boolean;
  public adminToolPath: string;
  public sidenav$: Observable<Sidenav>;
  public sidenavItems = SidenavItem;

  constructor(private router: Router, private store: Store<fromRoot.State>) {}

  public ngOnInit(): void {
    this.sidenav$ = this.store.select(fromRoot.getSidenav);
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.sidenavCollapsed = sidenav.sidenavCollapsed;
    });
    this.adminToolPath = environment.adminPath;
  }

  public openView(route: SidenavItem) {
    this.router.navigate(['/' + route]);
  }

  public toggleSidenavCollapsed(): void {
    this.store.dispatch(new ToggleSidenavCollapsedAction(!this.sidenavCollapsed));
  }

  public logout(): void {
    this.initiateLogout.emit();
  }
}
