import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Sidenav } from 'src/app/models/sidenav.model';
import { ISearchResult } from '../../models/search.model';
import * as fromRoot from '../../reducers';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'colimo-search',
  providers: [SearchService, DashboardService],
  styleUrls: ['search.component.scss'],
  templateUrl: 'search.component.html',
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  public sidenavCollapsed: boolean;

  public results: ISearchResult[];
  public selectedResult: number;

  @ViewChild('search') public searchInputEl;

  public searchTerms = new Subject<string>();
  private results$: Observable<ISearchResult[]>;

  private resultsSubscription: Subscription;
  private sidenavCollapsedSubscription: Subscription;

  constructor(private searchService: SearchService, private store: Store<fromRoot.State>) {
    this.onInputChange = this.onInputChange.bind(this);
  }

  /**
   * Wait 200ms after each keystroke, ignore if next search term is same as previous.
   * Switch to new observable each time the term changes, return empty observable if term empty.
   * Discard previous search observables, return only the latest search service observable.
   */
  public ngOnInit(): void {
    this.results$ = this.searchTerms.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term) {
          return this.searchService.getSearchResults({ query: term });
        } else {
          return observableOf<ISearchResult[]>([]);
        }
      }),
      catchError((error) => {
        console.log(error);
        return observableOf<ISearchResult[]>([]);
      }),
    );

    this.resultsSubscription = this.results$.subscribe((results: ISearchResult[]) => {
      this.results = results;
    });
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.sidenavCollapsed = sidenav.sidenavCollapsed;
    });
    this.determineOutsideClick = this.determineOutsideClick.bind(this);
    document.addEventListener('click', this.determineOutsideClick);
  }

  public ngAfterViewInit(): void {
    this.searchInputEl.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    document.removeEventListener('click', this.determineOutsideClick);
    if (this.resultsSubscription) {
      this.resultsSubscription.unsubscribe();
    }
    if (this.sidenavCollapsedSubscription) {
      this.sidenavCollapsedSubscription.unsubscribe();
    }
  }

  public onInputKeypress(event: Event): void {
    event.preventDefault();
  }

  /**
   * Access search service and assign response to observable
   * @param value - search input value
   */
  public onInputChange(value: string): void {
    this.searchTerms.next(value);
  }

  public onProjectSelected(index: number, event: Event): void {
    event.preventDefault();
    this.selectedResult = index;
  }

  /**
   * Reset result array and reset search term, hide autocomplete list
   */
  public hideAutocomplete(): void {
    this.searchTerms.next('');
    this.selectedResult = undefined;
    this.searchInputEl.nativeElement.value = '';
  }

  private determineOutsideClick(event: Event): void {
    if (!document.getElementById('sidenav').contains(event.target as Node)) {
      this.hideAutocomplete();
    }
  }
}
