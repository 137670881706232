import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Pipe responsible for transforming a base64 encoded string to an image URL.
 */
@Pipe({
  name: 'base64ToImageUrl',
})
export class Base64ToImageUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(base64Img: string): SafeUrl {
    if (base64Img == null || base64Img.trim() === '') {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl('data:image/png' + ';base64,' + base64Img);
  }
}
