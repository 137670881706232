import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CarFilterOptions } from 'src/app/models/car-filter-options.model';
import { Car } from 'src/app/models/car.model';
import { ChartFilters } from 'src/app/models/chart-filters.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';

@Component({
  selector: 'colimo-car-report-mwg-chart',
  templateUrl: './car-report-mwg-chart.component.html',
  styleUrls: ['./car-report-mwg-chart.component.scss'],
})
export class CarReportMwgChartComponent implements OnInit, OnChanges {
  @Input() public car: Car;
  @Input() public filterOptions: CarFilterOptions;
  // We need this property because if we display the chart before the car is displayed, the chart will be pushed outside of the window
  @Input() public carBodyStyleLoaded: boolean;

  public showChartFilters: boolean;
  public showChartTable: boolean;
  public chartFilters: ChartFilters;
  public selectedAngles: boolean[];
  public activeComponents: ComponentGroups;

  constructor() {}

  public ngOnInit(): void {
    this.showChartFilters = false;
    this.showChartTable = false;
    this.selectedAngles = [];
    this.activeComponents = { bodyOn: true, bumperOn: this.filterOptions.bumperOn };
    this.buildChartFilters();
  }

  public ngOnChanges(): void {
    if (this.car) {
      this.activeComponents = { bodyOn: true, bumperOn: this.filterOptions.bumperOn };
      this.buildChartFilters();
    }
  }

  private buildChartFilters(): void {
    this.chartFilters = {
      batchId: this.car.batchId,
      lineId: this.car.line,
      labSystem: this.filterOptions.labSystem ? 'LAB' : 'LCH',
      tolerance: this.filterOptions.tolerancesOn,
    };
  }
}
