<div class="full-height side-distance flex-column gap-25">
  <div class="flex-row gap-5">
    <div class="flex-row gap-10 center-start">
      <h1 class="app-name">Color Line Monitor</h1>
      <img src="assets/icon/colimo-logo.png" class="logo" />
    </div>
    <button mat-button class="closing-button" (click)="closeSidenav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="flex-row gap-20">
    <div
      
      class="top-button flex-column start-center flex-82px"
      (click)="openView(sidenavItems.COLOR)"
    >
      <div mat-icon-button class="info-button">
        <mat-icon>other_houses</mat-icon>
      </div>
      <span>{{ 'sidenav.home' | translate }}</span>
    </div>
    <div class="top-button flex-column start-center flex-82px">
      <div mat-icon-button class="info-button">
        <mat-icon>shopping_cart</mat-icon>
      </div>
      <div>{{ 'reports.filterLabel.products' | translate }}</div>
    </div>
    <div class="top-button flex-column start-center flex-82px">
      <div mat-icon-btton class="info-button" [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.NOTIFICATION }"
           (click)="openView(sidenavItems.NOTIFICATION)">
          <mat-icon *ngIf="alertsConfigured">notification_important</mat-icon>
          <mat-icon *ngIf="!alertsConfigured">notifications_off</mat-icon>
          <span [matBadgeHidden]="alertCount?.newRed < 1" matBadge="&#8288;"
                matBadgeSize="small" class="mat-badge-RED"></span> <!-- this '&#8288;' is for not displaying a number in the batch -->
          <span [matBadgeHidden]="alertCount?.newYellow < 1" matBadge="&#8288;"
                matBadgeSize="small" class="mat-badge-YELLOW"></span>
      </div>
      <div>{{ 'sidenav.alerts' | translate }}</div>
    </div>
  </div>
  <colimo-search></colimo-search>
  <mat-divider></mat-divider>
  <div class="flex-column gap-5">
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.COLOR }"
      (click)="openView(sidenavItems.COLOR)"
    >
      <mat-icon>palette</mat-icon>
      <span>{{ 'reports.reportTable.color' | translate }}</span>
    </div>
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.PAINTLINE }"
      (click)="openView(sidenavItems.PAINTLINE)"
    >
      <mat-icon>precision_manufacturing</mat-icon>
      <span>{{ 'sidenav.paintline' | translate }}</span>
    </div>
    <div
      class="clickable flexlayout justify-space-between"
      [ngClass]="{ 'selected-item': (sidenav$ | async).selectedSidenavItem === sidenavItems.MODEL }"
      (click)="openView(sidenavItems.MODEL)"
    >
      <mat-icon>directions_car</mat-icon>
      <span>{{ 'reports.reportTable.model' | translate }}</span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <colimo-settings [alertsConfigured]="alertsConfigured"></colimo-settings>
  <div class="full-height flex-column justify-end gap-10">
    <div class="clickable flexlayout justify-space-between" (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>{{ 'sidenav.logout' | translate }}</span>
    </div>
  </div>
</div>
