import { Action } from '@ngrx/store';
import { IBatch } from '../models/batch.model';
import { Iproject } from '../models/project.model';
import { type } from '../util';

export const ACTION_TYPES = {
  CREATE_ITEM: type<'[Current Project] Create item'>('[Current Project] Create item'),
  DELETE_ITEM: type<'[Current Project] Delete item'>('[Current Project] Delete item'),
  UPDATE_ITEM: type<'[Current Project] Update item'>('[Current Project] Update item'),
  INSERT_BATCHES: type<'[Current Project] Insert batches'>('[Current Project] Insert batches'),
  UPDATE_BATCH: type<'[Current Project] Update batch'>('[Current Project] Update batch'),
  TOGGLE_VISIBILITY: type<'[Current Project] Toggle visibility'>('[Current Project] Toggle visibility')
};

export class CreateItemAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_ITEM;

  constructor(public readonly payload: Iproject) {}
}

export class DeleteItemAction implements Action {
  public readonly type = ACTION_TYPES.DELETE_ITEM;

  constructor() {}
}

export class InsertBatchesAction implements Action {
  public readonly type = ACTION_TYPES.INSERT_BATCHES;

  constructor(public readonly payload: IBatch[]) {}
}

export class UpdateBatchAction implements Action {
  public readonly type = ACTION_TYPES.UPDATE_BATCH;

  constructor(public readonly payload: IBatch) {}
}

export class UpdateItemAction implements Action {
  public readonly type = ACTION_TYPES.UPDATE_ITEM;

  constructor(public readonly payload: Iproject) {}
}

export class ToggleVisibilityAction implements Action {
  public readonly type = ACTION_TYPES.TOGGLE_VISIBILITY;

  constructor(public readonly payload: boolean) {}
}

export type Actions
  = CreateItemAction
  | DeleteItemAction
  | UpdateItemAction
  | InsertBatchesAction
  | UpdateBatchAction
  | ToggleVisibilityAction;
