import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ToggleSidenavCollapsedAction } from 'src/app/actions/sidenav.actions';
import { CarFilterOptions } from 'src/app/models/car-filter-options.model';
import { Car } from 'src/app/models/car.model';
import { ComponentStatus } from 'src/app/models/component-status.model';
import { Sidenav } from 'src/app/models/sidenav.model';
import { CarService } from 'src/app/services/car/car.service';
import { TypeOfReport } from 'src/app/shared/enums/type-of-report.enum';
import * as fromRoot from '../../reducers';
import { KeyTextService } from 'src/app/services/key-text/key-text.service';

@Component({
  selector: 'colimo-car-report',
  templateUrl: './car-report.component.html',
  styleUrls: ['./car-report.component.scss'],
})
export class CarReportComponent implements OnInit, OnDestroy {
  public car: Car;
  public isLoading: boolean;
  public heading: string;
  public filterOptions: CarFilterOptions;
  public carsForLocation: Car[];
  public selectedComponentStatus: ComponentStatus;
  public labchStatisticsHeading: string;
  public labchBumperStatisticsHeading: string;
  public labchBumperAverageHeading: string;
  public carBodyStyleLoaded: boolean;
  public dEReportSelected: boolean;
  public dLabCHReportSelected: boolean;
  public dLabCHAveragePlotReportSelected: boolean;
  public carReportExportFlexLayout: string;
  public isCarReportExportLayout: boolean;
  public sidenavCollapsed: boolean;
  public selectedTypeOfReport: string;

  private stop$: Subject<boolean> = new Subject();
  private useUntouchedFilters: boolean = true;

  public componentTranslations: string[] = [];
  public positionTranslations: string[] = [];

  constructor(private carService: CarService,
              private store: Store<fromRoot.State>,
              private keyTextService: KeyTextService) {}

  public ngOnInit(): void {
    this.heading = 'reports.carReport';
    this.filterOptions = {
      locationId: null,
      carId: '',
      tolerancesOn: true,
      bumperOn: false,
      labSystem: true,
    };
    this.carReportExportFlexLayout = 'row';
    this.isCarReportExportLayout = false;
    this.resetTypeOfReport();
    this.dEReportSelected = true;
    this.labchStatisticsHeading = 'reports.filterLabel.statistics';
    this.labchBumperStatisticsHeading = 'dashboard.bumper';
    this.labchBumperAverageHeading = 'dashboard.mwg';
    this.carBodyStyleLoaded = false;
    this.selectedTypeOfReport = TypeOfReport.DE;
    this.store.select(fromRoot.getSidenav).subscribe((sidenav: Sidenav) => {
      this.sidenavCollapsed = sidenav.sidenavCollapsed;
    });

    this.keyTextService.getComponentTranslationsObservable().pipe(takeUntil(this.stop$)).subscribe({
      next: (compTranslations: string[]) => {
        this.componentTranslations = compTranslations;
      }, error: () => {
        //nothing
      }
    });
    this.keyTextService.getPositionTranslationsObservable().pipe(takeUntil(this.stop$)).subscribe({
      next: (posTranslations: string[]) => {
        this.positionTranslations = posTranslations;
      }, error: () => {
        //nothing
      }
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public searchCar(): void {
    this.carBodyStyleLoaded = false;
    this.car = undefined;
    if (this.filterOptions.carId && this.filterOptions.carId !== '') {
      this.isLoading = true;
      this.carService.getCar(this.filterOptions).subscribe((car: Car) => {
        this.isLoading = false;
        this.car = car;
        // switch report type if showDefaultDe in car is false and the filter should be used like untouched
        if (this.useUntouchedFilters && this.car) {
          if (this.car.defaultShowDe === false) {
            this.onSelectedTypeOfReportChange(TypeOfReport.DLABCH_AVERAGE_PLOT);
          } else {
            this.onSelectedTypeOfReportChange(TypeOfReport.DE);
          }
        }
      });
    }
  }

  public hideComponentsForCarsSearch(): void {
    this.car = null;
  }

  public onSelectedComponentStatusChange(selectedComponentStatus: ComponentStatus): void {
    this.selectedComponentStatus = selectedComponentStatus;
  }

  public onUsageOfUntouchedFilters(useUntouchedFilters: boolean): void {
    this.useUntouchedFilters = useUntouchedFilters;
  }

  public onSelectedTypeOfReportChange(selectedTypeOfReport: string): void {
    this.resetTypeOfReport();
    switch (selectedTypeOfReport) {
      case TypeOfReport.DLABCH:
        this.dLabCHReportSelected = true;
        this.selectedTypeOfReport = TypeOfReport.DLABCH;
        break;
      case TypeOfReport.DLABCH_AVERAGE_PLOT:
        this.dLabCHAveragePlotReportSelected = true;
        this.selectedTypeOfReport = TypeOfReport.DLABCH_AVERAGE_PLOT;
        break;
      default:
        this.dEReportSelected = true;
        this.selectedTypeOfReport = TypeOfReport.DE;
        break;
    }
  }

  public onCarBodyStyleLoaded(isLoaded: boolean) {
    this.carBodyStyleLoaded = isLoaded;
  }

  public exportCarReport(): void {
    this.carReportExportFlexLayout = 'column';
    this.isCarReportExportLayout = true;

    const sidenavCollapsedBeforeExport = this.sidenavCollapsed;
    if (!sidenavCollapsedBeforeExport) {
      this.store.dispatch(new ToggleSidenavCollapsedAction(true));
    }

    // The timeout is necessary because without an external library it's not possible for us to detect when the rearrangement of the layout is done.
    setTimeout(() => {
      window.print();
      this.isCarReportExportLayout = false;
      this.carReportExportFlexLayout = 'row';
      if (!sidenavCollapsedBeforeExport) {
        this.store.dispatch(new ToggleSidenavCollapsedAction(false));
      }
    }, 250);
  }

  private resetTypeOfReport(): void {
    this.dEReportSelected = false;
    this.dLabCHReportSelected = false;
    this.dLabCHAveragePlotReportSelected = false;
  }
}
