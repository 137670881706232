import {ChartDefaults} from '../../shared/enums/chart-defaults.enum';

export interface ChartStyle {
  label: string;
  fill?: boolean;
  backgroundColor: string;
  lineTension?: number;
  spanGaps?: boolean;
  radius?: number;
  borderWidth?: number;
  borderColor: string;
  data: number[];
}

export class DeltaEChartViewModel {
  public static readonly chartOptions: any = {
    plugins: {
      legend: {
        display:false
      }
    },
    layout: {
      padding: {
        bottom: 2,
        top: 5
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    showToleranceLines: true,
    scaleTolerance: [],
    toleranceOptions: {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      debug: true
    },
    legend: {
      display: false,
      hidden: true
    },
    animation: {
      duration: 500
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false
        },
        ticks: {
          display: false
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false
        },
        min: 0,
        ticks: {
          display: false,
          beginAtZero: true,
        }
      }
    },

  };

  private static readonly bodyGraphColor = 'rgba(0, 48, 103, 0.3)';
  private static readonly bumperGraphColor = 'rgba(0, 154, 255, 0.3)';

  public static readonly bodyData: ChartStyle = {
    label: 'Body',
    fill: true,
    backgroundColor: DeltaEChartViewModel.bodyGraphColor,
    lineTension: 0.4,
    spanGaps: false,
    radius: 0,
    borderWidth: 0.1,
    borderColor: DeltaEChartViewModel.bodyGraphColor,
    data: []
  };

  public static readonly bumperData: ChartStyle = {
    label: 'Bumper',
    fill: true,
    backgroundColor: DeltaEChartViewModel.bumperGraphColor,
    lineTension: 0.4,
    spanGaps: false,
    radius: 0,
    borderWidth: 0.1,
    borderColor: DeltaEChartViewModel.bumperGraphColor,
    data: []
  };
}
