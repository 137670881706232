import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ColorProduct } from '../../models/color-product.model';
import { ColorWithProducts, Product } from '../../models/viewmodels/color-with-products.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class ColorProductsService {
  private baseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath;
  }

  /**
   * Gets color products for given customer that are needed for reports.
   */
  public getColorProductsByCustomer(customerId: number): Observable<ColorWithProducts[]> {
    // Returns empty observable if mandatory parameter missing
    if (customerId == null) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.reports.colorProducts.replace('{customerId}', customerId.toString())}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => {
        const colorProducts = response as ColorProduct[];
        return this.convertToColorWithProducts(colorProducts);
      }),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  private convertToColorWithProducts(colorProducts: ColorProduct[]): ColorWithProducts[] {
    const colorWithProducts: ColorWithProducts[] = [];
    let colorIndex: number;

    colorProducts.forEach((colorProduct: ColorProduct) => {
      // check if color is already present
      const mayFoundColor = colorWithProducts.find((c, index) => {
        colorIndex = index;
        return c.colorId === colorProduct.colorId;
      });

      // if not, push the color
      if (mayFoundColor == null) {
        colorIndex = this.addColor(colorProduct, colorWithProducts);
      }

      // add the current product to corresponding color
      this.addProduct(colorProduct, colorWithProducts, colorIndex);
    });

    return colorWithProducts;
  }

  /**
   * Adds given ColorProduct to given list of ColorWithProducts.
   * Returns the index where the color has been added.
   * The index is needed for adding products to the corresponding color.
   */
  private addColor(colorProduct: ColorProduct, colorWithProducts: ColorWithProducts[]): number {
    const colorWithProduct: ColorWithProducts = {
      colorId: colorProduct.colorId,
      colorName: colorProduct.colorName,
      selected: true,
      products: [],
    };
    return colorWithProducts.push(colorWithProduct) - 1;
  }

  private addProduct(colorProduct: ColorProduct, colorWithProducts: ColorWithProducts[], colorIndex: number): void {
    const product: Product = {
      productId: colorProduct.productId,
      productName: colorProduct.productNr,
      selected: true,
    };
    colorWithProducts[colorIndex].products.push(product);
  }
}
