import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationResult, AuthenticationService, LoginCredentials } from 'colimo-ui-library';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This service provides functionality for a gui component that represents a
 * login form.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  public authenticate(loginCredentials: LoginCredentials): Observable<AuthenticationResult> {
    return this.authenticationService.authenticateViaLoginCredentials(loginCredentials).pipe(
      map((authenticationResult: AuthenticationResult) => {
        if (authenticationResult.successful === true) {
          this.router.navigate(['color']);
        }
        return authenticationResult;
      }),
    );
  }
}
