import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CarBodyStyle } from 'src/app/models/car-body-style';
import { CarFilterOptions } from 'src/app/models/car-filter-options.model';
import { Car } from 'src/app/models/car.model';
import { ComponentStatus } from 'src/app/models/component-status.model';
import { CarService } from 'src/app/services/car/car.service';

@Component({
  selector: 'colimo-car-report-overview',
  templateUrl: './car-report-overview.component.html',
  styleUrls: ['./car-report-overview.component.scss'],
})
export class CarReportOverviewComponent implements OnInit {
  @Input() public car: Car;
  @Input() public filterOptions: CarFilterOptions;

  @Output() public updateSelectedComponentStatus = new EventEmitter<ComponentStatus>();
  @Output() public carBodyStyleLoaded = new EventEmitter<boolean>();

  public componentStatusToDisplay: ComponentStatus[];
  public pathToImage: string;
  public carBodyStyle: CarBodyStyle;
  public image: SafeUrl;
  public imageHeight: number;
  public imageWidth: number;

  constructor(private carService: CarService, private sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    this.loadCarBodyStyle(this.car.carBodyStyleId);
    this.componentStatusToDisplay = this.car.componentsStatus;
    if (this.car.bumperStatus && this.car.bumperStatus.length !== 0) {
      this.componentStatusToDisplay = this.componentStatusToDisplay.concat(this.car.bumperStatus);
    }
  }

  private loadCarBodyStyle(carBodyStyleId: number): void {
    this.carBodyStyleLoaded.emit(false);
    this.carService.getCarBodyStyle(carBodyStyleId).subscribe((carBodyStyle: CarBodyStyle) => {
      this.carBodyStyle = carBodyStyle;
      this.carBodyStyleLoaded.emit(true);
      this.imageHeight = carBodyStyle.bodyStyle.imgHeight;
      this.imageWidth = carBodyStyle.bodyStyle.imgWidth;
      const imageType = this.carBodyStyle.bodyStyle.imgType;
      this.image = this.sanitizer.bypassSecurityTrustUrl(
        'data:image/' + imageType + ';base64,' + carBodyStyle.bodyStyle.imgBytes,
      );
    });
  }

  public selectComponentStatus(componentStatus: ComponentStatus) {
    this.updateSelectedComponentStatus.emit(componentStatus);
  }

  public deselectComponentStatus() {
    this.updateSelectedComponentStatus.emit(null);
  }
}
