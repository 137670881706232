import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ClarityModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { KpiHelpComponent } from '../components/kpi/kpi-help.component';
import { AccordionWrapperComponent } from './components/accordion-wrapper/accordion-wrapper.component';
import { BaseComponent } from './components/base/base.component';
import { CanvasWrapperComponent } from './components/canvas-wrapper/canvas-wrapper.component';
import { ChartFiltersComponent } from './components/chart-filters/chart-filters.component';
import { ColorComponent } from './components/color/color.component';
import { CustomerLogoComponent } from './components/customer-logo/customer-logo.component';
import { ErrorComponent } from './components/error/error.component';
import { IconsComponent } from './components/icons/icons.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { AppConfig } from './core/app.config';
import { OutsideClickDirective } from './directives/outside-click/outside-click.directive';
import { Base64ToImageUrlPipe } from './pipes/base64-to-image-url/base64-to-image-url.pipe';
import { IterablePipe } from './pipes/iterable.pipe';
import { KpiLabelPipe } from './pipes/kpi-label.pipe';
import { LabelChartType } from './pipes/label-chart-type.pipe';
import { StatePipe } from './pipes/state.pipe';
import { GlobalErrorHandlerService } from './services/global-error-handler/global-error-handler.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive/mat-table-responsive.directive';
import {ChartVidComponent} from "../components/chart-vid/chart-vid.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    ClarityModule,
    TranslateModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    ChartVidComponent
  ],
  declarations: [
    AccordionWrapperComponent,
    CanvasWrapperComponent,
    ChartFiltersComponent,
    ColorComponent,
    IconsComponent,
    LoaderComponent,
    BaseComponent,
    IterablePipe,
    LabelChartType,
    StatePipe,
    KpiLabelPipe,
    OutsideClickDirective,
    ErrorComponent,
    ModalComponent,
    KpiHelpComponent,
    CustomerLogoComponent,
    Base64ToImageUrlPipe,
    MatTableResponsiveDirective,
  ],
  exports: [
    AccordionWrapperComponent,
    CanvasWrapperComponent,
    ChartFiltersComponent,
    ColorComponent,
    IconsComponent,
    LoaderComponent,
    IterablePipe,
    LabelChartType,
    StatePipe,
    KpiLabelPipe,
    OutsideClickDirective,
    ErrorComponent,
    ModalComponent,
    KpiHelpComponent,
    CustomerLogoComponent,
    MatTableResponsiveDirective,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AppConfig, { provide: ErrorHandler, useClass: GlobalErrorHandlerService }],
    };
  }

  public static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandlerService }],
    };
  }
}
