<!-- The chart should only be displayed if data is not empty. -->
<div [ngClass]="dataAvailable ? '' : 'missingData'">
  <colimo-chart-deg
    [activePage]="chartFilters.page"
    [chartFilters]="chartFilters"
    [activeComponents]="activeComponents"
    [chartDataFDG$]="chartDataFDG$"
    [tinterAdditions$]="tinterAdditions$"
    [modelsComponents]="modelsComponents"
    [chartMultiBatchColors]="chartMultiBatchColors"
    [projects]="projects"
    (chartFiltersChange)="updateChartFilters($event)"
  >
  </colimo-chart-deg>

  <div class="dates-row flex-row">
    <div
      *ngFor="let date of fdgData?.dates; let i = index"
      class="date flex-10 justify-center"
      [ngClass]="isSmallTablet === true ? 'small-date' : ''"
    >
      {{ date | date: 'dd.MM.yyyy' }}
      <mat-icon
        *ngIf="tinterAdditions && tinterAdditions[i]?.length"
        class="tinter-addition-circle"
        [matTooltip]="tinterAdditions[i] | tinterAdditionTooltip"
        matTooltipPosition="above"
        matTooltipClass="tinter-addition-tooltip"
        >circle</mat-icon
      >
    </div>
  </div>

  <!-- Iterate chart types: DL, DA, DB etc. -->
  <ng-container *ngFor="let chartSlot of chartSlots; let i = index">
    <colimo-canvas-wrapper [chartType]="chartSlot.label" [quadrant]="chartSlot.quadrant" [isFixedHeight]="true">
      <div class="controls-wrapper flex-row gap-10">
        <button
          mat-mini-fab
          color="primary"
          [disabled]="chartSlot.zoomInfo.zoomInEnd"
          (click)="scaleRow(ZoomDir.In, chartSlot.label)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="primary"
          [disabled]="chartSlot.zoomInfo.zoomOutEnd"
          (click)="scaleRow(ZoomDir.Out, chartSlot.label)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>

      <div class="chart-row flex-row">
        <!-- Iterate chart row items -->
        <ng-container *ngFor="let chart of chartSlot.row; let j = index">
          <div class="type-label" *ngIf="j === 0">{{ chartSlot.label | labelByChartType: fdgData?.tolerances }}</div>

          <div class="chart-wrapper">
            <colimo-fdg-chart-component
              [dataSet]="chartData[i][j]"
              [dataAngles]="lineChartAngles"
              [dataScale]="scale"
              [slotIndex]="i"
              [rowIndex]="j"
              [rowLength]="chartSlot.row.length"
              [scaleDirection]="scaleDirections$[i]"
              [selectedAngles]="selectedAngles"
              [activeComponents]="activeComponents"
              [chartComponentColors]="chartComponentColors"
              [chartComponentBorderColors]="chartComponentBorderColors"
              [chartMultiBatchColors]="chartMultiBatchColors"
              [modelsComponents]="modelsComponents"
              (chartZoomed)="onChartsUpdated($event)"
              (hasRendered)="onLastChartRendered($event)"
            ></colimo-fdg-chart-component>
          </div>
        </ng-container>
      </div>
    </colimo-canvas-wrapper>
  </ng-container>

  <div class="flex-row justify-space-between">
    <div class="flex-170px">
      <button
        mat-button
        *ngIf="fdgData?.previousPage || fdgData?.previousPage === 0"
        
        class="flex-row align-start min-width"
        color="primary"
        [disabled]="isLoading"
        (click)="onPageChange(fdgData?.previousPage)"
      >
        <mat-icon class="navigation-arrow-left">arrow_left</mat-icon>{{ 'pagination.displayPrevious' | translate }}
      </button>
    </div>

    <div class="page-indicator">
      <div *ngIf="fdgData?.totalPages > 1">
        {{ 'pagination.page' | translate }} {{ fdgData?.page + 1 }} {{ 'pagination.of' | translate }}
        {{ fdgData?.totalPages }}
      </div>
    </div>

    <div class="justify-end">
      <button
        mat-button
        *ngIf="fdgData?.nextPage"
        class="subsequent-button flex-row align-start"
        color="primary"
        [disabled]="isLoading"
        (click)="onPageChange(fdgData?.nextPage)"
      >
        {{ 'pagination.displaySubsequent' | translate }}<mat-icon class="navigation-arrow-right">arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>


<colimo-loader [background]="'transparent'" [top]="'40%'" *ngIf="isLoading || isRenderingCharts"></colimo-loader>
