import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IBatch } from '../../models/batch.model';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'colimo-batch-list-with-tooltip',
  templateUrl: './batch-list-with-tooltip.component.html',
  styleUrls: ['./batch-list-with-tooltip.component.scss'],
})
export class BatchListWithTooltipComponent implements OnInit, OnDestroy  {
  @Input() public batches: IBatch[];
  public isMobile: boolean;
  private stop$: Subject<boolean> = new Subject();

  constructor(private mobileCheck: MobileCheck) {}

  ngOnInit(): void {
    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }
}
