import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayBlobService {

  constructor() { }

  /**
   * Displays given blob. In some browsers it will be downloaded.
   * In some browsers the user can choose between displaying or downloading the file.
   *
   * @param blob a generated report.
   * @param fileName the file name decides how the excel file is named that the user can view or download.
   */
  public displayBlob(blob: Blob, fileName: string): void {
    const fileURL = URL.createObjectURL(blob);
    // Some browser (e.g. Firefox) give users the choice wether to download or to open files directly.
    // Creating and clicking on the following temporary link is needed to trigger this selection of the browser.
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
