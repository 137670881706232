import { Component, Input } from '@angular/core';
import { Kpis } from '../../shared/enums/kpis.enum';

/**
 * A tab component which shows additional Information about KPIs.
 * It can be fed with a selectedTab value which opens the according tab.
 */
@Component({
  selector: 'colimo-kpi-help',
  styleUrls: ['kpi-help.component.scss'],
  templateUrl: 'kpi-help.component.html',
})
export class KpiHelpComponent {
  @Input() public selectedTab: Kpis;

  public kpis = Kpis;
}
