import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DashboardSelection } from 'src/app/models/dashboard-selection.model';
import { ModelDashboard } from 'src/app/models/model-dashboard.model';
import { ScreenSize } from 'src/app/models/screen-size.model';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'colimo-model-dashboard',
  providers: [DashboardService],
  templateUrl: './model-dashboard.component.html',
  styleUrls: ['./model-dashboard.component.scss'],
})
export class ModelDashboardComponent implements OnInit {
  public heading: string;
  public isLoading = true;
  public dashboard$: Observable<ModelDashboard[]>;
  public dashboardSelection$: Observable<DashboardSelection>;
  public isMobile$: Observable<ScreenSize>;

  constructor(
    private dashboardService: DashboardService,
    private store: Store<fromRoot.State>,
    private mobileCheck: MobileCheck,
  ) {}

  ngOnInit(): void {
    this.heading = 'reports.reportTable.model';
    this.dashboardService.getModelDashboard().subscribe(() => {
      this.isLoading = false;
    });
    this.dashboard$ = this.store.select(fromRoot.getModelDashboard);
    this.dashboardSelection$ = this.store.select(fromRoot.getDashboardSelection);
    this.isMobile$ = this.mobileCheck.getScreenSize();
  }

  public customerSelected(newCustomer: string, storedCustomer: string): void {
    if (newCustomer === storedCustomer) {
      this.dashboardService.setCurrentDashboardSelection({ customer: null, line: null, model: null });
    } else {
      this.dashboardService.setCurrentDashboardSelection({
        customer: newCustomer,
        line: null,
        model: null,
      });
    }
  }

  public modelSelected(customer: string, newModel: string, storedModel: string): void {
    if (newModel === storedModel) {
      this.dashboardService.setCurrentDashboardSelection({
        customer: customer,
        line: null,
        model: null,
      });
    } else {
      this.dashboardService.setCurrentDashboardSelection({
        customer: customer,
        line: null,
        model: newModel,
      });
    }
  }
}
