<ng-container *ngIf="batches; else noBatch">
  <ng-container *ngFor="let batch of batches; let batchIndex = index">
    <span
      class="batch-number"
      [ngClass]="isMobile === true ? 'mobile-batch-number' : ''"
      [matTooltip]="batch.productName"
      matTooltipPosition="above"
      matTooltipClass="batch-tooltip"
    >
      {{ batch.batchNumber }} <br />
    </span>
  </ng-container>
</ng-container>
<ng-template #noBatch>
  <span class="batch-number">--</span>
</ng-template>
