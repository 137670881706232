import { Injectable } from '@angular/core';
import { TinterAddition } from '../../models/tinter-addition.model';

/**
 * Service responsible for mapping tinter additions to their corresponding measurement dates.
 */
@Injectable({
  providedIn: 'root',
})
export class CorrespondingTinterAdditionsService {
  constructor() {}

  /**
   * Returns structured array of tinter additions grouped by corresponding dates.
   * Index of returned array has same value as index of given dates array.
   */
  public getTinterAdditionsCorrespondingToDates(
    startDateString: string,
    dateRange: string[],
    tinterAdditions: TinterAddition[],
  ): TinterAddition[][] {
    if (dateRange == null || dateRange.length === 0 || tinterAdditions == null || tinterAdditions.length === 0) {
      return [];
    }

    const relevantTinterAdditions: TinterAddition[] = tinterAdditions.filter(
      ta => {
        if (ta != null && ta.additionDate != null) {
          // BCH-608: Tinting is only relevant if it has been added after the start date
          const additionDate = new Date(ta.additionDate);
          const startDate = new Date(startDateString);
          if (additionDate.getTime() >= startDate.getTime()) {
            return ta;
          }
        }
      }
    );

    if (relevantTinterAdditions.length === 0) {
      return [];
    }
    return this._getTinterAdditionsCorrespondingToDates(dateRange, relevantTinterAdditions);
  }

  private _getTinterAdditionsCorrespondingToDates(
    dateRange: string[],
    relevantTinterAdditions: TinterAddition[],
  ): TinterAddition[][] {
    const correspondingTinterAdditions: TinterAddition[][] = [];

    relevantTinterAdditions.forEach(ta => {
      const closestIndex = this.findClosestIndex(ta.additionDate, dateRange);
      this.putInArrayAt(correspondingTinterAdditions, ta, closestIndex);
    });
    return correspondingTinterAdditions;
  }

  private findClosestIndex(searchedDateString: string, dateRange: string[]): number {
    const searchedDate = new Date(searchedDateString);

    for (let i = 0; i < dateRange.length; i++) {
      const dateFromRange = new Date(dateRange[i]);
      if (searchedDate.getTime() <= dateFromRange.getTime()) {
        return i;
      }
    }

    return -1;
  }

  private putInArrayAt(array: TinterAddition[][], tinterAddition2Add: TinterAddition, index: number): void {
    if (index < 0) {
      return;
    }
    if (array[index] == null) {
      array[index] = [];
    }
    array[index].push(tinterAddition2Add);
  }
}
