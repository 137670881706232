import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFdgData } from 'src/app/models/chart-fdg.model';
import { ChartFilters } from 'src/app/models/chart-filters.model';
import { ModelsComponents } from 'src/app/models/models-components.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartDataService } from 'src/app/services/chart-data/chart-data.service';
import { IDegData } from '../../models/chart-deg.model';
import { TinterAddition } from '../../models/tinter-addition.model';
import { DeltaEViewModel } from '../../models/viewmodels/delta-e.viewmodel';
import { BaseComponent } from '../../shared/components/base/base.component';
import { DifferenceType } from '../../shared/enums/difference-type.enum';
import { ChartDegGraphicComponent } from './chart-deg-graphic.component';
import {Iproject} from '../../models/project.model';

@Component({
  selector: 'colimo-chart-deg',
  templateUrl: './chart-deg.component.html',
  styleUrls: ['./chart-deg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDegComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild(ChartDegGraphicComponent) public chartDegGraphicComponent: ChartDegGraphicComponent;

  public deltaEViewModel: DeltaEViewModel = new DeltaEViewModel();
  public differenceType = DifferenceType.D_DE;

  @Input() public chartFilters: ChartFilters;
  @Input() public activePage: number;
  @Input() public activeComponents: ComponentGroups;
  @Input() public tinterAdditions$: Observable<TinterAddition[]>;
  @Input() public chartDataFDG$: Observable<IFdgData>;
  @Input() public modelsComponents: ModelsComponents;
  @Input() public chartMultiBatchColors: string[];
  @Input() public projects: Iproject[];

  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  private lastResponse: IDegData;

  constructor(private changeDetectorRef: ChangeDetectorRef, private chartDataService: ChartDataService) {
    super();
  }

  public ngOnInit(): void {
    this.getDegData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Only request chart data if it is not the first change. Otherwise two requests are executed when opening this chart tab.
    if (
      !(
        (changes.chartFilters && changes.chartFilters.firstChange) ||
        (changes.activeComponents && changes.activeComponents.firstChange) ||
        (changes.activeComponents && changes.activeComponents.firstChange)
      )
    ) {
      this.getDegData();
    }
    if (changes.activeComponents && this.lastResponse) {
      this.deltaEViewModel.componentGroups = changes.activeComponents.currentValue;
      this.deltaEViewModel.setValues(this.lastResponse);

      if (this.chartDegGraphicComponent) {
        this.chartDegGraphicComponent.update();
      }
    }
  }

  public updateChartFilters(chartFilters: ChartFilters): void {
    this.chartFilters = chartFilters;
    this.chartFiltersChange.emit(this.chartFilters);
  }

  private getDegData(): void {
    if (this.chartFilters.batchIds && this.chartFilters.lineIds) {
      this.chartDataService
        .getDegDataMultiBatch(this.chartFilters, this.modelsComponents)
        .pipe(takeUntil(this.stop$))
        .subscribe((response: IDegData) => {
          if (response.data) {
            this.lastResponse = response;
            this.deltaEViewModel.setValues(response);
            this.changeDetectorRef.detectChanges();
            if (this.chartDegGraphicComponent) {
              this.chartDegGraphicComponent.update();
            }
          }
        });
    } else {
      this.chartDataService
        .getDegData(this.chartFilters, this.modelsComponents)
        .pipe(takeUntil(this.stop$))
        .subscribe((response: IDegData) => {
          if (response.data) {
            this.lastResponse = response;
            this.deltaEViewModel.setValues(response);
            this.changeDetectorRef.detectChanges();
            if (this.chartDegGraphicComponent) {
              this.chartDegGraphicComponent.update();
            }
          }
        });
    }
  }
}
