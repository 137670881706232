<div class="policy-wrapper" [ngClass]="isMobile === true ? 'policy-wrapper-mobile' : ''">
  <colimo-page-header [heading]="'privacyPolicy.heading'"> </colimo-page-header>
  <h2 [ngClass]="isMobile === true ? 'heading-mobile' : ''">
    BASF freut sich über Ihren Besuch auf unserer Website und Ihr Interesse an unserem Unternehmen.
  </h2>
  <p>
    Das Thema Datenschutz hat bei BASF höchste Priorität. Daher möchten wir Sie im Folgenden ausführlich darüber
    informieren:
  </p>
  <div class="agenda">
    <div>A. Wer ist verantwortlich für die Datenverarbeitung und wer ist unser Datenschutzbeauftragter?</div>
    <div>B. Welche Daten verarbeiten wir, zu welchen Zwecken, für wie lange und auf welcher Rechtsgrundlage?</div>
    <div>C. Sind Sie verpflichtet, uns diese Daten zur Verfügung zu stellen?</div>
    <div>D. An wen geben wir Ihre personenbezogenen Daten weiter?</div>
    <div>E. Wie schützen wir Ihre personenbezogenen Daten?</div>
    <div>F. Welche Rechte haben Sie?</div>
    <div>G. Wo kann man sich beschweren?</div>
    <div>H. Datenschutz für Minderjährige</div>
  </div>
  <h3 class="letter-heading">
    A. Wer ist verantwortlich für die Datenverarbeitung und wer ist unser Datenschutzbeauftragter?
  </h3>
  <p>
    Den Verantwortlichen für die Datenverarbeitung finden Sie
    <a class="link" href="https://www.basf.com/global/de/legal/credits.html">hier</a>.
  </p>
  <p>Unseren Datenschutzbeauftragten erreichen Sie unter data-protection.eu@BASF.com</p>
  <h3 class="letter-heading">
    B. Welche Daten verarbeiten wir, zu welchen Zwecken, für wie lange und auf welcher Rechtsgrundlage?
  </h3>
  <p>
    Im Folgenden informieren wir Sie über die Datenverarbeitung auf unserer Seite (a), deren Zwecke und Rechtsgrundlage
    (b) sowie die jeweilige Speicherdauer und ggf. konkrete Widerspruchs- und Beseitigungsmöglichkeiten (c).
  </p>
  <h3 class="number-heading">1. Log Dateien</h3>
  <div class="bold-heading">a. Datenverarbeitung</div>
  <p>
    Wenn Sie unsere Website nur zu Informationszwecken besuchen, d.h. wenn Sie sich nicht registrieren oder auf andere
    Weise Daten übermitteln (z.B. über ein Kontaktformular), werden automatisch folgende Informationen von Ihrem Browser
    an unseren Server übermittelt:
  </p>
  <ul>
    <li>IP-Adresse Ihres Gerätes</li>
    <li>Informationen über Ihren Browser</li>
    <li>Name der Website, von der aus Sie uns besuchen</li>
    <li>Name der besuchten Seite (URL) bzw. der geöffneten Datei</li>
    <li>Datum und Uhrzeit Ihres Besuchs</li>
    <li>Statusinformationen wie Fehlermeldungen</li>
    <li>Übertragene Datenmenge und zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)</li>
    <li>Betriebssystem und Version des Betriebssystems Ihres Computers und name Ihres Access-Providers</li>
  </ul>
  <div class="bold-heading">b. Zwecke und Rechtsgrundlage</div>
  <p>
    Wenn Sie die BASF-Website besuchen, verwenden wir die IP-Adresse und weitere Informationen, die Ihr Browser unter B.
    1 automatisch an unseren Server übermittelt. a. um
  </p>
  <div>
    <div>(i) Ihnen die angeforderten Inhalte zur Verfügung zu stellen</div>
    <div>
      (ii) die Sicherheit und Stabilität unserer Website zu gewährleisten und unbefugte Nutzungen nachzuvollziehen
    </div>
    <div>(iii) eine komfortable Nutzung unserer Website zu ermöglichen.</div>
  </div>
  <p>
    Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse ergibt
    sich aus den oben aufgeführten Zwecken der Datenverarbeitung. In keinem Fall verwenden wir die erhobenen Daten zu
    dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
  </p>
  <div class="bold-heading">c. Speicherdauer</div>
  <p>
    Die Daten werden gelöscht, sobald sie für den Zweck, für den sie erhoben wurden, nicht mehr erforderlich sind. Im
    Falle der Erhebung von Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet
    ist. Im Falle der Speicherung der Daten in Logfiles ist dies spätestens nach sieben Tagen der Fall. Eine Speicherung
    über diesen Zeitraum hinaus ist möglich. In diesem Fall werden die IP-Adressen durch Löschen der letzten acht Bit
    anonymisiert, so dass eine Identifizierung von Ihnen nicht mehr möglich ist.
  </p>
  <h3 class="number-heading">2. Cookies</h3>
  <div class="bold-heading">a. Datenverarbeitung und jeweilige Rechtsgrundlage</div>
  <p>
    Wir verwenden Cookies auf unserer Website. Hierbei handelt es sich um Textinformationen, die im Browser auf dem
    Gerät des Betrachters einer besuchten Webseite (Webserver, Server) gespeichert werden können. Das Cookie wird
    entweder vom Webserver an den Browser gesendet oder im Browser durch ein Skript (JavaScript) generiert. Der
    Webserver kann diese Cookie-Informationen direkt vom Server auslesen, wenn der Nutzer diese Seite erneut besucht
    oder die Cookie-Informationen über ein Skript der Website an den Server übertragen. Cookies richten auf Ihrem
    Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
  </p>
  <p>
    In dem Cookie werden Informationen gespeichert, die sich auf das konkret verwendete Gerät beziehen. Dies bedeutet
    jedoch nicht, dass wir direkte Kenntnis von Ihrer Identität erhalten.
  </p>
  <p>Wir verwenden in der Regel folgende Cookies:</p>
  <ul>
    <li>Unbedingt erforderliche Cookies</li>
  </ul>
  <p>
    Diese Cookies sind für das Funktionieren der Website erforderlich. Im Allgemeinen werden diese Cookies nur als
    Reaktion auf Aktionen gesetzt, die Sie als Reaktion auf eine Serviceanfrage ergreifen, z. B. das Festlegen Ihrer
    Datenschutzeinstellungen, das Anmelden oder das Ausfüllen von Formularen. Sie können Ihren Browser so einstellen,
    dass er diese Cookies blockiert oder Sie über diese Cookies informiert. Einige Bereiche der Website funktionieren
    jedoch möglicherweise nicht ordnungsgemäß.
  </p>
  <p>
    Rechtsgrundlage für die Verarbeitung dieser Cookies ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes
    Interesse ergibt sich aus den unter B.1.b aufgeführten Zwecken der Datenverarbeitung.
  </p>
  <ul>
    <li>Performance Cookies</li>
  </ul>
  <p>
    Diese Cookies ermöglichen es uns, Besuche und Traffic-Quellen zu zählen, damit wir die Leistung unserer Website
    messen und verbessern können. Sie helfen uns bei der Beantwortung von Fragen darüber, welche Seiten am beliebtesten
    sind, welche am wenigsten genutzt werden und wie sich die Besucher auf der Website bewegen. Alle von diesen Cookies
    gesammelten Informationen sind aggregiert und daher anonym. Wenn Sie diese Cookies nicht zulassen, können wir nicht
    wissen, wann Sie unsere Website besucht haben.
  </p>
  <p>
    Rechtsgrundlage für die Verarbeitung dieser Cookies ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO, die
    Sie uns durch Ihre Auswahl im Cookie-Banner oder in unserem Privacy Preference Center erteilt haben.
  </p>
  <p>
    Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
    Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ändern Sie dazu bitte die Einstellungen im
    Privacy Preference Center.
  </p>
  <ul>
    <li>Funktionale Cookies</li>
  </ul>
  <p>
    Diese Cookies ermöglichen es der Website, erweiterte Funktionalität und Personalisierung bereitzustellen. Sie können
    von uns oder von Dritten, deren Dienste wir auf unseren Seiten nutzen, eingestellt werden. Wenn Sie diese Cookies
    nicht zulassen, funktionieren einige oder alle dieser Dienste möglicherweise nicht ordnungsgemäß.
  </p>
  <p>
    Rechtsgrundlage für die Verarbeitung dieser Cookies ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO, die
    Sie uns durch Ihre Auswahl im Cookie-Banner oder in unserem Privacy Preference Center erteilt haben.
  </p>
  <p>
    Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
    Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ändern Sie dazu bitte die Einstellungen im
    Privacy Preference Center.
  </p>
  <ul>
    <li>Cookies für Marketingzwecke</li>
  </ul>
  <p>
    Diese Cookies können über unsere Website gesetzt werden. Sie können von unseren Marketingpartnern verwendet werden,
    um Ihre Interessen zu profilieren und Ihnen relevante Anzeigen auf anderen Websites zu zeigen. Wenn Sie diese
    Cookies nicht zulassen, erleben Sie weniger gezielte Werbung.
  </p>
  <p>
    Rechtsgrundlage für die Verarbeitung dieser Cookies ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO, die
    Sie uns durch Ihre Auswahl im Cookie-Banner oder in unserem Privacy Preference Center erteilt haben.
  </p>
  <p>
    Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
    Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ändern Sie dazu bitte die Einstellungen im
    Privacy Preference Center.
  </p>
  <div class="bold-heading">b. Spezifische Verwendung von Cookies, Zwecke und Speicherdauer</div>
  <p>
    Insbesondere verwenden wir die folgenden Cookies, abhängig von den Cookie-Einstellungen, die Sie im Privacy
    Preference Center festlegen. Standardmäßig sind nur die unbedingt erforderlichen Cookies aktiviert. Wenn Sie dies
    auch nicht wünschen, haben Sie die Möglichkeit, Cookies in Ihrem Browser generell abzulehnen. In diesem Fall kann
    die Funktionalität der besuchten Website beeinträchtigt sein.
  </p>
  <!-- OneTrust Cookies List start -->
  <div id="ot-sdk-cookie-policy"></div>
  <!-- OneTrust Cookies List end -->
  <p>
    Weitere Details zu den jeweiligen Cookie-Anbietern finden Sie im Privacy Preference Center unter den jeweiligen
    Cookie-Kategorien.
  </p>
  <!-- OneTrust Cookies Settings button start -->
  <button mat-raised-button color="primary" (click)="openCookieBanner()" class="banner-button">Cookie Settings</button>
  <!-- OneTrust Cookies Settings button end -->
  <h3 class="number-heading">3. Kontakt</h3>
  <div class="bold-heading">a. Datenverarbeitung</div>
  <p>
    Wenn Sie mit uns in Kontakt treten, werden die Daten, die Sie in solchen Formularen angeben, an uns übermittelt und
    verarbeitet. Die Pflichtangaben, die für die elektronische Kontaktaufnahme über das jeweilige Kontaktformular
    ausgefüllt werden müssen, sind mit (*) gekennzeichnet. Wenn Sie uns weitere Daten zur Verfügung stellen, erfolgt
    dies freiwillig.
  </p>
  <p>Wenn Sie uns eine E-Mail senden, verarbeiten wir die zugehörigen Metadaten und den Inhalt der Nachricht.</p>
  <div class="bold-heading">b. Zweck und Rechtsgrundlage</div>
  <p>
    Ihre Daten werden verarbeitet, um mit Ihnen in Kontakt treten zu können, Ihr Anliegen zu bearbeiten und Ihnen unsere
    jeweiligen Dienste zur Verfügung zu stellen, um einen Missbrauch des Kontaktformulars zu verhindern und die
    Sicherheit unserer informationstechnischen Systeme sicherzustellen.
  </p>
  <p>
    Rechtsgrundlage für die Verarbeitung der als zwingend eingestuften Daten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Zu den
    vorgenannten Zwecken gehört auch ein berechtigtes Interesse an der Verarbeitung der Daten. Ist der Zweck der
    Kontaktaufnahme der Abschluss eines Vertrages, so ist die zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6
    Abs. 1 S. 1 lit.b DSGVO. Rechtsgrundlage für die Verarbeitung der Daten, die Sie uns freiwillig zur Verfügung
    gestellt haben, ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO.
  </p>
  <div class="bold-heading">c. Speicherdauer</div>
  <p>
    Die personenbezogenen Daten der betroffenen Person werden gelöscht oder unzugänglich gemacht, sobald der Zweck der
    Speicherung entfällt. Ferner können Daten gespeichert werden, wenn dies durch den europäischen oder nationalen
    Richtlinien- und Verordnungsgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
    Verantwortliche unterliegt, vorgesehen wurde. Daten werden auch nach Ablauf einer durch die oben genannten
    Bestimmungen vorgeschriebenen Speicherfrist gelöscht oder unzugänglich gemacht, es sei denn, es besteht die
    Notwendigkeit, die Daten zum Zwecke des Abschlusses oder der Erfüllung eines Vertrages weiter zu speichern.
  </p>
  <p>
    Im Falle einer Einwilligung haben Sie das Recht, diese Einwilligung jederzeit zu widerrufen, ohne dass die
    Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ändern Sie dazu
    bitte die Einstellungen im Privacy Preference Center.
  </p>
  <h3 class="number-heading">4. Einloggen</h3>
  <p>
    Für registrierte Nutzer verarbeiten wir die folgenden personenbezogenen Daten, um den Zugang zu unserer Plattform zu
    ermöglichen:
  </p>
  <ul>
    <li>Vorname, Nachname</li>
    <li>E-Mail Adresse</li>
    <li>Optional: Unternehmen</li>
    <li>Optional: Sprache (Standard Englisch)</li>
  </ul>
  <p>
    Wir verarbeiten Ihre personenbezogenen Daten, solange diese erforderlich sind, um Ihnen Zugang zu unserer Plattform
    zu ermöglichen. Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit.b) DSGVO.
  </p>
  <h3 class="letter-heading">C. Sind Sie verpflichtet, uns diese Daten zur Verfügung zu stellen?</h3>
  <p>
    Wenn Sie unsere Website besuchen, werden die in Abschnitt B. 1. a. sowie die Informationen aus den als unbedingt
    notwendig eingestuften Cookies werden automatisch verarbeitet. Die Weitergabe dieser Informationen ist freiwillig.
    Ohne die Bereitstellung dieser personenbezogenen Daten können wir die angeforderte Seite nicht anzeigen.
  </p>
  <p>Wenn Sie</p>
  <ul>
    <li>uns die Verwendung von Cookies erlauben, die nicht als unbedingt erforderlich eingestuft werden,</li>
    <li>
      mit uns in Kontakt treten und uns eine E-Mail senden oder unser Kontaktformular nutzen möchten (inkl. der
      notwendigen reCAPTCHA-Anfrage) oder
    </li>
    <li>eingebettete YouTube-Videos sehen möchten</li>
  </ul>
  <p>ist die Übermittlung der Informationen freiwillig.</p>
  <p>
    Wenn Sie mit uns In Kontakt treten, können wir Ihre Anfrage ohne die Angabe der im Einzelfall erforderlichen
    personenbezogenen Daten nicht in der gewählten Weise beantworten. Soweit es sich um Cookies handelt, kann die
    fehlende Einwilligung zu einer Einschränkung der Funktionalität der Website oder Teilen davon führen. Eingebettete
    Videos können nicht ohne Ihre Zustimmung abgespielt werden.
  </p>
  <h3 class="letter-heading">D. An wen geben wir Ihre personenbezogenen Daten weiter?</h3>
  <p>
    Innerhalb unseres Unternehmens erhalten nur Personen und Abteilungen Zugriff auf Ihre personenbezogenen Daten,
    soweit sie diese zur Erfüllung der oben genannten Zwecke benötigen.
  </p>
  <p>
    Wir beziehen auch Dienstleister mit ein. Diese Dienstleister werden nur auf unsere Weisung hin handeln und sind
    vertraglich zur Einhaltung der geltenden datenschutzrechtlichen Anforderungen verpflichtet.
  </p>
  <p>Im Übrigen geben wir Ihre Daten nur dann an Dritte weiter, wenn:</p>
  <ul>
    <li>Sie Ihre ausdrückliche Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben,</li>
    <li>
      die Weitergabe gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
      Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
      Interesse an der Nichtweitergabe Ihrer Daten haben,
    </li>
    <li>
      für uns eine gesetzliche Verpflichtung zur Weitergabe der Daten gemäß Art. 6 Abs. 1 S. 1 lit.c DSGVO besteht, oder
    </li>
    <li>
      dies ist gesetzlich zulässig und gemäß Art. 6 Abs. 1 S. 1 lit.b DSGVO zur Erfüllung von Vertragsverhältnissen mit
      Ihnen erforderlich.
    </li>
  </ul>
  <p>
    Eine Übermittlung Ihrer personenbezogenen Daten an Dienstleister in einem Drittland findet nur statt, wenn die
    besonderen Anforderungen der Art. 44 ff. DSGVO erfüllt sind. Wenn und soweit sich Diensteanbieter in einem Drittland
    befinden, kann es sein, dass sich entweder die Server des jeweiligen Diensteanbieters im Drittland befinden oder
    dass sich die Server in der EU befinden, aber im Falle einer Unterstützung aus einem Drittland abgerufen werden.
    Einige Drittländer verfügen nicht über ein Datenschutzniveau, das dem der EU entspricht, z. B. die USA oder China,
    da die Behörden den Zugang zu personenbezogenen Daten möglicherweise auf vereinfachte Weise vereinfacht haben oder
    dass es nur begrenzte Rechte auf solche Maßnahmen gibt. Wenn und soweit Sie eine Einwilligung erteilen, erteilen Sie
    ausdrücklich eine Einwilligung in die Übermittlung personenbezogener Daten in das jeweilige Drittland.
  </p>
  <h3 class="letter-heading">E. Wie schützen wir Ihre personenbezogenen Daten?</h3>
  <p>
    BASF setzt technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten gegen zufällige
    oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen.
    Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend überarbeitet.
  </p>
  <h3 class="letter-heading">F. Welche Rechte haben Sie?</h3>
  <p>
    Sie haben bestimmte Rechte gemäß der Datenschutz-Grundverordnung, einschließlich des Rechts, eine Kopie der
    personenbezogenen Daten, die wir über Sie gespeichert haben, anzufordern, wenn Sie dies schriftlich von uns
    anfordern:
  </p>
  <p>
    <b>Recht auf Zugang:</b> das Recht, Zugang zu Ihren Informationen (wenn wir sie verarbeiten) und bestimmten anderen
    Informationen (wie in dieser Datenschutzrichtlinie angegeben) zu erhalten; Recht auf Berichtigung: Wenn Ihre
    personenbezogenen Daten unrichtig oder unvollständig sind, haben Sie das Recht, Ihre personenbezogenen Daten
    berichtigen zu lassen;
  </p>
  <p>
    <b>Recht auf Löschung:</b> Dies wird auch als "Recht auf Vergessenwerden" bezeichnet und ermöglicht es Ihnen,
    einfach ausgedrückt, die Löschung oder Entfernung Ihrer Daten zu beantragen, wenn es keinen zwingenden Grund für uns
    gibt, sie weiterhin zu verwenden. Dies ist kein allgemeines Recht auf Löschung; es gibt Ausnahmen. Beispielsweise
    haben wir das Recht, Ihre personenbezogenen Daten weiterhin zu verwenden, wenn eine solche Verwendung zur Erfüllung
    unserer gesetzlichen Verpflichtungen oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
    erforderlich ist;
  </p>
  <p>
    <b>Recht auf Einschränkung der Verarbeitung:</b> : das Recht, die Verwendung Ihrer personenbezogenen Daten
    auszusetzen oder die Art und Weise, wie wir sie verarbeiten können, einzuschränken. Bitte beachten Sie, dass dieses
    Recht in bestimmten Situationen eingeschränkt ist: Wenn wir Ihre personenbezogenen Daten, die wir mit Ihrer
    Zustimmung von Ihnen erhoben haben, verarbeiten, können Sie die Einschränkung nur auf der Grundlage von: (a)
    Ungenauigkeit der Daten verlangen; (b) wenn unsere Verarbeitung rechtswidrig ist und Sie nicht möchten, dass Ihre
    personenbezogenen Daten gelöscht werden; (c) Sie es für einen Rechtsanspruch benötigen; oder (d) wenn wir die Daten
    nicht mehr für die Zwecke verwenden müssen, für die wir sie speichern. Wenn die Verarbeitung eingeschränkt ist,
    können wir Ihre Daten weiterhin speichern, dürfen sie jedoch nicht weiter verwenden. Wir führen Listen von Personen,
    die um eine Einschränkung der Verwendung ihrer personenbezogenen Daten gebeten haben, um sicherzustellen, dass die
    Einschränkung in Zukunft eingehalten wird;
  </p>
  <p>
    <b>Recht auf Datenübertragbarkeit:</b> das Recht zu verlangen, dass wir Ihre personenbezogenen Daten in einem
    strukturierten, allgemein gebräuchlichen und maschinenlesbaren Format für Ihre eigenen Zwecke über verschiedene
    Dienste hinweg verschieben, kopieren oder übertragen (soweit technisch machbar);
  </p>
  <p>
    <b>Widerspruchsrecht:</b> das Recht, unserer Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, auch wenn
    wir sie für unsere berechtigten Interessen, Direktmarketing, verarbeiten.
  </p>
  <p>
    Um Ihre Rechte auszuüben, kontaktieren Sie uns bitte, zum Beispiel per E-Mail oder per Post. Die Kontaktdaten finden
    Sie in Abschnitt A.
  </p>
  <h3 class="letter-heading">G. Wo können Sie sich beschweren?</h3>
  <p>
    Sie haben das Recht auf Beschwerde bei unserem Datenschutzbeauftragten (Kontaktdaten siehe Abschnitt A) oder bei
    einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts
    des mutmaßlichen Verstoßes.
  </p>
  <p>In beiden Fällen können Sie sich an unsere Lead Data Protection Authority wenden:</p>
  <p>Landesbeauftragter für den Datenschutz und die Informationsfreiheit Rheinland-Pfalz</p>
  <p>
    Hintere Bleiche 34<br />
    55116 Mainz<br />
    <a class="link" href="https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/"
      >https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/</a
    ><br />
    E-Mail: <span class="link">poststelle@datenschutz.rlp.de</span>
  </p>
  <h3 class="letter-heading">H. Datenschutz für Minderjährige</h3>
  <p>
    Diese Website richtet sich an Personen, die mindestens 18 Jahre alt sind. Wenn ein Minderjähriger über diese Website
    personenbezogene Daten übermittelt, werden wir diese Daten löschen und nicht weiter verarbeiten, sobald uns dies
    bekannt wird.
  </p>
</div>
