import { Component, Input, OnInit } from '@angular/core';
import { CarFilterOptions } from 'src/app/models/car-filter-options.model';
import { ComponentStatus } from 'src/app/models/component-status.model';
import { LabchComponentStatusView } from 'src/app/models/labch-component-status-view.model';
import { CarReportService } from 'src/app/services/car-report/car-report.service';
import { DifferenceType } from 'src/app/shared/enums/difference-type.enum';
import { Car } from 'src/app/models/car.model';

@Component({
  selector: 'colimo-car-report-labch-statistics',
  templateUrl: './car-report-labch-statistics.component.html',
  styleUrls: ['./car-report-labch-statistics.component.scss'],
})
export class CarReportLabchStatisticsComponent implements OnInit {
  @Input() public car: Car;
  @Input() public componentsStatus: ComponentStatus[];
  @Input() public filterOptions: CarFilterOptions;
  @Input() public selectedComponentStatus: ComponentStatus;
  @Input() public componentStatusCount: number;
  @Input() public heading: string;
  @Input() public labchBumperAverageHeading: string;
  @Input() public componentTranslations: string[];
  @Input() public positionTranslations: string[];

  public differenceType: DifferenceType;
  public displayedColumns: string[];
  public labchStatisticDataSource: LabchComponentStatusView[];

  constructor(private carReportService: CarReportService) {}

  public ngOnInit(): void {
    if (this.componentsStatus) {
      this.labchStatisticDataSource = this.carReportService.buildLabchStatisticDataSource(
        this.componentsStatus,
        this.componentStatusCount,
      );
    }
    this.displayedColumns = [
      'id',
      'component',
      'position',
      'status',
      'geometry',
      'dl',
      'da',
      'db',
      'dc',
      'dh',
      'de',
      'mde',
    ];
  }
}
