import { Pipe, PipeTransform } from '@angular/core';
import { DeltaValue } from '../../models/delta-value.model';

/**
 * Returns delta value of respective angle.
 */
@Pipe({
  name: 'deltaValueOfAngle',
})
export class DeltaValueOfAnglePipe implements PipeTransform {
  public transform(deltaEValues: DeltaValue[], angle: number): DeltaValue {
    if (deltaEValues == null || angle == null) {
      return null;
    }

    const mayFoundDeltaEValue = this.getDeValueForAngle(deltaEValues, angle);
    if (mayFoundDeltaEValue == null) {
      return null;
    }

    return mayFoundDeltaEValue;
  }

  private getDeValueForAngle(deltaEValues: DeltaValue[], angle: number): DeltaValue {
    return deltaEValues.find((deltaEValue: DeltaValue) => {
      if (deltaEValue == null) {
        return false;
      }
      if (deltaEValue.angle === angle) {
        return true;
      }
    });
  }
}
