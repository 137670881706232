import { Component, Input, OnInit } from '@angular/core';

/**
 * Responsible for displaying the number of mapped measurements
 * that are used for computation of KPIs.
 */
@Component({
  selector: 'colimo-mapped-measurements',
  templateUrl: './mapped-measurements.component.html',
  styleUrls: ['./mapped-measurements.component.scss'],
})
export class MappedMeasurementsComponent implements OnInit {
  @Input() public measurementCount: number;

  constructor() {}

  public ngOnInit(): void {}
}
