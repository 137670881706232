import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as Chart from 'chart.js';
import { Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartDataService } from 'src/app/services/chart-data/chart-data.service';
import { ChartFilters } from '../../models/chart-filters.model';
import { IMwgData } from '../../models/chart-mwg.model';
import { ModelsComponents } from '../../models/models-components.model';
import { ExportClipboardParameter } from '../../services/export-to-clipboard/export-clipboard-parameter';
import { ImwgDataValidatorService } from '../../services/imwg-data-validator/imwg-data-validator.service';
import { Scale } from './scale';
import {ChartDefaults} from '../../shared/enums/chart-defaults.enum';

/**
 * Displays a list of MWG charts (MittelWertGrafik), contains chart types and default labels
 */
@Component({
  selector: 'colimo-mwg-chart-list',
  styleUrls: ['mwg-chart-list.component.scss'],
  templateUrl: 'mwg-chart-list.component.html',
})
export class MWGchartListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public showChartFilters: boolean;
  @Input() public showChartTable: boolean;
  @Input() public chartFilters: ChartFilters;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public modelsComponents: ModelsComponents;
  @Input() public batchId: number;
  @Input() public lineId: string;
  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  @Output() public chartDataChange: EventEmitter<IMwgData> = new EventEmitter();
  @Output() public tolerancesChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() public mwgChartLoading: EventEmitter<boolean> = new EventEmitter();

  public chartData: IMwgData;
  public isLoading: boolean;
  public exportClipboardParameter: ExportClipboardParameter;

  // Only 5 chart types
  public mwgChartTypes = ['DL', 'DA', 'DB', 'DC', 'DH'];
  // API response
  // Default labels
  public lineChartLabels: string[] = [];
  // Default scale
  public scale: Scale = {
    max: 1.5,
    min: -1.5,
  };
  public dataAvailable: boolean;

  private stop$: Subject<boolean> = new Subject();

  constructor(private imwgDataValidatorService: ImwgDataValidatorService, private chartDataService: ChartDataService) {}

  public ngOnInit(): void {
    // Declare options for toleranceLine Plugin
    Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      asSquare: false,
      xOffset: 42,
      yOffset: 25,
    });
    this.getMwgData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartFilters && !changes.chartFilters.firstChange) {
      if (this.chartFilters) {
        this.getMwgData();
      }
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  private getMwgData(): void {
    this.setLoading(true);
    if (!this.batchId || !this.lineId) {
      this.chartDataService.getMwgData(this.chartFilters, this.modelsComponents).pipe(takeUntil(this.stop$)).subscribe((iMwgData: IMwgData) => {
        this.processMwgData(iMwgData);
        this.chartDataChange.emit(iMwgData);
      });
    } else {
      // create a deep copy of chart filters for model/comp/tolerance filter etc. and manipulate only the batch and line Id
      const chartFiltersForMultiBatch: ChartFilters = JSON.parse(JSON.stringify(this.chartFilters));
      chartFiltersForMultiBatch.batchId = this.batchId;
      chartFiltersForMultiBatch.lineId = this.lineId;
      this.chartDataService.getMwgData(chartFiltersForMultiBatch, this.modelsComponents).pipe(takeUntil(this.stop$)).subscribe((iMwgData: IMwgData) => {
        this.processMwgData(iMwgData);
        this.chartDataChange.emit(iMwgData);
      });
    }
    /*
    // this might be needed in the future multiple batches should be displayed in one chart
    const observables: Observable<IMwgData>[] = [];

    if (this.chartFilters.batchIds && this.chartFilters.lineIds) {
      const batchIds: string[] = this.chartFilters.batchIds.split(',');
      const lineIds: string[] = this.chartFilters.lineIds.split(',');
      // create a deep copy of chart filters for model/comp/tolerance filter etc.
      const chartFiltersForMultiBatch: ChartFilters = JSON.parse(JSON.stringify(this.chartFilters));
      for (let i = 0; i < batchIds.length; i++) {
        chartFiltersForMultiBatch.batchId = parseInt(batchIds[i]);
        chartFiltersForMultiBatch.lineId = lineIds[i];
        observables.push(this.chartDataService.getMwgData(chartFiltersForMultiBatch, this.modelsComponents));
      }
    } else {
      observables.push(this.chartDataService.getMwgData(this.chartFilters, this.modelsComponents));
    }

    forkJoin(observables).pipe(takeUntil(this.stop$)).subscribe({next: (iMwgDatas: IMwgData[]) => {
      //const currentMwgData = this.combineMultipleMwgChartData(iMwgDatas);
      this.processMwgData(iMwgDatas[0]);
      this.chartDataChange.emit(iMwgDatas[0]);
    }});*/
  }

  /*
  // this might be needed in the future multiple batches should be displayed in one chart
  private combineMultipleMwgChartData(chartDatas: IMwgData[]): IMwgData {
    let combined: IMwgData;
    if (!chartDatas) {
      return null;
    }
    if (chartDatas.length === 1) {
      return chartDatas[0];
    }
    combined = chartDatas[0];
    for (let x = 0; x < this.mwgChartTypes.length; x++) {
      const type = this.mwgChartTypes[x];
      if (combined.data[type] && combined.data[type].dataSets) { // perhaps check more if push is nessaray so values are set
        for (let t = 0; t < combined.data[type].dataSets.length; t++) {
          const set = combined.data[type].dataSets[t];
          set.type += '-' + combined.batchNumber + '-' + combined.lineNumber;
        }
      }
    }

    for (let i = 1; i < chartDatas.length; i++) {
      for (let j = 0; j < this.mwgChartTypes.length; j++) {
        const chartType = this.mwgChartTypes[j];
        if (chartDatas[i].data[chartType] && chartDatas[i].data[chartType].dataSets) { // perhaps check more if push is nessaray so values are set
          for (let t = 0; t < chartDatas[i].data[chartType].dataSets.length; t++) {
            const set = chartDatas[i].data[chartType].dataSets[t];
            set.type += '-' + chartDatas[i].batchNumber + '-' + chartDatas[i].lineNumber;
            combined.data[chartType].dataSets.push(set);
          }
        }
      }
    }
    return combined;
  }*/

  private processMwgData(iMwgData: IMwgData): void {
    const TIMEOUT_IN_MILLISECONDS = 30000;
    if (iMwgData && iMwgData.batchNumber && iMwgData.data) {
      this.chartData = iMwgData;
      this.buildExportClipboardParameter(iMwgData);
    }

    // Set global Chart options for toleranceLine Plugin
    Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: iMwgData.tolerances });
    Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, iMwgData.scaleTolerance ? iMwgData.scaleTolerance : []);

    if (this.chartFilters.tolerance === null || this.chartFilters.tolerance === undefined) {
      this.tolerancesChanged.emit(iMwgData.tolerances);
    }

    if (!this.imwgDataValidatorService.isValid(iMwgData)) {
      this.dataAvailable = false;
    } else {
      this.dataAvailable = true;
      if (iMwgData.scale) {
        this.scale.min = iMwgData.scale[0];
        this.scale.max = iMwgData.scale[1];
      }

      if (iMwgData.angles) {
        this.lineChartLabels = iMwgData.angles;
        // BCH-568: Displaying all available angles as default
        if (this.selectedAngles.length === 0 && this.lineChartLabels.length !== 0) {
          this.selectedAngles = new Array(this.lineChartLabels.length).fill(true);
          this.chartFiltersChange.emit(this.chartFilters);
        }
      }
      this.setLoading(false);
    }

    setTimeout(() => {
      this.setLoading(false);
    }, TIMEOUT_IN_MILLISECONDS);
  }

  private buildExportClipboardParameter(response: IMwgData): void {
    this.exportClipboardParameter = {
      batchId: response.batchId,
      line: response.lineNumber,
    };
  }

  private setLoading(loading: boolean): void {
    this.isLoading = loading;
    this.mwgChartLoading.emit(loading);
  }
}
