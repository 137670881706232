<div class="row limit-width">
  <!-- Iterate chart angles: 15°, 25°, 75° etc. -->
  <div class="flex-row flex-wrap">
    <div
      *ngFor="let chartSlot of chartSlots; let i = index"
      class="flex-33 fog-chart-min-width"
      [ngClass]="{ 'is-empty': !selectedAngles[i] }"
    >
      <div class="labels-wrapper" [ngClass]="{ 'is-empty': !chartAngles[i] }">
        <span class="type-label">{{ chartAngles[i] }}°</span>
      </div>

      <div class="charts-wrapper" [ngClass]="{ 'is-empty': !chartAngles[i] }">
        <div class="dl-wrapper">
          <span class="type-label is-left">{{ chartSlot.labels[0] | labelByChartType: iFogData?.tolerances }}</span>
          <colimo-canvas-wrapper [chartType]="'DL is-fog-right'">
            <!-- Narrow dl chart -->
            <colimo-fog-chart-component
              [type]="'dl'"
              [dataSet]="chartData[i][0]"
              [dataScale]="scale"
              [activeComponents]="activeComponents"
              [chartComponentColors]="chartComponentColors"
              [chartMultiBatchColors]="chartMultiBatchColors"
            ></colimo-fog-chart-component>
          </colimo-canvas-wrapper>
        </div>

        <div class="dataset-wrapper">
          <span class="type-label is-left">{{ chartSlot.labels[1] | labelByChartType: iFogData?.tolerances }}</span>

          <!-- horizontal wrapper -->
          <colimo-canvas-wrapper [chartType]="'DA is-fog-top'">
            <!-- vertical wrapper -->
            <colimo-canvas-wrapper [chartType]="'DB is-fog-right'">
              <!-- Main chart -->
              <colimo-fog-chart-component
                [type]="'main'"
                [dataSet]="chartData[i][1]"
                [dataScale]="scale"
                [activeComponents]="activeComponents"
                [chartComponentColors]="chartComponentColors"
                [chartMultiBatchColors]="chartMultiBatchColors"
              ></colimo-fog-chart-component>
            </colimo-canvas-wrapper>
          </colimo-canvas-wrapper>

          <span class="type-label is-bottom">{{ chartSlot.labels[2] | labelByChartType: iFogData?.tolerances }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(!iFogData || !iFogData.data || iFogData.data.length === 0) && !isLoading">
  <div>
    <p class="missingData">{{ 'dashboard.missingData' | translate }}</p>
  </div>
</div>

<colimo-loader [background]="'transparent'" [top]="'15%'" *ngIf="isLoading"></colimo-loader>
