<mat-card appearance="outlined" class="mat-elevation-z6 padding-16 box-shadow">
  <mat-card-title class="section-heading">{{ 'reports.filterLabel.carInformation' | translate }} - {{ car.vehicleId }}</mat-card-title>
  <div class="flex-row new-font-family">
    <div class="flex-1">
      <label>{{ 'reports.reportTable.batch' | translate }}</label>
      <div>{{ car.batchNr }}</div>
    </div>
    <div class="flex-1">
      <ng-container *ngIf="car.line; else refMeasurements">
        <label>{{ 'reports.reportTable.line' | translate }}</label>
        <div>{{ car.line }}</div>
      </ng-container>
      <ng-template #refMeasurements>
        <label>#{{ 'reports.reportTable.refMeasurements' | translate }}</label>
        <div>{{ car.avgMeasurements }}</div>
      </ng-template>
    </div>
    <div class="flex-1">
      <label>{{ 'reports.reportTable.model' | translate }}</label>
      <div>{{ car.model }}</div>
    </div>
    <div class="flex-1">
      <label>{{ 'reports.reportTable.product' | translate }}</label>
      <div>{{ car.product }}</div>
    </div>
    <div class="flex-1">
      <label>{{ 'reports.reportTable.color' | translate }}</label>
      <div>{{ car.colorName }}</div>
    </div>
    <div class="flex-1">
      <label>{{ 'reports.reportTable.dateMeasurement' | translate }}</label>
      <div>{{ car.measurementDate }}</div>
    </div>
  </div>
</mat-card>
