import * as ModalActions from '../actions/modal.actions';
import { Kpis } from '../shared/enums/kpis.enum';

interface IModalOpened {
  open: boolean;
  tab: Kpis;
}

const initialState: IModalOpened = {
  open: false,
  tab: null
};

/**
 * Reducer to set the opened modal and KPI-value
 */
export function reducer(state: IModalOpened = initialState, action: ModalActions.Actions): IModalOpened {
  switch (action.type) {

    case ModalActions.ACTION_TYPES.SET_MODAL: {
      return action.payload;
    }

    case ModalActions.ACTION_TYPES.REMOVE_MODAL: {
      return initialState;
    }

    default:
      return state;
  }
}
