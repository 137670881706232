import { Component, Input, OnInit } from '@angular/core';
import { Car } from 'src/app/models/car.model';

@Component({
  selector: 'colimo-car-report-information',
  templateUrl: './car-report-information.component.html',
  styleUrls: ['./car-report-information.component.scss'],
})
export class CarReportInformationComponent implements OnInit {
  @Input() public car: Car;

  constructor() {}

  public ngOnInit(): void {}
}
