<mat-card
  appearance="outlined"
  [ngClass]="{ 'remove-flex-for-export': isCarReportExportLayout }"
  class="report-card flex-column padding-16"
>
  <colimo-page-header [heading]="heading"> </colimo-page-header>
  <div class="block-for-export flex-row">
    <div
      class="page-break flex-column gap-10"
      [ngClass]="{ 'scrollable-column': !isCarReportExportLayout }"
    >
      <colimo-car-report-configuration
        class="elevation-border"
        [filterOptions]="filterOptions"
        [isCarReportExportLayout]="isCarReportExportLayout"
        [carBodyStyleLoaded]="carBodyStyleLoaded"
        [selectedTypeOfReport]="selectedTypeOfReport"
        (initiateSearch)="searchCar()"
        (hideComponentsForCarsSearch)="hideComponentsForCarsSearch()"
        (displayTypeOfReport)="onSelectedTypeOfReportChange($event)"
        (exportCarReport)="exportCarReport()"
        (usageOfUntouchedFilters)="onUsageOfUntouchedFilters($event)"
      ></colimo-car-report-configuration>
      <colimo-car-report-information class="elevation-border" *ngIf="car" [car]="car"></colimo-car-report-information>
      <colimo-car-report-overview
        class="elevation-border"
        *ngIf="car"
        [car]="car"
        [filterOptions]="filterOptions"
        (updateSelectedComponentStatus)="onSelectedComponentStatusChange($event)"
        (carBodyStyleLoaded)="onCarBodyStyleLoaded($event)"
      ></colimo-car-report-overview>
      <div *ngIf="isLoading">
        <colimo-loader [isRelative]="true"></colimo-loader>
      </div>
    </div>
    <div
      *ngIf="dEReportSelected"
      [ngClass]="{ 'scrollable-column': !isCarReportExportLayout }"
      class="flex-column-1 gap-10"
    >
      <colimo-car-report-statistics
        class="elevation-border"
        *ngIf="car"
        [car]="car"
        [filterOptions]="filterOptions"
        [selectedComponentStatus]="selectedComponentStatus"
        [componentTranslations]="componentTranslations"
        [positionTranslations]="positionTranslations"
      ></colimo-car-report-statistics>
      <colimo-car-report-bumper-statistics
        class="elevation-border"
        *ngIf="car && filterOptions.bumperOn && car.bumperStatus.length !== 0"
        [car]="car"
        [filterOptions]="filterOptions"
        [selectedComponentStatus]="selectedComponentStatus"
        [componentTranslations]="componentTranslations"
        [positionTranslations]="positionTranslations"
      ></colimo-car-report-bumper-statistics>
    </div>
    <div
      *ngIf="dLabCHReportSelected"
      [ngClass]="{ 'scrollable-column': !isCarReportExportLayout }"
      class="flex-column gap-10 flex-1"
    >
      <colimo-car-report-labch-statistics
        class="elevation-border"
        *ngIf="car"
        [car]="car"
        [componentsStatus]="car.componentsStatus"
        [filterOptions]="filterOptions"
        [selectedComponentStatus]="selectedComponentStatus"
        [heading]="labchStatisticsHeading"
        [componentTranslations]="componentTranslations"
        [positionTranslations]="positionTranslations"
      ></colimo-car-report-labch-statistics>
      <colimo-car-report-labch-statistics
        class="elevation-border"
        *ngIf="car && filterOptions.bumperOn && car.bumperStatus.length !== 0"
        [car]="car"
        [componentsStatus]="car.bumperStatus"
        [filterOptions]="filterOptions"
        [selectedComponentStatus]="selectedComponentStatus"
        [componentStatusCount]="car.componentsStatus.length"
        [heading]="labchBumperStatisticsHeading"
        [labchBumperAverageHeading]="labchBumperAverageHeading"
        [componentTranslations]="componentTranslations"
        [positionTranslations]="positionTranslations"
      ></colimo-car-report-labch-statistics>
    </div>
    <div
      *ngIf="dLabCHAveragePlotReportSelected"
      class="block-for-export flex-column flex-1 gap-10"
      [ngClass]="{ 'scrollable-column': !isCarReportExportLayout }"
    >
      <colimo-car-report-mwg-chart
        class="elevation-border"
        [car]="car"
        [filterOptions]="filterOptions"
        [carBodyStyleLoaded]="carBodyStyleLoaded"
      >
      </colimo-car-report-mwg-chart>
      <div class="page-break"></div>
      <colimo-car-report-labch-statistics
        class="elevation-border"
        *ngIf="car"
        [car]="car"
        [componentsStatus]="car.componentsStatus"
        [filterOptions]="filterOptions"
        [selectedComponentStatus]="selectedComponentStatus"
        [heading]="labchStatisticsHeading"
        [componentTranslations]="componentTranslations"
        [positionTranslations]="positionTranslations"
      ></colimo-car-report-labch-statistics>
      <colimo-car-report-labch-statistics
        class="elevation-border"
        *ngIf="car && filterOptions.bumperOn && car.bumperStatus.length !== 0"
        [car]="car"
        [componentsStatus]="car.bumperStatus"
        [filterOptions]="filterOptions"
        [selectedComponentStatus]="selectedComponentStatus"
        [componentStatusCount]="car.componentsStatus.length"
        [heading]="labchBumperStatisticsHeading"
        [labchBumperAverageHeading]="labchBumperAverageHeading"
        [componentTranslations]="componentTranslations"
        [positionTranslations]="positionTranslations"
      ></colimo-car-report-labch-statistics>
    </div>
  </div>
</mat-card>
