/**
 * Available difference types.
 */
export enum DifferenceType {
  DL = 'DL', // Difference of lightness between measurement and reference value
  DA = 'DA', // Difference between measurement and A- reference value
  DB = 'DB', // Difference between measurement and B- reference value
  DC = 'DC', // Difference between measurement and reference value as chroma
  DH = 'DH', // Difference between measurement and reference value as color angle
  D_DE = 'D_DE', // Total variance between measurement and reference value - without tolerances
  C_DE = 'C_DE', // Total variance between measurement and reference value - with charge tolerances
  S_DE = 'S_DE', // Total variance between measurement and reference value - with serial tolerances
  M_DE = 'M_DE', // Average of all measurement variances
}
