import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Iproject } from 'src/app/models/project.model';
import { MobileCheck } from '../../services/mobile-check/mobile-check.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'colimo-color-card',
  templateUrl: './color-card.component.html',
  styleUrls: ['./color-card.component.scss'],
})
export class ColorCardComponent implements OnInit, OnDestroy {
  @Input() public project: Iproject;

  public isMobile: boolean;
  private stop$: Subject<boolean> = new Subject();

  constructor(private mobileCheck: MobileCheck) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().pipe(takeUntil(this.stop$)).subscribe((device) => {
      this.isMobile = device.isMobile;
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }
}
