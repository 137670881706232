import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/models/language.model';
import { LanguagesService } from 'src/app/services/languages/languages.service';

@Component({
  selector: 'colimo-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  public currentLanguage$: Observable<Language>;

  constructor(private languagesService: LanguagesService) {}

  public ngOnInit(): void {
    this.currentLanguage$ = this.languagesService.currentLanguage$;
  }
}
