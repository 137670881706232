import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TinterAddition } from '../../models/tinter-addition.model';
import { TinterAdditionsCount } from '../../models/tinter-additions-count.model';

/**
 * Service responsible for loading tinter additions data via REST.
 */
@Injectable({
  providedIn: 'root',
})
export class TinterAdditionsService {
  private baseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath + environment.apiUserPath;
  }

  /**
   * Get tinter additions for given batch and line.
   */
  public getTinterAdditions(batchId: number, lineId: string): Observable<TinterAddition[]> {
    // Returns empty observable if mandatory pars missing
    if (!batchId || !lineId) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.tinterAdditions
      .replace('{batchId}', batchId.toString())
      .replace('{line}', lineId)}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as TinterAddition[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getTinterAdditionsMultiBatch(batchIds: string, lineIds: string): Observable<TinterAddition[]> {
    // Returns empty observable if mandatory pars missing
    if (!batchIds || !lineIds) {
      return EMPTY;
    }
    const apiEndpoint = this.baseUrl + '/tinterAdditions-multi-batch?batchIds=' + batchIds + '&lines=' + lineIds;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as TinterAddition[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  /**
   * Provides number of tinter additions for given batch and line.
   */
  public getNumberOfTinterAdditions(batchId: number, lineId: string): Observable<TinterAdditionsCount> {
    // Returns empty observable if mandatory pars missing
    if (!batchId || !lineId) {
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.tinterAdditionsCount
      .replace('{batchId}', batchId.toString())
      .replace('{line}', lineId)}`;

    return this.http.get(apiEndpoint).pipe(
      map(response => response as TinterAdditionsCount),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }
}
