import { Pipe, PipeTransform } from '@angular/core';
import { CarBodyStyle } from 'src/app/models/car-body-style';
import { ComponentPosition } from 'src/app/models/component-position';
import { ComponentStatus } from 'src/app/models/component-status.model';

@Pipe({
  name: 'componentStatusPositionCssClass',
})

// This pipe returns the position for a measurement point of a vehicle based on the car body style
export class ComponentStatusPositionCssClassPipe implements PipeTransform {
  public transform(componentStatus: ComponentStatus, carBodyStyle: CarBodyStyle): { [klass: string]: string } {
    if (!componentStatus || !componentStatus.component || !componentStatus.position || !carBodyStyle) {
      return { display: 'none' };
    }
    const component = componentStatus.component;
    const position = componentStatus.position;
    let imgXpos: string;
    let imgYpos: string;
    carBodyStyle.positions.forEach((carBodyStylePosition: ComponentPosition) => {
      if (carBodyStylePosition.component === component && carBodyStylePosition.position === position) {
        imgXpos = carBodyStylePosition.imgXpos.toString();
        imgYpos = carBodyStylePosition.imgYpos.toString();
      }
    });
    return { left: imgXpos + 'px', top: imgYpos + 'px' };
  }
}
