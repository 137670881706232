<div class="policy-wrapper" [ngClass]="isMobile === true ? 'policy-wrapper-mobile' : ''">
  <colimo-page-header [heading]="'privacyPolicy.heading'"> </colimo-page-header>
  <h2 [ngClass]="isMobile === true ? 'heading-mobile' : ''">
    BASF is pleased about your visit to our website and your interest in our company.
  </h2>
  <p>
    The topic of data protection has the highest priority at BASF. Therefore, we would like to inform you in detail
    about it in the following:
  </p>
  <div class="agenda">
    <div>A. Who is responsible for data processing and who is our data protection officer?</div>
    <div>B. What data do we process, for what purposes, for how long and on what legal basis?</div>
    <div>C. Are you obliged to provide us with this data?</div>
    <div>D. To whom do we pass on your personal data?</div>
    <div>E. How do we safeguard your personal data?</div>
    <div>F. What rights do you have?</div>
    <div>G. Where can you complain?</div>
    <div>H. Data protection for minors</div>
  </div>
  <h3 class="letter-heading">A. Who is responsible for data processing and who is our data protection officer?</h3>
  <p>
    You can find the person responsible for data processing
    <a class="link" href="https://www.basf.com/global/de/legal/credits.html">here</a>.
  </p>
  <p>Our data protection officer can be contacted at data-protection.eu@BASF.com</p>
  <h3 class="letter-heading">B. What data do we process, for what purposes, for how long and on what legal basis?</h3>
  <p>
    In the following, we inform you about the data processing on our site (a), its purposes and legal basis (b) as well
    as the respective storage period and, if applicable, specific objection and removal options (c).
  </p>
  <h3 class="number-heading">1. Log files</h3>
  <div class="bold-heading">a. Data processing</div>
  <p>
    If you visit our website for information purposes only, i.e. if you do not register or transmit data in any other
    way (e.g. via a contact form), the following information is automatically transmitted from your browser to our
    server:
  </p>
  <ul>
    <li>IP address of your device</li>
    <li>Information about your browser</li>
    <li>Name of the website from which you visit us</li>
    <li>Name of the visited page (URL) or the opened file</li>
    <li>Date and time of your visit</li>
    <li>Status information such as error messages</li>
    <li>Transferred data volume and the access status (file transferred, file not found, etc.)</li>
    <li>Operating system and version of your computer's operating system and the name of your access provider</li>
  </ul>
  <div class="bold-heading">b. Purposes and legal basis</div>
  <p>
    When you visit the BASF web site, we use the IP address and other information automatically transmitted to our
    server by your browser under B. 1. a. to
  </p>
  <div>
    <div>(i) provide you with the requested content</div>
    <div>(ii) ensure the security and stability of our website and to trace unauthorized use</div>
    <div>(iii) enable a comfortable use of our website.</div>
  </div>
  <p>
    The legal basis for the data processing is Art. 6 para. 1 sentence 1 lit. f GDPR. Our legitimate interest follows
    from the above listed purposes for data processing. Under no circumstances do we use the collected data for the
    purpose of drawing conclusions about your person.
  </p>
  <div class="bold-heading">c. Storage period</div>
  <p>
    The data will be deleted as soon as they are no longer necessary for the purpose for which they were collected. In
    the case of the collection of data for the provision of the website, this is the case when the respective session is
    ended. In the case of storage of the data in log files, this is the case after seven days at the latest. Storage
    beyond this period is possible. In this case, the IP addresses are anonymized by deleting the last eight bits, so
    that an identification of you is no longer possible.
  </p>
  <h3 class="number-heading">2. Cookies</h3>
  <div class="bold-heading">a. Data processing and respective legal basis</div>
  <p>
    We use cookies on our website. These are text information that can be stored in the browser on the device of the
    viewer to a visited website (web server, server). The cookie is either sent from the web server to the browser or
    generated in the browser by a script (JavaScript). The web server can read this cookie information directly from the
    server when the user visits this page again or transfer the cookie information to the server via a script of the
    website. Cookies do not cause any damage to your device, do not contain viruses, trojans or other malware.
  </p>
  <p>
    Information is stored in the cookie that is related to the specific device used. This does not mean, however, that
    we obtain direct knowledge of your identity.
  </p>
  <p>We generally use the following cookies:</p>
  <ul>
    <li>Strictly necessary cookies</li>
  </ul>
  <p>
    These cookies are necessary for the functioning of the website. In general, these cookies are only set in response
    to actions you take in response to a service request, such as setting your privacy preferences, logging in, or
    filling out forms. You can set your browser to block these cookies or to notify you about these cookies. However,
    some areas of the website may not function properly.
  </p>
  <p>
    The legal basis for the processing of these cookies is Art. 6 para. 1 sentence 1 lit. f GDPR. Our legitimate
    interest follows from the data processing purposes listed under B.1.b.
  </p>
  <ul>
    <li>Performance cookies</li>
  </ul>
  <p>
    These cookies enable us to count visits and traffic sources so that we can measure and improve the performance of
    our website. They help us answering questions about which pages are most popular, which are least used and how
    visitors move around the site. All information collected by these cookies is aggregated and therefore anonymous. If
    you do not allow these cookies, we cannot know when you visited our website.
  </p>
  <p>
    The legal basis for the processing of these cookies is your consent pursuant to Art. 6 para. 1 sentence 1 lit. a
    GDPR, which you have given us by making your selection in the cookie banner or in our Privacy Preference Center.
  </p>
  <p>
    You have the right to revoke your consent at any time, without affecting the lawfulness of the processing carried
    out on the basis of the consent until revocation. To do so, please change the settings in the Privacy Preference
    Center.
  </p>
  <ul>
    <li>Functional cookies</li>
  </ul>
  <p>
    These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by
    third parties whose services we use on our pages. If you do not allow these cookies, some or all of these services
    may not function properly.
  </p>
  <p>
    The legal basis for the processing of these cookies is your consent in accordance with Art. 6 para. 1 sentence 1
    lit. a GDPR, which you have given us by making your selection in the cookie banner or in our Privacy Preference
    Center.
  </p>
  <p>
    You have the right to revoke your consent at any time, without affecting the lawfulness of the processing carried
    out on the basis of the consent until revocation. To do so, please change the settings in the Privacy Preference
    Center.
  </p>
  <ul>
    <li>Cookies for marketing purposes</li>
  </ul>
  <p>
    These cookies can be set via our website. They may be used by our marketing partners to profile your interests and
    show you relevant ads on other websites. If you do not allow these cookies, you will experience less targeted
    advertising.
  </p>
  <p>
    The legal basis for the processing of these cookies is your consent in accordance with Art. 6 para. 1 sentence 1
    lit. a GDPR, which you have given us by making your selection in the cookie banner or in our Privacy Preference
    Center.
  </p>
  <p>
    You have the right to revoke your consent at any time, without affecting the lawfulness of the processing carried
    out on the basis of the consent until revocation. To do so, please change the settings in the Privacy Preference
    Center.
  </p>
  <div class="bold-heading">b. Specific use of cookies, purposes and storage period</div>
  <p>
    Specifically, we use the following cookies, depending on the cookie preferences you set in the Privacy Preference
    Center. Only the strictly necessary cookies are activated by default. If you do not want this either, you have the
    option of generally rejecting cookies in your browser. In this case, the functionality of the visited website may be
    impaired.
  </p>
  <!-- OneTrust Cookies List start -->
  <div id="ot-sdk-cookie-policy"></div>
  <!-- OneTrust Cookies List end -->
  <p>
    Further details about the respective cookie providers can be found in the Privacy Preference Center, under the
    respective cookie categories.
  </p>
  <!-- OneTrust Cookies Settings button start -->
  <button mat-raised-button color="primary" (click)="openCookieBanner()" class="banner-button">Cookie Settings</button>
  <!-- OneTrust Cookies Settings button end -->
  <h3 class="number-heading">3. Contact</h3>
  <div class="bold-heading">a. Data processing</div>
  <p>
    If you contact us, the data which you provide in such forms will be transmitted to us and processed. The mandatory
    data that must be filled in for electronic contact via the respective contact form are marked with (*). If you
    provide us with additional data, this is done voluntarily.
  </p>
  <p>If you send us an e-mail, we will process the associated metadata and the content of the message.</p>
  <div class="bold-heading">b. Purpose and legal basis</div>
  <p>
    Your data will be processed to enable us to contact you, to process your request and to provide you with our
    respective services, to prevent misuse of the contact form and to ensure the security of our information technology
    systems.
  </p>
  <p>
    The legal basis for the processing of data classified as being mandatory is Art. 6 para. 1 sentence 1 lit. f GDPR.
    The aforementioned purposes also include a legitimate interest in the processing of the data. If the purpose of the
    contact is the conclusion of a contract, the additional legal basis for the processing is Art. 6 para. 1 sentence 1
    lit. b GDPR. The legal basis for the processing of the data that you have voluntarily provided us with is your
    consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR.
  </p>
  <div class="bold-heading">c. Storage period</div>
  <p>
    The personal data of the person concerned will be deleted or made unavailable as soon as the purpose of the storage
    does no longer apply. Furthermore, data may be stored if this has been provided for by the European or national
    legislator in EU regulations, laws or other regulations to which the person responsible is subject. Data will also
    be deleted or made unavailable when a storage period prescribed by the above-mentioned provisions expires, unless
    there is a need to continue storing the data for the purpose of concluding or fulfilling a contract.
  </p>
  <p>
    In case of a consent, you have the right to revoke such consent at any time, without affecting the lawfulness of the
    processing carried out on the basis of the consent until revocation. To do so, please change the settings in the
    Privacy Preference Center.
  </p>
  <h3 class="number-heading">4. Login</h3>
  <p>For registered users, we process the following personal data in order to provide access to our platform:</p>
  <ul>
    <li>First name, last name</li>
    <li>E-Mail address</li>
    <li>Optional: Company</li>
    <li>Optional: Language (Default English)</li>
  </ul>
  <p>
    We process your personal data as long as those are necessary for providing you access to our platform. The legal
    basis for this processing is Art. 6 Sec. 1 lit. f) GDPR.
  </p>
  <h3 class="number-heading">5. Social media hyperlink</h3>
  <p>
    We have included hyperlinks to the following social media services on our website: LinkedIn, Facebook, Twitter,
    Vimeo, YouTube, Slideshare, flickr and Instagram.
  </p>
  <p>
    These hyperlinks are designed in the form of the corresponding logos and are stored on our own server. This means
    that when our website is loaded for the first time, no data about you as a user are transferred to the respective
    provider. You will be directed to the respective website only if you click on the logos. Further data about you may
    be processed on this website.
  </p>
  <p>We have no influence on this data processing on the respective linked websites.</p>
  <h3 class="letter-heading">C. Are you obliged to provide us with this data</h3>
  <p>
    When you visit our website, the information specified in section B. 1. a. as well as the information from the
    cookies classified as strictly necessary is processed automatically. The transfer of this information is voluntary.
    Without the provision of this personal data, we cannot display the requested page.
  </p>
  <p>If you</p>
  <ul>
    <li>allow us to use cookies that are not classified as strictly necessary,</li>
    <li>
      want to contact us and send us an e-mail or use our contact form (including the necessary reCAPTCHA query) or
    </li>
    <li>want to see embedded YouTube videos</li>
  </ul>
  <p>the transfer of the information is voluntary.</p>
  <p>
    If you contact us, we cannot answer your inquiry in the chosen way without the provision of the personal data
    required in the individual case. As far as cookies are concerned, the lack of consent can lead to a restriction of
    the functionality of the website or parts of it. Embedded videos cannot be played without your consent.
  </p>
  <h3 class="letter-heading">D. To whom do we pass on your personal data?</h3>
  <p>
    Within our company, only persons and departments are granted access to your personal data as far as they need it to
    fulfil the abovementioned purposes.
  </p>
  <p>
    We also involve service providers. These service providers will only act on our instructions and are contractually
    obliged to comply with the applicable data protection requirements.
  </p>
  <p>Otherwise, we will only pass on your data to third parties if:</p>
  <ul>
    <li>you have given your express consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR,</li>
    <li>
      the disclosure pursuant to Art. 6 para. 1 sentence 1 lit. f GDPR is necessary for the assertion, exercise or
      defense of legal claims and there is no reason to assume that you have an overriding interest worthy of protection
      in not disclosing your data,
    </li>
    <li>
      there is a legal obligation for us to pass on the data in accordance with Art. 6 para. 1 sentence 1 lit. c GDPR,
      or
    </li>
    <li>
      this is legally permissible and, in accordance with Art. 6 para. 1 sentence 1 lit. b GDPR, is necessary for the
      fulfillment of contractual relationships with you.
    </li>
  </ul>
  <p>
    A transfer of your personal data to service providers in a third country will only take place if the special
    requirements of Art. 44 ff. GDPR are fulfilled.
  </p>
  <h3 class="letter-heading">E. How do we secure your personal data?</h3>
  <p>
    BASF uses technical and organizational security measures to protect your personal data from accidental or
    intentional manipulation, loss, destruction or access by unauthorized persons. Our security measures are
    continuously revised in line with technological developments.
  </p>
  <h3 class="letter-heading">F. What rights do you have?</h3>
  <p>
    You have certain rights under the General Data Protection Regulation including the right to request a copy of the
    personal information we hold about you, if you request it from us in writing:
  </p>
  <p>
    <b>Right to access:</b> the right to obtain access to your information (if we’re processing it), and certain other
    information (like that provided in this Privacy Policy);
  </p>
  <p>
    <b>Right to correct:</b> if your personal information is inaccurate or incomplete you have the right to have your
    personal information rectified;
  </p>
  <p>
    <b>Right to erasure:</b> this is also known as ‘the right to be forgotten’ and, in simple terms, enables you to
    request the deletion or removal of your information where there’s no compelling reason for us to keep using it. This
    is not a general right to erasure; there are exceptions. For example, we have the right to continue using your
    personal data if such use is necessary for compliance with our legal obligations or for the establishment, exercise
    or defense of legal claims;
  </p>
  <p>
    <b>Right to restriction of processing:</b> the right to suspend the usage of your personal information or limit the
    way in which we can process it. Please note that this right is limited in certain situations: When we are processing
    your personal information that we collected from you with your consent you can only request restriction on the basis
    of: (a) inaccuracy of data; (b) where our processing is unlawful and you don’t want your personal information
    erased; (c) you need it for a legal claim; or (d) if we no longer need to use the data for the purposes for which we
    hold it. When processing is restricted, we can still store your information, but may not use it further. We keep
    lists of people who have asked for restriction of the use of their personal information to make sure the restriction
    is respected in future;
  </p>
  <p>
    <b>Right to data portability:</b> the right to request that we move, copy or transfer (where technically feasible)
    your personal information in a structured, commonly used and machine-readable format, for your own purposes across
    different services;
  </p>
  <p>
    <b>Right to object:</b> the right to object to our processing of your personal information including where we
    process it for our legitimate interests, direct marketing;
  </p>
  <p>
    To exercise your rights, please contact us, for example by e-mail or by post. You will find the contact details in
    section A.
  </p>
  <h3 class="letter-heading">G. Where can you complain?</h3>
  <p>
    You have the right to lodge a complaint with our data protection officer (for contact details see Section A) or with
    a supervisory authority, in particular in the Member State of your habitual residence, place of work or place of the
    alleged infringement.
  </p>
  <p>In either case, you can contact our Lead Data Protection Authority:</p>
  <p>
    State Commissioner for Data Protection and Freedom of Information of Rhineland-Palatinate (Landesbeauftragter für
    den Datenschutz und die Informationsfreiheit Rheinland-Pfalz)
  </p>
  <p>
    Hintere Bleiche 34<br />
    55116 Mainz<br />
    <a class="link" href="https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/"
      >https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/</a
    ><br />
    E-Mail: <span class="link">poststelle@datenschutz.rlp.de</span>
  </p>
  <h3 class="letter-heading">H. Data protection for minors</h3>
  <p>
    This website is intended for persons who are at least 18 years old. If a minor submits personal data via this
    website, we will delete this data and not process it further as soon as we become aware of this fact.
  </p>
</div>
