import * as SidenavActions from '../actions/sidenav.actions';
import { Sidenav } from '../models/sidenav.model';
import { SidenavItem } from '../shared/enums/sidenav-item.enum';

const initialState: Sidenav = { sidenavCollapsed: true, selectedSidenavItem: SidenavItem.COLOR, isColorRoute: false };

/**
 * Reducer for handling sidenav operations
 */
export function reducer(state: Sidenav = initialState, action: SidenavActions.Actions): Sidenav {
  switch (action.type) {
    case SidenavActions.ACTION_TYPES.TOGGLE_SIDENAV_COLLAPSED: {
      return {
        sidenavCollapsed: !state.sidenavCollapsed,
        selectedSidenavItem: state.selectedSidenavItem,
        isColorRoute: state.isColorRoute,
      };
    }
    case SidenavActions.ACTION_TYPES.SET_SELECTED_SIDENAV_ITEM: {
      return {
        sidenavCollapsed: state.sidenavCollapsed,
        selectedSidenavItem: action.payload,
        isColorRoute: state.isColorRoute,
      };
    }
    case SidenavActions.ACTION_TYPES.SET_IS_COLOR_ROUTE: {
      return {
        sidenavCollapsed: state.sidenavCollapsed,
        selectedSidenavItem: state.selectedSidenavItem,
        isColorRoute: action.payload,
      };
    }

    default:
      return state;
  }
}
