
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ISearchResult } from '../../models/search.model';

@Injectable()
export class SearchService {

  private baseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = environment.apiBasePath + environment.apiVersionPath;
  }

  /**
   * Gets search results by query parameter
   */
  public getSearchResults(parameters: { query: string }): Observable<ISearchResult[]> {
    if (!parameters.query || parameters.query.length < 2) {
      // Returns empty observable if search term doesn't meet requirements
      return EMPTY;
    }

    const apiEndpoint = `${this.baseUrl}/${environment.search.all}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    let params = new HttpParams();
    params = params.append('name', parameters.query);

    return this.http.post(apiEndpoint, null, { headers, params }).pipe(
      map(response => response as ISearchResult[]),
      catchError((error: HttpErrorResponse) => observableThrowError(error || error.error || 'Server error')));
  }
}
