<div class="container">
  <div class="flex-row gap-10 flex-100 is-full-height">

    <div class="flex-column flex-7 vertical-center">
        <button (click)="goBack()" class="navigation-arrow-button">
          <colimo-icons
                  [icon]="'back'"
                  [width]="'35px'"
                  [height]="'35px'"
                  class="arrow-icon"
          ></colimo-icons>
        </button>
    </div>

    <div class="flex-column flex-55 vertical-center">
      <h3 class="project-name" [ngClass]="isMobile === true ? 'mobile-project-name' : ''">{{project.name}}</h3>
      <div class="flex-row project-info">
        <colimo-color [gradient]="project.gradient" [imgJpeg]="project.imgJpeg" [size]="'small'"></colimo-color>
        <div class="flex-row-lt-sm-column">
          <div class="project-products">
            <h4 class="batch-number">{{project.batchNumber}}</h4>
            <div *ngFor="let product of project.products" class="product">
              <span class="product-name">
                {{product.name}}
              </span>
              <span *ngIf="product.type !== 'BODY_BUMPER'"
                    [ngClass]="{'is-bumper' : product.type === 'BUMPER', 'is-body' : product.type === 'BODY'}"
                    class="product-type">
                {{product.type}}
              </span>
              <ng-container *ngIf="product.type === 'BODY_BUMPER'">
                <span class="body-bumper is-body">{{ 'dashboard.body' | translate }}</span>
                <span class="body-bumper is-bumper">{{ 'dashboard.bumper' | translate }}</span>
              </ng-container>
              <span *ngIf="showProductSelection">
                <mat-checkbox (change)="productSelectionChanged($event, product.name)" color="primary" [checked]="!productExluded(product.name)">
                </mat-checkbox>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="project.kpiLine" class="flex-column flex-30 vertical-center margin-top-0">
      <h3 class="line-name">{{project.kpiLine | lineName}}</h3>
      <div class="kpi-wrapper">
        <colimo-kpi [kpis]="project.kpiLine.kpis"></colimo-kpi>
      </div>
    </div>

    <div *ngIf="project.inDashboard !== undefined" class="flex-column flex-20 vertical-center">
      <colimo-dashboard-controls
              [isHeader]="true"
              [resultId]="project.id"
              [isInDashboard]="project.inDashboard">
      </colimo-dashboard-controls>
    </div>

  </div>
</div>
