import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { KpiReport } from '../../models/kpi-report.model';

@Injectable({
  providedIn: 'root',
})
export class KpiReportService {
  private baseUrl: string;

  constructor(public http: HttpClient, private datePipe: DatePipe) {
    this.baseUrl = environment.apiBasePath + environment.apiUserPath;
  }

  public getKpiReport(
    customerId: number,
    lines: string[],
    products: string[],
    kpis: string[],
    startDate: Date,
    endDate: Date,
  ): Observable<KpiReport[]> {
    // Returns empty observable if mandatory parameters missing
    if (
      customerId == null ||
      lines == null ||
      products == null ||
      kpis == null ||
      startDate == null ||
      endDate == null
    ) {
      return EMPTY;
    }

    const apiEndpoint = this.buildUrl(customerId, lines, products, kpis, startDate, endDate);

    return this.http.get(apiEndpoint).pipe(
      map(response => response as KpiReport[]),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getKpiReportExcelExport(
    customerId: number,
    lines: string[],
    products: string[],
    kpis: string[],
    startDate: Date,
    endDate: Date,
  ): Observable<Blob> {
    // Returns empty observable if mandatory parameters missing
    if (
      customerId == null ||
      lines == null ||
      products == null ||
      kpis == null ||
      startDate == null ||
      endDate == null
    ) {
      return EMPTY;
    }

    const apiEndpoint = this.buildUrl(customerId, lines, products, kpis, startDate, endDate);

    return this.http
      .get(apiEndpoint, {
        headers: { ['Accept']: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        responseType: 'blob',
      })
      .pipe(
        map(response => new Blob([response])),
        catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
      );
  }

  private buildUrl(
    customerId: number,
    lines: string[],
    products: string[],
    kpis: string[],
    startDate: Date,
    endDate: Date,
  ): string {
    return `${this.baseUrl}/${environment.reports.kpiReport
      .replace('{customerId}', customerId.toString())
      .replace('{lines}', lines.toString())
      .replace('{products}', products.toString())
      .replace('{kpis}', kpis.toString())
      .replace('{startDate}', this.datePipe.transform(startDate, 'yyyy-MM-dd'))
      .replace('{endDate}', this.datePipe.transform(endDate, 'yyyy-MM-dd'))}`;
  }
}
