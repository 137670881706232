<div *ngIf="!isForecast" class="flex-row gap-5 gap-lt-sm-5 flex-lt-md-10 start-end padding-left">
  <div class="flex-20 flexlayout">
    <div *ngIf="kpis.CPI">
      <div class="flex-row justify-start align-center" [ngClass]="{ 'trend-button-alignment': forecastAvailable }">
        <h4 class="kpi-name" [ngClass]="{ 'aligned-kpi': !forecastAvailable }">CPI</h4>
        <button *ngIf="forecastAvailable" mat-mini-fab (click)="openTrendAnalysisDialog()" class="dialog-button margin-top">
          <div
            *ngIf="forecastAvailable"
            matTooltipClass="weekly-trend-tooltip"
            matTooltipPosition="above"
            [matTooltip]="('trendAnalysis.weeklyTrend' | translate) + (trendAnalysisResult.prediction.weeklyTrend | percent)">
            <mat-icon class="{{trendIcon.cssClass}}">{{trendIcon.icon}}</mat-icon>
          </div>
        </button>
      </div>
      <button class="label {{ 'label-' | kpiLabel: kpis.CPI.kpiColor }}" (click)="kpiSelected(kpiEnum.CPI)">
        &lt;Q{{ kpis.CPI.kpiValue }}&gt;
      </button>
    </div>
  </div>

  <div class="flex-20 flexlayout">
    <div *ngIf="kpis.BPI">
      <h4 class="kpi-name aligned-kpi">TPI</h4>
      <button class="label {{ 'label-' | kpiLabel: kpis.BPI.kpiColor }}" (click)="kpiSelected(kpiEnum.BPI)">
        &lt;Q{{ kpis.BPI.kpiValue }}&gt;
      </button>
    </div>
  </div>

  <div class="flex-20 flexlayout">
    <div *ngIf="kpis.LPI">
      <h4 class="kpi-name aligned-kpi">LPI</h4>
      <button class="label {{ 'label-' | kpiLabel: kpis.LPI.kpiColor }}" (click)="kpiSelected(kpiEnum.LPI)">
        &lt;Q{{ kpis.LPI.kpiValue }}&gt;
      </button>
    </div>
  </div>

  <div class="flex-20 flexlayout">
    <div *ngIf="kpis.API">
      <h4 class="kpi-name aligned-kpi">API</h4>
      <button class="label {{ 'label-' | kpiLabel: kpis.API.kpiColor }}" (click)="kpiSelected(kpiEnum.API)">
        &lt;Q{{ kpis.API.kpiValue }}&gt;
      </button>
    </div>
  </div>

  <div class="flex-20 flexlayout">
    <div *ngIf="kpis.CHI">
      <h4 class="kpi-name aligned-kpi">CHI</h4>
      <button class="label {{ 'label-' | kpiLabel: kpis.CHI.kpiColor }}" (click)="kpiSelected(kpiEnum.CHI)">
        &lt;Q{{ kpis.CHI.kpiValue }}&gt;
      </button>
    </div>
  </div>
</div>
