import { Action } from '@ngrx/store';
import { ModelDashboard } from '../models/model-dashboard.model';
import { type } from '../util';

/**
 * Action for assigning models to the store
 */

export const ACTION_TYPES = {
  CREATE_ITEMS: type<'[ModelDashboard] Create items'>('[ModelDashboard] Create items'),
};

export class CreateItemsAction implements Action {
  public readonly type = ACTION_TYPES.CREATE_ITEMS;
  constructor(public readonly payload: ModelDashboard[]) {}
}

export type Actions = CreateItemsAction;
