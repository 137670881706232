import { Action } from '@ngrx/store';
import { SidenavItem } from '../shared/enums/sidenav-item.enum';
import { type } from '../util';

export const ACTION_TYPES = {
  TOGGLE_SIDENAV_COLLAPSED: type<'[Sidenav] Toggle sidenav collapsed'>('[Sidenav] Toggle sidenav collapsed'),
  SET_SELECTED_SIDENAV_ITEM: type<'[Sidenav] Set selected sidenav item'>('[Sidenav] Set selected sidenav item'),
  SET_IS_COLOR_ROUTE: type<'[Sidenav] Set is color route'>('[Sidenav] Set is color route'),
};

export class ToggleSidenavCollapsedAction implements Action {
  public readonly type = ACTION_TYPES.TOGGLE_SIDENAV_COLLAPSED;

  constructor(public readonly payload: boolean) {}
}

export class SetSelectedSidenavItemAction implements Action {
  public readonly type = ACTION_TYPES.SET_SELECTED_SIDENAV_ITEM;

  constructor(public readonly payload: SidenavItem) {}
}

export class SetIsColorRoute implements Action {
  public readonly type = ACTION_TYPES.SET_IS_COLOR_ROUTE;

  constructor(public readonly payload: boolean) {}
}

export type Actions = ToggleSidenavCollapsedAction | SetSelectedSidenavItemAction | SetIsColorRoute;
