<div [ngClass]="isMobile === true ? 'contact-wrapper-mobil' : 'contact-wrapper'">
  <colimo-page-header [heading]="'sidenav.contactPage'"> </colimo-page-header>
  <h1>{{ 'sidenav.contactPage' | translate }}</h1>
  <p>
    {{ 'contactPage.contactUs' | translate }}
    <a href="mailto:{{ contactEmail }}?Subject=CoLiMo%20request" target="_blank">
      <mat-icon class="material-icons-outlined link-external">open_in_new</mat-icon>
      {{ contactEmail }}
    </a>
  </p>
</div>
