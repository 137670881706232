/* eslint-disable max-lines */
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { KeyText } from '../../../models/key-text.model';
import { ColorWithProducts, Product } from '../../../models/viewmodels/color-with-products.viewmodel';
import { LocationWithLines } from '../../../models/viewmodels/location-with-lines.viewmodel';
import { ColorProductsService } from '../../../services/color-products/color-products.service';
import { KeyTextService } from '../../../services/key-text/key-text.service';
import { KpiReportFilterDataTransformerService } from '../../../services/kpi-report-filter-data-transformer/kpi-report-filter-data-transformer.service';
import { KpiReportFilterToggleService } from '../../../services/kpi-report-filter-toggle/kpi-report-filter-toggle.service';
import { KpisService } from '../../../services/kpis/kpis.service';
import { LocationLinesService } from '../../../services/location-lines/location-lines.service';

@Component({
  selector: 'colimo-kpi-report-filter',
  templateUrl: './kpi-report-filter.component.html',
  styleUrls: ['./kpi-report-filter.component.scss'],
})
export class KpiReportFilterComponent implements OnInit, OnDestroy {
  @Output()
  public selectedCustomerChanged: EventEmitter<KeyText> = new EventEmitter();

  @Output()
  public selectedLinesChanged: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public selectedProductsChanged: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public selectedKpisChanged: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public selectedStartDateChanged: EventEmitter<Date> = new EventEmitter();

  @Output()
  public selectedEndDateChanged: EventEmitter<Date> = new EventEmitter();

  public customers: KeyText[];
  public locationWithLines: LocationWithLines[];
  public colorsWithProducts: ColorWithProducts[];
  public kpis: string[];
  public startDate: Date;
  public endDate: Date;

  public customerSelected: KeyText;
  public kpiSelected: boolean[];

  public selectedLinesValues: string[];
  public selectedProductsValues: string[];

  public bsConfig: Partial<BsDatepickerConfig>;

  private customersSubscription: Subscription;
  private locationWithLinesSubscription: Subscription;
  private colorWithProductsSubscription: Subscription;

  constructor(
    private keyTextService: KeyTextService,
    private locationLinesService: LocationLinesService,
    private colorProductsService: ColorProductsService,
    private kpisService: KpisService,
    private kpiReportFilterToggleService: KpiReportFilterToggleService,
    private kpiReportFilterDataTransformerService: KpiReportFilterDataTransformerService,
  ) {}

  public ngOnInit(): void {
    this.loadCustomers();
    this.loadKpis();
    this.initStartDate();
    this.initEndDate();
    this.bsConfig = { containerClass: 'theme-dark-blue' };
  }

  public ngOnDestroy(): void {
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }
    if (this.locationWithLinesSubscription) {
      this.locationWithLinesSubscription.unsubscribe();
    }
    if (this.colorWithProductsSubscription) {
      this.colorWithProductsSubscription.unsubscribe();
    }
  }

  public toggleProductsDropdown(): void {
    this.kpiReportFilterToggleService.toggleDropdownByClassName('products-dropdown');
  }

  public closeProductsDropdown(): void {
    this.kpiReportFilterToggleService.closeDropdownByClassName('products-dropdown');
  }

  public toggleLinesDropdown(): void {
    this.kpiReportFilterToggleService.toggleDropdownByClassName('lines-dropdown');
  }

  public closeLinesDropdown(): void {
    this.kpiReportFilterToggleService.closeDropdownByClassName('lines-dropdown');
  }

  public onCustomerChanged(changedCustomerKeyEvent: Event): void {
    const changedCustomerKey = (changedCustomerKeyEvent.target as HTMLSelectElement).value;
    this.customerSelected = this.customers.find((customer: KeyText) => customer.key === changedCustomerKey);
    this.selectedCustomerChanged.emit(this.customerSelected);
    this.loadLocationWithLines();
    this.loadColorsWithProducts();
  }

  public onLocationChanged(location: LocationWithLines): void {
    this.kpiReportFilterToggleService.toggleCorrespondingLines(location);
    this.emitSelectedLines();
  }

  public onLineChanged(changedLineIndex: number, correspondingLocation: LocationWithLines): void {
    this.kpiReportFilterToggleService.toggleCorrespondingLocation(changedLineIndex, correspondingLocation);
    this.emitSelectedLines();
  }

  public onKpiChanged(changedKpiIndex: number): void {
    this.emitSelectedKpis();
  }

  public onColorChanged(color: ColorWithProducts): void {
    this.kpiReportFilterToggleService.toggleCorrespondingProducts(color);
    this.emitSelectedProducts();
  }

  public onProductChanged(product: Product): void {
    this.kpiReportFilterToggleService.toggleCorrespondingColor(product, this.colorsWithProducts);
    this.emitSelectedProducts();
  }

  public onStartDateChanged(dateSelected: Date): void {
    this.startDate = dateSelected;
    this.selectedStartDateChanged.emit(this.startDate);
  }

  public onEndDateChanged(dateSelected: Date): void {
    this.endDate = dateSelected;
    this.selectedEndDateChanged.emit(this.endDate);
  }

  private emitSelectedLines(): void {
    this.selectedLinesValues = this.kpiReportFilterDataTransformerService.getSelectedLines(this.locationWithLines);
    this.selectedLinesChanged.emit(this.selectedLinesValues);
  }

  private emitSelectedProducts(): void {
    this.selectedProductsValues = this.kpiReportFilterDataTransformerService.getSelectedProducts(
      this.colorsWithProducts,
    );
    this.selectedProductsChanged.emit(this.selectedProductsValues);
  }

  private emitSelectedKpis(): void {
    const selectedKpis = this.kpiReportFilterDataTransformerService.getSelectedKpis(this.kpiSelected, this.kpis);
    this.selectedKpisChanged.emit(selectedKpis);
  }

  private initStartDate(): void {
    this.startDate = new Date();
    // default period: a year
    this.startDate.setDate(this.startDate.getDate() - 360);
    this.selectedStartDateChanged.emit(this.startDate);
  }

  private initEndDate(): void {
    this.endDate = new Date();
    this.selectedEndDateChanged.emit(this.endDate);
  }

  private loadKpis(): void {
    this.kpis = this.kpisService.getKpis();
    this.kpiSelected = this.kpiReportFilterDataTransformerService.createSelectedKpisArray(this.kpis);
    this.emitSelectedKpis();
  }

  private loadCustomers(): void {
    this.customersSubscription = this.keyTextService.getCustomers().subscribe((customers: KeyText[]) => {
      this.customers = customers.sort((a: KeyText, b: KeyText) => a.text.localeCompare(b.text));
      if (this.customers && this.customers.length !== 0) {
        this.customerSelected = this.customers[0];
        this.selectedCustomerChanged.emit(this.customerSelected);
        this.loadLocationWithLines();
        this.loadColorsWithProducts();
      } else {
        // We need to emit a null value, otherwise the loading indicator in the parent component won't disappear
        this.selectedCustomerChanged.emit(null);
      }
    });
  }

  private loadLocationWithLines(): void {
    this.locationWithLinesSubscription = this.locationLinesService
      .getLocationWithLinesByCustomer(this.customerSelected)
      .subscribe((locationWithLines: LocationWithLines[]) => {
        this.locationWithLines = locationWithLines.sort((a: LocationWithLines, b: LocationWithLines) => a.locationName.localeCompare(b.locationName));
        this.emitSelectedLines();
      });
  }

  private loadColorsWithProducts(): void {
    this.colorWithProductsSubscription = this.colorProductsService
      .getColorProductsByCustomer(Number(this.customerSelected.key))
      .subscribe((colorWithProducts: ColorWithProducts[]) => {
        this.colorsWithProducts = colorWithProducts.sort((a: ColorWithProducts, b: ColorWithProducts) => a.colorName.localeCompare(b.colorName));
        this.emitSelectedProducts();
      });
  }
}
