import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ToggleSidenavCollapsedAction } from 'src/app/actions/sidenav.actions';
import { MobileCheck } from 'src/app/services/mobile-check/mobile-check.service';
import * as fromRoot from '../../../reducers';
import {AlertCount} from '../../../models/alert-count';
import {NotificationService} from '../../../services/notification/notification.service';

@Component({
  selector: 'colimo-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() public heading: string;

  public isMobile: boolean;
  public alertCount: AlertCount;

  private stop$: Subject<boolean> = new Subject();

  constructor(private mobileCheck: MobileCheck,
              private store: Store<fromRoot.State>,
              private notificationService: NotificationService) {}

  public ngOnInit(): void {
    this.mobileCheck
      .getScreenSize()
      .pipe(takeUntil(this.stop$))
      .subscribe((deviceInfo) => {
        this.isMobile = deviceInfo.isMobile;
      });
    this.notificationService.getAlertCountObservable().subscribe({
      next: (count: AlertCount) => {
        this.alertCount = count;
      }
    });
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public openSidenav(): void {
    this.store.dispatch(new ToggleSidenavCollapsedAction(true));
  }
}
