<div class="status-table mb-1">
  <table>
    <thead>
      <th>{{ 'reports.filterLabel.measurement' | translate }}</th>
      <th>{{ 'dashboard.geometries' | translate }}</th>
      <th>{{ differenceTypeDL | labelByChartType: tolerancesOn }}</th>
      <th>%</th>
      <th>{{ differenceTypeDA | labelByChartType: tolerancesOn }}</th>
      <th>%</th>
      <th>{{ differenceTypeDB | labelByChartType: tolerancesOn }}</th>
      <th>%</th>
      <th>{{ differenceTypeDE | labelByChartType: tolerancesOn }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let componentStatus of componentsStatus">
        <tr *ngFor="let angle of availableAngles; let i = index">
          <td *ngIf="i === 0" [rowSpan]="availableAngles.length" class="component">
            <span>{{ componentStatus.component }}</span>
          </td>
          <ng-container *ngIf="componentStatus.deltaValues | deltaValueOfAngle: angle as deltaValue">
            <td>{{ angle }}°</td>
            <td>{{ deltaValue.dL | number: '1.2-2' }}</td>
            <td class="probability" [ngClass]="[deltaValue.probabL | probabilityCssClass]">
              {{ deltaValue.probabL | percent }}
            </td>
            <td>{{ deltaValue.dA | number: '1.2-2' }}</td>
            <td class="probability" [ngClass]="[deltaValue.probabA | probabilityCssClass]">
              {{ deltaValue.probabA | percent }}
            </td>
            <td>{{ deltaValue.dB | number: '1.2-2' }}</td>
            <td class="probability" [ngClass]="[deltaValue.probabB | probabilityCssClass]">
              {{ deltaValue.probabB | percent }}
            </td>
            <td>{{ deltaValue.dE | number: '1.2-2' }}</td>
          </ng-container>
        </tr>
        <tr class="empty-row"></tr>
      </ng-container>
    </tbody>
  </table>
</div>
