<mat-card appearance="outlined" class="mat-elevation-z6 flex-column padding-16 box-shadow">
  <mat-card-title class="section-heading">{{ 'reports.filterLabel.statistics' | translate }}</mat-card-title>
  <div class="flex-column">
    <table mat-table [dataSource]="car.componentsStatus" class="flex-1 export-table">
      <ng-container matColumnDef="span">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"></th>
      </ng-container>

      <ng-container matColumnDef="dE">
        <th class="de-cell" mat-header-cell *matHeaderCellDef [attr.colspan]="availableAngles.length">
          {{ 'D_DE' | labelByChartType: filterOptions.tolerancesOn }}
        </th>
      </ng-container>

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.numberAbbr' | translate }}</th>
        <td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="component">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.component' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ componentTranslations[element.component] ? componentTranslations[element.component]  : element.component }}</td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.position' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ positionTranslations[element.position] ? positionTranslations[element.position] : element.position }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'reports.reportTable.status' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon [ngClass]="element | componentStatusCssClass: filterOptions.tolerancesOn : car?.defaultShowDe" class="status-circle">
            lens
          </mat-icon>
        </td>
      </ng-container>

      <ng-container *ngIf="availableAngles && availableAngles.length !== 0">
        <ng-container [matColumnDef]="angle" *ngFor="let angle of availableAngles">
          <th mat-header-cell *matHeaderCellDef>{{ angle }}°</th>
          <td mat-cell *matCellDef="let element">
            {{ (element.deltaValues | deltaValueOfAngle: +angle).dE | number: '1.2-2' }}
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsDE"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngStyle]="selectedComponentStatus === row ? { filter: 'brightness(90%)' } : {}"
      ></tr>
    </table>
  </div>
</mat-card>
