<mat-dialog-content>
  <div class="flex-column gap-10">
    <div class=" flex-row gap-5 start-center">
      <span>{{ 'trendAnalysis.currentValue' | translate }} </span
      ><button class="label {{ 'label-' | kpiLabel: data.kpiLine.kpis.CPI.kpiColor }}" disabled>
        &lt;Q{{ data.kpiLine.kpis.CPI.kpiValue }}&gt;
      </button>
    </div>
    <div
      *ngIf="trendAnalysisResult?.prediction && trendAnalysisResult?.prediction?.tendency"
      class="flex-row start-center"
    >
      <span *ngIf="daysUntilNewKpi">
        {{ 'trendAnalysis.prediction1' | translate }}
        <b> {{ trendAnalysisResult.prediction.futureKpiValue }} </b> {{ 'trendAnalysis.prediction2' | translate }}
        <b *ngIf="daysUntilNewKpi <= maxPredictionDays"> {{ daysUntilNewKpi }}</b>
        <b *ngIf="daysUntilNewKpi > maxPredictionDays"> {{ maxPredictionDays }}+</b>
        {{ 'trendAnalysis.prediction3' | translate }}
      </span>
      <ng-container [ngSwitch]="trendAnalysisResult.prediction.tendency">
        {{ 'trendAnalysis.tendency' | translate }}:
        <mat-icon *ngSwitchCase="'WILL_IMPROVE'" class="up-arrow"> trending_up </mat-icon>
        <mat-icon *ngSwitchCase="'WILL_STAY_THE_SAME'" class="flat-arrow"> trending_flat </mat-icon>
        <mat-icon *ngSwitchCase="'WILL_WORSEN'" class="down-arrow"> trending_down </mat-icon>
      </ng-container>
    </div>
    <span *ngIf="!isLoading && !trendAnalysisResult?.prediction?.ratings">{{
      'trendAnalysis.noForecast' | translate
    }}</span>
    <div [hidden]="isLoading">
      <ng-container *ngIf="chartDataAvailable">
        <h3 mat-dialog-title class="margins">{{ 'trendAnalysis.forecast' | translate }}</h3>
        <div style="width: 320px; height: 320px">
            <canvas #chartCanvasTrends width="320" height="320"></canvas>
        </div>
      </ng-container>
    </div>
  </div>
  <colimo-loader *ngIf="isLoading" [isRelative]="true" style="width: 50%; height: 50%;"></colimo-loader>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">Ok</button>
</mat-dialog-actions>
